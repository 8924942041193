import { ReportChapterObject } from '../../base';

export const technicalImplementationsRefactoring: Omit<ReportChapterObject, 'number'> = {
    id: 'technical-implemetations-refactoring',
    caption: `Playbook: Refactoring Legacy Code`,
    title: `AI Enhances Accuracy and Efficiency in Code Modernization`,
    description: `Modernizing legacy code is most effective when combining the precision of AST-based transformations with the flexibility of LLM-driven approaches. Organizations like Slack and Airbnb have shown that hybrid workflows, structured feedback loops, and incremental test generation can significantly improve accuracy, efficiency, and scalability—especially when supported by privacy-conscious infrastructure.`,
    sections: [
        {
            id: '1',
            title: `Hybrid Models Balance Accuracy, Flexibility, and Scale in AI Code Transformation`,
            caption: `Adoption`,
            content: [
                {
                    kind: 'text',
                    text: `Multiple organizations have successfully integrated generative AI (GenAI) into their development workflows to modernize legacy code and handle large-scale code migrations. Two primary paradigms have emerged, each with distinct strengths and limitations. Traditional rule-based transformations rely on abstract syntax tree (AST) parsing to systematically refactor or migrate code. This method offers deterministic conversions, making it effective for highly structured or repetitive changes, but it requires extensive and carefully maintained rule sets, which can become difficult to manage at scale.`,
                },
                {
                    kind: 'text',
                    text: `On the other hand, large language model (LLM)–driven conversions use AI models like ChatGPT or Claude to generate or refactor code with impressive speed and contextual understanding. While these models can overcome some limitations of AST-based methods, they are also prone to hallucinations or inconsistent results if prompts are not carefully crafted.`,
                },
                {
                    kind: 'text',
                    text: `To address the trade-offs of both approaches, many organizations are now adopting hybrid models that combine AST-driven tools with LLMs. This gives teams more control over how each file or function is processed and validated, striking a balance between accuracy and flexibility. As one team at Slack noted, "Our attempts to create a universal prompt for all requests were met with challenges, and the standalone application of AI failed to provide the consistent results we sought."`,
                },
            ],
            keywords: [
                {
                    id: '2',
                    text: `Modernization`,
                    description: null,
                },
                {
                    id: '3',
                    text: `Scalability`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '2',
            title: `ASTs Provide a Reliable but Limited Foundation for Code Migration`,
            caption: `AST`,
            content: [
                {
                    kind: 'text',
                    text: `Organizations like Slack initially invested in AST-based codemods to manage large-scale migrations, such as transitioning from Enzyme to React Testing Library (RTL). Abstract Syntax Trees (ASTs) allow rule-based refactoring by traversing code as a structured tree of nodes. This approach is especially effective for well-defined changes, like renaming methods or updating import statements. Slack, for example, began by targeting the 10 most-used Enzyme methods before scaling up to cover all 65.`,
                },
                {
                    kind: 'text',
                    text: `However, as the scope of transformation grew, so did the complexity. Each new method or variation required increasingly specific logic, making the ruleset difficult to maintain. More importantly, ASTs lacked the contextual awareness needed to understand rendered DOM structures or the intent behind specific tests. As one Slack engineer noted, "One important requirement for our conversion was achieving 100%-correct transformations… AST lacks the capability to incorporate contextual information such as the rendered component DOM."`,
                },
                {
                    kind: 'text',
                    text: `Despite these limitations, AST transformations remain a powerful tool. They offer deterministic, low-risk changes and can serve as a structured first pass—handling simpler tasks before handing off more nuanced cases to LLMs.`,
                },
            ],
            keywords: [
                {
                    id: '1',
                    text: `AST`,
                    description: null,
                },
                {
                    id: '2',
                    text: `Refactoring`,
                    description: null,
                },
                {
                    id: '3',
                    text: `Code Migration`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '3',
            title: `Structured Inputs and Feedback Loops Improve LLM Code Conversion`,
            caption: `LLM Conversion`,
            content: [
                {
                    kind: 'text',
                    text: `Organizations like Slack and Airbnb have integrated large language models (LLMs) such as OpenAI and Claude to support code conversion efforts. These models are particularly effective when paired with well-crafted prompts and structured context. Airbnb, for instance, improved accuracy by including detailed inputs like source code, test files, validation errors, and common solutions. Poorly structured prompts, by contrast, often led to inconsistent or incorrect results.`,
                },
                {
                    kind: 'text',
                    text: `To improve reliability, teams use iterative feedback loops—feeding compilation or test errors back into the LLM to refine the output. This "trial-and-error" approach, sometimes involving multiple retries and dynamic prompts, proved successful at scale. As Airbnb described it, "The most effective route to improve outcomes was simply brute force: retry steps multiple times until they passed or we reached a limit."`,
                },
                {
                    kind: 'text',
                    text: `Slack further enhanced LLM performance by capturing and feeding the rendered DOM tree into the model. This added context reduced hallucinations and improved alignment with React Testing Library (RTL) conventions. As noted, automating simpler conversions and annotating complex ones helped minimize incorrect or nonsensical outputs.`,
                },
            ],
            keywords: [
                {
                    id: '1',
                    text: `Prompting`,
                    description: null,
                },
                {
                    id: '2',
                    text: `Feedback Loops`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '4',
            title: `Hybrid Models Combine Structure and Context for More Accurate Conversion`,
            caption: `AST/LLM integration`,
            content: [
                {
                    kind: 'text',
                    text: `A highly effective strategy adopted by teams like Slack is to combine deterministic AST transformations with the flexible reasoning capabilities of LLMs. In this hybrid model, the AST engine first handles the simpler, mechanical changes—such as renaming methods, updating imports, or adding test stubs. During this pre-processing phase, code is also annotated with comments and placeholders to flag areas that require deeper, context-aware transformation.`,
                },
                {
                    kind: 'text',
                    text: `These annotated code segments are then passed into structured LLM prompts. Because the model sees both the newly formed AST structure and the legacy logic, it can reason more accurately about the required changes. This prompt enhancement bridges the gap between static rule-based methods and adaptive AI generation.`,
                },
                {
                    kind: 'text',
                    text: `Finally, automated post-processing steps validate that the updated code compiles, adheres to style guides, and passes all relevant tests. If issues are detected, the feedback is sent back to the LLM to refine its output. As Slack noted, embedding AST outputs and in-code comments directly into LLM prompts significantly reduced hallucinations and improved conversion accuracy.`,
                },
            ],
            keywords: [
                {
                    id: '1',
                    text: `Validation`,
                    description: null,
                },
                {
                    id: '2',
                    text: `LLM`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '5',
            title: `Scalable Pipelines Improve Speed and Reliability of Code Transformation`,
            caption: `Reliability`,
            content: [
                {
                    kind: 'text',
                    text: `Organizations like Airbnb and Slack have scaled hybrid AI code conversion by operationalizing their pipelines using state-machine-style flows. In this approach, each file moves through a defined sequence of stages—such as parsing, transformation, and validation. The pipeline enforces strict transitions, only allowing files to progress if the current step completes successfully, ensuring broken or incomplete code doesn't propagate.`,
                },
                {
                    kind: 'text',
                    text: `Organizations like Airbnb and Slack have scaled hybrid AI code conversion by operationalizing their pipelines using state-machine-style flows. In this approach, each file moves through a defined sequence of stages—such as parsing, transformation, and validation. The pipeline enforces strict transitions, only allowing files to progress if the current step completes successfully, ensuring broken or incomplete code doesn't propagate.`,
                },
                {
                    kind: 'text',
                    text: `To support both scale and flexibility, Slack runs nightly batch jobs that process hundreds of files while also enabling developers to request on-demand conversions that complete in just a few minutes. This combination of automated bulk processing and responsive tooling makes the entire migration and maintenance workflow faster and more reliable.`,
                },
            ],
            keywords: [
                {
                    id: '1',
                    text: `Automation`,
                    description: null,
                },
                {
                    id: '2',
                    text: `Validation`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '6',
            title: `LLMs Enable Incremental Testing, Refactoring, and Documentation`,
            caption: `Unit Testing`,
            content: [
                {
                    kind: 'text',
                    text: `A growing use case for LLMs is the automated generation and refinement of unit tests, often paired with incremental code refactoring. In this workflow, codebases are divided at the class or module level, allowing each unit to be processed independently. This approach keeps prompts within token limits and ensures a more focused, manageable scope.`,
                },
                {
                    kind: 'text',
                    text: `When generated tests fail—either due to compilation errors or runtime issues—the system feeds error messages back to the LLM. The model then produces updated tests in an iterative correction loop, continuing until the output meets predefined quality thresholds.`,
                },
                {
                    kind: 'text',
                    text: `Beyond test creation, LLMs assist in refactoring code, particularly in high-complexity areas. Suggestions often involve splitting large methods or simplifying logic to reduce cyclomatic complexity. These changes are then validated using static code analysis tools like checkstyle or linters to ensure compliance with quality standards.`,
                },
                {
                    kind: 'text',
                    text: `In parallel, a "document AI agent" can automatically generate domain-specific documentation for refactored code. This adds interpretability and reduces onboarding time by explaining functionality in clear, business-relevant language.`,
                },
            ],
            keywords: [
                {
                    id: '1',
                    text: `Testing`,
                    description: null,
                },
                {
                    id: '2',
                    text: `Refactoring`,
                    description: null,
                },
                {
                    id: '3',
                    text: `Documentation`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '7',
            title: `Privacy-First Deployments Safeguard AI Workflows`,
            caption: `Privacy`,
            content: [
                {
                    kind: 'text',
                    text: `While AI-driven code transformations offer significant benefits, they also raise important security and infrastructure considerations. To mitigate risks, many organizations run their AI workflows entirely within private infrastructure. Whether through internal endpoints or trusted vendor partnerships, companies like Slack and Airbnb ensure their code remains within secure, compliant environments.`,
                },
                {
                    kind: 'text',
                    text: `For added protection and efficiency, some teams opt for lightweight, on-device deployments—often using local GPUs. This setup not only reduces privacy concerns but also keeps operational costs low. In large-scale settings, containerized deployments with strict resource isolation help maintain control while scaling safely.`,
                },
                {
                    kind: 'text',
                    text: `As one developer put it, "It runs on my laptop… there is completely zero problems with data privacy because we don't send it [offsite]."`,
                },
            ],
            keywords: [
                {
                    id: '1',
                    text: `Security`,
                    description: null,
                },
                {
                    id: '2',
                    text: `Infrastructure`,
                    description: null,
                },
                {
                    id: '3',
                    text: `On-Premise`,
                    description: null,
                },
            ],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
    ],
};
