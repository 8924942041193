import React from 'react';
import { RewardRootController, RewardRootLoader } from './rewardRootInterface';
import { RewardRootContainerProps } from './rewardRootProps';
import { useLocation, useNavigate } from 'react-router';
import { RewardContainerBaseConfig } from '../../base';
import { RewardErrorContainerProps } from '../common';
import { z } from 'zod';

export function creatRewardRootContainer(
    config: RewardContainerBaseConfig,
    loader: RewardRootLoader,
    controller: RewardRootController
): React.FC<RewardRootContainerProps> {
    const {
        repository,
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: {
            root: { useContext },
        },
    } = config;

    const UrlSchema = z.object({ token: z.string().optional() });

    return ({ children, ...containerProps }) => {
        const options = useOptions(UrlSchema);
        const context = useContext();
        const mutation = {
            profile: {
                create: repository.reward.profile.useCreate(context),
            },
        };
        const data = loader.useLoad(context, {
            token: options.token ?? null,
            mutation: {
                profile: mutation.profile.create,
            },
        });
        const props = controller.useProps(context, data, {
            navigation: {
                navigate: useNavigate(),
                location: useLocation(),
            },
        });
        return <>{children}</>;
    };
}
