import { chain } from 'lodash';
import pluralize from 'pluralize';
import { AiOutlineUser } from 'react-icons/ai';
import { Graphics } from '../../../../../config/svg';
import { ReportingControllerBaseConfig } from '../../../base';
import { ReportInstanceDetailController } from './reportDetailInterface';
import { ReportDetailViewProps, ReportSourceItemProps } from './reportDetailProps';
import { buildReportItemProps } from '../item';
import { ReportChapterViewProps } from '../../chapter';
import { ReportAggregateViewProps } from '../aggregate';

export function createReportDetailController(
    config: ReportingControllerBaseConfig
): ReportInstanceDetailController {
    return {
        useProps(context, item, props): ReportDetailViewProps {
            const uniqueSources = chain(item.content.chapters)
                .flatMap((chapter) =>
                    chapter.sections.flatMap((section) => section.sources)
                )
                .uniqBy((source) => source.name)
                .value();
            const counts = {
                totalSources: uniqueSources.length,
            };
            return {
                source: {
                    categories: chain([
                        {
                            id: '0',
                            label: 'All',
                            count: 28,
                        },
                        {
                            id: '1',
                            label: 'Company blog',
                            count: 5,
                        },
                        {
                            id: '2',
                            label: 'Interviews',
                            count: 23,
                        },
                        {
                            id: '3',
                            label: 'Case studies',
                            count: 3,
                        },
                    ])
                        .orderBy((category) => category.count, 'desc')
                        .value(),
                    items: chain(item.content.chapters)
                        .flatMap((chapter) =>
                            chapter.sections.flatMap((section) =>
                                section.sources.map((source): ReportSourceItemProps => {
                                    const referenceCount = chapter.sections.reduce(
                                        (acc, section) =>
                                            acc +
                                            section.sources.filter(
                                                (candidate) =>
                                                    candidate.name === source.name
                                            ).length,
                                        0
                                    );
                                    return {
                                        source: {
                                            id: source.id,
                                            label: source.name,
                                            description: source.description,
                                            image:
                                                source.kind === 'system'
                                                    ? { src: Graphics.Brand.Icon }
                                                    : null,
                                            anchor: source.url
                                                ? { id: '', href: source.url }
                                                : null,
                                        },
                                        references: {
                                            count: referenceCount,
                                            label: [
                                                referenceCount,
                                                pluralize('reference', referenceCount),
                                            ].join(' '),
                                        },
                                    };
                                })
                            )
                        )
                        .uniqBy((item) => item.source.label)
                        .orderBy((item) => item.references.count, 'desc')
                        .value(),
                },
                item: {
                    report: buildReportItemProps({
                        report: item.report,
                        author: item.author,
                    }),
                    summary: {
                        description: item.content.summary,
                    },
                    chapters: item.content.chapters.map(
                        (
                            chapter,
                            index
                        ): ReportAggregateViewProps['chapters'][number] => {
                            const chapterNumber = index + 1;
                            return {
                                id: chapter.id,
                                caption: `${chapterNumber}. ${chapter.caption}`,
                                title: chapter.title,
                                description: chapter.description,
                                updated: {
                                    relative: 'Updated today',
                                },
                                edited: {
                                    description: `Edited 4 hours ago by ${item.author.name}`,
                                },
                                action: {
                                    label: 'Read more',
                                    link: {
                                        to: chapter.id,
                                    },
                                },
                            };
                        }
                    ),
                },
            };
        },
    };
}
