import { assert } from '@varos/util-typescript';
import { AssistantLoaderBaseConfig } from '../../../base';
import { ThreadNewLoader } from './threadNewInterface';

export function createConversationNewLoader(
    config: AssistantLoaderBaseConfig
): ThreadNewLoader {
    const {
        repository: {
            workflow: workflowRepository,
            inspection: inspectionRepository,
            thread: threadRepository,
        },
    } = config;
    return {
        useLoad(context, props) {
            const workflowQuery = workflowRepository.useLookup(
                context,
                {
                    id: props.workflow.type,
                },
                {
                    suspense: true,
                }
            );
            assert(workflowQuery.status === 'success', 'expected suspense');
            const inspectionQuery = inspectionRepository.useLookup(
                context,
                props.workflow,
                {
                    suspense: true,
                }
            );
            assert(inspectionQuery.status === 'success', 'expected suspense');
            const threadQuery = threadRepository.useFind(
                context,
                {
                    workflow: workflowQuery.data.slug,
                    limit: 5,
                },
                {
                    suspense: true,
                }
            );
            assert(threadQuery.status === 'success', 'expected suspense');
            return {
                workflow: workflowQuery.data,
                inspection: inspectionQuery.data,
                recent: threadQuery.data,
            };
        },
    };
}
