import { assertNever } from '@varos/util-typescript';
import { AuthorObject, ReportObject } from '../../../fixture';
import { ReportItemViewProps } from './reportItemProps';
import { AiOutlineUser } from 'react-icons/ai';

function buildStatus(status: ReportObject['status']): ReportItemViewProps['status'] {
    if (status === 'draft') {
        return {
            colorScheme: 'gray',
            label: 'Draft',
            description: 'This report is not ready for review',
        };
    }
    if (status === 'published') {
        return {
            colorScheme: 'green',
            label: 'Ready',
            description: 'This report is ready for review',
        };
    }
    if (status === 'archived') {
        return {
            colorScheme: 'red',
            label: 'Archived',
            description: 'This report is no longer active',
        };
    }
    if (status === 'generating') {
        return {
            colorScheme: 'orange',
            label: 'Generating',
            description: 'This report is being generated',
        };
    }
    assertNever(status);
}

export function buildReportItemProps(item: {
    report: ReportObject;
    author: AuthorObject;
}): ReportItemViewProps {
    const { report, author } = item;
    return {
        id: report.id,
        title: report.title,
        status: buildStatus(report.status),
        stats: {
            items: [
                {
                    id: '1',
                    label: 'Public sources',
                    description: `All public and proprietary content reviewed by Varos’ AI before filtering for relevance and reliability.`,
                    value: `27,280`,
                },
                {
                    id: '2',
                    label: 'Expert interviews',
                    description: `Expert conversations conducted by Varos to gather unique, first-hand insights that supplement public data.`,
                    value: `28`,
                },
                {
                    id: '3',
                    label: 'Gold standard',
                    description: `The most trusted and high-quality sources selected through Varos’ AI scoring system to power key insights in the report.`,
                    value: `23`,
                },
            ],
        },
        keywords: [
            {
                id: '0',
                label: author.name,
                Icon: AiOutlineUser,
            },
            {
                id: '1',
                label: 'Updated today',
                Icon: null,
            },
        ],
        author: {
            name: author.name,
            avatar: 'https://bit.ly/broken-link',
        },
        lastUpdated: {
            relative: '2 days ago',
        },
        createdAt: {
            relative: '5 days ago',
        },
    };
}
