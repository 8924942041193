import {
    AnyKeywordItemProps,
    KeywordItemLinkProps,
    KeywordItemTextProps,
} from './keywordItemProps';

export function isKeywordText(props: AnyKeywordItemProps): props is KeywordItemTextProps {
    return props.kind === 'text';
}

export function isKeywordLink(props: AnyKeywordItemProps): props is KeywordItemLinkProps {
    return props.kind === 'link';
}
