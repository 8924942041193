import { z } from 'zod';
import { AccountProfileSchema } from '../me';
export const DomainIdentitySchema = z.object({
    domain: z.string(),
    verified: z.boolean(),
});

export const OrganizationAccountDetailsSchema = z.object({
    kind: z.literal('organization'),
    name: z.string(),
    owner: z.object({
        user_id: z.number(),
    }),
    primary_domain: DomainIdentitySchema,
    domains: z.array(DomainIdentitySchema),
    profile: z.nullable(AccountProfileSchema),
    organization_id: z.number(),
});

export const VarosIdentitySchema = z.object({
    kind: z.literal('varos'),
    email: z.string(),
    email_verified: z.boolean(),
    last_email_verification_sent_at: z.date({ coerce: true }),
});

export const GoogleIdentitySchema = z.object({
    kind: z.literal('google'),
    email: z.string(),
});

export const UserIdentitySchema = z.discriminatedUnion('kind', [
    VarosIdentitySchema,
    GoogleIdentitySchema,
]);

export const UserAccountDetailsSchema = z.object({
    kind: z.literal('user'),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    identities: z.array(UserIdentitySchema),
    user_id: z.number(),
    profile: z.nullable(AccountProfileSchema),
});

export const AccoutDetailsSchema = z.discriminatedUnion('kind', [
    OrganizationAccountDetailsSchema,
    UserAccountDetailsSchema,
]);

export const AccountSchema = z.object({
    id: z.string(),
    state: z.object({
        finished_on_boarding: z.boolean(),
        pending_actions: z.nullable(
            z.array(
                z.object({
                    is_required: z.boolean(),
                    scope: z.union([
                        z.literal('account'),
                        z.object({
                            kind: z.literal('assets'),
                            asset_ids: z.array(z.number()),
                        }),
                    ]),
                    spec: z.discriminatedUnion('kind', [
                        z.object({
                            kind: z.literal('ingestion_survey'),
                            surveys: z.array(
                                z.object({
                                    survey_id: z.string(),
                                    populate_data: z.record(z.string()).nullable(),
                                })
                            ),
                        }),
                        z.object({
                            kind: z.literal('ingestion_integration'),
                            integration_definitions: z.array(z.string()),
                        }),
                        z.object({
                            kind: z.literal('reward_profile'),
                            token: z.string(),
                        }),
                    ]),
                })
            )
        ),
        default_asset_id: z.nullable(z.number()),
    }),
    details: AccoutDetailsSchema,
    created_at: z.date({ coerce: true }),
    updated_at: z.nullable(z.date({ coerce: true })),
});

export const AccountListSchema = z.object({
    data: z.array(AccountSchema),
});
