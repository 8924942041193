import { ButtonProps } from '@chakra-ui/react';
import { NotificationItemViewProps } from '../item';
import { NotificationListController } from './notificationListInterface';
import { ReportingShellControllerBaseConfig } from '../../../base';

export function createNotificationListController(
    config: ReportingShellControllerBaseConfig
): NotificationListController {
    return {
        useProps(context, item, props) {
            const unreadCount = item.items.filter((item) => true).length;
            return {
                unread: {
                    label: `${unreadCount} unread`,
                },
                items: item.items.map(
                    (item): NotificationItemViewProps => ({
                        id: item.id,
                        title: item.title,
                        body: item.description,
                        unread: true,
                        timestamp: {
                            label: `1d ago`,
                        },
                    })
                ),
            };
        },
    };
}
