import { AssistantRepositoryConfig } from './assistantConfig';
import { AssistantRepositories } from './assistantInterface';
import { createCallRepository } from './call';
import { createInspectionRepository } from './inspection';
import { createMessageRepository } from './message';
import { createSessionRepository } from './session';
import { createStateRepository } from './state';
import { createThreadRepository } from './thread';
import { createWorkflowRepository } from './workflow';

export function createAssistantRepositories(
    config: AssistantRepositoryConfig
): AssistantRepositories {
    const thread = createThreadRepository(config.kernel, config.api);
    return {
        session: createSessionRepository(config.kernel, config.api),
        thread,
        message: createMessageRepository(config.kernel, config.api),
        workflow: createWorkflowRepository(config.kernel, config.api),
        inspection: createInspectionRepository(config.kernel, config.api),
        state: createStateRepository(config.kernel, config.api),
        call: createCallRepository({
            ...config,
            repository: {
                thread,
            },
        }),
    };
}
