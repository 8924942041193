import { useEffect, useLayoutEffect } from 'react';
import { Kernel } from '../../base';
import { ApplicationPluginProvider } from '../../entrypoint';
import { ApplicationLayoutEnhancer, TabLayoutController } from '../../layout';
import {
    ApplicationAccountController,
    ApplicationNavigationController,
    ApplicationNavigationControllerProps,
    ApplicationShellEnhancer,
} from '../../shell';
import { MobilePluginConfig } from './mobilePluginConfig';

/**
 * Limits application pages and navigation to ones that support a mobile experience
 * @param config
 */
export function createMobilePlugin(init: MobilePluginConfig): ApplicationPluginProvider {
    const set = new Set(init.include as string[]);

    function createShellEnhancer(): ApplicationShellEnhancer {
        return (create) => (config) => {
            console.log(
                'applying filtering of mobile routes in shell navigation',
                init.include
            );
            function enhanceNavigationController(
                instance: ApplicationNavigationController
            ): ApplicationNavigationController {
                return {
                    ...instance,
                    useProps(context, item, props) {
                        const browser = config.kernel.controller.browser.useProps({});
                        let mappedProps: ApplicationNavigationControllerProps = props;
                        if (browser.mode === 'mobile') {
                            mappedProps = {
                                ...props,
                                items: props.items.filter((item) => set.has(item.id)),
                            };
                        }
                        const [first] = mappedProps.items;
                        useLayoutEffect(() => {
                            if (
                                props.location.pathname.includes('/home') &&
                                browser.mode === 'mobile'
                            ) {
                                console.log(
                                    'home not available on mobile, redirecting...'
                                );
                                props.navigate(first.path, { replace: true });
                            }
                        }, [browser.mode === 'mobile', first, props.location.pathname]);
                        return instance.useProps(context, item, mappedProps);
                    },
                };
            }
            function enhanceAccountController(
                instance: ApplicationAccountController
            ): ApplicationAccountController {
                return {
                    ...instance,
                    useProps(context, item, props) {
                        const browser = config.kernel.controller.browser.useProps({});
                        if (browser.mode !== 'mobile') {
                            return instance.useProps(context, item, props);
                        }
                        return instance.useProps(context, item, {
                            ...props,
                            actions: props.actions.filter((item) => set.has(item.id)),
                        });
                    },
                };
            }
            return create({
                ...config,
                provider: {
                    ...config.provider,
                    createAccountController(...args) {
                        const instance = config.provider.createAccountController(...args);
                        return enhanceAccountController(instance);
                    },
                    createNavigationController(...args) {
                        const instance = config.provider.createNavigationController(
                            ...args
                        );
                        return enhanceNavigationController(instance);
                    },
                },
            });
        };
    }

    function enhanceTabLayoutController(
        kernel: Kernel,
        instance: TabLayoutController
    ): TabLayoutController {
        console.log('applying filtering of mobile routes in tab layout', init.include);
        return {
            ...instance,
            useProps(props) {
                const browser = kernel.controller.browser.useProps({});
                if (browser.mode !== 'mobile') {
                    return instance.useProps(props);
                }
                return instance.useProps({
                    ...props,
                    items: props.items.filter((item) => set.has(item.id)),
                });
            },
        };
    }

    function createLayoutEnhancer(): ApplicationLayoutEnhancer {
        return (create) => (config) => {
            return create({
                ...config,
                provider: {
                    ...config.provider,
                    createTabController(...args) {
                        const [kernel] = args;
                        const instance = config.provider.createTabController(...args);
                        return enhanceTabLayoutController(kernel, instance);
                    },
                },
            });
        };
    }

    return {
        create(config) {
            return {
                shell: {
                    application: createShellEnhancer(),
                },
                layout: createLayoutEnhancer(),
            };
        },
    };
}
