import { compose } from '@varos/util-typescript';
import React from 'react';
import { Outlet, Route } from 'react-router';
import {
    createRegistrationAcceptCreateContainer,
    createRegistrationAcceptCreateController,
    createRegistrationAcceptCreateLoader,
    createRegistrationAcceptCreateRoute,
    createRegistrationAcceptWelcomeContainer,
    createRegistrationAcceptWelcomeDesktopView,
    createRegistrationAcceptWelcomeMobileView,
    createRegistrationAcceptWelcomeController,
    createRegistrationAcceptProfileContainer,
    createRegistrationAcceptProfileController,
    createRegistrationAcceptProfileView,
    createRegistrationAcceptOrganizationContainer,
    createRegistrationAcceptOrganizationController,
    createRegistrationAcceptOrganizationView,
    createRegistrationAcceptFinalizeContainer,
    createRegistrationAcceptFinalizeController,
    createRegistrationAcceptFinalizeDesktopView,
    createAcceptContextProvider,
    createStepLayoutController,
    createStepLayoutDesktopView,
    createStepLayoutMobileView,
} from './page';
import {
    createRegistrationContextProvider,
    RegistrationContainerConfig,
    RegistrationControllerConfig,
    RegistrationLoaderConfig,
    RegistrationViewConfig,
} from './base';
import { RegistrationRouterConfig, RegistrationRouterInit } from './registrationConfig';
import { RegistrationRouter, RegistrationRouterEnhancer } from './registrationInterface';
import {
    createRegisetrationLayoutContainer,
    createRegistrationLayoutController,
    createRegistrationLayoutView,
} from './layout';
import {
    createRegistrationAcceptCreateDesktopView,
    createRegistrationAcceptFinalizeMobileView,
} from './page/invitation/accept/theme';
import { createRegistrationAcceptCreateMobileView } from './page/invitation/accept/theme/mobile/root';
import { createAutoSubmitMiddeware } from './middleware';

export function configureRegistrationRouter(
    init: RegistrationRouterInit,
    enhancer?: RegistrationRouterEnhancer
) {
    let enhancers: RegistrationRouterEnhancer[] = enhancer ? [enhancer] : [];
    enhancers = [...enhancers, createAutoSubmitMiddeware()];
    return create(
        {
            ...init,
            internal: {
                accept: {
                    createController: createRegistrationAcceptCreateController,
                },
                profile: {
                    createController: createRegistrationAcceptProfileController,
                },
            },
        },
        compose(...enhancers)
    );
}

function create(
    config: RegistrationRouterConfig,
    enhancer?: RegistrationRouterEnhancer
): RegistrationRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const context = {
        root: createRegistrationContextProvider({
            hook: {},
        }),
        accept: createAcceptContextProvider(),
    };

    const containerConfig: RegistrationContainerConfig = {
        ...config,
        context: context.root,
    };

    const loaderConfig: RegistrationLoaderConfig = {
        ...config,
    };
    const controllerConfig: RegistrationControllerConfig = {
        ...config,
        anchor: {},
        controller: {
            step: createStepLayoutController(),
        },
    };
    const viewConfig: RegistrationViewConfig = {
        ...config,
    };

    const Layout = {
        Root: createRegisetrationLayoutContainer(
            containerConfig,
            createRegistrationLayoutController(),
            createRegistrationLayoutView(viewConfig)
        ),
        Accept: {
            Desktop: createStepLayoutDesktopView(viewConfig),
            Mobile: createStepLayoutMobileView(viewConfig),
        },
    };

    const Theme = {
        Desktop: {
            Step: createStepLayoutDesktopView(viewConfig),
            Welcome: createRegistrationAcceptWelcomeDesktopView(viewConfig),
            Profile: createRegistrationAcceptProfileView(
                viewConfig,
                Layout.Accept.Desktop
            ),
            Organization: createRegistrationAcceptOrganizationView(
                viewConfig,
                Layout.Accept.Desktop
            ),
            Finalize: createRegistrationAcceptFinalizeDesktopView(viewConfig),
        },
        Mobile: {
            Step: createStepLayoutMobileView(viewConfig),
            Welcome: createRegistrationAcceptWelcomeMobileView(viewConfig),
            Profile: createRegistrationAcceptProfileView(
                viewConfig,
                Layout.Accept.Mobile
            ),
            Organization: createRegistrationAcceptOrganizationView(
                viewConfig,
                Layout.Accept.Mobile
            ),
            Finalize: createRegistrationAcceptFinalizeMobileView(viewConfig),
        },
    };

    const View = {
        Welcome: createRegistrationAcceptWelcomeContainer(
            {
                ...containerConfig,
                accept: context.accept,
            },
            createRegistrationAcceptWelcomeController(controllerConfig)
        ),
        Profile: createRegistrationAcceptProfileContainer(
            {
                ...containerConfig,
                accept: context.accept,
            },
            config.internal.profile.createController(controllerConfig)
        ),
        Organization: createRegistrationAcceptOrganizationContainer(
            {
                ...containerConfig,
                accept: context.accept,
            },
            createRegistrationAcceptOrganizationController(controllerConfig)
        ),
        Finalize: createRegistrationAcceptFinalizeContainer(
            {
                ...containerConfig,
                accept: context.accept,
            },
            createRegistrationAcceptFinalizeController(controllerConfig)
        ),
    };

    const AcceptRoute = createRegistrationAcceptCreateRoute(
        containerConfig,
        createRegistrationAcceptCreateContainer(
            { ...containerConfig, accept: { context: context.accept } },
            createRegistrationAcceptCreateLoader(loaderConfig),
            config.internal.accept.createController(controllerConfig),
            {
                Desktop: createRegistrationAcceptCreateDesktopView({
                    ...viewConfig,
                    Container: View,
                    Theme: Theme.Desktop,
                }),
                Mobile: createRegistrationAcceptCreateMobileView({
                    ...viewConfig,
                    Container: View,
                    Theme: Theme.Mobile,
                }),
            }
        )
    );

    return (
        <Route
            path={config.mount}
            element={
                <Layout.Root>
                    <Outlet />
                </Layout.Root>
            }
        >
            <Route path="invitations" element={<AcceptRoute />} />
        </Route>
    );
}
