import { compose } from '@varos/util-typescript';
import { ApplicationEntryEnhancer } from '../applicationMiddleware';
import { ApplicationPluginConfig } from './applicationPluginConfig';
import { ApplicationPluginProvider } from './applicationPluginInterface';

export function applyApplicationPlugins(
    ...providers: ApplicationPluginProvider[]
): ApplicationEntryEnhancer {
    return (create) => (outerConfig) => {
        const pluginConfig: ApplicationPluginConfig = {};
        const plugins = providers.map((item) => item.create(pluginConfig));

        const rootEnhancer = compose(
            ...plugins.flatMap((plugin) => (plugin.root ? plugin.root : []))
        );
        const createComposed = rootEnhancer(create);
        return createComposed({
            ...outerConfig,
            enhancer: {
                shell: {
                    application: [
                        ...outerConfig.enhancer.shell.application,
                        ...plugins.flatMap((plugin) =>
                            plugin.shell?.application ? [plugin.shell.application] : []
                        ),
                    ],
                    contributor: [
                        ...outerConfig.enhancer.shell.contributor,
                        ...plugins.flatMap((plugin) =>
                            plugin.shell?.contributor ? [plugin.shell.contributor] : []
                        ),
                    ],
                },
                layout: [
                    ...outerConfig.enhancer.layout,
                    ...plugins.flatMap((plugin) =>
                        plugin.layout ? [plugin.layout] : []
                    ),
                ],
            },
        });
    };
}
