import React, { useRef } from 'react';
import { ReportingShellContextContainerProps } from './reportingContextProps';
import {
    ReportingShellContext,
    ReportingShellContextController,
    ReportingShellContextLoader,
} from './reportingContextInterface';
import { ReportingShellContainerBaseConfig } from '../../../base';
import { ReportingShellContextUrlParamsSchema } from './reportingContextSchema';

export function createReportingShellContextContainer(
    config: Pick<ReportingShellContainerBaseConfig, 'kernel'>,
    Context: React.Context<ReportingShellContext | null>,
    loader: ReportingShellContextLoader,
    controller: ReportingShellContextController
): React.FC<ReportingShellContextContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const overlayRef = useRef<HTMLDivElement>(null);
        const options = useOptions(ReportingShellContextUrlParamsSchema);
        const data = loader.useLoad(options);
        const props = controller.useProps(data, {
            overlayRef,
        });
        return <Context.Provider {...props.provider}>{children}</Context.Provider>;
    };
}
