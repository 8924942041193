import { ReportChapterObject } from '../../base';

export const improvementAreas: Omit<ReportChapterObject, 'number'> = {
    id: 'improvement-areas',
    caption: `Improvement areas`,
    title: `Strategic Efforts Needed to Boost AI Tool Adoption in Enterprises`,
    description: `Despite notable productivity gains, adoption of AI code generation tools remains limited, often plateauing below 50%, highlighting a critical need for targeted education and structured adoption initiatives. Enterprises primarily use large vendors (GitHub, Gemini) due to data privacy concerns, limiting broader adoption of specialized AI startups.`,
    sections: [
        {
            id: '1',
            title: `Adoption Requires Deliberate Strategy`,
            caption: `Adoption`,
            content: [
                {
                    kind: 'text',
                    text: `Even with clear benefits, generative AI tools often plateau at around 50% adoption without intentional enablement strategies. Organizations that succeed typically run dedicated development sprints focused purely on learning—no deliverables, just space for developers to explore and master AI tools. Internal knowledge sharing from high performers (using platforms like Varos) and adjusted KPIs that incentivize AI usage also play a key role.`,
                },
                {
                    kind: 'text',
                    text: `As one leader put it: "They're dedicating a two-week sprint cycle with no deliverables, where developers focus solely on building with AI tools and mastering that approach."`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '2',
            title: `Vendor Selection is Conservative`,
            caption: `Vendors`,
            content: [
                {
                    kind: 'text',
                    text: `Despite a growing number of AI startups, enterprises tend to stick with mainstream tools like GitHub Copilot, Gemini, and Cursor, showing a clear preference for established vendors. This is largely due to data privacy concerns, improved internal capabilities that make custom solutions viable, and security and procurement hurdles that favor well-known providers.`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '3',
            title: `Legacy Code Requires Human Oversight`,
            caption: `Legacy`,
            content: [
                {
                    kind: 'text',
                    text: `AI struggles with poorly documented, complex legacy systems. As one user noted, "Auto-refactoring legacy code with AI might give you a quick suggestion, but these old codebases are riddled with hidden dependencies and outdated patterns. Without thorough human oversight, an automated change can introduce more issues than it resolves."`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '4',
            title: `Quality Control is Non-Negotiable`,
            caption: `Supervision`,
            content: [
                {
                    kind: 'text',
                    text: `Successful implementations treat AI as an assistant, not a replacement. As one developer put it, "I treat AI like my junior engineer—he writes me a lot of code but I also need to give a lot of instructions and rigorous testing."`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '5',
            title: `Data Privacy Drives Implementation Choices`,
            caption: `Security`,
            content: [
                {
                    kind: 'text',
                    text: `Security concerns often dictate architecture and vendor decisions. Enterprises commonly build in-house versions of popular tools, restrict usage to specific environments or users, and deploy open-source models on-premises. As one team explained, "We rebuilt our own version of Cursor because we needed a tool that could deeply understand our code without compromising security. With our in-house solution, we maintain full control over the code context and protect sensitive data, which external models simply can't guarantee."`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
        {
            id: '6',
            title: `Measuring ROI Beyond Code Volume`,
            caption: `Impact`,
            content: [
                {
                    kind: 'text',
                    text: `The most successful adopters measure AI's impact beyond just code volume. They focus on developer productivity, time saved on routine tasks, reduction in technical debt, and greater capacity to drive innovation.`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
    ],
};
