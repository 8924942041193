import React, { Suspense } from 'react';

import {
    Button,
    ButtonGroup,
    Center,
    HStack,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineReload, AiOutlineWarning } from 'react-icons/ai';
import { ApplicationErrorViewProps } from '../../../../../shell';
import { ContributorThemeViewConfig } from '../../../base';
import { contributorThemeStyles } from '../../../style';

export function createReportingShellErrorView(
    config: ContributorThemeViewConfig
): React.FC<ApplicationErrorViewProps> {
    const {
        UI: {},
    } = config;
    return (props) => {
        return (
            <HStack
                h="full"
                w="full"
                justify="center"
                align="center"
                py={8}
                minH={contributorThemeStyles.minScreenHeight}
            >
                <Center w="full" h="full">
                    <VStack
                        align="start"
                        p={12}
                        // position="relative"
                        spacing={{ base: 4, xl: 6 }}
                        // top={{ base: -6, xl: -24 }}
                        justify="center"
                    >
                        <Icon w={12} h={12} color="red.500" as={AiOutlineWarning} />
                        <VStack align="start" color="whiteAlpha.800" spacing={1}>
                            <Text fontWeight="medium" letterSpacing="wide">
                                Something went wrong
                            </Text>
                            <Text color="whiteAlpha.700">
                                If this issue continues, please contact support
                            </Text>
                        </VStack>
                        <ButtonGroup spacing={6}>
                            <Button
                                variant="outline"
                                borderColor="whiteAlpha.300"
                                borderRadius="full"
                                bg="none"
                                leftIcon={<Icon as={AiOutlineReload} />}
                                onClick={() => window.location.reload()}
                                _hover={{ bg: 'whiteAlpha.50' }}
                            >
                                Refresh page
                            </Button>
                        </ButtonGroup>
                    </VStack>
                </Center>
            </HStack>
        );
    };
}
