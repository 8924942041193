import { compose } from '@varos/util-typescript';
import { Outlet, Route } from 'react-router';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../../../styles/theme';
import { FormatterImpl } from '../../../hooks';
import { createPageControllerV2 } from '../../view/page';
import { EnvironmentVariables } from '../../config/environment';
import { createAlertListController, createFormController } from '../../view/common';
import { createErrorKernelEnhancer, createKernelTrackingStrategy } from '../../strategy';
import { createKernelToastEnhancer } from '../../middleware';
import { InterviewEntrypointConfig } from './interviewEntrypointConfig';
import { InterviewEntry } from './interviewEntrypointInterface';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export function createInterviewEntrypoint(
    config: InterviewEntrypointConfig
): InterviewEntry {
    const TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyMzc3IiwiZW1haWwiOiJkZXNpZ25lci1kZW1vQHZhcm9zLmlvIiwiYWNjdCI6ImFjY3RfUExja2htWDZHYWFaUUdvSmJadmY3TSIsIm9yZ19pZCI6NCwic3UiOmZhbHNlLCJmX3NlIjpmYWxzZSwiZGVtbyI6ZmFsc2UsInJvbGUiOiJhZG1pbiIsImltcGVyIjpmYWxzZSwiZXhwIjoxNzQyOTgwNTE4fQ.lpflB6LKBpqK9VreC-4weN6lfYSyIUrQdo8bhpBJG2c`;

    const axios = config.infra.createAxios({
        defaults: {},
        async getToken() {
            return TOKEN;
        },
    });

    const infra = {
        help: config.infra.createHelp({
            getClient: config.infra.getIntercom,
        }),
        getIntercom: config.infra.getIntercom,
        errorFormatter: config.infra.createErrorFormatter(),
        formatter: config.infra.createFormatter(),
        axios: axios,
        formatterOld: new FormatterImpl(),
        optionParser: config.infra.createUrlOptionParser(),
        useTracker: config.infra.createUseTracker(),
        toaster: config.infra.createToaster(),
        repository: config.infra.createRepository(),
        sentry: config.infra.createSentry(),
        queryClient: new QueryClient(),
    };

    const kernel = config.infra.createKernel(
        {
            adapter: {
                authenticator: {
                    async getToken(context) {
                        return { token: TOKEN };
                    },
                    async logout(context) {
                        return;
                    },
                },
                help: infra.help,
            },
            provider: {
                createFormController: createFormController,
                createPageController(controllerConfig) {
                    // TODO clean this up
                    return createPageControllerV2({
                        ...controllerConfig,
                        provider: {
                            createAlertListController: createAlertListController,
                        },
                    });
                },
            },
            infra: {
                optionParser: infra.optionParser,
                toaster: infra.toaster,
                formatter: infra.formatterOld,
                useTracker: infra.useTracker,
                repository: infra.repository,
            },
            navigation: {
                rootPath: '/u',
            },
            help: {
                adapter: infra.help,
                exclude: ['/assistant'],
            },
            overlay: {
                stateKey: 'modal',
            },
        },
        compose(
            createKernelTrackingStrategy(),
            createKernelToastEnhancer(),
            createErrorKernelEnhancer({ client: infra.sentry })
        )
    );

    const view = {
        application: config.common.createApplication(),
    };
    const hooks = {};

    const api = {
        assistant: config.api.createAssistant({
            baseUrl: EnvironmentVariables.ASSISTANT_API_BASE_URL,
            axios,
        }),
    };

    const repository = {
        assistant: config.repository.createAssistant({
            kernel,
            api: api.assistant,
        }),
    };

    const UI = {
        Application: config.ui.createApplication(),
    };

    const assistantRouter = config.router.createAssistant({
        mount: '',
        kernel,
        controller: view.application,
        hook: {
            useAuth() {
                return {
                    user: null,
                    scheme: { kind: 'apiKey', token: TOKEN, assume: null },
                };
            },
            useAccount: null,
        },
        UI: {
            Application: UI.Application,
        },
        infra: {
            navigation: {
                legal: {
                    termsOfService(props) {
                        return { to: '/' };
                    },
                },
            },
        },
        repository: repository.assistant,
    });

    return {
        root: (
            <Route
                element={
                    <ChakraProvider theme={theme}>
                        <QueryClientProvider client={infra.queryClient}>
                            <Outlet />
                        </QueryClientProvider>
                    </ChakraProvider>
                }
            >
                {assistantRouter.element}
            </Route>
        ),
    };
}
