import { ContributorErrorController } from './contributorErrorInterface';

export function createContributorErrorController(): ContributorErrorController {
    return {
        useProps(props) {
            return {
                label: props.info.error.message,
                link: {
                    back: { to: '/u' },
                },
            };
        },
    };
}
