import { useMemo } from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { createPcm16RecorderController as createVoiceRecorderController } from '@varos/openai-realtime-react';
import { Icons } from '../../../../../../../config';
import { ActionItemViewPropsV2, AnyActionV2 } from '../../../../../../view/common';
import { AssistantControllerBaseConfig } from '../../../../base';
import { ThreadComposerController } from './composerInterface';
import { ThreadComposerViewProps } from './composerProps';

export function createThreadComposerController(
    config: AssistantControllerBaseConfig
): ThreadComposerController {
    const {
        kernel: {
            provider: { createActionController },
        },
    } = config;

    const voiceToggleController = createActionController({
        id: 'toggle_voice_mode',
    });

    const sendController = createActionController({
        id: 'send_message',
    });

    const recordController = createActionController({
        id: 'record_voice',
    });

    const voiceController = createVoiceRecorderController({
        workerPath: `${process.env.PUBLIC_PATH}/assets/audio-processor.js`.replaceAll(
            '//',
            '/'
        ),
    });

    return {
        useProps(props): ThreadComposerViewProps {
            const recorder = voiceController.useProps(props.recorder);
            const voice = voiceToggleController.useProps(props.voice);
            const send = sendController.useProps(props.send);

            const record = recordController.useProps(
                recorder.state === 'recording'
                    ? {
                          id: '1',
                          name: 'stop_recording',
                          description: 'Stop recording',
                          Icon: Icons.Assistant.Microphone,
                          colorScheme: 'red',
                          kind: 'custom',
                          variant: 'default',
                          isActive: true,
                          onPerform() {
                              recorder.stop();
                          },
                      }
                    : {
                          id: '2',
                          name: 'record_voice_message',
                          description: 'Record voice message',
                          Icon: Icons.Assistant.Microphone,
                          kind: 'custom',
                          variant: 'default',
                          onPerform() {
                              recorder.start();
                          },
                      }
            );

            const timerLabel = useMemo(() => {
                if (recorder.timerMs === 0) return null;
                const totalSeconds = Math.floor(recorder.timerMs / 1000);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }, [recorder.timerMs]);

            let actions: ActionItemViewPropsV2[] = [];

            if (props.input.value.length > 0) {
                actions = [...actions, send];
            }
            if (props.input.value.length === 0) {
                actions = [...actions, voice];
            }

            return {
                mode: props.mode,
                record:
                    props.mode === 'voice'
                        ? null
                        : {
                              timer:
                                  timerLabel === null
                                      ? null
                                      : {
                                            elapsedMs: recorder.timerMs,
                                            label: timerLabel,
                                        },
                              action: record,
                          },
                textarea: {
                    inputRef: props.input.ref,
                    input: {
                        onKeyDown(event) {
                            if (
                                props.input.value.trim().length > 0 &&
                                event.key === 'Enter' &&
                                !event.shiftKey
                            ) {
                                send.trigger();
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        },
                        value: props.input.value,
                        onChange(event) {
                            props.input.onChange(event.target.value);
                        },
                    },
                },
                action: actions,
            };
        },
    };
}
