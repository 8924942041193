import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import {
    RegistrationAcceptCreateController,
    RegistrationAcceptProfileController,
} from '../../page';
import { RegistrationRouterEnhancer } from '../../registrationInterface';
import {
    AccountExternalInvitation,
    AccountMemberInvitation,
} from '../../../../domain/accounts';
import { AutoSubmitMiddelewareConfig } from './autoSubmitMiddlewareConfig';

/**
 * Auto submit onboarding flow for contributor accounts if all required registration information is available
 * in the invitation payload
 * @param init
 * @returns
 */
export function createAutoSubmitMiddeware(
    init: AutoSubmitMiddelewareConfig = {}
): RegistrationRouterEnhancer {
    return (create) => (config) => {
        function shouldAutosubmit(
            item: AccountExternalInvitation | AccountMemberInvitation
        ) {
            const isInvitationReady = item.status === 'ready';
            const isUserInvitation =
                item.kind === 'external' && item.account.kind === 'user';
            const hasRequiredValues =
                !!item.first_name && !!item.last_name && !!item.email;
            return isUserInvitation && hasRequiredValues && isInvitationReady;
        }

        function enhanceRootController(
            instance: RegistrationAcceptCreateController
        ): RegistrationAcceptCreateController {
            return {
                ...instance,
                useProps(item, props) {
                    useEffect(() => {
                        if (shouldAutosubmit(item.invitation)) {
                            console.log('moving to profile step for auto submission...');
                            // NOTE - this is a hack to move to the next step
                            // this should be done in a more safe way
                            props.stepper.onChange(1);
                            return;
                        }
                        return;
                    }, []);
                    return instance.useProps(item, props);
                },
            };
        }
        function enhanceProfileController(
            instance: RegistrationAcceptProfileController
        ): RegistrationAcceptProfileController {
            return {
                ...instance,
                useProps(item, props) {
                    const navigate = useNavigate();
                    const formValues = props.form.form.watch();
                    const submitted = useRef(false);
                    useEffect(() => {
                        if (!shouldAutosubmit(item.data.invitation)) {
                            return;
                        }
                        if (submitted.current) {
                            return;
                        }
                        submitted.current = true;
                        async function submit() {
                            await props.form.onSubmit(formValues);
                            navigate('/');
                        }
                        console.info('auto-submitting form', formValues);
                        submit();
                    }, []);
                    return instance.useProps(item, props);
                },
            };
        }
        return create({
            ...config,
            internal: {
                ...config.internal,
                accept: {
                    createController(...args) {
                        return enhanceRootController(
                            config.internal.accept.createController(...args)
                        );
                    },
                },
                profile: {
                    createController(...args) {
                        return enhanceProfileController(
                            config.internal.profile.createController(...args)
                        );
                    },
                },
            },
        });
    };
}
