import React from 'react';
import { ReportingErrorController } from './reportingErrorInterface';
import { ReportingErrorContainerProps } from './reportingErrorProps';
import { ReportingShellContainerBaseConfig } from '../../base';

export function createReportingErrorContainer(
    config: ReportingShellContainerBaseConfig,
    controller: ReportingErrorController
): React.FC<{ children?: React.ReactNode | undefined } & ReportingErrorContainerProps> {
    return ({ as: View, children, ...containerProps }) => {
        const props = controller.useProps(containerProps);
        return <View {...props} />;
    };
}
