import { useNavigationType } from 'react-router';
import { debounce } from 'lodash';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { ContributorShellConfig } from '../../contributorShellConfig';
import {
    ContributorAccountController,
    ContributorNavigationController,
} from '../../view';
import { ContributorRootController } from './contributorRootlInterface';
import { ContributorRootViewProps } from './contributorRootProps';

export function createContributorRootController(
    config: ContributorShellConfig & {
        controller: {
            navigation: ContributorNavigationController;
            account: ContributorAccountController;
        };
    }
): ContributorRootController {
    const {
        view,
        kernel: {
            controller,
            infra: {
                overlay: { Portal },
            },
        },
        controller: { navigation: navigationController, account: accountController },
    } = config;

    // Store scroll positions

    return {
        useProps(context, item, props): Omit<ContributorRootViewProps, 'children'> {
            const scrollPositions = useRef<Map<string, number>>(new Map());
            const ref = useRef<HTMLButtonElement>(null);
            const browser = controller.browser.useProps({});
            const navigationType = useNavigationType();
            const navigation = navigationController.useProps(
                context,
                {},
                props.navigation
            );
            const account = accountController.useProps(
                context,
                item.account,
                props.account
            );

            // restore scroll position on back event
            // TODO extract out logic
            useLayoutEffect(() => {
                const setScrollPosition = (path: string, position: number) => {
                    const positions = scrollPositions.current;

                    // Add the new scroll position
                    positions.set(path, position);

                    // If the map exceeds the limit, remove the oldest entry
                    if (positions.size > 5) {
                        const firstKey = positions.keys().next().value; // Get the oldest key
                        if (firstKey) {
                            positions.delete(firstKey);
                        }
                    }
                };

                const { pathname } = props.location;

                // Determine scroll behavior based on navigation type
                if (navigationType === 'POP') {
                    // Clear the most recent scroll position
                    const lastKey = Array.from(scrollPositions.current.keys()).pop(); // Get the most recent key
                    if (lastKey) {
                        scrollPositions.current.delete(lastKey);
                    }

                    // Restore scroll position for back/forward navigation
                    const savedPosition = scrollPositions.current.get(pathname);
                    if (props.scroll.ref.current && savedPosition !== undefined) {
                        props.scroll.ref.current.scrollTo({
                            top: savedPosition,
                            behavior: 'instant',
                        });
                    }
                } else if (navigationType === 'PUSH') {
                    // Scroll to top for "push" navigation
                    if (props.scroll.ref.current) {
                        props.scroll.ref.current.scrollTo({
                            top: 0,
                            behavior: 'instant',
                        });
                    }
                }

                // Debounced scroll handler
                const handleScroll = debounce(() => {
                    if (props.scroll.ref.current) {
                        setScrollPosition(pathname, props.scroll.ref.current.scrollTop);
                    }
                }, 100); // Adjust debounce delay as needed

                const currentScrollElement = props.scroll.ref.current;

                if (currentScrollElement) {
                    currentScrollElement.addEventListener('scroll', handleScroll);
                }

                return () => {
                    if (currentScrollElement) {
                        currentScrollElement.removeEventListener('scroll', handleScroll);
                    }
                    handleScroll.cancel(); // Cancel any pending debounced calls
                };
            }, [props.scroll.ref.current, props.location.pathname, navigationType]);

            return {
                browser,
                scroll: {
                    ref: props.scroll.ref,
                },
                error: {
                    // NOTE reset error boundary when navigating
                    hash: props.location.pathname,
                },
                home: props.home,
                status: props.status
                    ? {
                          alert: {
                              status: props.status.kind,
                          },
                          label: props.status.label,
                          action: props.status.action,
                      }
                    : null,
                navigation: {
                    view: navigation,
                    trigger: {
                        ref: ref,
                        button: {
                            onClick() {
                                props.navigation.disclosure.onOpen();
                            },
                        },
                    },
                    menu: {
                        isOpen: props.navigation.disclosure.isOpen,
                        onOpen: props.navigation.disclosure.onOpen,
                        onClose: props.navigation.disclosure.onClose,
                    },
                },
                footer: {
                    copyright: {
                        label: props.footer.copyright,
                    },
                    groups: props.footer.groups.map((group, index) => ({
                        id: index.toString(),
                        label: group.label,
                        links: group.links.map((link, index) => ({
                            id: index.toString(),
                            label: link.label,
                            anchor: {
                                id: link.label.toLowerCase().replaceAll(' ', '_'),
                                href: link.href,
                                target: '_blank',
                            },
                        })),
                    })),
                },
                account: {
                    view: account,
                    trigger: {
                        ref: ref,
                        button: {
                            onClick() {
                                props.account.disclosure.onOpen();
                            },
                        },
                    },
                    menu: {
                        isOpen: props.account.disclosure.isOpen,
                        onOpen: props.account.disclosure.onOpen,
                        onClose: props.account.disclosure.onClose,
                    },
                },
            };
        },
    };
}
