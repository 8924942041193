import {
    AiOutlineGroup,
    AiOutlineSend,
    AiOutlineTrophy,
    AiOutlineUser,
    AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import { MdLink, MdOutlinePersonSearch, MdOutlineTextsms } from 'react-icons/md';
import { TbTargetArrow } from 'react-icons/tb';
import { AiOutlineAim } from 'react-icons/ai';
import { BsChatLeftDots, BsSoundwave, BsStars } from 'react-icons/bs';
import { HiOutlineCube, HiOutlineLightBulb } from 'react-icons/hi';
import { AiOutlineBulb } from 'react-icons/ai';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { TbZoomMoney } from 'react-icons/tb';
import { BsClipboardData } from 'react-icons/bs';
import { MdOutlineManageSearch, MdOutlinePrivacyTip } from 'react-icons/md';
import { MdOutlineGroupWork } from 'react-icons/md';
import { FaRegEnvelope } from 'react-icons/fa';
import { MdOutlineQuestionAnswer } from 'react-icons/md';
import { BsChatLeftDotsFill } from 'react-icons/bs';
import { RiUserSearchLine } from 'react-icons/ri';
import { ImMagicWand } from 'react-icons/im';
import { BiCommentAdd, BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';
import { BsSendPlus } from 'react-icons/bs';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';
import { FiEdit } from 'react-icons/fi';
import { BsCurrencyDollar } from 'react-icons/bs';
import { BsSend } from 'react-icons/bs';
import { MdPercent } from 'react-icons/md';
import { GoOrganization } from 'react-icons/go';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { PiChatText } from 'react-icons/pi';

export const Icons = {
    Assistant: {
        AdvancedVoiceMode: BsSoundwave,
        TextMode: MdOutlineTextsms,
        Microphone: BiMicrophone,
        MicrophoneOff: BiMicrophoneOff,
        Send: AiOutlineSend,
    },
    Peers: {
        CompetitiveSets: AiOutlineUsergroupAdd,
        Company: GoOrganization,
    },
    Accounts: {
        User: AiOutlineUser,
        Asset: GoOrganization,
        Integration: MdLink,
    },
    Rewards: {
        Offer: HiOutlineCube,
        Trophy: AiOutlineTrophy,
    },
    Reports: {
        Report: BsClipboardData,
        Submission: AiOutlineSend,
        Invitation: HiOutlineEnvelope,
        Study: PiChatText,
        Invite: FaRegEnvelope,
        Group: MdOutlineGroupWork,
        Cohort: MdOutlineManageSearch,
        Answer: BsChatLeftDotsFill,
        Privacy: MdOutlinePrivacyTip,
        Manual: RiUserSearchLine,
        Automatic: ImMagicWand,
    },
    Attributes: {
        Currency: BsCurrencyDollar,
        Percentage: MdPercent,
    },
    Networks: {
        Operator: HiOutlineLightBulb,
        Agency: HiOutlineUserGroup,
        Target: TbTargetArrow,
        Filter: MdOutlinePersonSearch,
        Budget: TbZoomMoney,
        AdditionalDetail: FiEdit,
        Submit: BsSend,
        Currency: BsCurrencyDollar,
    },
    Feature: {
        Highlight: BsStars,
    },
};
