import { OrganizationType } from '../../../api/platform/me';
import { AccountScope } from '../../../app';
import { AssetCreateProps } from '../../../domain/assets';
import { AccountProfileAdapter } from '../../../app/accounts';
import { ProfileImplConfig } from './profileImplConfig';
import { UserProfile } from '../../../domain/accounts';

export function createProfileImpl(config: ProfileImplConfig): AccountProfileAdapter {
    const {
        config: { defaultPlan },
        api: { platform, billing, ...api },
        adapter,
    } = config;

    const idempotencyKey = Date.now().toString();

    return {
        async put(context, props) {
            const [options, current] = await Promise.all([
                adapter.registration.get(context),
                platform.me.get(context),
            ]);

            const isCreate = current.user.memberships.length === 0;
            if (props.account?.kind === 'individual') {
                throw new Error('individual account type creation is not supported yet');
            }

            const response = await platform.me.update(context, {
                first_name: props.firstname,
                last_name: props.lastname,
                asset_url: null,
                title: null,
                organization:
                    props.account?.kind === 'organization'
                        ? {
                              role: 'admin',
                              organization: {
                                  name: props.account.organizationName,
                                  domain: props.account.domain,
                                  company_type:
                                      props.account.organizationType ||
                                      'ecommerce_website',
                                  finished_on_boarding: props.account.finishedOnboarding,
                              },
                          }
                        : null,
            });

            let org = response.current_account;
            if (!org) {
                if (
                    context.auth.scheme.kind === 'legacy' &&
                    response.user.memberships &&
                    response.user.memberships.length > 0
                ) {
                    // NOTE we have to refresh the auth context so that it
                    // contains the newly created organization context
                    await context.auth.scheme.store.connectToAccount(
                        response.user.memberships[0].account_id
                    );
                    const updatedResponse = await platform.me.get(context);
                    org = updatedResponse.current_account;
                }
            }
            if (org) {
                const orgContext: AccountScope = {
                    ...context,
                    account: { id: org.id },
                };

                const [subscription, assets] = await Promise.all([
                    billing.subscription.get(context),
                    adapter.asset.find(orgContext, { limit: 100, visible: null }),
                ]);

                if (!subscription.active) {
                    console.info('activating organization subscription...');
                    const plan = defaultPlan;
                    const subscription = await billing.subscription.create(
                        context,
                        {
                            plan,
                        },
                        { wait: 15000, idempotency_key: idempotencyKey }
                    );
                    console.log(`succesfully subscribed to ${plan} plan`, subscription);
                }

                if (
                    assets.items.length === 0 &&
                    org?.profile?.tags['company_type'] !== 'ad_agency'
                ) {
                    // NOTE we auto-create assets for non-agencies based on the data provided as part of
                    // profile form
                    const payload: AssetCreateProps = {
                        name: props.account?.organizationName!,
                        url: props.account?.domain!,
                        type: 'website',
                        isVisible: true,
                    };
                    console.info(
                        'no assets found, auto-creating asset for brand...',
                        payload
                    );
                    const asset = await adapter.asset.create(orgContext, payload);
                    console.info(`asset created`, asset);
                }

                if (isCreate && options?.source === 'report') {
                    console.info(
                        'registration option set to report, updating client preferences...'
                    );
                    try {
                        const preference = await adapter.preference.update(
                            {
                                ...context,
                                account: { id: org.id },
                            },
                            {
                                layout: 'light',
                            }
                        );
                        console.log('client preferences set', preference);
                    } catch (error) {
                        console.error(`failed to create client preferences`, error);
                    }
                }
            }
            let retVal: UserProfile = {
                firstname: response.user.first_name,
                lastname: response.user.last_name,
                account: null,
                personalProfile: null,
            };
            if (org) {
                if (org.kind === 'organization') {
                    retVal.account = {
                        kind: 'organization',
                        organizationName: org.name,
                        organizationType: (org?.profile?.tags['company_type'] ||
                            null) as OrganizationType | null,
                        domain: org.domain,
                        role:
                            response.user.memberships.find((m) => m.account_id == org.id)
                                ?.role || 'standard',
                        finishedOnboarding: org.finished_on_boarding,
                        profile: org.profile,
                    };
                } else {
                    retVal.account = {
                        kind: 'individual',
                        finishedOnboarding: org.finished_on_boarding,
                        profile: org.profile,
                    };
                }
            }
            return retVal;
        },
    };
}
