import React, { useEffect } from 'react';
import {
    ApplicationController,
    ModalController,
    ModalControllerConfig,
    ModalV1Controller,
    ModalV2Controller,
} from '../../../view/common';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyModalEvent } from './trackingModalEvent';

export function createModalTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyModalEvent>();
            return tracker;
        }

        function enhanceV1Controller(controller: ModalV1Controller): ModalV1Controller {
            return {
                ...controller,
                useProps(...args) {
                    const [deps, controllerProps] = args;
                    const tracker = useTracker();
                    const viewProps = controller.useProps(...args);
                    useEffect(() => {
                        if (viewProps.containerProps.isOpen) {
                            tracker.track('modal_viewed', {
                                modal_id: controllerProps.id,
                            });
                        }
                    }, [viewProps.containerProps.isOpen]);
                    return viewProps;
                },
            };
        }

        function enhanceV2Controller(
            config: { id: string },
            controller: ModalV2Controller
        ): ModalV2Controller {
            return {
                ...controller,
                useProps(controllerProps) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(controllerProps);
                    useEffect(() => {
                        if (viewProps.containerProps.isOpen) {
                            tracker.track('modal_viewed', {
                                modal_id: config.id,
                            });
                        }
                    }, [viewProps.containerProps.isOpen]);
                    return viewProps;
                },
            };
        }

        function enhanceController(
            config: ModalControllerConfig,
            controller: ModalController
        ): ModalController {
            return {
                ...controller,
                useProps(props) {
                    const tracker = useTracker();
                    const viewProps = controller.useProps(props);
                    useEffect(() => {
                        if (viewProps.containerProps.isOpen) {
                            tracker.track('modal_viewed', {
                                modal_id: config.id,
                            });
                        }
                    }, [viewProps.containerProps.isOpen]);
                    return viewProps;
                },
            };
        }

        function enhanceApplicationController(
            controller: ApplicationController
        ): ApplicationController {
            return {
                ...controller,
                createModal(...args) {
                    const instance = controller.createModal(...args);
                    return enhanceController(...args, instance);
                },
            };
        }

        const instance = create({
            ...init,
            v2: {
                ...init.v2,
                application: {
                    ...init.v2.application,
                    createView(config) {
                        const instance = init.v2.application.createView(config);
                        return enhanceApplicationController(instance);
                    },
                },
            },
            controller: {
                ...init.controller,
                layout: {
                    ...init.controller.layout,
                    createV1Modal(...args) {
                        const controller = init.controller.layout.createV1Modal(...args);
                        return enhanceV1Controller(controller);
                    },
                    createModal(modalConfig, ...rest) {
                        const controller = init.controller.layout.createModal(
                            modalConfig,
                            ...rest
                        );
                        return enhanceController(modalConfig, controller);
                    },
                },
            },
        });
        return instance;
    };
}
