import React from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import { AiOutlineHome } from 'react-icons/ai';
import { Icons } from '../../../config';
import { createReportingRouter } from '../../route';
import { PageIds } from '../../config';
import { ReportingShellConfig, ReportingShellInit } from './reportingShellConfig';
import { createReportingRootContainer } from './page/root/reportingRootContainer';
import { createReportingRootController } from './page/root/reportingRootController';
import { createReportingRootLoader } from './page/root/reportingRootLoader';
import {
    ReportingShellContainerBaseConfig,
    ReportingShellControllerBaseConfig,
    ReportingShellLoaderBaseConfig,
} from './base';
import {
    createNotificationListController,
    createReportingAccountController,
    createReportingNavigationController,
    createShortcutListController,
} from './view';
import {
    ReportingShellComponents,
    ReportingShellEnhancer,
} from './reportingShellInterface';
import {
    ReportingShellContext,
    createReportingContextController,
    creatReportingContextLoader,
    createReportingErrorContainer,
    createReportingErrorController,
    createReportingShellContextContainer,
    createReportingShellContextProvider,
} from './page';

export function createReportingShell(
    config: ReportingShellInit,
    enhancer?: ReportingShellEnhancer
) {
    return create(
        {
            ...config,
            provider: {
                createRootController: createReportingRootController,
                createNavigationController: createReportingNavigationController,
                createAccountController: createReportingAccountController,
                createNotificationController: createNotificationListController,
                createShortcutController: createShortcutListController,
                createReportingRouter: createReportingRouter,
            },
            enhancer: {
                reporting: null,
            },
        },
        enhancer
    );
}

function create(
    config: ReportingShellConfig,
    enhancer?: ReportingShellEnhancer
): ReportingShellComponents {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const Context = React.createContext<ReportingShellContext | null>(null);

    const loaderConfig: ReportingShellLoaderBaseConfig = {
        kernel: config.kernel,
        repository: config.repository,
    };

    const controllerConfig: ReportingShellControllerBaseConfig = {
        kernel: config.kernel,
        view: config.view,
        hook: config.hook,
    };

    const context = createReportingShellContextProvider(
        createReportingShellContextContainer(
            config,
            Context,
            creatReportingContextLoader(loaderConfig),
            createReportingContextController(controllerConfig)
        ),
        Context
    );

    const containerConfig: ReportingShellContainerBaseConfig = {
        basePath: config.basePath,
        kernel: config.kernel,
        context,
        Theme: config.Theme,
        view: config.view,
        UI: config.UI,
        repository: config.repository,
    };

    const loader = createReportingRootLoader(loaderConfig);
    const controller = config.provider.createRootController({
        ...config,
        controller: {
            navigation: config.provider.createNavigationController(controllerConfig),
            account: config.provider.createAccountController(controllerConfig),
            shortcut: config.provider.createShortcutController(controllerConfig),
            notification: config.provider.createNotificationController(controllerConfig),
        },
    });

    const Shell = createReportingRootContainer(
        {
            ...containerConfig,
            shortcuts: {
                items: [
                    {
                        id: 'shortcut',
                        label: 'New report',
                        kind: 'link',
                        variant: 'primary',
                        Icon: Icons.Reports.Report,
                        path: `/${config.basePath}/home`,
                    },
                ],
            },
            navigation: {
                items: [
                    {
                        id: 'home',
                        label: 'Home',
                        Icon: AiOutlineHome,
                        path: `/${config.basePath}/home`,
                    },
                    {
                        id: 'reports',
                        label: 'Reports',
                        Icon: AiOutlineHome,
                        path: `/${config.basePath}/reports`,
                    },
                ],
            },
            Container: {
                Error: createReportingErrorContainer(
                    containerConfig,
                    createReportingErrorController()
                ),
            },
        },
        loader,
        controller
    );

    const Layout = {
        Centered: config.layout.centered.create({}),
    };

    const {
        Pages: { Report: ReportPage, Chapter: ChapterPage, Content: ContentPage },
    } = config.provider.createReportingRouter(
        {
            UI: config.UI,
            Layout,
            kernel: config.kernel,
            context,
            controller: config.view,
        },
        config.enhancer.reporting ?? undefined
    );

    const { Provider: ContextProvider } = context;

    const element = (
        <Route
            path={config.basePath}
            element={
                <ContextProvider>
                    <Shell>
                        <Outlet />
                    </Shell>
                </ContextProvider>
            }
        >
            <Route index={true} element={<Navigate to="home" replace={true} />} />
            <Route path="home">
                <Route index={true} element={<ReportPage.Generate />} />
            </Route>
            <Route path="reports">
                <Route index={true} element={<ReportPage.Overview />} />
                <Route path="new" element={<ReportPage.New />} />
                <Route path=":reportId">
                    <Route index={true} element={<ReportPage.Detail />} />
                    <Route
                        path=":chapterId"
                        element={
                            <>
                                <ChapterPage.Detail />
                                <ContentPage.Overlay />
                            </>
                        }
                    />
                </Route>
            </Route>
        </Route>
    );

    return {
        element,
    };
}
