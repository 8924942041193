import {
    Box,
    Button,
    ButtonGroup,
    ButtonProps,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    SimpleGrid,
    StackDivider,
    Tag,
    Text,
    TextProps,
    Tooltip,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import pluralize from 'pluralize';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    AiOutlineEdit,
    AiOutlineHeart,
    AiOutlineHistory,
    AiOutlineInfoCircle,
    AiOutlinePlus,
} from 'react-icons/ai';
import { VAROS_YELLOW } from '../../../../../config';
import { ReportingViewBaseConfig } from '../../../base';
import { ReportDetailViewProps } from './reportDetailProps';
import { ReportChapterViewProps } from '../../chapter';
import { ReportAggregateChapterProps } from '../aggregate';

export function createReportDetailView(
    config: ReportingViewBaseConfig
): React.FC<ReportDetailViewProps> {
    const {
        UI: {
            Application: { Link },
        },
        Layout: {
            Centered: {
                Component: {
                    Root: LayoutRoot,
                    Header: LayoutHeader,
                    Content: LayoutContent,
                    Body: LayoutBody,
                },
            },
        },
    } = config;

    const colors = {
        // accent: VAROS_YELLOW[50],
        // accentLight: VAROS_YELLOW[50],
        accent: 'whiteAlpha.500',
        accentLight: 'whiteAlpha.200',
    } satisfies Record<string, TextProps['color']>;

    const Card: React.FC<ReportAggregateChapterProps> = (props) => {
        return (
            <VStack align="start" w="full" spacing={4}>
                <VStack align="start" spacing={2} w="full">
                    <VStack align="start" spacing={1} w="full">
                        <HStack w="full" justify="space-between" align="start">
                            <Text
                                fontWeight="bold"
                                color={colors.accent}
                                fontSize="sm"
                                textTransform="uppercase"
                                letterSpacing="wider"
                            >
                                {props.caption}
                            </Text>
                            <Tooltip
                                placement="top"
                                openDelay={300}
                                px={2}
                                py={1}
                                bg="gray.50"
                                color="gray.900"
                                shouldWrapChildren={true}
                                label={props.edited.description}
                            >
                                <Text
                                    userSelect="none"
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color="whiteAlpha.500"
                                >
                                    4h ago
                                </Text>
                            </Tooltip>
                        </HStack>
                        <HStack w="full" justify="space-between" align="start">
                            <Link
                                {...props.action.link}
                                style={{ textDecoration: 'none' }}
                            >
                                <Heading
                                    color="whiteAlpha.800"
                                    fontWeight="semibold"
                                    fontSize="lg"
                                    cursor="pointer"
                                    _hover={{ textDecor: 'underline' }}
                                    noOfLines={1}
                                >
                                    {props.title}
                                </Heading>
                            </Link>
                        </HStack>
                    </VStack>
                    <Text color="whiteAlpha.600" fontSize="md" noOfLines={2}>
                        {props.description}
                    </Text>
                </VStack>
                <HStack spacing={2} flexShrink={0} w="full" justify="space-between">
                    <Link {...props.action.link}>
                        <Button
                            variant="outline"
                            colorScheme="blue"
                            bg="none"
                            borderRadius="full"
                        >
                            Read more
                        </Button>
                    </Link>
                </HStack>
            </VStack>
        );
    };
    return (props) => (
        <LayoutRoot>
            <LayoutContent>
                <LayoutHeader>
                    <HStack w="full" justify="space-between" align="start">
                        <HStack w="full">
                            <VStack align="start" spacing={2} w="full">
                                <VStack align="start" spacing={0} w="full">
                                    <HStack fontSize="md" fontWeight="medium">
                                        <Link to="../">
                                            <Text
                                                color="blue.300"
                                                _hover={{ color: 'blue.400' }}
                                                cursor="pointer"
                                            >
                                                Reports
                                            </Text>
                                        </Link>
                                    </HStack>
                                    <VStack align="start" w="full" spacing={0}>
                                        <HStack w="full" justify="space-between">
                                            <Heading
                                                color="whiteAlpha.900"
                                                fontSize="2xl"
                                                fontWeight="semibold"
                                            >
                                                {props.item.report.title}
                                            </Heading>
                                            <HStack spacing={3}>
                                                <HStack spacing={1}>
                                                    <Tooltip
                                                        placement="top"
                                                        openDelay={300}
                                                        px={3}
                                                        py={2}
                                                        bg="gray.50"
                                                        color="gray.900"
                                                        shouldWrapChildren={true}
                                                        label="Favorite this report"
                                                    >
                                                        <IconButton
                                                            aria-label="back"
                                                            bg="none"
                                                            _hover={{
                                                                bg: 'whiteAlpha.50',
                                                            }}
                                                            icon={
                                                                <Icon
                                                                    fontSize="lg"
                                                                    color="whiteAlpha.800"
                                                                    as={AiOutlineHeart}
                                                                />
                                                            }
                                                        />
                                                    </Tooltip>
                                                </HStack>
                                                <ButtonGroup
                                                    isAttached={true}
                                                    borderWidth={1}
                                                    borderColor="whiteAlpha.300"
                                                    variant="outline"
                                                    borderRadius="full"
                                                    overflow="hidden"
                                                >
                                                    <Button
                                                        color="whiteAlpha.800"
                                                        bg="none"
                                                        _hover={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        borderRightWidth={1}
                                                        borderRightColor="whiteAlpha.300"
                                                    >
                                                        Configure
                                                    </Button>
                                                    <IconButton
                                                        aria-label="more"
                                                        bg="none"
                                                        _hover={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        icon={
                                                            <Icon
                                                                fontSize="lg"
                                                                as={ChevronDownIcon}
                                                            />
                                                        }
                                                    />
                                                </ButtonGroup>
                                            </HStack>
                                        </HStack>
                                        <HStack
                                            spacing={2}
                                            color="whiteAlpha.500"
                                            divider={<Box border="none">&#x2022;</Box>}
                                        >
                                            {props.item.report.keywords.map((keyword) => (
                                                <HStack key={keyword.id} spacing={2}>
                                                    {keyword.Icon && (
                                                        <Icon as={keyword.Icon} />
                                                    )}
                                                    <Text>{keyword.label}</Text>
                                                </HStack>
                                            ))}
                                        </HStack>
                                    </VStack>
                                </VStack>
                            </VStack>
                        </HStack>
                    </HStack>
                </LayoutHeader>
                <LayoutBody>
                    <VStack align="start" w="full" spacing={12}>
                        <SimpleGrid
                            columns={2}
                            gap={6}
                            w="full"
                            templateColumns="min-content 1fr"
                        >
                            <Box maxH={16} py={1} h="full">
                                <Box
                                    bg="whiteAlpha.200"
                                    w={1.5}
                                    h="full"
                                    borderRadius="full"
                                />
                            </Box>
                            <Text fontSize="lg" color="whiteAlpha.700">
                                {props.item.summary.description}
                            </Text>
                        </SimpleGrid>
                        <VStack align="start" w="full" spacing={4}>
                            <HStack
                                w="full"
                                fontWeight="semibold"
                                color="whiteAlpha.800"
                                fontSize="lg"
                            >
                                <Text>Research stats</Text>
                                <Tooltip
                                    placement="top"
                                    px={3}
                                    py={2}
                                    bg="gray.50"
                                    color="gray.900"
                                    shouldWrapChildren={true}
                                    hasArrow={true}
                                    label={
                                        <>
                                            Varos combines large-scale AI analysis of
                                            public data with proprietary expert
                                            interviews, then applies a custom scoring
                                            system to filter for the most relevant,
                                            recent, reliable, and tactical insights used
                                            in the report
                                        </>
                                    }
                                >
                                    <Icon as={AiOutlineInfoCircle}></Icon>
                                </Tooltip>
                            </HStack>
                            <SimpleGrid
                                columns={{ base: 1, md: 2, lg: 3 }}
                                gap={{ base: 4, md: 6 }}
                                w="full"
                            >
                                {props.item.report.stats.items.map((stat) => (
                                    <VStack
                                        key={stat.label}
                                        align="start"
                                        spacing={1}
                                        w="full"
                                        bg="whiteAlpha.50"
                                        p={4}
                                        borderRadius="lg"
                                        userSelect="none"
                                    >
                                        <HStack
                                            w="full"
                                            justify="space-between"
                                            fontWeight="bold"
                                            textTransform="uppercase"
                                            letterSpacing="wider"
                                            spacing={3}
                                            color="whiteAlpha.500"
                                        >
                                            <Text fontSize="sm" noOfLines={1}>
                                                {stat.label}
                                            </Text>
                                            <Tooltip
                                                placement="top"
                                                px={3}
                                                py={2}
                                                bg="gray.50"
                                                color="gray.900"
                                                shouldWrapChildren={true}
                                                hasArrow={true}
                                                label={stat.description}
                                                isDisabled={stat.description === null}
                                            >
                                                <Icon as={AiOutlineInfoCircle}></Icon>
                                            </Tooltip>
                                        </HStack>
                                        <Text
                                            fontWeight="medium"
                                            fontSize="xl"
                                            color={VAROS_YELLOW[100]}
                                        >
                                            {stat.value}
                                        </Text>
                                    </VStack>
                                ))}
                            </SimpleGrid>
                            <HStack w="full" justify="end">
                                <Text
                                    color="whiteAlpha.500"
                                    cursor="pointer"
                                    _hover={{ textDecor: 'underline' }}
                                >
                                    Learn more about Varos research methodology
                                </Text>
                            </HStack>
                        </VStack>
                        <VStack
                            align="start"
                            w="full"
                            spacing={4}
                            // divider={
                            //     <StackDivider
                            //         borderWidth={1}
                            //         borderColor="whiteAlpha.200"
                            //     />
                            // }
                        >
                            <HStack
                                w="full"
                                fontWeight="semibold"
                                color="whiteAlpha.800"
                                fontSize="lg"
                            >
                                <Text>Executive summary</Text>{' '}
                                <Tooltip
                                    placement="top"
                                    px={3}
                                    py={2}
                                    bg="gray.50"
                                    color="gray.900"
                                    shouldWrapChildren={true}
                                    hasArrow={true}
                                    label={
                                        <>
                                            Voluptate commodo amet adipisicing quis cillum
                                            labore fugiat eiusmod elit minim incididunt
                                            velit et
                                        </>
                                    }
                                >
                                    <Icon as={AiOutlineInfoCircle}></Icon>
                                </Tooltip>
                            </HStack>
                            <Grid
                                w="full"
                                gap={4}
                                templateColumns={{
                                    base: 'repeat(1, 1fr)',
                                    // md: 'repeat(2, 1fr)',
                                }}
                            >
                                {props.item.chapters.map((chapter) => (
                                    <GridItem
                                        key={chapter.id}
                                        // bg="whiteAlpha.50"
                                        borderRadius="lg"
                                        borderWidth={2}
                                        borderColor="whiteAlpha.200"
                                        p={6}
                                    >
                                        <Card {...chapter} />
                                    </GridItem>
                                ))}
                                <GridItem
                                    display={{
                                        base: 'none',
                                        md: 'block',
                                    }}
                                    // bg="whiteAlpha.50"
                                    borderRadius="lg"
                                    borderWidth={2}
                                    borderColor="whiteAlpha.100"
                                    borderStyle="dashed"
                                    p={6}
                                    cursor="pointer"
                                    userSelect="none"
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                >
                                    <VStack w="full" h="full" justify="center">
                                        <HStack
                                            color="whiteAlpha.500"
                                            fontWeight="medium"
                                        >
                                            <Icon fontSize="lg" as={AiOutlinePlus}></Icon>
                                            <Text>Add topic</Text>
                                        </HStack>
                                    </VStack>
                                </GridItem>
                            </Grid>
                        </VStack>

                        <VStack
                            align="start"
                            w="full"
                            spacing={4}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.200"
                                />
                            }
                        >
                            <HStack w="full" justify="space-between">
                                <HStack
                                    fontWeight="semibold"
                                    color="whiteAlpha.800"
                                    fontSize="lg"
                                >
                                    <Text>Sources</Text>
                                    <Tooltip
                                        placement="top"
                                        px={3}
                                        py={2}
                                        bg="gray.50"
                                        color="gray.900"
                                        shouldWrapChildren={true}
                                        hasArrow={true}
                                        label={
                                            <>
                                                A curated list of all sources—interviews,
                                                blogs, case studies, and more—that were
                                                analyzed or referenced to generate
                                                insights in this report.
                                            </>
                                        }
                                    >
                                        <Icon as={AiOutlineInfoCircle} />
                                    </Tooltip>
                                </HStack>
                                <Text
                                    color="blue.300"
                                    fontWeight="medium"
                                    _hover={{ textDecor: 'underline' }}
                                    cursor="pointer"
                                    fontSize="sm"
                                >
                                    View all
                                </Text>
                            </HStack>
                            <VStack align="start" w="full" spacing={0}>
                                <Wrap>
                                    {props.source.categories.map((category, index) => {
                                        if (index === 0) {
                                            return (
                                                <Button
                                                    key={category.id}
                                                    size="md"
                                                    borderRadius="full"
                                                >
                                                    <HStack>
                                                        <Text>All</Text>
                                                        <Tag size="sm">28</Tag>
                                                    </HStack>
                                                </Button>
                                            );
                                        }
                                        return (
                                            <Button
                                                key={category.id}
                                                size="md"
                                                borderRadius="full"
                                                bg="none"
                                                borderWidth={2}
                                                borderColor="whiteAlpha.200"
                                                _hover={{ bg: 'whiteAlpha.50' }}
                                            >
                                                <HStack color="whiteAlpha.600">
                                                    <Text>{category.label}</Text>
                                                    <Tag size="sm">{category.count}</Tag>
                                                </HStack>
                                            </Button>
                                        );
                                    })}
                                </Wrap>
                                <VStack align="start" w="full" spacing={0}>
                                    {props.source.items.map(
                                        ({ source, ...item }, index, array) => (
                                            <VStack
                                                key={source.id}
                                                align="start"
                                                w="full"
                                                spacing={1}
                                                py={6}
                                                borderBottomWidth={
                                                    array.length - 1 === index
                                                        ? undefined
                                                        : 2
                                                }
                                                borderStyle="dashed"
                                                borderColor="whiteAlpha.200"
                                            >
                                                <HStack w="full" spacing={3}>
                                                    <Text
                                                        color="whiteAlpha.800"
                                                        fontWeight="semibold"
                                                        noOfLines={1}
                                                        cursor="pointer"
                                                        _hover={{
                                                            textDecor: 'underline',
                                                        }}
                                                    >
                                                        {source.label}
                                                    </Text>
                                                    <HStack>
                                                        <Text
                                                            fontSize="sm"
                                                            fontWeight="medium"
                                                            color="whiteAlpha.500"
                                                            whiteSpace="nowrap"
                                                        >
                                                            {item.references.label}
                                                        </Text>
                                                    </HStack>
                                                </HStack>
                                                <Text
                                                    color="whiteAlpha.600"
                                                    noOfLines={1}
                                                >
                                                    {source.description}
                                                </Text>
                                            </VStack>
                                        )
                                    )}
                                </VStack>
                            </VStack>
                        </VStack>
                    </VStack>
                </LayoutBody>
            </LayoutContent>
        </LayoutRoot>
    );
}
