import * as Sentry from '@sentry/browser';
import { createAssistantSdk } from '@varos/assistant-sdk';
import { createToaster } from '../../../infra';
import { useTracker } from '../../../hooks';
import { createInterviewEntrypoint } from '../../entrypoint/interview';
import { createApplicationIcons } from '../../icon';
import {
    createAxios,
    createChakraUI,
    createErrorFormatterImpl,
    createIntercomHelpImpl,
    createIntlFormatter,
    createRepositoryImpl,
    createUrlOptionParser,
} from '../../impl';
import { configureKernel } from '../../base';
import { createApplicationController } from '../../view/common';
import { createAssistantRepositories } from '../../app/assistant';
import { configureAssistantRouter } from '../../route';

export function configureInterviewEntrypoint() {
    return createInterviewEntrypoint({
        infra: {
            createKernel: configureKernel,
            createUseTracker() {
                return useTracker as any;
            },
            createApplicationIcons: createApplicationIcons,
            createHelp: createIntercomHelpImpl,
            createToaster: createToaster,
            createUrlOptionParser: createUrlOptionParser,
            createErrorFormatter: createErrorFormatterImpl,
            getIntercom() {
                // @ts-expect-error
                return window.Intercom ?? null;
            },
            createAxios: createAxios,
            createRepository: createRepositoryImpl,
            createSentry() {
                return Sentry;
            },
            createFormatter() {
                return createIntlFormatter();
            },
        },
        ui: {
            createApplication: createChakraUI,
        },
        api: {
            createAssistant: createAssistantSdk,
        },
        common: {
            createApplication: createApplicationController,
        },
        repository: {
            createAssistant: createAssistantRepositories,
        },
        router: {
            createAssistant: configureAssistantRouter,
        },
    });
}
