import {
    Box,
    BoxProps,
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    StackDivider,
    Text,
    VStack,
    Link as ChakraLink,
    Checkbox,
} from '@chakra-ui/react';
import React from 'react';
import { Graphics } from '../../../../../config/svg';
import { Icons } from '../../../../../../config';
import { AssistantViewBaseConfig } from '../../../base';
import { ThreadNewViewProps } from './threadNewProps';
import {
    MdOutlineRadioButtonChecked,
    MdOutlineRadioButtonUnchecked,
} from 'react-icons/md';
import { AiOutlineClockCircle, AiOutlineInfo, AiOutlineInfoCircle } from 'react-icons/ai';

export function createThreadNewView(
    config: AssistantViewBaseConfig
): React.FC<{ children?: React.ReactNode | undefined } & ThreadNewViewProps> {
    const {
        Layout,
        UI: {
            Application: { Link, Tooltip },
        },
    } = config;

    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    return (props) => {
        return (
            <form
                style={{ width: '100%', height: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Layout title={undefined}>
                    <HStack w="full" justify="center" p={{ base: 3, md: 6, '2xl': 16 }}>
                        <VStack
                            align="start"
                            w="full"
                            maxW={containerStyle.maxW}
                            p={6}
                            spacing={{ base: 8, md: 8 }}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.300"
                                />
                            }
                        >
                            <VStack w="full" align="start" spacing={{ base: 8, md: 8 }}>
                                <Box
                                    p={{ base: 4, md: 6 }}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.300"
                                    color="whiteAlpha.600"
                                    borderRadius="full"
                                >
                                    <Image
                                        position="relative"
                                        left={-0.5}
                                        top={0.5}
                                        w={{ base: 4, md: 6 }}
                                        h={{ base: 4, md: 6 }}
                                        src={Graphics.Brand.Icon}
                                    />
                                </Box>
                                <VStack align="start" spacing={4} w="full">
                                    <Grid
                                        w="full"
                                        templateColumns={{ base: '1fr', md: '3fr 1fr' }}
                                        gap={{ base: 3, md: 4 }}
                                    >
                                        <GridItem>
                                            <VStack align="start" spacing={0}>
                                                <Text
                                                    fontSize="sm"
                                                    fontWeight="bold"
                                                    color="blue.200"
                                                    textTransform="uppercase"
                                                    letterSpacing="wider"
                                                >
                                                    Interview
                                                </Text>
                                                <Text fontSize="2xl" fontWeight="medium">
                                                    {props.workflow.title}
                                                </Text>
                                            </VStack>
                                        </GridItem>
                                        <GridItem>
                                            <HStack
                                                w="full"
                                                justify={{ base: 'start', md: 'end' }}
                                                flexShrink={0}
                                            >
                                                <Tooltip
                                                    placement="top"
                                                    arrowShadowColor="gray.100"
                                                    shouldWrapChildren={true}
                                                    py={2}
                                                    px={3}
                                                    hasArrow={true}
                                                    bg="gray.100"
                                                    color="gray.900"
                                                    label={`The interview will take about 15 minutes`}
                                                >
                                                    <HStack
                                                        whiteSpace="nowrap"
                                                        userSelect="none"
                                                        fontSize="md"
                                                        color="whiteAlpha.500"
                                                        spacing={2}
                                                        flexShrink={0}
                                                        fontWeight="medium"
                                                    >
                                                        <Icon as={AiOutlineClockCircle} />
                                                        <Text>15 minutes</Text>
                                                    </HStack>
                                                </Tooltip>
                                            </HStack>
                                        </GridItem>
                                    </Grid>

                                    {props.workflow.description && (
                                        <Text fontSize="lg" color="whiteAlpha.700">
                                            {props.workflow.description}
                                        </Text>
                                    )}
                                </VStack>
                                <VStack align="start" w="full" spacing={3}>
                                    <Text fontWeight="semibold" color="whiteAlpha.800">
                                        Select interview mode
                                    </Text>
                                    <Grid
                                        w="full"
                                        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                                        gap={{ base: 3, md: 4 }}
                                    >
                                        {props.field.mode.options.map((option) => (
                                            <GridItem key={option.id}>
                                                <HStack
                                                    onClick={option.onClick}
                                                    aria-checked={option.isSelected}
                                                    w="full"
                                                    justify="space-between"
                                                    cursor="pointer"
                                                    userSelect="none"
                                                    role="group"
                                                    spacing={3}
                                                    p={{ base: 3, md: 4 }}
                                                    borderWidth={2}
                                                    borderColor="whiteAlpha.300"
                                                    borderRadius="xl"
                                                    _checked={{
                                                        borderColor: 'blue.300',
                                                        _hover: { bg: 'none' },
                                                    }}
                                                    _hover={{ bg: 'whiteAlpha.50' }}
                                                >
                                                    <HStack w="full" spacing={3}>
                                                        <Icon as={option.Icon} />
                                                        <Text
                                                            color="whiteAlpha.800"
                                                            fontWeight="semibold"
                                                        >
                                                            {option.label}
                                                        </Text>
                                                        <Tooltip
                                                            {...option.tooltip}
                                                            placement="top"
                                                            arrowShadowColor="gray.100"
                                                            shouldWrapChildren={true}
                                                            py={2}
                                                            px={3}
                                                            hasArrow={true}
                                                            bg="gray.100"
                                                            color="gray.900"
                                                        >
                                                            <Icon
                                                                as={AiOutlineInfoCircle}
                                                            />
                                                        </Tooltip>
                                                    </HStack>
                                                    <Icon
                                                        as={
                                                            option.isSelected
                                                                ? MdOutlineRadioButtonChecked
                                                                : MdOutlineRadioButtonUnchecked
                                                        }
                                                        aria-checked={option.isSelected}
                                                        color="whiteAlpha.300"
                                                        _checked={{ color: 'blue.300' }}
                                                    />
                                                </HStack>
                                            </GridItem>
                                        ))}
                                    </Grid>
                                </VStack>
                            </VStack>
                            <Grid
                                w="full"
                                templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                                gap={{ base: 4, md: 4 }}
                            >
                                <GridItem>
                                    <HStack
                                        w="full"
                                        align="start"
                                        spacing={{ base: 3, md: 4 }}
                                        color="whiteAlpha.800"
                                        userSelect="none"
                                    >
                                        <Checkbox
                                            {...props.terms.confirm.checkbox}
                                            size="lg"
                                            position="relative"
                                            top={1}
                                        />
                                        <Text>
                                            By clicking this, I agree to Varos'{' '}
                                            <ChakraLink
                                                as={Link}
                                                {...props.terms.link}
                                                display="inline-block"
                                                whiteSpace="wrap"
                                                wordBreak="break-word"
                                                color="blue.300"
                                            >
                                                Terms & Conditions
                                            </ChakraLink>
                                        </Text>
                                    </HStack>
                                </GridItem>
                                <GridItem
                                // order={{ base: -1, md: 'inherit' }}
                                >
                                    <HStack w="full" justify="end">
                                        <HStack
                                            w={{ base: 'full', md: 'fit-content' }}
                                            justify="end"
                                        >
                                            <Tooltip
                                                {...props.form.getSubmitTooltipProps()}
                                                w="full"
                                                placement="top"
                                                arrowShadowColor="gray.100"
                                                py={2}
                                                px={3}
                                                hasArrow={true}
                                                bg="gray.100"
                                                color="gray.900"
                                            >
                                                <Button
                                                    {...props.form.getSubmitButtonProps()}
                                                    w="full"
                                                    size="lg"
                                                    borderRadius="full"
                                                    colorScheme="green"
                                                    _focus={{ outline: 'none' }}
                                                >
                                                    {props.workflow.button.label}
                                                </Button>
                                            </Tooltip>
                                        </HStack>
                                    </HStack>
                                </GridItem>
                            </Grid>
                        </VStack>
                    </HStack>
                </Layout>
            </form>
        );
    };
}
