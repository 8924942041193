import { QueryCache, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AssistantSdk, ThreadCheckpointPayload } from '@varos/assistant-sdk';
import { ApplicationContext, Kernel } from '../../../base';
import { ConversationThreadCreateProps } from './conversationThreadProps';
import { ThreadRepository } from './conversationThreadInterface';

export function createThreadRepository(
    kernel: Kernel,
    api: AssistantSdk
): ThreadRepository {
    const PREFIX = ['convesations', 'thread'];
    const PREFIX_FIND = [...PREFIX, 'find'];
    const PREFIX_LOOKUP = [...PREFIX, 'lookup'];

    function getLookupKey(context: ApplicationContext, props: { id: string }) {
        return [...PREFIX_LOOKUP, context.principal?.id, props.id];
    }

    return {
        async refetch(context, client, id) {
            if (id) {
                console.log('refetching thread', id);
                const queryKey = getLookupKey(context, { id });
                await client.refetchQueries(queryKey);
                return;
            }
            console.log('refetching threads list entry');
            await client.refetchQueries(PREFIX_FIND);
        },
        useFind(context, query, options) {
            const client = useQueryClient();
            const result = kernel.infra.repository.useQuery({
                ...options,
                queryKey: [...PREFIX_FIND, query],
                async queryFn() {
                    const response = await api.threadList(query);
                    return response.data.data;
                },
                onSuccess(data) {
                    for (const item of data) {
                        const queryKey = getLookupKey(context, item);
                        client.setQueryData(queryKey, item);
                    }
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: getLookupKey(context, query),
                async queryFn() {
                    const response = await api.threadRetrieve({
                        threadId: query.id,
                    });
                    return response.data;
                },
            });
            return result;
        },
        useCreate(context) {
            const client = useQueryClient();
            const result = useMutation({
                async mutationFn(props: ConversationThreadCreateProps) {
                    const response = await api.threadCreate({
                        threadCreatePayload: props,
                    });
                    return response.data;
                },
                async onSuccess(data, variables, _context) {
                    const queryKey = getLookupKey(context, data);
                    client.setQueryData(queryKey, data);
                    client.invalidateQueries(PREFIX);
                },
            });
            return result;
        },
        useCheckpoint(context) {
            const client = useQueryClient();
            const result = useMutation({
                async mutationFn(
                    props: [threadId: string, payload: ThreadCheckpointPayload]
                ) {
                    const [id, payload] = props;
                    const response = await api.threadCheckpoint({
                        threadId: id,
                        threadCheckpointPayload: payload,
                    });
                    return response.data;
                },
            });
            return result;
        },
    };
}
