import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AnyToolInstance, createDynamicTool } from '@varos/openai-realtime-client';
import {
    createClientController,
    createConnectionController,
} from '@varos/openai-realtime-react';
import {
    MessageCreatePayload,
    ThreadCreateFormPayloadData,
    ThreadObject,
} from '@varos/assistant-sdk';
import { useSearchParams } from 'react-router-dom';
import { AssistantCallCreateMutation } from '../../../../../app/assistant';
import { AssistantContainerBaseConfig } from '../../../base';
import {
    ThreadDetailController,
    ThreadDetailLoader,
    ThreadDetailServiceProvider,
    ThreadStateProvider,
} from './threadDetailInterface';
import { ThreadDetailOptionSchema } from './threadDetailSchema';
import { ThreadDetailAggregate, ThreadDetailStateValues } from './threadDetailModel';
import { ThreadDetailContainerProps, ThreadDetailViewProps } from './threadDetailProps';
import { useDisclosure } from '@chakra-ui/react';

export function createThreadDetailContainer(
    config: AssistantContainerBaseConfig,
    states: ThreadStateProvider,
    services: ThreadDetailServiceProvider,
    loader: ThreadDetailLoader,
    controller: ThreadDetailController,
    View: React.FC<ThreadDetailViewProps>
): React.FC<ThreadDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        repository: {
            session: sessionRepository,
            message: messageRepository,
            call: callRepository,
        },
        context: {
            root: { useContext },
            thread: { Provider: ThreadContextProvider },
        },
    } = config;

    const BASE_URL = 'https://api.openai.com/v1/realtime';
    const MODEL = 'gpt-4o-realtime-preview-2024-12-17';

    // every 25 minutes
    const TOKEN_REFRESH_INTERVAL = 25 * 60 * 1000;

    const clientController = createClientController({
        autosaveIntervalMs: 500,
        autoReplyIfEmpty: true,
        maxMessageBufferSize: 50,
    });

    const connectionController = createConnectionController({
        endpoint: `${BASE_URL}?model=${MODEL}`,
        closeGracePeriodMs: 10000,
    });

    function getBackPath(thread: ThreadObject) {
        if (typeof (thread.input as ThreadCreateFormPayloadData).offer === 'string') {
            const { offer } = thread.input as ThreadCreateFormPayloadData;
            return `/contributor/rewards/offers/${offer}`;
        }
        return '/contributor/rewards/offers/discover';
    }

    function createTools(
        item: ThreadDetailAggregate,
        props: {
            mutation: {
                call: AssistantCallCreateMutation;
            };
        }
    ): AnyToolInstance[] {
        return item.session.actions.map((action) => {
            return createDynamicTool({
                definition: {
                    name: action.name,
                    description: action.description,
                    // @ts-expect-error
                    parameters: action.schema,
                },
                async apply(payload) {
                    console.log('calling remote tool', payload, action);
                    const result = await props.mutation.call.mutateAsync({
                        thread: item.thread.id,
                        tool: action.name,
                        arguments: payload,
                    });
                    if (result.closed) {
                        console.log('thread was closed by tool');
                    }
                    console.log('remote tool result', result);
                    return result.artifacts;
                },
            });
        });
    }

    return ({ ...containerProps }) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const context = useContext();
        const options = useOptions(ThreadDetailOptionSchema);
        const globalState = states.useState(context, {
            searchParams: [searchParams, setSearchParams],
        });

        // Create the disclosure for the end confirmation directly
        const endDisclosure = useDisclosure();

        const mutation = {
            message: {
                create: messageRepository.useCreate(context),
            },
            session: {
                create: sessionRepository.useCreate(context),
            },
            call: {
                create: callRepository.useCreate(context),
            },
        };

        const mutationRef = useRef(mutation.session.create);

        useEffect(() => {
            mutationRef.current = mutation.session.create;
        }, [mutation.session.create]);

        const data = loader.useLoad(context, {
            thread: {
                id: options.threadId,
            },
            session: {
                thread: options.threadId,
                type: 'realtime',
                modalities:
                    globalState.mode.value === 'text' ? ['text'] : ['text', 'audio'],
            },
        });

        // Use state to track session token with a counter to force reconnection
        const [sessionToken, setSessionToken] = useState<string>(
            mutation.session.create.data?.client_secret ?? data.session.client_secret
        );

        // Refresh session token periodically
        useEffect(() => {
            let mounted = true;
            const refreshSession = async () => {
                try {
                    console.log('refreshing session');
                    const response = await mutationRef.current.mutateAsync({
                        thread: options.threadId,
                        type: 'realtime',
                        modalities:
                            globalState.mode.value === 'text'
                                ? ['text']
                                : ['text', 'audio'],
                    });

                    if (mounted && response.client_secret !== sessionToken) {
                        console.log('received new token - updating');
                        // Update both token and counter to force reconnection
                        setSessionToken(response.client_secret);
                    }
                } catch (error) {
                    console.error('Failed to refresh session token:', error);
                }
            };

            // Set up interval for token refresh
            const interval = setInterval(refreshSession, TOKEN_REFRESH_INTERVAL);

            // Run initial refresh
            refreshSession();

            return () => {
                mounted = false;
                clearInterval(interval);
            };
        }, [globalState.mode.value, options.threadId]);

        const tools = useMemo((): AnyToolInstance[] => {
            // NOTE that tools are static and should not change during the conversation
            return createTools(data, {
                mutation: {
                    call: mutation.call.create,
                },
            });
        }, []);

        const connection = connectionController.useProps({
            mode: globalState.mode.value === 'text' ? 'text-only' : 'audio',
            disabled: data.thread.status === 'closed',
            debug: false, // Enable debugging to track token changes
            token: sessionToken,
            onReady() {
                console.log('connection is ready');
            },
            onClose() {
                console.log('connection closed');
            },
            onError(error) {
                console.error('connection error', error);
            },
        });

        const clientProps = clientController.useProps({
            initial: data.initialMessages,
            connection: connection,
            async onAutosave(messages) {
                for (const message of messages) {
                    const payload: MessageCreatePayload = {
                        thread: data.thread.id,
                        role: message.role,
                        content:
                            message.kind == 'text'
                                ? [
                                      {
                                          kind: 'text',
                                          text: message.content,
                                      },
                                  ]
                                : [
                                      {
                                          kind: 'audio_input',
                                          transcript: message.transcript,
                                          duration_ms: message.durationMs,
                                      },
                                  ],
                    };
                    await mutation.message.create.mutateAsync(payload);
                }
            },
            tools,
        });

        const mostRecentMessage = clientProps.message.items[0];

        const error =
            mutation.call.create.error ??
            (connection.state.status === 'error' ? connection.state.error : null) ??
            (connection.state.status === 'disconnected'
                ? new Error('Chat session disconnected')
                : null);

        const props = controller.useProps(context, data, {
            messages: {
                items: clientProps.message.items,
            },
            confirm: {
                end: {
                    disclosure: endDisclosure,
                    isSubmitting: null,
                    async onSubmit() {
                        console.log('Starting end interview directive...');

                        try {
                            // Send directive and wait for completion - this will show loading state automatically
                            await clientProps.sendDirective({
                                role: 'system',
                                text: 'The user has requested to end the interview prematurely',
                            });
                            console.log('Directive completed');

                            // Create a delay function
                            const delay = (ms: number) =>
                                new Promise((resolve) => setTimeout(resolve, ms));

                            // Instead of polling in a loop, just wait a fixed amount of time
                            // This gives the system time to process the directive and close the thread
                            console.log('Waiting for thread closure to complete...');
                            await delay(2000);

                            console.log('Thread close detection complete');

                            // The confirmation controller will automatically:
                            // 1. Handle the loading state while this promise is resolving
                            // 2. Close the modal when this promise resolves
                        } catch (error) {
                            console.error('End interview directive failed:', error);
                            return undefined; // Prevent closing on error
                        }
                    },
                },
            },
            mode: globalState.mode.value,
            connection,
            status:
                !error &&
                (mostRecentMessage?.role === 'user' ||
                    clientProps.message.items.length === 0)
                    ? 'processing'
                    : 'settled',
            isSubmitting: false,
            error: error ?? null,
            navigation: {
                back: {
                    path: getBackPath(data.thread),
                    showBackButton: data.thread.status === 'closed',
                },
            },
        });

        const service = services.useService(context, data, {
            connection: connection,
            client: clientProps,
            mutation: {
                message: {
                    create: mutation.message.create,
                },
                session: {
                    create: mutation.session.create,
                },
            },
        });

        return (
            <ThreadContextProvider item={data} service={service}>
                <View {...props} />
            </ThreadContextProvider>
        );
    };
}
