import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDisclosure, useToken } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import { PageIds } from '../../../../config';
import { ReportingShellUrlParamSchema } from '../../reportingShellSchema';
import { ReportingShellContainerBaseConfig } from '../../base';
import { ReportingRootContainerProps } from './reportingRootProps';
import { ReportingErrorContainerProps } from '../error';
import { ReportingRootController, ReportingRootLoader } from './reportingRootlInterface';
import {
    ReportingNavigationControllerProps,
    ShortcutListControllerProps,
} from '../../view';

export function createReportingRootContainer(
    config: ReportingShellContainerBaseConfig & {
        Container: {
            Error: React.FC<ReportingErrorContainerProps>;
        };
        navigation: Pick<ReportingNavigationControllerProps, 'items'>;
        shortcuts: Pick<ShortcutListControllerProps, 'items'>;
    },
    loader: ReportingRootLoader,
    controller: ReportingRootController
): React.FC<ReportingRootContainerProps> {
    const {
        UI: {
            Application: { createErrorBoundary },
        },
        Container: { Error: ErrorContainer },
        Theme: {
            Desktop: { Shell: DesktopView, Error: DesktopErrorView },
            Mobile: { Shell: MobileView, Error: MobileErrorView },
        },
        context: { useContext },
        kernel: {
            infra: {
                overlay: { Provider: OverlayProvider },
                optionParser: { useOptions },
            },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    return ({ children, ...containerProps }) => {
        const location = useLocation();
        const navigate = useNavigate();
        const overlayRef = useRef<HTMLDivElement>(null);
        const backgroundColor = useToken('colors', 'whiteAlpha.100');
        const [searchParams, setSearchParams] = useSearchParams();

        const context = useContext();

        const options = useOptions(ReportingShellUrlParamSchema);
        const data = loader.useLoad(context, containerProps);

        const props = controller.useProps(context, data, {
            location,
            navigate,
            home: {
                link: {
                    to: `/${config.basePath}`,
                },
            },
            status: null,
            shortcut: {
                items: config.shortcuts.items,
            },
            account: {
                disclosure: useDisclosure(),
                actions: [],
                action: {
                    logout: {
                        id: 'logout',
                        name: 'Logout',
                        kind: 'custom',
                        Icon: AiOutlineLogout,
                        async onPerform() {
                            await config.kernel.infra.authenticator.logout(context);
                            window.location.href = '/';
                        },
                    },
                },
            },
            notification: {
                disclosure: useDisclosure(),
            },
            navigation: {
                navigate,
                location,
                disclosure: useDisclosure({
                    isOpen: options.overlay === 'workspace',
                    onOpen() {
                        searchParams.set('overlay', 'workspace');
                        setSearchParams(searchParams);
                    },
                    onClose() {
                        searchParams.delete('overlay');
                        setSearchParams(searchParams);
                    },
                }),
                items: config.navigation.items,
            },
            footer: {
                copyright: `© Copyright ${new Date().getFullYear()} | Varos. All rights reserved.`,
                groups: [
                    {
                        label: 'Company',
                        links: [
                            {
                                label: 'Home',
                                href: 'https://varosresearch.com',
                            },
                            {
                                label: 'About us',
                                href: 'https://varosresearch.com',
                            },
                            {
                                label: 'How Varos works',
                                href: 'https://varosresearch.com',
                            },
                        ],
                    },
                ],
            },
            scroll: {
                ref: useRef(null),
            },
        });

        if (props.browser.mode === 'mobile') {
            return (
                <OverlayProvider overlayRef={overlayRef}>
                    <Helmet>
                        {/* set correct theme background for mobiel devices */}
                        <meta name="theme-color" content={backgroundColor} />
                    </Helmet>
                    <MobileView {...props}>
                        <ErrorBoundary
                            key={props.error.hash}
                            fallback={(info) => (
                                <ErrorContainer as={MobileErrorView} info={info} />
                            )}
                        >
                            {children}
                        </ErrorBoundary>
                    </MobileView>
                </OverlayProvider>
            );
        }
        return (
            <DesktopView {...props}>
                <ErrorBoundary
                    key={props.error.hash}
                    fallback={(info) => (
                        <ErrorContainer as={DesktopErrorView} info={info} />
                    )}
                >
                    {children}
                </ErrorBoundary>
            </DesktopView>
        );
    };
}
