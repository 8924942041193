import { benchmarks } from './developer/benchmarks';
import { implementations } from './developer/caseStudies';
import { improvementAreas } from './developer/improvementAreas';
import { technicalImplementationsRefactoring } from './developer/technicalImplementationsRefactoring';
import { technicalImplementationsTesting } from './developer/technicalImplementationsTesting';
import { vendors } from './developer/vendors';
import type { AuthorObject, ReportContentObject, ReportObject } from '../base/types';

export const report: ReportObject = {
    id: 'automating-developer-maintenance-tasks',
    title: `Automating Maintenance Tasks with GenAI`,
    status: 'published',
    updatedAt: new Date(2025, 3, 4),
};

export const author: AuthorObject = {
    id: '1',
    name: 'Susie',
};

export const content: ReportContentObject = {
    summary: `This report reveals a clear trend: large enterprises including banks, tech companies, and telecommunications firms are achieving significant productivity gains by strategically implementing generative AI for software development maintenance tasks. Organizations like Citi, JPMorgan, Slack, and AT&T have documented 20-40% productivity improvements by automating repetitive coding work. The most successful implementations focus on specific use cases rather than wholesale replacement of existing processes, and they combine traditional methods with AI while maintaining strong governance and security controls. Importantly, these tools free developer time from maintenance tasks to focus on innovation and business value.`,
    chapters: [
        implementations,
        benchmarks,
        improvementAreas,
        technicalImplementationsRefactoring,
        technicalImplementationsTesting,
        vendors,
    ].map((item, index) => ({ ...item, number: index + 1 })),
};
