import React, { useMemo } from 'react';
import {
    ThreadDetailContextProvider,
    ThreadDetailContextValue,
} from './threadContextInterface';

export function createThreadDetailContextProvider(): ThreadDetailContextProvider {
    const Context = React.createContext<ThreadDetailContextValue | null>(null);
    return {
        Provider({ children, ...containerProps }) {
            const value = useMemo((): ThreadDetailContextValue => {
                return {
                    item: containerProps.item,
                    service: containerProps.service,
                };
            }, [containerProps.item, containerProps.service]);
            return <Context.Provider value={value}>{children}</Context.Provider>;
        },
        useContext() {
            const context = React.useContext(Context);
            if (!context) {
                throw new Error('not inside thread context');
            }
            return context;
        },
    };
}
