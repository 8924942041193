import { ThreadCreatePayload } from '@varos/assistant-sdk';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Icons } from '../../../../../../config';
import { AssistantContainerBaseConfig } from '../../../base';
import { ThreadNewController, ThreadNewLoader } from './threadNewInterface';
import { ThreadNewViewProps } from './threadNewProps';
import { ThreadNewUrlParamSchema } from './threadNewSchema';
import { ConversationNewFormValues } from './threadNewModel';
import { ZodError } from 'zod';

export function createThreadNewContainer(
    config: AssistantContainerBaseConfig,
    loader: ThreadNewLoader,
    controller: ThreadNewController,
    View: React.FC<{ children?: React.ReactNode | undefined } & ThreadNewViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
                toaster: { useToast },
            },
        },
        repository: {
            thread: threadRepository,
            message: messageRepository,
            session: sessionRepository,
        },
        context: {
            root: { useContext },
        },
    } = config;
    return ({ ...containerProps }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const toast = useToast();
        const options = useOptions(ThreadNewUrlParamSchema);
        const context = useContext();

        if (options instanceof ZodError) {
            console.error('failed to parse thread url params, redirecting...', options);
            return <Navigate to="/" replace={true} />;
        }

        const prefetch = {
            message: messageRepository.usePrefetch(context),
        };

        const mutation = {
            thread: threadRepository.useCreate(context),
            session: sessionRepository.useCreate(context),
        };

        const data = loader.useLoad(context, {
            workflow: options.thread,
        });

        const form = useForm<ConversationNewFormValues>({
            defaultValues: {
                mode: 'text',
                confirmed: false,
            },
        });

        const formValues = form.watch();

        const props = controller.useProps(context, data, {
            thread: {
                input: options.thread.data,
            },
            location,
            values: formValues,
            confirm: {
                value: formValues.confirmed,
                onChange(value) {
                    form.setValue('confirmed', value);
                },
            },
            field: {
                mode: {
                    options: [
                        {
                            label: 'Text mode',
                            description:
                                'Chat via text with an option to use push-to-talk whenever you like',
                            Icon: Icons.Assistant.TextMode,
                            value: 'text',
                        },
                        {
                            label: 'Voice mode',
                            description:
                                'Have a smooth, ongoing voice conversation with our advanced assistant',
                            Icon: Icons.Assistant.AdvancedVoiceMode,
                            value: 'voice',
                        },
                    ],
                },
            },
            form: {
                id: 'assistant_thread_create_form',
                form,
                async onSubmit(values) {
                    const thread = await mutation.thread.mutateAsync({
                        ...(options.thread as ThreadCreatePayload),
                        title: data.inspection.title,
                        description: data.inspection.description ?? undefined,
                        metadata: data.inspection.metadata,
                    });
                    await prefetch.message.load(
                        {
                            thread,
                        },
                        {}
                    );
                    const session = await mutation.session.mutateAsync({
                        thread: thread.id,
                        type: 'realtime',
                        modalities: values.mode == 'text' ? ['text'] : ['text', 'audio'],
                    });
                    console.log('session', session);
                    const path = config.navigation.thread.detail({
                        thread,
                        mode: values.mode,
                        token: session.client_secret,
                        returnPath: options.return_path,
                    });
                    navigate(path.to, { replace: true });
                },
                onSubmitError(error) {
                    toast({
                        kind: 'error',
                        description: error.message,
                    });
                },
                ...(formValues.confirmed
                    ? {}
                    : {
                          isDisabled: true,
                          disabledReason: `Please agree to our terms & conditions to proceed with interview`,
                      }),
            },
        });

        if (props.thread.open) {
            console.log('redirecting to already open workflow thread', props.thread.open);
            return <Navigate {...props.thread.open.link} replace={true} />;
        }

        return <View {...props} />;
    };
}
