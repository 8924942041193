import { ConversationWorkflowAdapter } from './conversationWorkflowAdapter';
import { WorkflowRepository } from './conversationWorkflowInterface';
import { Kernel } from '../../../base';
import { AssistantSdk } from '@varos/assistant-sdk';

export function createWorkflowRepository(
    kernel: Kernel,
    api: AssistantSdk
): WorkflowRepository {
    const PREFIX = ['conversations', 'workflows'];
    return {
        useFind(context, query, options) {
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.principal?.id, query],
                queryFn: async () => {
                    const response = await api.workflowList({});
                    return response.data.data;
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.principal?.id, query],
                queryFn: async () => {
                    const response = await api.workflowRetrieve({
                        workflowId: query.id,
                    });
                    return response.data;
                },
            });
            return result;
        },
    };
}
