import React from 'react';
import { BeatLoader } from 'react-spinners';
import {
    Alert,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    SimpleGrid,
    Spinner,
    Stack,
    SystemProps,
    Text,
    Textarea,
    Tooltip,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import {
    AiFillAudio,
    AiOutlineAudio,
    AiOutlineClose,
    AiOutlineSend,
} from 'react-icons/ai';
import { BiMicrophone } from 'react-icons/bi';
import { BsStopCircleFill } from 'react-icons/bs';
import { Graphics } from '../../../../../config/svg';
import { Icons } from '../../../../../../config';
import { assertNever } from '../../../../../util';
import { AssistantViewBaseConfig } from '../../../base';
import { ButtonElementContainerProps } from '../../../view/button';
import { VisualizationBlockContainerProps } from '../../../view';
import {
    createComposerView,
    ThreadComposerContainerProps,
    ThreadComposerViewProps,
} from './composer';
import { createBlockView } from './block';
import { createThreadInfoView } from './info';
import { MessageItemViewProps } from './message';
import { ThreadDetailViewProps } from './threadDetailProps';
import { SpinnerIcon } from '@chakra-ui/icons';

export function createThreadDetailView(
    config: AssistantViewBaseConfig & {
        Container: {
            Composer: React.FC<ThreadComposerContainerProps>;
        };
    },
    ElementUI: {
        Button: React.FC<
            { children?: React.ReactNode | undefined } & ButtonElementContainerProps
        >;
        Visualization: React.FC<
            { children?: React.ReactNode | undefined } & VisualizationBlockContainerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & ThreadDetailViewProps> {
    const {
        UI: {
            Application: { Link },
        },
        Layout,
        Container: { Composer: ComposerContainer },
    } = config;

    const Detail = createThreadInfoView(config);

    const commonStyle = {
        gutter: { base: 3, lg: 0 },
    } satisfies {
        gutter: BoxProps['width'];
    };

    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    const messageStyle = {
        maxW: '32rem',
    } satisfies BoxProps;

    const Block = createBlockView(
        { ...config, style: { container: containerStyle } },
        ElementUI
    );

    const ComposerView = createComposerView(config);

    const SystemMessageGroup: React.FC<MessageItemViewProps> = (props) => {
        return (
            <Stack align="start" w="full" spacing={6} direction="column-reverse">
                <HStack w="full" spacing={{ base: 3, lg: 6 }} align="start">
                    <HStack
                        align="center"
                        justify="center"
                        position="relative"
                        // left={-14}
                        top={{ base: -0.5, lg: -1 }}
                        flexShrink={0}
                        // bg="whiteAlpha.300"
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderRadius="full"
                        h={{ base: 7, lg: 8 }}
                        w={{ base: 7, lg: 8 }}
                    >
                        <Image
                            position="relative"
                            h={{ base: 2.5, lg: 3 }}
                            w={{ base: 2.5, lg: 3 }}
                            top="1px"
                            right="1px"
                            src={Graphics.Brand.Icon}
                            alt="system"
                            // filter="grayscale(100%)"
                        />
                    </HStack>
                    <VStack w="full" align="start" spacing={3} color="whiteAlpha.900">
                        {props.content
                            .filter(
                                // NOTE we render select blocks as suggestions
                                (block) =>
                                    !(block.kind === 'input' && block.type === 'select')
                            )
                            .flatMap((block, index) => (
                                <HStack key={index} w="full" maxW={messageStyle.maxW}>
                                    <Block data={block} />
                                </HStack>
                            ))}
                    </VStack>
                </HStack>
            </Stack>
        );
    };

    const UserMessageGroup: React.FC<MessageItemViewProps> = (props) => {
        return (
            <VStack w="full" align="end" spacing={3}>
                {props.content.map((block, index) => {
                    if (block.kind === 'event') {
                        return <Block key={index} data={block} />;
                    }
                    if (block.kind === 'text') {
                        return (
                            <HStack key={index} w="full" justify="end">
                                <HStack
                                    w="fit-content"
                                    maxW={messageStyle.maxW}
                                    bg="whiteAlpha.200"
                                    borderRadius="lg"
                                    px={{ base: 4, lg: 6 }}
                                    py={{ base: 3, lg: 4 }}
                                >
                                    <Text color="whiteAlpha.800">{block.text}</Text>
                                </HStack>
                            </HStack>
                        );
                    }
                    if (block.kind === 'audio_input') {
                        return (
                            <HStack
                                key={index}
                                w="full"
                                justify="end"
                                color="green.300"
                                fontSize="lg"
                                userSelect="none"
                            >
                                <Tooltip
                                    placement="top"
                                    arrowShadowColor="gray.100"
                                    shouldWrapChildren={true}
                                    py={2}
                                    px={3}
                                    hasArrow={true}
                                    bg="gray.100"
                                    color="gray.900"
                                    label={block.transcript}
                                    isDisabled={block.transcript === null}
                                >
                                    <HStack
                                        w="fit-content"
                                        maxW={messageStyle.maxW}
                                        bg="whiteAlpha.200"
                                        borderRadius="lg"
                                        px={{ base: 4, lg: 6 }}
                                        h={{ base: 12 }}
                                        align="center"
                                        fontWeight="medium"
                                    >
                                        <Icon as={AiFillAudio} />
                                        {block.duration_ms && (
                                            <Text fontSize="sm">
                                                {(block.duration_ms / 1000).toFixed(1)}s
                                            </Text>
                                        )}
                                    </HStack>
                                </Tooltip>
                            </HStack>
                        );
                    }
                    return (
                        <React.Fragment key={index}>
                            user message type '{block.kind}' not implemented
                        </React.Fragment>
                    );
                })}
            </VStack>
        );
    };

    const MessageGroup: React.FC<MessageItemViewProps> = (props) => {
        if (props.author.kind === 'system') {
            return <SystemMessageGroup {...props} />;
        }
        if (props.author.kind === 'user') {
            return <UserMessageGroup {...props} />;
        }
        assertNever(props.author.kind);
    };

    return (props) => {
        const colorSpinner = useToken('colors', 'whiteAlpha.400');
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Layout
                title={props.page.title}
                isScrollable={false}
                sidebar={<Detail {...props.detail} />}
                // showBackButton={props.showBackButton}
                actionContent={
                    !props.thread.isClosed ? (
                        <>
                            <Button
                                {...props.confirm.end.trigger.button}
                                colorScheme="red"
                                variant="outline"
                                borderRadius="full"
                            >
                                End interview
                            </Button>
                            <AlertDialog {...props.confirm.end.alert} isCentered={true}>
                                <AlertDialogOverlay>
                                    <AlertDialogContent position="relative" top={-24}>
                                        <AlertDialogHeader
                                            fontSize="lg"
                                            fontWeight="bold"
                                        >
                                            End interview
                                        </AlertDialogHeader>
                                        <AlertDialogBody>
                                            Are you sure you want to end the interview
                                            early? You might not be eligible for the
                                            reward
                                        </AlertDialogBody>
                                        <AlertDialogFooter>
                                            <ButtonGroup spacing={3}>
                                                <Button
                                                    ref={
                                                        props.confirm.end.cancel.cancelRef
                                                    }
                                                    {...props.confirm.end.cancel.button}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    {...props.confirm.end.submit.button}
                                                    colorScheme="red"
                                                >
                                                    End interview
                                                </Button>
                                            </ButtonGroup>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </>
                    ) : (
                        <></>
                    )
                }
            >
                <Grid
                    h="full"
                    w="full"
                    templateColumns="1fr"
                    templateRows="1fr min-content"
                    templateAreas={`
                    "conversation"
                    "composer"
                `}
                >
                    {/* Messages */}
                    <GridItem gridArea="conversation">
                        <Stack
                            maxH={
                                props.status
                                    ? {
                                          base: '70dvh',
                                          md: '75dvh',
                                      }
                                    : {
                                          base: '75dvh',
                                          md: '80dvh',
                                      }
                            }
                            w="full"
                            align="center"
                            direction="column-reverse"
                            overflowY="scroll"
                            // onScroll={props.chat.onScroll}
                            // ref={props.chat.containerRef}
                            css={{
                                overflowAnchor: 'none', // <--- helps Chrome ignore its "anchoring"
                                scrollbarGutter: 'stable both-edges',
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                    background: scrollbarBg,
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    // background: scrollbarBg,
                                    background: scrollbarHoverBg,
                                },
                            }}
                            pt={8}
                            pb={{
                                base: 16,
                                md: 32,
                                '2xl': 48,
                            }}
                            spacing={{ base: 6, lg: 12 }}
                        >
                            {props.user.status === 'speaking' && (
                                <HStack
                                    w="full"
                                    justify="end"
                                    color="green.300"
                                    fontSize="lg"
                                    maxW={containerStyle.maxW}
                                >
                                    <HStack
                                        w="fit-content"
                                        maxW={messageStyle.maxW}
                                        bg="whiteAlpha.200"
                                        borderRadius="lg"
                                        px={{ base: 4, lg: 6 }}
                                        h={{ base: 12 }}
                                        align="center"
                                        fontWeight="medium"
                                    >
                                        <Icon as={AiFillAudio} />
                                        <Text fontSize="sm">Speaking...</Text>
                                    </HStack>
                                </HStack>
                            )}
                            {props.thread.isClosed && (
                                <HStack w="full" justify="center">
                                    <HStack
                                        maxW={containerStyle.maxW}
                                        w="full"
                                        justify="center"
                                        fontWeight="medium"
                                        fontSize="sm"
                                        color="whiteAlpha.400"
                                        borderTopWidth={2}
                                        borderColor="whiteAlpha.200"
                                        borderStyle="solid"
                                        pt={{ base: 4, md: 8 }}
                                        px={{ base: 4, lg: 6 }}
                                    >
                                        <Text>Conversation closed</Text>
                                    </HStack>
                                </HStack>
                            )}
                            {/* Loading indicator */}
                            {props.isLoading && (
                                <HStack
                                    maxW={containerStyle.maxW}
                                    w="full"
                                    px={commonStyle.gutter}
                                >
                                    <HStack
                                        w="full"
                                        spacing={{ base: 3, lg: 6 }}
                                        maxW={messageStyle.maxW}
                                    >
                                        <HStack
                                            align="center"
                                            justify="center"
                                            position="relative"
                                            // left={-14}
                                            top={{ base: -0.5, lg: -1 }}
                                            flexShrink={0}
                                            // bg="whiteAlpha.300"
                                            borderWidth={2}
                                            borderColor="whiteAlpha.300"
                                            borderRadius="full"
                                            h={{ base: 7, lg: 8 }}
                                            w={{ base: 7, lg: 8 }}
                                        >
                                            <Image
                                                position="relative"
                                                h={{ base: 2.5, lg: 3 }}
                                                w={{ base: 2.5, lg: 3 }}
                                                top="1px"
                                                right="1px"
                                                src={Graphics.Brand.Icon}
                                                alt="system"
                                                // filter="grayscale(100%)"
                                            />
                                        </HStack>
                                        <HStack
                                            w="full"
                                            maxW={containerStyle.maxW}
                                            color="whiteAlpha.800"
                                            position="relative"
                                            top={{ base: -0.5, lg: -1 }}
                                        >
                                            <BeatLoader
                                                color={colorSpinner}
                                                speedMultiplier={0.7}
                                                size="1rem"
                                            />
                                        </HStack>
                                    </HStack>
                                </HStack>
                            )}
                            {props.message.items.map((item, index) => (
                                <HStack
                                    key={item.id}
                                    maxW={containerStyle.maxW}
                                    w="full"
                                    px={commonStyle.gutter}
                                >
                                    <MessageGroup {...item} />
                                </HStack>
                            ))}
                            <HStack
                                w="full"
                                justify="center"
                                fontWeight="medium"
                                fontSize="sm"
                                color="whiteAlpha.400"
                            >
                                <Text>Start of conversation</Text>
                            </HStack>
                        </Stack>
                    </GridItem>
                    {/* Composer */}
                    <GridItem
                        gridArea="composer"
                        position="sticky"
                        bottom={0}
                        zIndex={10}
                        bg="#0a0a0a"
                    >
                        <HStack w="full" justify="center">
                            <VStack
                                maxW={containerStyle.maxW}
                                w="full"
                                h="full"
                                align="start"
                                spacing={{ base: 2, md: 4 }}
                            >
                                {props.status && (
                                    <Alert {...props.status.alert} zIndex={10}>
                                        {props.status.isLoading && (
                                            <HStack pr={4}>
                                                <Spinner
                                                    color="blue.200"
                                                    size="sm"
                                                    speed="2s"
                                                />
                                            </HStack>
                                        )}
                                        {!props.status.isLoading && (
                                            <AlertIcon
                                                as={props.status.Icon ?? undefined}
                                            />
                                        )}
                                        <AlertTitle w="full">
                                            <HStack w="full" justify="space-between">
                                                <Text>{props.status.label}</Text>
                                                {props.status.action ? (
                                                    <Button
                                                        {...props.status.action.button}
                                                        variant="link"
                                                    >
                                                        {props.status.action.label}
                                                    </Button>
                                                ) : null}
                                                {props.status.secondary ? (
                                                    <Text>
                                                        {props.status.secondary.label}
                                                    </Text>
                                                ) : null}
                                            </HStack>
                                        </AlertTitle>
                                    </Alert>
                                )}
                                <HStack w="full" justify="center" pb={{ base: 0, md: 8 }}>
                                    <HStack
                                        w="full"
                                        px={{ base: 3, md: 4 }}
                                        py={{ base: 3, md: 4 }}
                                        bg="whiteAlpha.50"
                                        borderColor="whiteAlpha.100"
                                        borderWidth={1}
                                        borderRadius="2xl"
                                        borderBottomRadius={{ base: 'none', md: '2xl' }}
                                        overflow="hidden"
                                        zIndex={10}
                                    >
                                        {!props.thread.isClosed && (
                                            <ComposerContainer as={ComposerView} />
                                        )}
                                        {props.thread.isClosed && (
                                            <HStack w="full" justify="space-between">
                                                <Text
                                                    fontWeight="medium"
                                                    color="whiteAlpha.600"
                                                >
                                                    Conversation is closed
                                                </Text>
                                                <Link {...props.navigation.back.link}>
                                                    <Button colorScheme="blue">
                                                        {props.navigation.back.label}
                                                    </Button>
                                                </Link>
                                            </HStack>
                                        )}
                                    </HStack>
                                </HStack>
                            </VStack>
                        </HStack>
                    </GridItem>
                </Grid>
            </Layout>
        );
    };
}
