import { z } from 'zod';
import { ContributorShellContextLoaderProps } from './contributorContextProps';

export const ContributorShellContextUrlParamsSchema = z
    .object({
        assetId: z.number({ coerce: true }).optional(),
    })
    .transform(
        (item): ContributorShellContextLoaderProps => ({
            asset: item.assetId ? { id: item.assetId } : null,
        })
    );
