import {
    AiOutlineCalendar,
    AiOutlineClose,
    AiOutlineEdit,
    AiOutlinePlus,
    AiOutlineReload,
    AiOutlineWarning,
} from 'react-icons/ai';
import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Center,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    Textarea,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';
import { Icons } from '../../../../../../config';
import { ReportingViewBaseConfig } from '../../../base';
import { ReportNewViewProps } from './reportNewProps';

export function createReportNewView(
    config: ReportingViewBaseConfig
): React.FC<ReportNewViewProps> {
    const {
        Layout: {
            Centered: {
                Component: {
                    Root: LayoutRoot,
                    Header: LayoutHeader,
                    Content: LayoutContent,
                    Body: LayoutBody,
                },
            },
        },
        UI: {
            Application: { Link },
            Date: {
                DatePicker: {
                    Container: DateContainer,
                    Absolute: DateAbsolute,
                    Relative: DateRelative,
                },
            },
        },
    } = config;

    const containerStyle = {
        maxW: { base: '90vw', lg: '85vw', xl: '75vw', '2xl': '72rem' },
    } satisfies Partial<BoxProps>;

    const SectionList: React.FC<React.PropsWithChildren> = (props) => {
        return (
            <VStack
                w="full"
                spacing={8}
                align="start"
                divider={<StackDivider borderColor="whiteAlpha.100" borderWidth={1} />}
            >
                {props.children}
            </VStack>
        );
    };

    const Section: React.FC<
        React.PropsWithChildren & { title: React.ReactNode; description: React.ReactNode }
    > = (props) => {
        return (
            <VStack align="start" w="full" spacing={4}>
                <VStack align="start" w="full" spacing={4}>
                    <Heading fontSize="lg" fontWeight="semibold" color="whiteAlpha.900">
                        {props.title}
                    </Heading>
                    <Text color="whiteAlpha.700">{props.description}</Text>
                </VStack>
                {props.children}
            </VStack>
        );
    };

    return (props) => {
        return (
            <LayoutRoot>
                <LayoutContent>
                    <LayoutHeader>
                        <HStack w="full" justify="space-between" align="start">
                            <HStack w="full">
                                <VStack align="start" spacing={2}>
                                    <VStack align="start" spacing={1}>
                                        <HStack fontSize="md" fontWeight="medium">
                                            <Text
                                                color="blue.300"
                                                _hover={{ color: 'blue.400' }}
                                                cursor="pointer"
                                            >
                                                Reports
                                            </Text>
                                        </HStack>
                                        <Heading fontSize="2xl" fontWeight="semibold">
                                            New report
                                        </Heading>
                                        {/* <Text color="whiteAlpha.800">
                                            Dolore anim commodo occaecat aliqua aliquip.
                                            Anim labore mollit esse dolor incididunt ex
                                            elit deserunt in labore ex elit enim.
                                        </Text> */}
                                    </VStack>
                                </VStack>
                            </HStack>
                            <HStack spacing={3}>
                                <Button
                                    variant="outline"
                                    borderRadius="full"
                                    borderColor="whiteAlpha.300"
                                    aria-label="edit"
                                    bg="none"
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                >
                                    Cancel
                                </Button>
                            </HStack>
                        </HStack>
                    </LayoutHeader>
                    <LayoutBody>
                        <SectionList>
                            <Section
                                title="1. Select a topic"
                                description={
                                    <>
                                        Sit Lorem proident labore ut. Lorem proident elit
                                        aute irure in ullamco duis sunt commodo sunt aute
                                        aliqua in. Cillum et quis tempor labore duis
                                        tempor culpa commodo sunt ipsum excepteur irure
                                        magna quis.
                                    </>
                                }
                            >
                                <Wrap shouldWrapChildren={true} spacing={3}>
                                    <Tag size="lg">Automating KYC with AI</Tag>
                                    <Tag size="lg">
                                        Automating developer maintenance work
                                    </Tag>
                                    <Tag size="lg">3 more</Tag>
                                </Wrap>
                            </Section>
                            <Section
                                title="2. Add context"
                                description={
                                    <>
                                        Ex ea laborum excepteur irure consequat eiusmod ex
                                        occaecat excepteur mollit. Ex nostrud sint dolore
                                        qui amet culpa. Veniam et in ea aliqua aute irure
                                    </>
                                }
                            >
                                <Textarea rows={3} placeholder="Describe goal" />
                                <Wrap shouldWrapChildren={true} spacing={3}>
                                    <Tag size="lg" fontSize="md" fontWeight="medium">
                                        <HStack userSelect="none">
                                            <Text>Company is Varos</Text>
                                            <Icon
                                                cursor="pointer"
                                                color="whiteAlpha.500"
                                                _hover={{ color: 'whiteAlpha.600' }}
                                                as={AiOutlineClose}
                                            />
                                        </HStack>
                                    </Tag>
                                    <Tag size="lg" fontSize="md" fontWeight="medium">
                                        <HStack userSelect="none">
                                            <Text>Role is KYC manager</Text>
                                            <Icon
                                                cursor="pointer"
                                                color="whiteAlpha.500"
                                                _hover={{ color: 'whiteAlpha.600' }}
                                                as={AiOutlineClose}
                                            />
                                        </HStack>
                                    </Tag>
                                    <IconButton
                                        h={8}
                                        minW={8}
                                        w={8}
                                        aria-label="Add"
                                        bg="none"
                                        borderWidth={1}
                                        borderColor="whiteAlpha.300"
                                        _hover={{ bg: 'whiteAlpha.50' }}
                                        icon={<Icon as={AiOutlinePlus} />}
                                    />
                                </Wrap>
                            </Section>
                            <Section
                                title="3. Filter data-sources"
                                description={
                                    <>
                                        Incididunt pariatur nostrud commodo qui labore
                                        pariatur reprehenderit tempor ullamco commodo
                                        commodo velit velit enim. Elit enim tempor.
                                    </>
                                }
                            >
                                <Wrap shouldWrapChildren={true} spacing={3}>
                                    <Button
                                        variant="outline"
                                        colorScheme="blue"
                                        borderRadius="full"
                                    >
                                        Platform is YouTube, Reddit
                                    </Button>
                                    <IconButton
                                        aria-label="Add"
                                        bg="none"
                                        borderWidth={1}
                                        borderColor="whiteAlpha.300"
                                        _hover={{ bg: 'whiteAlpha.50' }}
                                        icon={<Icon as={AiOutlinePlus} />}
                                    />
                                </Wrap>
                            </Section>
                            <Section
                                title="4. Setup private data collection"
                                description={
                                    <>
                                        Magna ipsum pariatur aliqua qui magna proident
                                        velit non. Fugiat non ut eiusmod anim occaecat eu
                                        exercitation sint ex magna ipsum. Sunt dolore
                                        proident ex do laboris.
                                    </>
                                }
                            >
                                <Wrap shouldWrapChildren={true} spacing={3}>
                                    <Button
                                        bg="none"
                                        borderWidth={1}
                                        borderColor="whiteAlpha.300"
                                        _hover={{ bg: 'whiteAlpha.50' }}
                                    >
                                        Setup
                                    </Button>
                                </Wrap>
                            </Section>
                            <HStack w="full" justify="end">
                                <Button colorScheme="green">Create report</Button>
                            </HStack>
                        </SectionList>
                    </LayoutBody>
                </LayoutContent>
            </LayoutRoot>
        );
    };
}
