import { assert } from '@varos/util-typescript';
import { ReportingLoaderBaseConfig } from '../../../base';
import { ReportNewLoader } from './reportNewInterface';

export function createReportNewLoader(
    config: ReportingLoaderBaseConfig
): ReportNewLoader {
    const {} = config;
    return {
        useLoad(context, props) {
            // const reportQuery = repository.instance.useLookup(
            //     context,
            //     {
            //         report: { id: props.template.slug },
            //     },
            //     {
            //         staleTime: Infinity,
            //         suspense: true,
            //     }
            // );
            // assert(reportQuery.status === 'success', 'expected suspense');
            return {
                // template: reportQuery.data,
            };
        },
    };
}
