import React from 'react';
import {
    ModalCloseButton,
    HStack,
    ModalBody,
    ModalContent,
    Modal,
    useDisclosure,
    IconButton,
    Icon,
} from '@chakra-ui/react';
import {
    ContentOverlayContainerProps,
    ContentOverlayViewProps,
} from './contentOverlayProps';
import { ReportingContainerBaseConfig } from '../../../base';
import { ContentOverlayController } from './contentOverlayInterface';
import { ContentOverlayLoader } from './contentOverlayInterface';
import { useSearchParams } from 'react-router-dom';
import {
    ContentDetailParameterSchema,
    ContentOverlayParameterSchema,
} from './contentOverlaySchema';
import { AiOutlineClose } from 'react-icons/ai';

export function createContentOverlayContainer(
    config: ReportingContainerBaseConfig,
    loader: ContentOverlayLoader,
    controller: ContentOverlayController,
    View: React.FC<ContentOverlayViewProps>
): React.FC<ContentOverlayContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    const Main: React.FC<ContentOverlayContainerProps> = ({ ...containerProps }) => {
        const context = useContext();
        const options = useOptions(ContentDetailParameterSchema);

        if (!options.source) {
            // NOTE this is hack beccause there is race condition for when the source is not available after modal close
            return <></>;
        }

        const data = loader.useLoad(context, {
            source: {
                id: options.source,
            },
        });
        const props = controller.useProps(context, data, {});
        return <View {...props} />;
    };

    return (props) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const options = useOptions(ContentOverlayParameterSchema);
        return (
            <Modal
                isOpen={!!options.source}
                onClose={() => {
                    searchParams.delete('source');
                    return setSearchParams(searchParams, { replace: true });
                }}
                size="full"
                returnFocusOnClose={false}
            >
                <ModalContent bg="black" _focus={{ outline: 'none' }} position="relative">
                    <ModalBody bg="whiteAlpha.50">
                        <ModalCloseButton
                            as={IconButton}
                            icon={<Icon as={AiOutlineClose} />}
                            variant="ghost"
                            color="whiteAlpha.700"
                            borderWidth={1}
                            position="fixed"
                            top={4}
                            right={4}
                            borderColor="whiteAlpha.200"
                            borderRadius="full"
                            size="lg"
                            bg="none"
                            _hover={{ bg: 'whiteAlpha.50' }}
                        />
                        <Main />
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    };
}
