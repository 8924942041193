import { SourceObject } from '../../base';

export const slackCaseStudy: SourceObject = {
    id: 'slack-case-study',
    kind: 'system',
    type: 'case-study',
    name: 'Balancing Old Tricks with New Feats: AI-Powered Conversion From Enzyme to React Testing Library at Slack',
    description:
        'An internal engineering case study published by Slack detailing how their frontend teams migrated from Enzyme to React Testing Library using a hybrid AST and AI-driven approach.',
    url: null,
    markdown: `## Background and Source

The case study comes from an internal Slack Engineering article titled _"Balancing Old Tricks with New Feats: AI-Powered Conversion From Enzyme to React Testing Library at Slack,"_ written by **Sergii Gorbachov** (Senior Software Engineer at Slack). It discusses how Slack transitioned its frontend testing framework from Enzyme to React Testing Library using a mix of AST-based codemods and LLM-driven code transformations.

> In the world of frontend development, one thing remains certain: change is the only constant.

## Successes

Slack's hybrid approach boosted test conversion rates from **45% to 80%** for selected files. About **64% of frontend developers** adopted the tool, and it reduced developer time by **22%** for passing conversions.

> This strategic pivot, and the integration of both AST and AI technologies, helped us achieve the remarkable 80% conversion success rate...

## Challenges

Migrating **15,000+ test cases** representing over **10,000 engineering hours** was no small feat. AST transformations lacked necessary context, LLM output was inconsistent, and some Enzyme patterns needed manual handling.

> With each new transformation rule we tackled, the difficulty seemed to escalate...

## Technical Implementation

Slack started with AST-based codemods and then added **Claude 2.1** (Anthropic LLMs) for better context. Combining **DOM tree collection** and **context-aware prompts** made the hybrid pipeline work well for both simple and complex cases.

> By automating the conversion of simpler cases and providing annotations... we successfully minimized hallucinations...

## Results and Open Sourcing

The hybrid model reached an **80% success rate** and saved developer time. Slack also **open-sourced the tool** as [\`@slack/enzyme-to-rtl-codemod\`](https://www.npmjs.com/package/@slack/enzyme-to-rtl-codemod), sharing their lessons with the community.

> This data-centric approach provides clear evidence of tangible time savings...`,
};
