import { useQueryClient } from '@tanstack/react-query';
import {
    AssistantSdk,
    MessageCreatePayload,
    MessageUpdatePayload,
} from '@varos/assistant-sdk';
import { Kernel, ServiceUnavailableError } from '../../../base';
import { MessageCreateMutation, MessageRepository } from './conversationMessageInterface';
import { MessageEntity } from './conversationMessageModel';
import { getMessageQueryKey } from './conversationMessageHelper';

export function createMessageRepository(
    kernel: Kernel,
    api: AssistantSdk
): MessageRepository {
    interface MutationContextType {
        previousMessages: MessageEntity[] | undefined;
        optimisticMessages: MessageEntity[];
    }

    return {
        usePrefetch(context) {
            const client = useQueryClient();
            return {
                load(query, options) {
                    const queryKey = getMessageQueryKey(context, query);
                    return client.prefetchQuery({
                        ...options,
                        queryKey,
                        queryFn: async () => {
                            const response = await api.messageList({
                                thread: query.thread.id,
                            });
                            return response.data.data;
                        },
                    });
                },
            };
        },
        useFind(context, query, options) {
            const queryKey = getMessageQueryKey(context, query);
            const result = kernel.infra.repository.useQuery<MessageEntity[], Error>({
                ...options,
                staleTime: Infinity,
                queryKey,
                queryFn: async () => {
                    const response = await api.messageList({
                        thread: query.thread.id,
                    });
                    return response.data.data;
                },
                keepPreviousData: true,
            });
            return result;
        },
        useCreate(context): MessageCreateMutation {
            const client = useQueryClient();
            const mutation = kernel.infra.repository.useMutation(context, {
                async mutationFn(props: MessageCreatePayload) {
                    // console.log('creating message', props);
                    const response = await api.messageCreate({
                        messageCreatePayload: props,
                    });
                    return response.data;
                },
                onMutate: async (props): Promise<MutationContextType> => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: {
                            id: props.thread,
                        },
                    });

                    // await client.cancelQueries({ queryKey });
                    await client.cancelQueries({ queryKey: messageQueryKey });

                    const previousMessages =
                        client.getQueryData<MessageEntity[]>(messageQueryKey);

                    const now = new Date();
                    // Create the optimistic messages with unique temporary IDs
                    const optimisticMessages = props.content.map(
                        (item, index): MessageEntity => ({
                            id: `optimistic-${Date.now()}-${index}`, // Unique temporary ID
                            object: 'message',
                            role: props.role,
                            thread: props.thread,
                            content: [item],
                            created_at: now,
                        })
                    );

                    // Update the cache with the optimistic messages
                    client.setQueryData<MessageEntity[]>(
                        messageQueryKey,
                        (oldMessages = []) => [...optimisticMessages, ...oldMessages]
                    );

                    return { previousMessages, optimisticMessages };
                },
                onError: async (error, variables, querycontext) => {
                    console.error('error', error);
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: {
                            id: variables.thread,
                        },
                    });
                    if (querycontext?.previousMessages) {
                        client.setQueryData(
                            messageQueryKey,
                            querycontext.previousMessages
                        );
                    }
                    if (!(error instanceof ServiceUnavailableError)) {
                        // await client.invalidateQueries({ queryKey });
                        await client.invalidateQueries({ queryKey: messageQueryKey });
                    }
                },
                onSuccess: (data, variables, querycontext) => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: {
                            id: variables.thread,
                        },
                    });
                    // Assume 'data' includes 'messages' array
                    const serverMessages = [data]; // Adjust according to your data structure
                    const optimisticIds = new Set(
                        querycontext?.optimisticMessages.map((msg) => msg.id)
                    );

                    client.setQueryData<MessageEntity[]>(
                        messageQueryKey,
                        (messages = []) => {
                            // Remove optimistic messages
                            const messagesWithoutOptimistic = messages.filter(
                                (message) => !optimisticIds.has(message.id)
                            );
                            // Add server messages
                            return [
                                ...serverMessages.slice(),
                                ...messagesWithoutOptimistic,
                            ];
                        }
                    );
                    return data;
                },
            });
            return mutation;
        },
        useUpdate(context) {
            const client = useQueryClient();
            const mutation = kernel.infra.repository.useMutation(context, {
                async mutationFn(props: { id: string } & MessageUpdatePayload) {
                    const { id, ...rest } = props;
                    // Call the update API endpoint – adjust the call if your API shape is different.
                    const response = await api.messageUpdate({
                        messageId: id,
                        messageUpdatePayload: rest,
                    });
                    return response.data;
                },
                onSuccess: (data, props, queryContext) => {
                    const messageQueryKey = getMessageQueryKey(context, {
                        thread: { id: props.thread },
                    });
                    // Update the cache with the server's updated message data,
                    // ensuring that the optimistic update is replaced.
                    client.setQueryData<MessageEntity[]>(
                        messageQueryKey,
                        (oldMessages = []) => {
                            return oldMessages.map((message) =>
                                message.id === props.id ? data : message
                            );
                        }
                    );
                    return data;
                },
            });
            return mutation;
        },
    };
}
