import { PageIds } from '../../../../../config';
import { buildStatusProps } from '../../../../../view/common';
import { RewardControllerBaseConfig } from '../../../base';
import { buildOfferItemProps, OfferItemTaskProps } from '../../../fragment';
import { OfferDetailViewProps } from './offerDetailProps';
import { OfferDetailController } from './offerDetailInterface';
import { useMemo } from 'react';

export function createOfferDetailController(
    config: RewardControllerBaseConfig
): OfferDetailController {
    const {
        kernel: {
            provider: { createPageController },
        },
    } = config;

    const pageController = createPageController({
        id: PageIds.REWARD_OFFER_DETAIL,
    });

    return {
        useProps(context, item, props): OfferDetailViewProps {
            const page = pageController.useProps(
                {
                    entity: item.offer.root,
                },
                {
                    metadata: {
                        offer_id: item.offer.root.id,
                        offer_slug: item.offer.root.slug,
                        offer_version: item.offer.root.version,
                        offer_promoted: !!item.offer.promotion,
                    },
                }
            );

            const completed = item.eligibility.tasks.filter(
                (candidate) => candidate.status === 'completed'
            ).length;

            const total = item.eligibility.tasks.length;
            const isEligible = item.eligibility.status === 'eligible';

            const offer = buildOfferItemProps(config, item.offer, {
                location: props.location,
            });

            const [autostart = null, ..._rest] = useMemo(
                () =>
                    props.autostart
                        ? offer.tasks.flatMap((task) => {
                              if (
                                  task.task.action.kind === 'pending' &&
                                  !!task.task.action.link
                              ) {
                                  return [{ link: task.task.action.link }];
                              }
                              return [];
                          })
                        : [],
                [props.autostart, offer.tasks]
            );

            return {
                autostart: autostart,
                discover: {
                    link: {
                        to: '../',
                        replace: true,
                    },
                },
                root: {
                    status: buildStatusProps({
                        kind: 'warning',
                        label: 'Unclaimed',
                        value: 'pending',
                        description: `Offer is available but not yet claimed`,
                        ordinal: null,
                    }),
                },
                task: {
                    status: isEligible
                        ? {
                              label: `Requirements are fulfilled`,
                              secondary: `If there is a match we will reach out to you within 24 hours`,
                              alert: {
                                  status: 'success',
                              },
                          }
                        : {
                              label: `Complete requirements to unlock rewards`,
                              secondary: null,
                              alert: {
                                  status: 'warning',
                              },
                          },
                    summary: {
                        label: `${completed}/${total} fulfilled`,
                        colorScheme: isEligible ? 'green' : 'orange',
                    },
                    progress: {
                        colorScheme: isEligible ? 'green' : 'orange',
                        // add some gutter (10%) to better show empty progress
                        value: total > 0 ? Math.round(10 + (completed / total) * 95) : 1,
                        min: 1,
                        max: 100,
                    },
                },
                terms: props.terms,
                item: offer,
                related: item.related
                    ? item.related.map((item) =>
                          buildOfferItemProps(config, item, { location: props.location })
                      )
                    : null,
            };
        },
    };
}
