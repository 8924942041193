import { useEffect, useMemo } from 'react';
import { assert } from '@varos/util-typescript';
import { EventBlock } from '../../../../../app/assistant';
import { AssistantLoaderBaseConfig } from '../../../base';
import { ThreadDetailLoader } from './threadDetailInterface';
import { ThreadDetailAggregate } from './threadDetailModel';
import { MessageEntity } from '@varos/openai-realtime-client';

export function createThreadDetailLoader(
    config: AssistantLoaderBaseConfig
): ThreadDetailLoader {
    const {
        repository: {
            thread: threadRepository,
            message: messageRepository,
            workflow: workflowRepository,
            session: sessionRepository,
        },
    } = config;

    return {
        useLoad(context, props) {
            const threadQuery = threadRepository.useLookup(context, props.thread, {
                suspense: true,
            });
            assert(threadQuery.status === 'success', 'expected suspense');
            const workflowQuery = workflowRepository.useLookup(
                context,
                {
                    id: threadQuery.data.workflow,
                },
                {
                    suspense: true,
                }
            );
            assert(workflowQuery.status === 'success', 'expected suspense');
            const messageQuery = messageRepository.useFind(
                context,
                { thread: props.thread },
                {
                    suspense: true,
                }
            );
            assert(messageQuery.status === 'success', 'expected suspense');

            const sessionQuery = sessionRepository.useLookup(
                context,
                useMemo(() => props.session, []),
                {
                    // NOTE this configuration is subtle but important. We want to ensure
                    // that we always recreate the session and force a suspend to properly
                    // reinitialze the session context.
                    // specifically, we want to ensure we we generate the system instructions with the
                    // most recent message history to properly rehydrate the conversation context
                    refetchOnMount: true,
                    cacheTime: 0,
                    refetchOnWindowFocus: false,
                    suspense: true,
                }
            );
            assert(sessionQuery.status === 'success', 'expected suspense');

            const entries = useMemo(
                () =>
                    messageQuery.data.map((message, index, array) => ({
                        message,
                        result:
                            array[index - 1]?.content.find(
                                (item): item is EventBlock => item.kind === 'event'
                            ) ?? null,
                    })),
                [messageQuery.data]
            );

            const initialMessages = useMemo(() => {
                return entries.flatMap((message) =>
                    message.message.content.flatMap((fragment): MessageEntity[] => {
                        if (fragment.kind === 'text') {
                            return [
                                {
                                    id: message.message.id,
                                    role: message.message.role,
                                    kind: 'text',
                                    content: fragment.text,
                                    committed: true,
                                },
                            ];
                        }
                        if (fragment.kind === 'audio_input') {
                            return [
                                {
                                    id: message.message.id,
                                    role: message.message.role,
                                    kind: 'audio',
                                    transcript: fragment.transcript,
                                    durationMs: fragment.duration_ms ?? 0,
                                    committed: true,
                                },
                            ];
                        }
                        return [];
                    })
                );
            }, [entries]);

            return useMemo(
                (): ThreadDetailAggregate => ({
                    workflow: workflowQuery.data,
                    thread: threadQuery.data,
                    session: sessionQuery.data,
                    entries,
                    initialMessages,
                    mostRecentMessage: messageQuery.data[0] ?? null,
                    chat: {
                        messages: messageQuery.data.map((item) => ({
                            text: item.id,
                        })),
                    },
                }),
                [
                    initialMessages,
                    messageQuery.data,
                    threadQuery.data,
                    workflowQuery.data,
                    sessionQuery.data,
                ]
            );
        },
    };
}
