import { useQueryClient } from '@tanstack/react-query';
import { StateCreateRequest } from '@varos/assistant-sdk';
import { AssistantSdk } from '@varos/assistant-sdk';
import { ApplicationContext, Kernel } from '../../../base';
import {
    AssistantStateCreateMutation,
    AssistantStateRepository,
} from './assistantStateInterface';

export function createStateRepository(
    kernel: Kernel,
    api: AssistantSdk
): AssistantStateRepository {
    const PREFIX = ['conversations', 'state'];

    // Helper function to generate consistent query keys
    const getQueryKey = (context: ApplicationContext) => [
        ...PREFIX,
        context.principal?.id,
    ];

    return {
        useCreate(context): AssistantStateCreateMutation {
            const client = useQueryClient();
            const mutation = kernel.infra.repository.useMutation(context, {
                async mutationFn(props: StateCreateRequest) {
                    const response = await api.stateCreate({
                        stateCreateRequest: props,
                    });
                    return response.data;
                },
            });
            return mutation;
        },
    };
}
