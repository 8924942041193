import { ReportingControllerBaseConfig } from '../../base';
import { ReportGenerateController } from './reportHomeInterface';
import { ReportGenerateViewProps } from './reportHomeProps';

export function createReportGenerateController(
    config: ReportingControllerBaseConfig
): ReportGenerateController {
    const {
        kernel: {
            provider: { createFormController },
        },
    } = config;
    const formController = createFormController({
        submitOnEnter: true,
    });
    return {
        useProps(context, item, props): ReportGenerateViewProps {
            const form = formController.useProps(props.form);
            return {
                form,
                field: {
                    question: {
                        input: form.form.register('question'),
                    },
                },
                submit: {
                    button: {
                        ...form.getSubmitButtonProps(),
                        isDisabled: props.values.question.trim().length === 0,
                    },
                },
            };
        },
    };
}
