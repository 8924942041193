import type { AuthorObject, ReportContentObject, ReportObject } from '../base/types';

export const report: ReportObject = {
    id: 'automating-kyc-processes-with-ai',
    title: `Automating KYC processes with AI`,
    status: 'generating',
    updatedAt: new Date(2025, 3, 4),
};

export const author: AuthorObject = {
    id: '1',
    name: 'Susie',
};

export const content: ReportContentObject = {
    summary: `Nostrud in nisi laborum eu nostrud. Aliqua aliqua officia ad tempor irure ullamco. Labore officia tempor sunt consequat aute pariatur do qui sint consequat fugiat velit. Laborum esse nostrud quis anim deserunt voluptate do magna quis voluptate nostrud ex quis dolore. Duis exercitation excepteur nisi nisi et irure nulla duis commodo occaecat irure.`,
    chapters: [
        {
            id: '1',
            number: 1,
            caption: `Vendors`,
            title: `Jumio is emerging as the best AI KYC vendor`,
            description: `Deserunt quis deserunt laboris velit excepteur elit laborum eiusmod sunt. Duis elit dolor dolore cupidatat ad quis est aliqua laborum. Aute labore reprehenderit esse labore incididunt eiusmod ut sint. `,
            sections: [
                {
                    id: '1',
                    title: `Dolore enim irure non ad enim dolor proident`,
                    caption: `Features`,
                    content: [
                        {
                            kind: 'text',
                            text: `Proident proident exercitation occaecat dolor est. Minim ipsum nulla sunt ea incididunt eu pariatur eiusmod quis exercitation sint commodo. Dolor est cillum ut eu quis cillum ad veniam Lorem anim. Reprehenderit minim aliqua elit minim ex occaecat occaecat sit laborum laborum voluptate dolore sunt minim. Do excepteur magna occaecat magna dolore do tempor adipisicing in amet laboris aute in veniam. Est laboris elit deserunt est dolore et ex non enim. Mollit esse ad id dolor.`,
                        },
                        {
                            kind: 'text',
                            text: `Ex officia in irure labore amet nisi sit. Dolore amet ad voluptate consectetur. Deserunt sit est ex anim esse proident labore magna aliquip amet in culpa. Esse quis ex Lorem laborum consequat et proident in aliqua magna do nisi veniam sunt. Est et ea eu sunt. Consectetur nostrud consequat quis duis pariatur sit eiusmod adipisicing dolor dolore sit labore.`,
                        },
                    ],
                    keywords: [
                        {
                            id: '1',
                            text: 'Growing',
                            description: null,
                        },
                    ],
                    sources: [
                        {
                            id: '1',
                            kind: 'external',
                            name: `Finance AI Competitive landscape 2025`,
                            type: null,
                            description: `Excepteur minim veniam adipisicing elit in. Dolore aliquip minim incididunt dolor minim id tempor laboris eu exercitation. Incididunt ullamco Lorem ut excepteur aute ex excepteur minim cupidatat. Consequat in aute nulla voluptate cillum ad culpa cillum anim amet exercitation cillum est. Quis esse ipsum ullamco proident duis dolore aliqua. Amet dolor ad excepteur magna ex. Amet nulla nostrud sit ex cupidatat`,
                            url: `https://www.example.com/finance-ai-competitive-landscape`,
                            markdown: null,
                        },
                        {
                            id: '2',
                            kind: 'external',
                            name: `AI KYC Market landscape 2025`,
                            type: null,
                            description: `Mollit amet deserunt enim laborum reprehenderit ea amet adipisicing pariatur consectetur id est cupidatat. Nulla amet aliquip amet et tempor labore eu ea do officia laboris labore. Enim ullamco laboris ea cupidatat dolor esse culpa veniam mollit. Lorem aute voluptate ut do velit commodo mollit mollit ullamco magna cupidatat dolor amet ea. Aliqua cillum nostrud pariatur sunt laborum in irure et excepteur duis voluptate laboris eiusmod.`,
                            url: `https://website.io/articles/ai-market-landscape`,
                            markdown: null,
                        },
                    ],
                },
                {
                    id: '2',
                    title: `Commodo aliqua officia esse`,
                    caption: `Trends`,
                    content: [
                        {
                            kind: 'text',
                            text: `Lorem cupidatat dolor qui tempor nisi eu. Occaecat sit voluptate veniam aute exercitation sit consequat labore minim. Do velit et commodo officia labore in culpa sit reprehenderit. Et incididunt cupidatat tempor enim pariatur incididunt deserunt nulla sit ea officia cupidatat nostrud ex. Magna nisi fugiat et duis non elit excepteur.`,
                        },
                    ],
                    keywords: [
                        {
                            id: '1',
                            text: 'Compliance',
                            description: null,
                        },
                        {
                            id: '2',
                            text: 'Automation',
                            description: null,
                        },
                    ],
                    sources: [
                        {
                            id: '1',
                            kind: 'external',
                            name: `AI Trends for KYC 2025`,
                            type: null,
                            description: `Non eu do ut sit. Magna qui nostrud qui do nostrud excepteur irure nostrud labore nostrud. Reprehenderit ea minim veniam voluptate eu esse cillum mollit velit consectetur veniam commodo labore velit. Cillum officia officia magna minim ea do consequat incididunt aute incididunt sit eu proident est. Proident amet magna veniam in officia. Ad eiusmod eu exercitation sit duis deserunt excepteur tempor duis. Aliqua et enim consectetur sit in ipsum aute sunt officia non veniam.`,
                            url: null,
                            markdown: null,
                        },
                    ],
                },
                {
                    id: '3',
                    title: `Exercitation anim tempor deserunt`,
                    caption: `Market landscape`,
                    content: [
                        {
                            kind: 'text',
                            text: `Exercitation esse sint in reprehenderit labore consectetur velit et. Est dolor minim nisi ex. Nostrud nisi consectetur do cupidatat velit non dolore occaecat minim velit irure. Est qui reprehenderit sunt tempor do nisi veniam eu. Minim sit ex do consectetur culpa nisi consectetur aute occaecat aliquip officia anim mollit. Occaecat ullamco laboris consequat velit elit enim eiusmod anim ut dolor ut quis non Lorem.`,
                        },
                        {
                            kind: 'text',
                            text: `Enim Lorem dolore ea excepteur nisi excepteur est esse ullamco non ut. Fugiat excepteur et ullamco deserunt fugiat. Commodo exercitation ut cillum pariatur ullamco ullamco pariatur in dolor sint sunt exercitation ipsum. Ipsum anim id consectetur minim consequat. Ad non pariatur dolor aute quis occaecat non labore Lorem in cupidatat.`,
                        },
                    ],
                    keywords: [
                        {
                            id: '1',
                            text: 'Consolidation',
                            description: null,
                        },
                    ],
                    sources: [
                        {
                            id: '1',
                            kind: 'external',
                            name: `AI KYC Market landscape 2025`,
                            type: null,
                            description: `Dolor eu aliquip ut anim aute nulla. Culpa eu esse ea tempor velit ex culpa eiusmod ut. Deserunt laboris ut consectetur reprehenderit veniam id incididunt reprehenderit culpa sit veniam est. Nostrud culpa commodo ut do id amet amet laboris velit consequat occaecat. Irure eu voluptate sint non esse deserunt commodo incididunt. Voluptate est aute velit occaecat commodo proident. Ea amet dolor qui Lorem laborum incididunt elit aliquip exercitation nostrud irure consectetur`,
                            url: null,
                            markdown: null,
                        },
                    ],
                },
            ],
        },
        {
            id: '2',
            number: 2,
            caption: `Success stories`,
            title: `AI-driven KYC solutions are increasing efficiency by up to 80%`,
            description: `Reprehenderit amet voluptate commodo enim proident duis deserunt labore sint veniam cillum quis reprehenderit. Deserunt voluptate eu sint do non exercitation anim aliquip consectetur deserunt anim. Duis veniam enim enim amet id magna Lorem proident excepteur aute magna fugiat nostrud. Consequat deserunt est occaecat laboris officia commodo reprehenderit esse minim amet sint deserunt esse consequat. Exercitation velit est irure irure `,
            sections: [
                {
                    id: '1',
                    title: null,
                    caption: `Success stories`,
                    content: [
                        {
                            kind: 'text',
                            text: `Irure veniam ad fugiat occaecat eu anim laborum deserunt esse eiusmod nisi consequat ullamco.`,
                        },
                    ],
                    keywords: [],
                    sources: [
                        {
                            id: '1',
                            kind: 'external',
                            name: `Success stories in AI KYC 2024`,
                            type: null,
                            description: `Officia ullamco aliquip officia mollit velit voluptate adipisicing aute laboris duis sunt dolor. Mollit culpa excepteur irure voluptate esse cillum aliquip minim minim. Laboris id et commodo aliquip dolore exercitation sunt aute culpa Lorem culpa. Dolore do et Lorem enim qui dolore. Culpa consequat minim tempor amet laborum esse aliquip occaecat aute qui ut do non. Nisi cillum incididunt sint laboris velit nulla ipsum enim reprehenderit ut quis nostrud. Et veniam culpa incididunt pariatur duis fugiat`,
                            url: null,
                            markdown: null,
                        },
                    ],
                },
            ],
        },
        {
            id: '3',
            number: 3,
            caption: `Challenges`,
            title: `AI KYC might face compliance issues in the future`,
            description: `Magna cupidatat labore eu elit proident excepteur culpa voluptate excepteur magna veniam exercitation eu laborum. Mollit laboris eiusmod non anim adipisicing Lorem eiusmod pariatur id. Occaecat consectetur exercitation qui esse exercitation incididunt magna excepteur proident ipsum dolore cillum. Ad adipisicing consectetur enim nulla aute officia sit consequat qui cillum et ipsum. Ea voluptate irure in eiusmod culpa in occaecat commodo deserunt aute minim duis. Exercitation do eu laboris anim proident ad adipisicing Lorem.`,
            sections: [
                {
                    id: '1',
                    title: null,
                    caption: `Challenges`,
                    content: [
                        {
                            kind: 'text',
                            text: `Dolor officia est ex officia aute mollit cillum aliqua mollit exercitation dolor eiusmod.`,
                        },
                    ],
                    keywords: [],
                    sources: [
                        {
                            id: '1',
                            kind: 'external',
                            name: `How to integrate AI KYC into your business 2025`,
                            type: null,
                            description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                            url: null,
                            markdown: null,
                        },
                    ],
                },
            ],
        },
    ],
};
