import {
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Graphics } from '../../../config/svg';
import { LegalViewConfig } from '../base';
import { LegalLayoutViewProps } from './legalLayoutProps';

export function createLegalLayoutView(
    config: Pick<LegalViewConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & LegalLayoutViewProps> {
    const {
        UI: {
            Application: { Link },
        },
    } = config;
    return (props) => {
        return (
            <Grid
                w="100dvw"
                h="100dvh"
                templateRows="min-content 1fr"
                templateColumns="1fr"
            >
                {/* Header */}
                <GridItem
                    py={4}
                    px={6}
                    borderBottomWidth={2}
                    borderColor="whiteAlpha.300"
                >
                    <SimpleGrid columns={3}>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={12}>
                                <Link {...props.link.home}>
                                    <Image src={Graphics.Brand.WhiteText} alt="brand" />
                                </Link>
                                {props.link.back && (
                                    <Link {...props.link.back}>
                                        <Button
                                            variant="link"
                                            leftIcon={<Icon as={BsArrowLeft} />}
                                            _focus={{ outline: 'none' }}
                                        >
                                            Go back
                                        </Button>
                                    </Link>
                                )}
                            </HStack>
                        </HStack>
                        <HStack w="full" justify="center"></HStack>
                        <HStack justify="end" spacing={6}></HStack>
                    </SimpleGrid>
                </GridItem>
                <GridItem>
                    <HStack w="full" justify="center">
                        <VStack align="start" maxW="42rem">
                            {props.children}
                        </VStack>
                    </HStack>
                </GridItem>
            </Grid>
        );
    };
}
