import {
    ThreadDetailServiceProvider,
    ThreadDetailService,
} from './threadDetailInterface';
import { MessageCreateProps } from '@varos/openai-realtime-client';

export function createThreadDetailService(): ThreadDetailServiceProvider {
    return {
        useService(context, item, serviceProps): ThreadDetailService {
            const { connection, client, mutation } = serviceProps;
            return {
                async toggleMode(props) {
                    await mutation.session.create.mutateAsync({
                        thread: item.thread.id,
                        type: 'realtime',
                        modalities: props.mode === 'text' ? ['text'] : ['text', 'audio'],
                    });
                    return;
                },
                text: {
                    async send(props) {
                        const payload: MessageCreateProps = {
                            role: 'user',
                            content: props.text,
                            kind: 'text',
                            committed: false,
                        };
                        client.send(payload);
                        await client.reply();
                        return;
                    },
                },
                voice: {
                    chunk(props) {
                        connection.sendPayload({
                            event_id: `append_${Date.now()}`,
                            type: 'input_audio_buffer.append',
                            audio: props.base64,
                        });
                    },
                    start() {
                        connection.sendPayload({
                            event_id: `commit_${Date.now()}`,
                            type: 'input_audio_buffer.clear',
                        });
                    },
                    async stop(props) {
                        connection.sendPayload({
                            event_id: `commit_${Date.now()}`,
                            type: 'input_audio_buffer.commit',
                        });
                        await client.reply();
                    },
                },
            };
        },
    };
}
