import { RouterStrategy } from './routerStrategy';

/**
 * Creates a hash-based routing strategy that uses hash fragments for navigation
 * @returns A hash-based routing strategy
 */
export function createHashStrategy(): RouterStrategy {
    /**
     * Normalize a path for hash-based routing
     * @param path The path to normalize
     * @returns The normalized path
     */
    const normalizePath = (path: string | null): string => {
        if (path === null) {
            return '';
        }
        return path.startsWith('/') ? path.slice(1) : path;
    };

    return {
        /**
         * Navigate to a path using hash-based routing
         * @param path The path to navigate to (or null for root)
         * @param replace Whether to replace the current history entry
         */
        navigate(path: string | null, replace?: boolean): void {
            const newPath = normalizePath(path);
            if (replace) {
                window.location.replace(`#${newPath}`);
            } else {
                window.location.hash = newPath;
            }
        },

        /**
         * Get the current path from the hash
         * @returns The current path
         */
        getCurrentPath(): string {
            const hash = window.location.hash.slice(1);
            return hash || '';
        },

        /**
         * Create a hash URL for the given path
         * @param path The path to create a URL for
         * @returns The hash URL string
         */
        createUrl(path: string | null): string {
            return `#${normalizePath(path)}`;
        },
    };
}
