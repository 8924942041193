import React, { useRef } from 'react';
import { ContributorShellContextContainerProps } from './contributorContextProps';
import {
    ContributorShellContext,
    ContributorShellContextController,
    ContributorShellContextLoader,
} from './contributorContextInterface';
import { ContributorShellContainerBaseConfig } from '../../../base';
import { ContributorShellContextUrlParamsSchema } from './contributorContextSchema';

export function createContributorShellContextContainer(
    config: Pick<ContributorShellContainerBaseConfig, 'kernel'>,
    Context: React.Context<ContributorShellContext | null>,
    loader: ContributorShellContextLoader,
    controller: ContributorShellContextController
): React.FC<ContributorShellContextContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const overlayRef = useRef<HTMLDivElement>(null);
        const options = useOptions(ContributorShellContextUrlParamsSchema);
        const data = loader.useLoad(options);
        const props = controller.useProps(data, {
            overlayRef,
        });
        return <Context.Provider {...props.provider}>{children}</Context.Provider>;
    };
}
