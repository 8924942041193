import { createFormDefinitionRepository } from './definition';
import { FormClientConfig, FormClientInit } from './formClientConfig';
import {
    FormClient,
    FormClientEnhancer,
    FormClientRepositories,
} from './formClientInterface';

export function createFormClient(config: FormClientInit, enhancer?: FormClientEnhancer) {
    return create(
        {
            ...config,
            provider: {
                createDefinitionRepository: createFormDefinitionRepository,
            },
        },
        enhancer
    );
}

function create(config: FormClientConfig, enhancer?: FormClientEnhancer): FormClient {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const repository: FormClientRepositories = {
        definition: config.provider.createDefinitionRepository(config.kernel, config.sdk),
    };
    return { repository };
}
