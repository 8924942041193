import { LockIcon } from '@chakra-ui/icons';
import { ButtonProps, ComponentWithAs, IconProps } from '@chakra-ui/react';
import {
    EligibilityRewardStatus,
    RewardMode,
    TaskDefinitionType,
    TaskMode,
    TaskStatusKind,
} from '@varos/reward-sdk';
import { IconType } from 'react-icons';

export const RewardsCopy = {
    status: {
        fulfilled: {
            label: `Fulfilled`,
            description: `Requirements for this reward are fulfilled`,
            colorScheme: 'green',
            Icon: null,
        },
        unfulfilled: {
            label: `Unfulfilled`,
            description: `Requirements for this reward are not yet fulfilled`,
            colorScheme: 'orange',
            Icon: LockIcon,
        },
    } satisfies {
        [TKey in EligibilityRewardStatus]: {
            label: string;
            description: string;
            colorScheme: ButtonProps['colorScheme'];
            Icon: IconType | ComponentWithAs<'svg', IconProps> | null;
        };
    },
    reward: {
        all: {
            label: `All rewards`,
            description: `Every reward can be claimed`,
        },
        any: {
            label: `Choose one`,
            description: `At most one reward can be claimed`,
        },
        best: {
            label: `Best of`,
            description: `The best reward can be claimed`,
        },
    } satisfies { [TKey in RewardMode]: { label: string; description: string } },
    task: {
        type: {
            interview: {
                label: `Finish interview`,
                description: `An automated, AI-driven interview must be completed by the user`,
                Icon: null,
                action: {
                    label: 'Start',
                },
            },
            form: {
                label: 'Complete interview',
                description: `An automated, AI-driven interview must be completed by the user`,
                Icon: null,
                action: {
                    label: 'Start',
                },
            },
            onboarded: {
                label: 'Onboarded',
                description: `Account must be fully onboarded`,
                Icon: null,
                action: {
                    label: 'Start',
                },
            },
        } satisfies {
            [TKey in TaskDefinitionType]: {
                label: string;
                description: string;
                Icon: IconType | ComponentWithAs<'svg', IconProps> | null;
                action: {
                    label: string;
                };
            };
        },
        status: {
            pending: {
                label: 'Pending',
                description: `Aliqua occaecat ad reprehenderit esse excepteur incididunt culpa aliquip eu mollit do anim ipsum mollit`,
                colorScheme: 'orange',
            },
            completed: {
                label: 'Completed',
                description: `Eu consectetur deserunt ea minim nostrud veniam esse aliqua anim aliqua aliquip veniam`,
                colorScheme: 'green',
            },
            error: {
                label: 'Error',
                description: `Deserunt cupidatat qui ea eu sint laboris est labore duis Lorem id irure`,
                colorScheme: 'red',
            },
        } satisfies {
            [TKey in TaskStatusKind]: {
                label: string;
                description: string;
                colorScheme: ButtonProps['colorScheme'];
            };
        },
        mode: {
            all: {
                label: `All tasks`,
                description: `All tasks defined for offer must be completed to claim reward`,
            },
            any: {
                label: `One of`,
                description: `At least one task defined for offer must be completed to claim reward`,
            },
        } satisfies { [TKey in TaskMode]: { label: string; description: string } },
    },
};
