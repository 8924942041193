import React from 'react';
import { ReportingContainerBaseConfig } from '../../base';
import { ReportGenerateController, ReportGenerateLoader } from './reportHomeInterface';
import { ReportGenerateContainerProps, ReportGenerateViewProps } from './reportHomeProps';
import { useForm } from 'react-hook-form';
import { ReportHomeFormValues } from './reportHomeModel';
import { useNavigate } from 'react-router';

export function createReportGenerateContainer(
    config: ReportingContainerBaseConfig,
    loader: ReportGenerateLoader,
    controller: ReportGenerateController,
    View: React.FC<ReportGenerateViewProps>
): React.FC<ReportGenerateContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    return ({ ...containerProps }) => {
        const context = useContext();
        const data = loader.useLoad(context, {});
        const navigate = useNavigate();
        const form = useForm<ReportHomeFormValues>({
            defaultValues: {
                question: '',
            },
        });
        const props = controller.useProps(context, data, {
            values: form.watch(),
            form: {
                id: 'question',
                form,
                async onSubmit(values) {
                    await new Promise((resolve) => setTimeout(resolve, 1500));
                    const params = new URLSearchParams();
                    params.set('question', values.question);
                    navigate(`/reporting/reports/new?${params.toString()}`);
                },
            },
        });
        return <View {...props} />;
    };
}
