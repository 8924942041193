import { assert } from '@varos/util-typescript';
import { ReportingShellControllerBaseConfig } from '../../../base';
import {
    ReportingShellContext,
    ReportingShellContextController,
} from './reportingContextInterface';

export function createReportingContextController(
    config: ReportingShellControllerBaseConfig
): ReportingShellContextController {
    return {
        useProps(item, props) {
            const auth = config.hook.useAuth();
            assert(auth.user?.id, 'missing user id');
            const value: ReportingShellContext = {
                kind: 'organization',
                principal: {
                    kind: 'user',
                    id: auth.user.id,
                    account: 'acct_adasasdas',
                },
                organization: null,
                auth,
            };
            return {
                provider: {
                    value,
                },
            };
        },
    };
}
