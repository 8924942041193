// Helper function to calculate pixel offset from rem value
export const calculatePixelOffset = (gutterValueRem: string | undefined): number => {
    try {
        if (!gutterValueRem) {
            throw new Error('Gutter value is undefined');
        }
        // Ensure this runs only in the browser context
        if (typeof window === 'undefined' || typeof document === 'undefined') {
            console.warn('Cannot calculate pixel offset outside browser context.');
            return 32; // Return fallback if not in browser
        }

        const rootFontSizeStr = window.getComputedStyle(
            document.documentElement
        ).fontSize;
        const rootFontSizePx = parseFloat(rootFontSizeStr);
        const gutterRem = parseFloat(gutterValueRem);

        if (!isNaN(rootFontSizePx) && !isNaN(gutterRem)) {
            return gutterRem * rootFontSizePx;
        }
    } catch (error) {
        console.error('Error calculating scroll offset:', error);
    }
    // Fallback if calculation fails or not in browser
    return 32;
};
