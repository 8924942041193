import { AssistantContainerBaseConfig } from '../../../base';
import { ThreadStateProvider } from './threadDetailInterface';
import { ThreadDetailRouteProps } from './threadDetailProps';
import { ThreadDetailOptionSchema } from './threadDetailSchema';

export function createThreadStateProvider(
    config: AssistantContainerBaseConfig
): ThreadStateProvider {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    return {
        useState(context, props) {
            const [searchParams, setSearchParams] = props.searchParams;
            const options = useOptions(ThreadDetailOptionSchema);
            return {
                mode: {
                    value: options.mode ?? 'text',
                    onChange(value) {
                        const urlKey: keyof ThreadDetailRouteProps = 'mode';
                        if (value === 'text') {
                            searchParams.delete(urlKey);
                        } else {
                            searchParams.set(urlKey, value);
                        }
                        setSearchParams(searchParams, { replace: true });
                    },
                },
            };
        },
    };
}
