import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { SupportLinks, Videos } from '../../../../../config';
import { RewardContainerBaseConfig } from '../../../base';
import { OfferOverviewController, OfferOverviewLoader } from './offerOverviewInterface';
import { OfferOverviewViewProps } from './offerOverviewProps';
import { useDisclosure } from '@chakra-ui/react';

export function createOfferOverviewContainer(
    config: RewardContainerBaseConfig,
    loader: OfferOverviewLoader,
    controller: OfferOverviewController,
    View: React.FC<OfferOverviewViewProps>
): React.FC {
    const {
        kernel,
        repository: {
            asssitant: { state: stateRepository },
        },
        context: {
            root: { useContext },
        },
    } = config;

    let prewarmedExecuted = false;
    return (containerProps) => {
        const location = useLocation();
        const context = useContext();
        const [searchTerm, setSearchTerm] = useState('');
        const data = loader.useLoad(context, {});

        const mutation = {
            state: stateRepository.useCreate(context),
        };

        useEffect(() => {
            async function run() {
                if (prewarmedExecuted) {
                    console.info('prewarm already ran, skipping...');
                    return;
                }
                prewarmedExecuted = true;
                console.log('prewarming offers...');
                await Promise.all(
                    data.search.flatMap((offer) =>
                        offer.tasks?.map(async (task) => {
                            console.info('preparing workflow state', task.root);
                            try {
                                const result = await mutation.state.mutateAsync({
                                    type: 'task',
                                    data: {
                                        task: task.root.key,
                                        offer: offer.root.id,
                                        prompt: task.root.config.prompt,
                                    },
                                });
                                console.info('successfully prewarmed offer', result);
                            } catch (error) {
                                console.error(`failed to prewarm task`, error, task);
                                return;
                            }
                        })
                    )
                );
                console.log('prewarming complete!');
            }
            run();
        }, []);

        const props = controller.useProps(context, data, {
            location,
            search: {
                items: data.search,
                value: searchTerm,
                onChange: setSearchTerm,
            },
            content: {
                earn: {
                    disclosure: useDisclosure(),
                    video: Videos.REWARDS_EARN,
                },
                featuring: {
                    disclosure: useDisclosure(),
                    video: Videos.REWARDS_FEATURING,
                },
            },
            request: {
                kind: 'custom',
                name: 'Questions? Ask us',
                onPerform() {
                    kernel.infra.help.adapter.open();
                },
            },
            action: {
                learn: null,
                // learn: {
                //     label: 'Learn more',
                //     anchor: {
                //         id: 'rewards_hero_learn_more',
                //         href: SupportLinks.REWARDS_LEARN_MORE,
                //         target: '_blank',
                //     },
                // },
            },
        });
        return <View {...props} />;
    };
}
