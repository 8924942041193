import { ContributorThemeConfig, ContributorThemeInit } from './contributorThemeConfig';
import { ContributorTheme, ContributorThemeEnhancer } from './contributorThemeInterface';
import { ContributorThemeViewConfig } from './base';
import {
    createContributorShellDesktopView,
    createContributorShellMobileView,
    createContributorShellErrorView,
} from './shell';

export function createContributorTheme(
    config: ContributorThemeInit,
    enhancer?: ContributorThemeEnhancer
): ContributorTheme {
    return create({ ...config }, enhancer);
}

function create(
    config: ContributorThemeConfig,
    enhancer?: ContributorThemeEnhancer
): ContributorTheme {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const viewConfig: ContributorThemeViewConfig = {
        UI: config.UI,
    };
    return {
        Shell: {
            Desktop: {
                Shell: createContributorShellDesktopView(viewConfig),
                Error: createContributorShellErrorView(viewConfig),
            },
            Mobile: {
                Shell: createContributorShellMobileView(viewConfig),
                Error: createContributorShellErrorView(viewConfig),
            },
        },
    };
}
