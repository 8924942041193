import * as fixture from '../../fixture';
import { ReportingLoaderBaseConfig } from '../../base';
import { ReportChapterLoader } from './reportChapterInterface';
import { ReportChapterAggregate } from './reportChapterModel';

export function createReportChapterLoader(
    config: ReportingLoaderBaseConfig
): ReportChapterLoader {
    const CANDIDATES = [
        {
            report: fixture.MaintenanceReport.report,
            author: fixture.MaintenanceReport.author,
            content: fixture.MaintenanceReport.content,
        },
        {
            report: fixture.AutomatingKYC.report,
            author: fixture.AutomatingKYC.author,
            content: fixture.AutomatingKYC.content,
        },
    ];
    return {
        useLoad(context, props): ReportChapterAggregate {
            const report = CANDIDATES.find(
                (candidate) => candidate.report.id === props.report.id
            );
            if (!report) {
                throw new Error(`Report with id ${props.report.id} not found`);
            }
            const chapter = report.content.chapters.find(
                (candidate) => candidate.id === props.chapter.id
            );
            if (!chapter) {
                throw new Error(`Chapter with id ${props.chapter.id} not found`);
            }
            return {
                report: report.report,
                chapter,
                content: report.content,
                author: report.author,
            };
        },
    };
}
