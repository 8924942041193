import { useQueryClient } from '@tanstack/react-query';
import { SessionCreatePayload, SessionObject } from '@varos/assistant-sdk';
import { AssistantSdk } from '@varos/assistant-sdk';
import { ApplicationContext, Kernel } from '../../../base';
import {
    AssistantSessionCreateMutation,
    AssistantSessionRepository,
} from './assistantSessionInterface';

export function createSessionRepository(
    kernel: Kernel,
    api: AssistantSdk
): AssistantSessionRepository {
    const PREFIX = ['conversations', 'session'];

    // Helper function to generate consistent query keys
    const getQueryKey = (context: ApplicationContext) => [
        ...PREFIX,
        context.principal?.id,
    ];

    return {
        useLookup(context, props, options) {
            const queryKey = getQueryKey(context);
            const query = kernel.infra.repository.useQuery({
                ...options,
                queryKey: [...queryKey, props],
                queryFn: async () => {
                    const response = await api.sessionCreate({
                        sessionCreatePayload: props,
                    });
                    return response.data;
                },
            });
            return query;
        },
        useCreate(context): AssistantSessionCreateMutation {
            const client = useQueryClient();
            const mutation = kernel.infra.repository.useMutation(context, {
                async mutationFn(props: SessionCreatePayload) {
                    const response = await api.sessionCreate({
                        sessionCreatePayload: props,
                    });
                    return response.data;
                },
            });
            return mutation;
        },
    };
}
