import { useQueryClient } from '@tanstack/react-query';
import { FormLookupRequest, FormSdk } from '@varos/form-sdk';
import { Kernel } from '../../../base';
import { FormDefinitionRepository } from './definitionRepositoryInterface';

export function createFormDefinitionRepository(
    kernel: Kernel,
    api: FormSdk
): FormDefinitionRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['form', 'v2', 'definition'];

    function getLookupKey(props: FormLookupRequest | FormLookupRequest[]) {
        return [...PREFIX, 'lookup', props];
    }

    function getLookupMultiKey(props: FormLookupRequest | FormLookupRequest[]) {
        return [...PREFIX, 'lookup', 'multi', props];
    }

    return {
        useLookup(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: getLookupKey(props),
                async queryFn() {
                    const response =
                        await api.respondent.respondentDefinitionRetrieve(props);
                    return response.data;
                },
            });
            return query;
        },
        useLookupMulti(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: getLookupMultiKey(props),
                async queryFn() {
                    const responses = await Promise.all(
                        props.map((request) =>
                            api.respondent.respondentDefinitionRetrieve(request)
                        )
                    );
                    return responses.map((item) => item.data);
                },
            });
            return query;
        },
    };
}
