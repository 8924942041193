import { useQueryClient } from '@tanstack/react-query';
import { RewardSdk } from '@varos/reward-sdk';
import { Kernel } from '../../../base';
import { PromotionRepository } from './promotionInterface';

export function createPromotionRepository(
    kernel: Kernel,
    api: RewardSdk
): PromotionRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['reward', 'promotion'];
    return {
        useFind(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, props],
                async queryFn() {
                    const response = await api.contributor.promotionList(props);
                    return response.data.data;
                },
            });
            return query;
        },
    };
}
