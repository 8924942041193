import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    StackDivider,
    Tag,
    Text,
    useToken,
    VStack,
    Wrap,
    Link as ChakraLink,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
} from '@chakra-ui/react';
import React from 'react';
import { MdOpenInNew, MdOutlineSavedSearch } from 'react-icons/md';
import { BsArrowRightShort } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import { Graphics, Images } from '../../../../../config/svg';
import { VAROS_YELLOW } from '../../../../../config';
import { Icons } from '../../../../../../config';
import { RewardViewBaseConfig } from '../../../base';
import { OfferOverviewViewProps } from './offerOverviewProps';
import { rewardStyles } from '../../../constant';
import { OfferItemViewProps } from '../../../fragment';
import { ModalViewProps, VideoViewProps } from 'src/v2/view/common';

export function createOfferOverviewView(
    config: RewardViewBaseConfig
): React.FC<OfferOverviewViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
        },
    } = config;

    const OfferCard: React.FC<OfferItemViewProps> = (props) => (
        <VStack
            align="start"
            w="full"
            p={6}
            bg="whiteAlpha.100"
            borderRadius="xl"
            spacing={4}
        >
            <HStack spacing={4}>
                <Box
                    // borderWidth={1}
                    // borderColor="whiteAlpha.300"
                    bg="whiteAlpha.100"
                    borderRadius="lg"
                    flexShrink={0}
                    p={2}
                    userSelect="none"
                >
                    <Image w={8} h={8} fit="contain" {...props.image} />
                </Box>
                <VStack align="start" w="full" spacing={1}>
                    <Link {...props.link}>
                        <Text fontWeight="semibold" color="whiteAlpha.900" fontSize="lg">
                            {props.label}
                        </Text>
                    </Link>
                    <Wrap shouldWrapChildren={true} userSelect="none">
                        {props.tags.map((tag) => (
                            <Tag key={tag.id} colorScheme="blue" variant="subtle">
                                {tag.label}
                            </Tag>
                        ))}
                    </Wrap>
                </VStack>
            </HStack>
            <Text color="whiteAlpha.700" noOfLines={2}>
                {props.caption}
            </Text>
            <HStack w="full" justify="space-between">
                <Link {...props.link}>
                    <Button
                        size="md"
                        rightIcon={<Icon fontSize="xl" as={BsArrowRightShort} />}
                        bg="whiteAlpha.50"
                        _hover={{ bg: 'whiteAlpha.100' }}
                    >
                        Claim reward
                    </Button>
                </Link>
                <Link {...props.link}>
                    <Button size="md" variant="link">
                        View details
                    </Button>
                </Link>
            </HStack>
        </VStack>
    );

    const ContentModal: React.FC<{
        modal: ModalViewProps;
        video: VideoViewProps;
        children: string;
    }> = (props) => (
        <>
            <Button
                {...props.modal.triggerProps}
                colorScheme="blue"
                variant="outline"
                size="lg"
                borderRadius="full"
            >
                {props.children}
            </Button>
            <Modal {...props.modal.containerProps} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton _focus={{ outline: 'none' }} />
                    <ModalBody p={0}>
                        <VStack w="full" align="start" p={12} spacing={6}>
                            <VStack w="full" align="start" spacing={1}>
                                <HStack color="#c6e74f">
                                    <Icon as={Icons.Feature.Highlight} />
                                    <Text fontWeight="bold">Feature highlight</Text>
                                </HStack>
                                <VStack w="full" align="start" spacing={1}>
                                    <Heading fontSize="2xl">{props.video.title}</Heading>
                                    {props.video.description && (
                                        <Text
                                            fontWeight="medium"
                                            fontSize="xl"
                                            color="whiteAlpha.700"
                                        >
                                            {props.video.description}
                                        </Text>
                                    )}
                                </VStack>
                            </VStack>
                            <Box
                                bg="whiteAlpha.300"
                                borderRadius="lg"
                                overflow="hidden"
                                w="full"
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    paddingBottom: '64.86486486486486%',
                                    height: 0,
                                }}
                            >
                                <iframe
                                    src={props.video.src}
                                    frameBorder="0"
                                    allowFullScreen
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </Box>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );

    return (props) => {
        const [gradientStart, gradientEnd] = useToken('colors', [
            'whiteAlpha.50',
            'whiteAlpha.200',
        ]);
        return (
            <HStack w="full" justify="center" py={{ base: 6, lg: 12 }}>
                <VStack
                    maxW={rewardStyles.content.maxW}
                    align="start"
                    w="full"
                    spacing={{ base: 12, lg: 24 }}
                >
                    <VStack align="start" w="full" spacing={12}>
                        <HStack w="full" justify="space-between">
                            <HStack>
                                <Heading fontWeight="medium" fontSize="2xl">
                                    {/* Varos Rewards */}
                                    Varos Expert Interviews
                                </Heading>
                            </HStack>
                            <ButtonGroup display={{ base: 'none', md: 'flex' }}>
                                <Button
                                    {...props.request.action.button}
                                    variant="outline"
                                    borderColor="whiteAlpha.300"
                                    bg="none"
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                    rightIcon={<Icon as={MdOpenInNew} />}
                                >
                                    {props.request.action.label}
                                </Button>
                            </ButtonGroup>
                        </HStack>
                        {/* banner */}
                        <VStack
                            align="start"
                            w="full"
                            spacing={0}
                            borderRadius="xl"
                            borderEndRadius="xl"
                            bgGradient={`linear(to-br, ${gradientStart} 0%, ${gradientStart} 30%, ${gradientEnd} 100%)`}
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.100"
                                />
                            }
                        >
                            <Box w="full">
                                <Grid
                                    w="full"
                                    templateColumns={{ base: '1fr', md: '2fr 1fr' }}
                                    p={8}
                                    columnGap={{ base: 16 }}
                                    rowGap={{ base: 8 }}
                                >
                                    <GridItem>
                                        <VStack
                                            align="start"
                                            spacing={6}
                                            maxW="32rem"
                                            h="full"
                                            w="full"
                                        >
                                            <VStack
                                                align="start"
                                                spacing={3}
                                                w="full"
                                                borderColor={{
                                                    base: 'inherit',
                                                    md: VAROS_YELLOW['200'],
                                                }}
                                                borderLeftWidth={{
                                                    base: 'inherit',
                                                    md: 4,
                                                }}
                                                pl={{ base: 'inherit', md: 8 }}
                                            >
                                                <Heading
                                                    fontSize="2xl"
                                                    fontWeight="medium"
                                                    color="whiteAlpha.900"
                                                >
                                                    Get Rewarded for Sharing Your
                                                    Expertise
                                                </Heading>
                                                <Text
                                                    fontSize="lg"
                                                    color="whiteAlpha.700"
                                                >
                                                    Participate in our AI-powered
                                                    interview to share your insights, earn
                                                    rewards, and help shape actionable
                                                    research for Enterprise Tech Execs
                                                </Text>
                                            </VStack>
                                        </VStack>
                                    </GridItem>
                                    <GridItem as={HStack} justify="end">
                                        <HStack
                                            w="full"
                                            h="full"
                                            spacing={8}
                                            align="center"
                                            justify="end"
                                        >
                                            {props.hero.action.learn && (
                                                <Anchor
                                                    {...props.hero.action.learn.anchor}
                                                >
                                                    <Button
                                                        size="lg"
                                                        w={{
                                                            base: 'full',
                                                            md: 'auto',
                                                        }}
                                                        variant="link"
                                                        borderRadius="full"
                                                        _hover={{
                                                            bg: 'inherit',
                                                            textDecor: 'underline',
                                                        }}
                                                        _focus={{
                                                            bg: 'inherit',
                                                            outline: 'none',
                                                        }}
                                                    >
                                                        {props.hero.action.learn.label}
                                                    </Button>
                                                </Anchor>
                                            )}
                                            {props.hero.action.start && (
                                                <ChakraLink
                                                    as={Link}
                                                    w={{ base: 'full', md: 'auto' }}
                                                    {...props.hero.action.start.link}
                                                >
                                                    <Button
                                                        size="lg"
                                                        w={{
                                                            base: 'full',
                                                            md: 'auto',
                                                        }}
                                                        bg={VAROS_YELLOW['200']}
                                                        color="black"
                                                        borderRadius="full"
                                                        _hover={{
                                                            bg: VAROS_YELLOW['200'],
                                                        }}
                                                        _focus={{
                                                            bg: VAROS_YELLOW['200'],
                                                        }}
                                                    >
                                                        {props.hero.action.start.label}
                                                    </Button>
                                                </ChakraLink>
                                            )}
                                        </HStack>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <HStack justify="center" w="full" p={8}>
                                <Box w="full">
                                    <SimpleGrid
                                        w="full"
                                        color="whiteAlpha.500"
                                        columns={{ base: 2, md: 2, xl: 4 }}
                                        rowGap={8}
                                        columnGap={24}
                                        fontSize="lg"
                                        fontWeight="medium"
                                        justifyItems="center"
                                    >
                                        <HStack textAlign="center">
                                            <Text
                                                color="whiteAlpha.400"
                                                fontWeight="bold"
                                                letterSpacing="wide"
                                                textTransform="uppercase"
                                                fontSize="sm"
                                            >
                                                Backed by leading VCs
                                            </Text>
                                        </HStack>
                                        <HStack h="full" align="center">
                                            <Anchor
                                                id="offer_overview_hero_investor_ycombinator"
                                                href="https://www.ycombinator.com"
                                                target="_blank"
                                            >
                                                <Image
                                                    filter="grayscale(100%) opacity(50%)"
                                                    w={36}
                                                    src={Graphics.Investor.YCombinator}
                                                    alt="brand"
                                                />
                                            </Anchor>
                                        </HStack>
                                        <HStack h="full" align="center">
                                            <Anchor
                                                id="offer_overview_hero_investor_ibex"
                                                href="https://www.ibexinvestors.com"
                                                target="_blank"
                                            >
                                                <Image
                                                    filter="grayscale(100%) opacity(50%)"
                                                    w={32}
                                                    src={Images.Investor.Ibex}
                                                    alt="brand"
                                                />
                                            </Anchor>
                                        </HStack>
                                        <HStack h="full" align="center">
                                            <Anchor
                                                id="offer_overview_hero_investor_mosaic"
                                                href="https://mgp.vc"
                                                target="_blank"
                                            >
                                                <Image
                                                    filter="grayscale(100%) opacity(50%)"
                                                    w={32}
                                                    src={Graphics.Investor.Mosaic}
                                                    alt="brand"
                                                />
                                            </Anchor>
                                        </HStack>
                                    </SimpleGrid>
                                </Box>
                            </HStack>
                        </VStack>
                    </VStack>
                    <Box w="full">
                        <Grid
                            w="full"
                            templateColumns={{ base: '1fr', lg: '1fr min-content 1fr' }}
                            columnGap={24}
                            rowGap={8}
                        >
                            <GridItem>
                                <VStack w="full" align="start" spacing={8}>
                                    <VStack align="start" w="full" spacing={6}>
                                        <VStack align="start" w="full" spacing={4}>
                                            <Heading
                                                color="whiteAlpha.800"
                                                fontSize="xl"
                                                fontWeight="semibold"
                                            >
                                                Earn Instant Cash Rewards
                                            </Heading>
                                            <Text fontSize="lg" color="whiteAlpha.600">
                                                Share your insights in our AI-powered
                                                interview and earn cash rewards if your
                                                responses meet our quality check
                                            </Text>
                                        </VStack>
                                        <ContentModal
                                            modal={props.content.earn.modal}
                                            video={props.content.earn.video}
                                        >
                                            1-min video
                                        </ContentModal>
                                    </VStack>
                                </VStack>
                            </GridItem>
                            <GridItem display={{ base: 'none', lg: 'flex' }}>
                                <VStack h="full" spacing={4}>
                                    <Box
                                        h="full"
                                        w={1}
                                        bg="whiteAlpha.200"
                                        borderRadius="md"
                                    />
                                    <Text
                                        color="whiteAlpha.300"
                                        textTransform="uppercase"
                                        fontSize="md"
                                        fontWeight="bold"
                                    >
                                        And
                                    </Text>
                                    <Box
                                        h="full"
                                        w={1}
                                        bg="whiteAlpha.200"
                                        borderRadius="md"
                                    />
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack w="full" align="start" spacing={8}>
                                    <VStack align="start" w="full" spacing={6}>
                                        <VStack align="start" w="full" spacing={4}>
                                            <Heading
                                                color="whiteAlpha.800"
                                                fontSize="xl"
                                                fontWeight="semibold"
                                            >
                                                Showcase Your Profile to Enterprise Tech
                                                Execs
                                            </Heading>
                                            <Text fontSize="lg" color="whiteAlpha.600">
                                                We connect top talent with enterprise tech
                                                execs by showcasing your profile to key
                                                decision-makers if your interview meets
                                                our quality standards
                                            </Text>
                                        </VStack>
                                        <ContentModal
                                            modal={props.content.featured.modal}
                                            video={props.content.featured.video}
                                        >
                                            1-min video
                                        </ContentModal>
                                    </VStack>
                                </VStack>
                            </GridItem>
                        </Grid>
                    </Box>
                    {/* Promotions */}
                    {props.promotions && (
                        <VStack align="start" w="full" spacing={6}>
                            <Alert w="full" status="info">
                                <AlertIcon display={{ base: 'none', md: 'block' }} />
                                <AlertTitle w="full">
                                    <HStack w="full" justify="space-between">
                                        <Text>
                                            You have been invited to claim these special
                                            offers
                                        </Text>
                                        <Button
                                            display={{ base: 'none', md: 'block' }}
                                            variant="link"
                                        >
                                            Learn more
                                        </Button>
                                    </HStack>
                                </AlertTitle>
                            </Alert>
                            <VStack align="start" spacing={6} w="full">
                                <Heading
                                    color="whiteAlpha.900"
                                    fontWeight="medium"
                                    fontSize="xl"
                                >
                                    Special offers for you
                                </Heading>
                                <VStack align="start" w="full" spacing={6}>
                                    <SimpleGrid
                                        w="full"
                                        columns={
                                            props.promotions.items.length === 1
                                                ? { base: 1, md: 2, '2xl': 2 }
                                                : { base: 1, md: 2, '2xl': 3 }
                                        }
                                        gap={{ base: 6, md: 8 }}
                                    >
                                        {props.promotions.items.map((item) => (
                                            <OfferCard key={item.id} {...item} />
                                        ))}
                                        {props.promotions.items.length % 2 === 1 ? (
                                            <VStack
                                                display={{ base: 'none', md: 'flex' }}
                                                w="full"
                                                h="full"
                                                borderWidth={2}
                                                borderStyle="dashed"
                                                borderColor="whiteAlpha.200"
                                                borderRadius="xl"
                                                justify="center"
                                                color="whiteAlpha.400"
                                                fontWeight="medium"
                                                // fontSize="sm"
                                                // textTransform="uppercase"
                                                // letterSpacing="wide"
                                            >
                                                <Text>Want to receive more offers?</Text>
                                                <Button
                                                    variant="link"
                                                    color="whiteAlpha.500"
                                                    fontSize="sm"
                                                    fontWeight="semibold"
                                                    textTransform="uppercase"
                                                    letterSpacing="wide"
                                                    _hover={{ textDecor: 'underline' }}
                                                >
                                                    Contact us
                                                </Button>
                                            </VStack>
                                        ) : null}
                                    </SimpleGrid>
                                </VStack>
                            </VStack>
                        </VStack>
                    )}
                    {/* Search */}
                    {props.search && (
                        <VStack align="start" w="full" spacing={6}>
                            <Heading
                                color="whiteAlpha.900"
                                fontWeight="medium"
                                fontSize="xl"
                            >
                                All offers
                            </Heading>
                            <HStack w="full">
                                <InputGroup w="full" size="lg">
                                    <Input
                                        {...props.search.input.input}
                                        borderRadius="full"
                                        _focus={{ outline: 'none', shadow: 'none' }}
                                    />
                                    <InputLeftElement>
                                        <Icon
                                            color="whiteAlpha.500"
                                            as={AiOutlineSearch}
                                        />
                                    </InputLeftElement>
                                </InputGroup>
                            </HStack>
                            <VStack align="start" w="full" spacing={6}>
                                {props.search.input.empty && (
                                    <HStack w="full" justify="center">
                                        <Text fontWeight="medium" color="whiteAlpha.600">
                                            {props.search.input.empty.label}
                                        </Text>
                                    </HStack>
                                )}
                                {!props.search.input.empty && (
                                    <VStack align="start" w="full" spacing={4}>
                                        <HStack w="full">
                                            <Text
                                                color="whiteAlpha.500"
                                                fontSize="sm"
                                                fontWeight="medium"
                                            >
                                                {props.search.pagination.label}
                                            </Text>
                                        </HStack>
                                        <SimpleGrid
                                            w="full"
                                            columns={{ base: 1, md: 2, '2xl': 3 }}
                                            gap={6}
                                        >
                                            {props.search.items.map((item) => (
                                                <OfferCard key={item.id} {...item} />
                                            ))}
                                        </SimpleGrid>
                                    </VStack>
                                )}
                            </VStack>
                        </VStack>
                    )}
                </VStack>
            </HStack>
        );
    };
}
