import { HStack, StackDivider, VStack } from '@chakra-ui/react';
import {
    CenteredLayoutProvider,
    CenteredLayoutComponents,
} from './centeredLayoutInterface';

export function createCenteredLayoutProvider(): CenteredLayoutProvider {
    return {
        create(config) {
            return {
                Component: {
                    Root(props) {
                        return (
                            <VStack w="full" align="start">
                                {props.children}
                            </VStack>
                        );
                    },
                    Content(props) {
                        return (
                            <VStack
                                w="full"
                                align="center"
                                pt={8}
                                pb={24}
                                spacing={6}
                                // divider={
                                //     <StackDivider
                                //         borderWidth={1}
                                //         borderColor="whiteAlpha.100"
                                //     />
                                // }
                            >
                                {props.children}
                            </VStack>
                        );
                    },
                    Header(props) {
                        return (
                            <HStack maxW="48rem" w="full">
                                {props.children}
                            </HStack>
                        );
                    },
                    Body(props) {
                        return (
                            <HStack maxW="48rem" w="full">
                                {props.children}
                            </HStack>
                        );
                    },
                },
            };
        },
    };
}
