import React from 'react';
import { ReportingContainerBaseConfig } from '../../../base';
import { ReportNewUrlParamsSchema } from './reportNewSchema';
import { ReportNewController, ReportNewLoader } from './reportNewInterface';
import { ReportNewContainerProps, ReportNewViewProps } from './reportNewProps';

export function createReportNewContainer(
    config: ReportingContainerBaseConfig,
    loader: ReportNewLoader,
    controller: ReportNewController,
    View: React.FC<ReportNewViewProps>
): React.FC<ReportNewContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    return ({ ...containerProps }) => {
        const options = useOptions(ReportNewUrlParamsSchema);
        const context = useContext();
        const data = loader.useLoad(context, {
            template: { slug: options.template },
        });
        const props = controller.useProps(context, data, {});
        return <View {...props} />;
    };
}
