import React, { useState, useEffect, useContext } from 'react';
import { RouterConfig } from './routerConfig';
import {
    Router,
    RouterEnhancer,
    RouterLinkProps,
    RouterLinkComponent,
} from './routerInterface';
import { RouterRootProps } from './root';
import { RouterRouteProps } from './route/routerRouteProps';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RouterStrategy, createHashStrategy } from './strategies';
import { createRouterContext, RouterContextType } from './routerContext';

// Get the appropriate strategy based on configuration
function getRouterStrategy(config: RouterConfig): RouterStrategy {
    // Currently only supports hash strategy, but can be extended
    if (config.state.kind === 'hash') {
        return createHashStrategy();
    }

    // Default to hash strategy for now
    return createHashStrategy();
}

export function createRouter(config: RouterConfig, enhancer?: RouterEnhancer): Router {
    if (enhancer) {
        return enhancer(createRouter)(config);
    }

    // Create a unique context for this router instance
    const RouterContext = createRouterContext();

    // Get the appropriate strategy based on configuration
    const strategy = getRouterStrategy(config);

    // Create hooks for this specific router instance
    const useNavigate = () => {
        const context = useContext(RouterContext);
        return (path: string | null, replace: boolean = false) => {
            strategy.navigate(path, replace);
        };
    };

    const useLocation = () => {
        const context = useContext(RouterContext);
        return { pathname: context.currentPath };
    };

    // Link component that uses the router's navigation
    // If React Router is available, we can wrap it; otherwise, use our custom implementation
    const Link: RouterLinkComponent = <C extends React.ElementType = 'a'>({
        to,
        replace = false,
        children,
        className,
        style,
        onClick,
        as,
        ...rest
    }: RouterLinkProps<C>) => {
        const Component = as ?? 'a';
        const context = useContext(RouterContext);

        // Common click handler for both implementations
        const handleNavClick = (e: React.MouseEvent) => {
            // Let the browser handle the event if:
            // 1. It's not a left click
            // 2. Meta keys are pressed (Ctrl, Alt, Shift, Meta)
            if (e.button !== 0 || e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
                return;
            }

            e.preventDefault();

            // Call the provided onClick handler if any
            if (onClick) {
                onClick(e);
            }

            // Navigate to the destination using the strategy directly
            strategy.navigate(to, replace);
        };

        // If React Router is available in the project, try to use its Link
        try {
            if (Component === 'a') {
                // Only use ReactRouterLink if we're rendering as an 'a' tag
                // Create a URL for React Router Link
                const url = strategy.createUrl(to);

                // Use React Router's Link component but intercept its behavior
                return (
                    <ReactRouterLink
                        to={url}
                        className={className}
                        style={style}
                        onClick={handleNavClick}
                        {...rest}
                    >
                        {children}
                    </ReactRouterLink>
                );
            }
        } catch (e) {
            // If ReactRouterLink is not available, fall back to our implementation
        }

        // For custom components or when React Router is not available
        const linkProps =
            Component === 'a'
                ? {
                      href: strategy.createUrl(to),
                      className,
                      style,
                      onClick: handleNavClick,
                      ...rest,
                  }
                : {
                      className,
                      style,
                      onClick: handleNavClick,
                      ...rest,
                  };

        return <Component {...linkProps}>{children}</Component>;
    };

    return {
        Component: {
            Root(props: RouterRootProps) {
                const [currentPath, setCurrentPath] = useState<string>(() =>
                    strategy.getCurrentPath()
                );

                // Listen for hash changes in the URL
                useEffect(() => {
                    const handleHashChange = () => {
                        setCurrentPath(strategy.getCurrentPath());
                    };

                    window.addEventListener('hashchange', handleHashChange);
                    return () => {
                        window.removeEventListener('hashchange', handleHashChange);
                    };
                }, []);

                // Navigation function for this context
                const navigate = (path: string | null, replace: boolean = false) => {
                    strategy.navigate(path, replace);
                };

                // Provide the routing context
                return (
                    <RouterContext.Provider value={{ currentPath, navigate }}>
                        {props.children}
                    </RouterContext.Provider>
                );
            },
            Route(props: RouterRouteProps) {
                const { currentPath } = useContext(RouterContext);

                // For index routes
                if ('index' in props && props.index) {
                    return currentPath === '' ? props.element : null;
                }

                // For path-based routes
                if ('path' in props) {
                    const routePath = props.path;

                    // Simple path matching (can be enhanced for path parameters)
                    if (currentPath === routePath) {
                        return props.element;
                    }
                }

                return null;
            },
            Link,
        },
        useNavigate,
        useLocation,
    };
}
