import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { USER_PROVIDER_QUERY_PREFIX } from '../../../impl';
import { collectionFromQuery } from '../../../base';
import { AccountScope } from '../../platform';
import { ORG_WORKSPACE_QUERY_KEY } from '../../../context';
import { AssetAdapter } from './assetAdapter';
import { AssetQuery, AssetByIdQuery } from './assetQuery';
import { AssetRepository } from './assetInterface';

export function createAssetRepository(adapter: AssetAdapter): AssetRepository {
    const PREFIX = ['assets-v2'];
    function getPrefix(context: AccountScope, query: AssetQuery | AssetByIdQuery) {
        return [...PREFIX, context.account.id, query];
    }
    return {
        useFind(context, query, options) {
            const applied: AssetQuery = {
                ...query,
                visible: query.visible ?? null,
                // visible: null,
                limit: query.limit ?? 1000,
            };
            const queryKey = getPrefix(context, applied);
            return useQuery({
                staleTime: Infinity,
                queryKey,
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response.items;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            // return collectionFromQuery(result);
        },
        useLookup(context, query, options) {
            const queryKey = getPrefix(context, query);
            return useQuery({
                staleTime: Infinity,
                queryKey,
                async queryFn() {
                    const response = await adapter.fetch(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
        },
        useCreate(context) {
            const client = useQueryClient();
            return useMutation({
                mutationFn(props) {
                    return adapter.create(context, props);
                },
                async onSuccess(data, variables, context) {
                    console.log('refetching queries');
                    await client.refetchQueries(PREFIX);

                    // HACK we should base the context query on the same repository implementation
                    await client.refetchQueries(ORG_WORKSPACE_QUERY_KEY);
                    await client.refetchQueries(USER_PROVIDER_QUERY_PREFIX);
                },
            });
        },
        useUpdate(context) {
            const client = useQueryClient();
            return useMutation({
                mutationFn([id, props]) {
                    return adapter.update(context, id, props);
                },
                async onSuccess(data, variables, context) {
                    console.log('refetching queries');
                    await client.refetchQueries(PREFIX);

                    // HACK we should base the context query on the same repository implementation
                    await client.refetchQueries(ORG_WORKSPACE_QUERY_KEY);
                    await client.refetchQueries(USER_PROVIDER_QUERY_PREFIX);
                },
            });
        },
        useClaim(context) {
            const client = useQueryClient();
            return useMutation({
                mutationFn(props) {
                    return adapter.claim(context, props);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);

                    // HACK we should base the context query on the same repository implementation
                    await client.refetchQueries(ORG_WORKSPACE_QUERY_KEY);
                    await client.refetchQueries(USER_PROVIDER_QUERY_PREFIX);
                },
            });
        }
    };
}
