import React, { Suspense } from 'react';
import { Select as ChakraSelect } from 'chakra-react-select';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Center,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Stack,
    StackDivider,
    Tag,
    Text,
    Tooltip,
    useToken,
    VStack,
} from '@chakra-ui/react';
import { Graphics } from '../../../../../config/svg';
import { GRAY } from '../../../../../config';
import { CardSpinner } from '../../../../../../domain';
import { ReportingRootViewProps } from '../../../../../shell';
import { ContributorThemeViewConfig } from '../../../base';
import { contributorThemeStyles } from '../../../style';
import { MdOutlineNotificationsNone } from 'react-icons/md';
import { AiOutlineCaretDown, AiOutlinePlus } from 'react-icons/ai';
import { ChevronDownIcon } from '@chakra-ui/icons';

export function createReportingShellDesktopView(
    config: ContributorThemeViewConfig
): React.FC<ReportingRootViewProps> {
    const {
        UI: {
            Application: { Link, Anchor },
        },
    } = config;

    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Grid
                w="100dvw"
                h="100dvh"
                templateRows="min-content 1fr"
                templateColumns="1fr"
            >
                {/* Header */}
                <GridItem
                    py={3}
                    px={6}
                    borderBottomWidth={2}
                    borderColor="whiteAlpha.300"
                >
                    <HStack justify="center">
                        <SimpleGrid
                            // maxW={{ base: '64rem', '2xl': '76rem' }}
                            w="full"
                            columns={3}
                            templateColumns={{
                                base: '1fr 1fr',
                                md: '1fr minmax(auto, 32rem) 1fr',
                            }}
                        >
                            <HStack w="full" justify="space-between">
                                <HStack spacing={12}>
                                    <Link {...props.home.link}>
                                        <Image
                                            src={Graphics.Brand.WhiteText}
                                            h={8}
                                            maxH={8}
                                            alt="brand"
                                        />
                                    </Link>
                                </HStack>
                            </HStack>
                            <HStack>
                                {/* <InputGroup w="full">
                                    <InputLeftElement h={9}>
                                        <Icon
                                            fontSize="lg"
                                            color="whiteAlpha.600"
                                            as={AiOutlineSearch}
                                        />
                                    </InputLeftElement>
                                    <Input
                                        h={9}
                                        border="none"
                                        bg="whiteAlpha.100"
                                        placeholder="Search"
                                        _focus={{ outline: 'none', bg: 'whiteAlpha.200' }}
                                    />
                                </InputGroup> */}
                            </HStack>
                            <HStack
                                color="whiteAlpha.800"
                                spacing={4}
                                justify="end"
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.100"
                                        borderWidth={1}
                                    />
                                }
                            >
                                <HStack spacing={2} fontSize="md" fontWeight="medium">
                                    {props.navigation.view.navigation.items.map(
                                        (item) => (
                                            <Link key={item.id} {...item.link}>
                                                <HStack
                                                    aria-current={item.isActive}
                                                    w="full"
                                                    borderRadius="md"
                                                    h={9}
                                                    align="center"
                                                    px={3}
                                                    cursor="pointer"
                                                    // letterSpacing="wide"
                                                    fontSize="sm"
                                                    fontWeight="semibold"
                                                    _hover={{ bg: 'whiteAlpha.50' }}
                                                    {...(item.isActive
                                                        ? { bg: 'whiteAlpha.100' }
                                                        : {})}
                                                >
                                                    <Text whiteSpace="nowrap">
                                                        {item.label}
                                                    </Text>
                                                    {item.tag && (
                                                        <Tag
                                                            {...item.tag}
                                                            size="sm"
                                                            fontWeight="bold"
                                                            color="blackAlpha.900"
                                                            bg="#c6e74f"
                                                            letterSpacing="wide"
                                                        />
                                                    )}
                                                </HStack>
                                            </Link>
                                        )
                                    )}
                                </HStack>
                                <HStack spacing={4}>
                                    <HStack spacing={2}>
                                        <Menu>
                                            <MenuButton
                                                cursor="pointer"
                                                minW={9}
                                                h={9}
                                                px={2}
                                                position="relative"
                                                borderColor="whiteAlpha.300"
                                                borderWidth={1}
                                                borderRadius="md"
                                                bg="none"
                                                _hover={{
                                                    bg: 'whiteAlpha.50',
                                                }}
                                                _expanded={{
                                                    bg: 'whiteAlpha.50',
                                                }}
                                            >
                                                <HStack spacing={2}>
                                                    <Icon
                                                        color="whiteAlpha.800"
                                                        fontSize="lg"
                                                        as={AiOutlinePlus}
                                                    />

                                                    <Icon
                                                        top={0.5}
                                                        position="relative"
                                                        color="whiteAlpha.800"
                                                        fontSize="xs"
                                                        as={AiOutlineCaretDown}
                                                    />
                                                </HStack>
                                            </MenuButton>
                                            <MenuList p={2}>
                                                {props.shortcut.items.map((item) => (
                                                    <Link
                                                        key={item.id}
                                                        {...item.action.link}
                                                    >
                                                        <MenuItem
                                                            w="full"
                                                            borderRadius="md"
                                                            cursor="pointer"
                                                            icon={
                                                                <Icon
                                                                    fontSize="md"
                                                                    as={item.Icon}
                                                                />
                                                            }
                                                            fontWeight="medium"
                                                            _hover={{
                                                                bg: 'whiteAlpha.50',
                                                            }}
                                                        >
                                                            <Text whiteSpace="nowrap">
                                                                {item.label}
                                                            </Text>
                                                        </MenuItem>
                                                    </Link>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                        <Popover
                                            {...props.notification.menu}
                                            placement="bottom-start"
                                        >
                                            <PopoverTrigger>
                                                <HStack position="relative">
                                                    <Box
                                                        bg="black"
                                                        p={0.5}
                                                        right={-0.5}
                                                        top={-0.5}
                                                        position="absolute"
                                                        float="right"
                                                        borderRadius="full"
                                                        zIndex={10}
                                                    >
                                                        <Box
                                                            bg="blue.200"
                                                            w={2.5}
                                                            h={2.5}
                                                            borderRadius="full"
                                                        />
                                                    </Box>
                                                    <IconButton
                                                        aria-expanded={
                                                            props.notification.menu.isOpen
                                                        }
                                                        aria-label="Notifications"
                                                        minW={9}
                                                        h={9}
                                                        w={9}
                                                        borderColor="whiteAlpha.300"
                                                        borderWidth={1}
                                                        bg="none"
                                                        _hover={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        _expanded={{
                                                            bg: 'whiteAlpha.50',
                                                        }}
                                                        icon={
                                                            <Icon
                                                                color="whiteAlpha.800"
                                                                fontSize="lg"
                                                                as={
                                                                    MdOutlineNotificationsNone
                                                                }
                                                            />
                                                        }
                                                    />
                                                </HStack>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                bg="black"
                                                // overflow="hidden"
                                                p={0}
                                                border="none"
                                            >
                                                <PopoverHeader
                                                    px={4}
                                                    py={3}
                                                    bg="whiteAlpha.300"
                                                    borderLeftWidth={1}
                                                    borderRightWidth={1}
                                                    borderTopWidth={1}
                                                    borderColor="whiteAlpha.300"
                                                    borderStyle="solid"
                                                    borderTopRadius="md"
                                                    // fontSize="sm"
                                                >
                                                    <HStack
                                                        w="full"
                                                        justify="space-between"
                                                    >
                                                        <HStack>
                                                            <Text
                                                                color="whiteAlpha.600"
                                                                fontWeight="semibold"
                                                            >
                                                                Notifications
                                                            </Text>
                                                        </HStack>
                                                        {props.notification.view
                                                            .unread && (
                                                            <Text
                                                                color="blue.200"
                                                                fontWeight="semibold"
                                                                fontSize="sm"
                                                            >
                                                                {
                                                                    props.notification
                                                                        .view.unread.label
                                                                }
                                                            </Text>
                                                        )}
                                                    </HStack>
                                                </PopoverHeader>
                                                <PopoverBody
                                                    p={0}
                                                    bg="whiteAlpha.300"
                                                    borderLeftWidth={1}
                                                    borderRightWidth={1}
                                                    borderBottomWidth={1}
                                                    borderColor="whiteAlpha.300"
                                                    borderStyle="solid"
                                                    borderBottomRadius="md"
                                                    fontSize="sm"
                                                >
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        divider={
                                                            <StackDivider borderColor="whiteAlpha.200" />
                                                        }
                                                        spacing={0}
                                                    >
                                                        {props.notification.view.items.map(
                                                            (item) => (
                                                                <HStack
                                                                    key={item.id}
                                                                    px={4}
                                                                    py={4}
                                                                    spacing={4}
                                                                    align="start"
                                                                    cursor="pointer"
                                                                    _hover={{
                                                                        bg: 'whiteAlpha.50',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        aria-hidden={
                                                                            !item.unread
                                                                        }
                                                                        bg="blue.200"
                                                                        mt={1.5}
                                                                        w={2.5}
                                                                        h={2.5}
                                                                        borderRadius="full"
                                                                        _hidden={{
                                                                            visibility:
                                                                                'hidden',
                                                                        }}
                                                                    />
                                                                    <VStack
                                                                        align="start"
                                                                        w="full"
                                                                        spacing={0}
                                                                    >
                                                                        <HStack
                                                                            w="full"
                                                                            justify="space-between"
                                                                            spacing={8}
                                                                        >
                                                                            <Text
                                                                                color="whiteAlpha.900"
                                                                                fontWeight="semibold"
                                                                                noOfLines={
                                                                                    1
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.title
                                                                                }
                                                                            </Text>
                                                                            <Text
                                                                                fontSize="sm"
                                                                                fontWeight="medium"
                                                                                color="whiteAlpha.500"
                                                                                whiteSpace="nowrap"
                                                                            >
                                                                                {
                                                                                    item
                                                                                        .timestamp
                                                                                        .label
                                                                                }
                                                                            </Text>
                                                                        </HStack>
                                                                        <Text
                                                                            color="whiteAlpha.700"
                                                                            noOfLines={1}
                                                                        >
                                                                            {item.body}
                                                                        </Text>
                                                                    </VStack>
                                                                </HStack>
                                                            )
                                                        )}
                                                    </VStack>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </HStack>
                                    <Popover
                                        {...props.account.menu}
                                        placement="bottom-start"
                                    >
                                        <PopoverTrigger>
                                            <Avatar cursor="pointer" size="sm" />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            maxW="20rem"
                                            bg="#292929"
                                            _focus={{ outline: 'none' }}
                                            _focusVisible={{ outline: 'none' }}
                                        >
                                            <PopoverBody p={0}>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    divider={
                                                        <StackDivider
                                                            borderColor="whiteAlpha.200"
                                                            // borderWidth={1}
                                                        />
                                                    }
                                                    spacing={0}
                                                >
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        spacing={0}
                                                    >
                                                        <HStack
                                                            fontWeight="medium"
                                                            pt={3}
                                                            px={3}
                                                            spacing={3}
                                                        >
                                                            <Avatar size="sm" />
                                                            <VStack
                                                                align="start"
                                                                spacing={0}
                                                            >
                                                                <Text>
                                                                    {
                                                                        props.account.view
                                                                            .account.label
                                                                    }
                                                                </Text>
                                                                <Text
                                                                    fontSize="sm"
                                                                    color="whiteAlpha.600"
                                                                    userSelect="none"
                                                                >
                                                                    {
                                                                        props.account.view
                                                                            .account.role
                                                                    }
                                                                </Text>
                                                            </VStack>
                                                        </HStack>
                                                        <VStack
                                                            align="start"
                                                            w="full"
                                                            py={2}
                                                            spacing={0}
                                                        >
                                                            {props.account.view.actions.items.map(
                                                                (action) => (
                                                                    <Link
                                                                        key={action.id}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        {...action.link}
                                                                    >
                                                                        <Button
                                                                            py={2}
                                                                            px={3}
                                                                            fontWeight="inherit"
                                                                            borderRadius="none"
                                                                            bg="none"
                                                                            width="full"
                                                                            justifyContent="start"
                                                                            iconSpacing={
                                                                                3
                                                                            }
                                                                            leftIcon={
                                                                                <Icon
                                                                                    as={
                                                                                        action.Icon
                                                                                    }
                                                                                />
                                                                            }
                                                                            _hover={{
                                                                                bg: 'whiteAlpha.100',
                                                                            }}
                                                                        >
                                                                            {action.label}
                                                                        </Button>
                                                                    </Link>
                                                                )
                                                            )}
                                                        </VStack>
                                                    </VStack>
                                                    <HStack w="full" py={2}>
                                                        <Button
                                                            {...props.account.view.logout
                                                                .button}
                                                            py={2}
                                                            px={3}
                                                            fontWeight="inherit"
                                                            borderRadius="none"
                                                            bg="none"
                                                            width="full"
                                                            justifyContent="start"
                                                            iconSpacing={3}
                                                            leftIcon={
                                                                props.account.view.logout
                                                                    .Icon ? (
                                                                    <Icon
                                                                        as={
                                                                            props.account
                                                                                .view
                                                                                .logout
                                                                                .Icon
                                                                        }
                                                                    />
                                                                ) : undefined
                                                            }
                                                            _hover={{
                                                                bg: 'whiteAlpha.100',
                                                            }}
                                                        >
                                                            Logout
                                                        </Button>
                                                    </HStack>
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </HStack>
                            </HStack>
                        </SimpleGrid>
                    </HStack>
                </GridItem>
                <GridItem
                    ref={props.scroll.ref}
                    overflowY="scroll"
                    css={{
                        scrollbarGutter: 'stable both-edges',
                        '&::-webkit-scrollbar': {
                            '-webkit-appearance': 'none',
                            width: '0.5rem',
                        },
                        '&::-webkit-scrollbar-track': {},
                        '&::-webkit-scrollbar-corner': {
                            '-webkit-appearance': 'none',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '1rem',
                            background: scrollbarBg,
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            // background: scrollbarBg,
                            background: scrollbarHoverBg,
                        },
                    }}
                >
                    <Grid h="full" templateRows="1fr auto">
                        <GridItem>
                            <Suspense
                                fallback={
                                    <Center
                                        h="full"
                                        w="full"
                                        minH={contributorThemeStyles.minScreenHeight}
                                    >
                                        <CardSpinner />
                                    </Center>
                                }
                            >
                                <VStack
                                    w="full"
                                    align="start"
                                    px={{ base: 3, sm: 6, md: 12 }}
                                    minH="75vh"
                                >
                                    {props.children}
                                </VStack>
                            </Suspense>
                        </GridItem>
                        <GridItem>
                            <HStack
                                borderTopWidth={2}
                                borderColor="whiteAlpha.200"
                                mt={4}
                                py={{ base: 12, xl: 16 }}
                                w="full"
                                justify="center"
                            >
                                <SimpleGrid
                                    maxW="64rem"
                                    w="full"
                                    columns={{ base: 2, md: 4 }}
                                    gap={24}
                                >
                                    <HStack w="full" align="top">
                                        <Link {...props.home.link}>
                                            <Image
                                                src={Graphics.Brand.WhiteText}
                                                w={32}
                                                alt="brand"
                                            />
                                        </Link>
                                    </HStack>
                                    {props.footer.groups.map((group) => (
                                        <VStack
                                            key={group.id}
                                            align="start"
                                            w="full"
                                            spacing={2}
                                        >
                                            <Text
                                                fontWeight="semibold"
                                                color="whiteAlpha.800"
                                            >
                                                {group.label}
                                            </Text>
                                            <VStack
                                                align="start"
                                                color="whiteAlpha.600"
                                                spacing={2}
                                            >
                                                {group.links.map((link) => (
                                                    <Anchor
                                                        key={link.id}
                                                        {...link.anchor}
                                                    >
                                                        {link.label}
                                                    </Anchor>
                                                ))}
                                            </VStack>
                                        </VStack>
                                    ))}
                                </SimpleGrid>
                            </HStack>
                            <HStack
                                borderTopWidth={2}
                                borderColor="whiteAlpha.100"
                                py={8}
                                w="full"
                                justify="center"
                                color="whiteAlpha.500"
                            >
                                <HStack>
                                    <Text>{props.footer.copyright.label}</Text>
                                </HStack>
                            </HStack>
                        </GridItem>
                    </Grid>
                </GridItem>
            </Grid>
        );
    };
}
