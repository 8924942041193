import React from 'react';
import {
    ReportingShellContextProvider,
    ReportingShellContext,
} from './reportingContextInterface';
import { ReportingShellContextContainerProps } from './reportingContextProps';

export function createReportingShellContextProvider(
    Container: React.FC<ReportingShellContextContainerProps>,
    Context: React.Context<ReportingShellContext | null>
): ReportingShellContextProvider {
    return {
        Provider: Container,
        useContext() {
            const context = React.useContext(Context);
            if (!context) {
                throw new Error('not inside report context');
            }
            return context;
        },
    };
}
