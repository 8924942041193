import { ReportChapterObject } from '../../base';
import { CaseStudies } from '../../content';

export const implementations: Omit<ReportChapterObject, 'number'> = {
    id: 'implementations',
    caption: `Implementations`,
    title: `Companies Are Using AI to Make Software Maintenance Faster and Easier`,
    description: `Leading tech companies like Slack, TikTok, and AT&T are implementing AI to streamline software maintenance by automating legacy code migration, debugging, test generation, and repetitive development tasks. Slack combined AST transformations with LLMs to migrate over 15,000 test cases, TikTok uses GenAI for modular code generation and bug fixing, and AT&T built a secure, RAG-based AI platform to automate the entire software lifecycle. Across the board, companies are finding that the most effective solutions blend AI automation with internal tools, domain-specific context, and human validation.`,
    sections: [
        {
            id: '1',
            title: `AI-powered conversion from Enzyme to React Testing Library at Slack`,
            caption: `Slack`,
            content: [
                {
                    kind: 'text',
                    text: `Slacks case study highlights a forward-thinking approach to solving legacy migration challenges by effectively combining traditional programming techniques with cutting-edge AI. The strategic shift to a hybrid model that leverages both AST transformations and LLM-generated insights not only accomplished a high conversion success rate but also significantly improved the daily productivity of the development teams. Through diligent evaluation and continuous refinement, Slack has demonstrated that with the right blend of technology and human oversight, even the most complex migration projects can yield substantial time savings and enhanced product quality.`,
                },
            ],
            keywords: [],
            sources: [CaseStudies.slackCaseStudy],
        },
        {
            id: '2',
            title: `GenAI streamlines maintenance software development`,
            caption: `TikTok`,
            content: [
                {
                    kind: 'text',
                    text: `This case study highlights how a leading global video platform has successfully integrated GenAI into its software development lifecycle. By combining public GenAI models with tailored internal solutions—and incorporating advanced features such as automated Jira ticketing—the company has achieved significant productivity gains, enhanced code quality, and streamlined maintenance processes, all while effectively navigating challenges related to data security and legacy code integration. Varos' private interview provided in-depth insights into both the successes and hurdles of this innovative approach.`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `Varos Interview`,
                    kind: 'external',
                    type: null,
                    description: `Laboris anim deserunt consectetur ea officia pariatur velit elit dolor dolore cillum non consequat`,
                    url: null,
                    markdown: null,
                },
            ],
        },
        {
            id: '3',
            title: `GenAI automates code generation`,
            caption: `AT&T`,
            content: [
                {
                    kind: 'text',
                    text: `AT&Ts use of generative AI has not only revolutionized its enterprise security policies but also fundamentally transformed its software development processes. By launching a secure, role-based platform built on RAG architecture and intelligent routing, the company has automated key segments of the software lifecycle"from initial requirement gathering to automated code and test generation. The resulting efficiency gains, as evidenced by significant developer productivity and faster employee query resolution times, demonstrate a clear competitive advantage while ensuring compliance and security.`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `AT&T Secure Connections 2024 - Security Policy Strategies with Generative AI`,
                    description: `Nostrud non consectetur pariatur esse ipsum velit id mollit commodo pariatur Lorem`,
                    kind: 'external',
                    type: null,
                    url: `https://www.youtube.com/watch?v=gN3-88BJfcg`,
                    markdown: null,
                },
            ],
        },
    ],
};
