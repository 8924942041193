import React from 'react';
import {
    Box,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineArrowRight, AiOutlineCheckCircle } from 'react-icons/ai';
import { Graphics } from '../../../../../../../../config/svg';
import { RegistrationViewConfig } from '../../../../../../base';
import { AcceptFinalizeViewProps } from '../../../fragment';
import { BsArrowRightShort } from 'react-icons/bs';

export function createRegistrationAcceptFinalizeMobileView(
    config: RegistrationViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & AcceptFinalizeViewProps> {
    const {
        UI: {
            Application: { Button },
        },
    } = config;
    return (props) => {
        return (
            <Grid
                w="full"
                h="full"
                py={12}
                px={12}
                gap={12}
                templateRows="1fr min-content"
            >
                <GridItem>
                    <VStack align="center" spacing={4} w="full">
                        {/* <Box
                            borderWidth={3}
                            borderColor="gray.100"
                            p={6}
                            borderRadius="full"
                        >
                            <Image
                                position="relative"
                                left={-1}
                                top={1}
                                w={8}
                                h={8}
                                alt="brand"
                                src={Graphics.Brand.Icon}
                            />
                        </Box> */}
                        <Icon color="green.300" h={20} w={20} as={AiOutlineCheckCircle} />
                        <VStack w="full" spacing={3} align="center" textAlign="center">
                            <Heading color="black">{props.page.header.title}</Heading>
                            {props.page.header.description && (
                                <Text
                                    fontSize="lg"
                                    fontWeight="medium"
                                    color="blackAlpha.700"
                                >
                                    {props.page.header.description}
                                </Text>
                            )}
                        </VStack>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack align="start" w="full" spacing={3}>
                        <Button
                            {...props.button}
                            w="full"
                            colorScheme="green"
                            rightIcon={<Icon fontSize="2xl" as={BsArrowRightShort} />}
                            borderRadius="full"
                            size="lg"
                        >
                            Go to platform
                        </Button>
                    </VStack>
                </GridItem>
            </Grid>
        );
    };
}
