import React, { useEffect } from 'react';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { useAccountContextV2 } from '../../context';
import { Navigate } from 'react-router';
import {
    ApplicationNavigationController,
    ShellLayoutController,
    ApplicationShellEnhancer,
} from '../../shell';
import { PageIdType } from '../../config';

export function createAccountProfileEnhancer(): ApplicationEntryEnhancer {
    const pages: PageIdType[] = ['reports', 'reports_detail', 'reports_list'];
    const include = new Set(pages);
    return (create) => (config) => {
        // function enhanceController(
        //     controller: AccountRouteController
        // ): AccountRouteController {
        //     return {
        //         ...controller,
        //         useProps(...args) {
        //             const viewProps = controller.useProps(...args);
        //             const {
        //                 data: { account },
        //             } = useAccountContextV2();
        //             if (account.details.kind === 'organization') {
        //                 return viewProps;
        //             } else {
        //                 return {
        //                     ...viewProps,
        //                     workspaceUrl: null,
        //                     dashboardUrl: null,
        //                     homeUrl: `/u/account/reporting`,
        //                     creativeCoOpUrl: null,
        //                     researchUrl: null,
        //                     insightsUrl: null,
        //                     hideContext: false,
        //                     reportsUrl: `/u/account/reporting`,
        //                     peersUrl: null,
        //                     expertCallUrl: null,
        //                     integrationsUrl: null,
        //                     links: {
        //                         newCompany: null,
        //                         newGroup: null,
        //                         newIntegration: null,
        //                     },
        //                 };
        //             }
        //         },
        //     };
        // }

        /**
         * Change home button to be reports for user accounts
         * @param instance
         * @returns
         */
        function enhanceShellRootController(
            instance: ShellLayoutController
        ): ShellLayoutController {
            return {
                ...instance,
                useProps(conext, item, props) {
                    const {
                        data: { account },
                    } = useAccountContextV2();
                    return instance.useProps(
                        conext,
                        item,
                        account.details.kind === 'organization'
                            ? props
                            : {
                                  ...props,
                                  shortcuts: {
                                      ...props.shortcuts,
                                      groups: props.shortcuts.groups.flatMap((group) => {
                                          const filtered = group.items.filter(
                                              (candidate) => include.has(candidate.id)
                                          );
                                          if (filtered.length === 0) {
                                              return [];
                                          }
                                          return [{ ...group, filtered }];
                                      }),
                                  },
                                  home: {
                                      link: {
                                          to: `/u/account/reporting`,
                                      },
                                  },
                              }
                    );
                },
            };
        }

        /**
         * Only show report page for user accounts and remove asset context
         * @param instance
         * @returns
         */
        function enhanceShellNavigationController(
            instance: ApplicationNavigationController
        ): ApplicationNavigationController {
            return {
                ...instance,
                useProps(conext, item, props) {
                    const {
                        data: { account },
                    } = useAccountContextV2();

                    useEffect(() => {
                        if (account.details.kind !== 'user') {
                            // NOTE only redirect non-org accounts to contributor app
                            // TODO explicitly model contributor account type
                            return;
                        }
                        if (props.location.pathname.includes('/contributor')) {
                            // NOTE already redirecting, nothing to do
                            return;
                        }
                        console.info('redirecting contributor account...');
                        props.navigate(`/contributor/rewards`, { replace: true });
                    }, [props.location.pathname, account.details.kind]);

                    return instance.useProps(conext, item, props);

                    // return instance.useProps(
                    //     conext,
                    //     item,
                    //     account.details.kind === 'organization'
                    //         ? props
                    //         : {
                    //               ...props,
                    //               items: props.items.flatMap((item) =>
                    //                   item.id === 'reports'
                    //                       ? [{ ...item, path: `/u/account/reporting` }]
                    //                       : []
                    //               ),
                    //           }
                    // );
                },
            };
        }

        function createShellEnhancer(): ApplicationShellEnhancer {
            return (create) => (config) => {
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        createRootController(...args) {
                            const instance = config.provider.createRootController(
                                ...args
                            );
                            return enhanceShellRootController(instance);
                        },
                        createNavigationController(...args) {
                            const instance = config.provider.createNavigationController(
                                ...args
                            );
                            return enhanceShellNavigationController(instance);
                        },
                    },
                });
            };
        }

        return create({
            ...config,
            // redirect: {
            //     ...config.redirect,
            //     createRoot(rootConfig) {
            //         return config.redirect.createRoot({
            //             ...rootConfig,
            //             provider: {
            //                 createController() {
            //                     const underlyingController =
            //                         rootConfig.provider.createController();
            //                     return {
            //                         useProps(controllerProps) {
            //                             if (controllerProps.workspace) {

            //                                 if (pendingAction) {
            //                                     return {
            //                                         to: pendingAction.route,
            //                                     };
            //                                 }
            //                             }
            //                             return underlyingController.useProps(
            //                                 controllerProps
            //                             );
            //                         },
            //                     };
            //                 },
            //             },
            //         });
            //     },
            // },
            route: {
                ...config.route,
                // createAccountRoute(routeConfig) {
                //     return config.route.createAccountRoute({
                //         ...routeConfig,
                //         providers: {
                //             ...routeConfig.providers,
                //             createController(...args) {
                //                 const controller = routeConfig.providers.createController(
                //                     ...args
                //                 );
                //                 return enhanceController(controller);
                //             },
                //         },
                //     });
                // },
                // createAccountWorkspaceRoute(routeConfig) {
                //     return config.route.createAccountWorkspaceRoute({
                //         ...routeConfig,
                //         providers: {
                //             ...routeConfig.providers,
                //             createController(...args) {
                //                 const controller = routeConfig.providers.createController(
                //                     ...args
                //                 );
                //                 return enhanceController(controller);
                //             },
                //         },
                //     });
                // },
                settings: {
                    ...config.route.settings,
                    createOrganization(orgConfig) {
                        const orgRoute =
                            config.route.settings.createOrganization(orgConfig);
                        return {
                            ...orgRoute,
                            Redirect: () => {
                                return <Navigate to="subscription" replace={true} />;
                            },
                        };
                    },
                },
            },
            enhancer: {
                ...config.enhancer,
                shell: {
                    ...config.enhancer.shell,
                    application: [
                        ...config.enhancer.shell.application,
                        createShellEnhancer(),
                    ],
                },
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createOrganization() {
                        const controller =
                            config.controller.settings.createOrganization();
                        return {
                            useProps() {
                                const props = controller.useProps();
                                const {
                                    data: { account },
                                } = useAccountContextV2();
                                if (account.details.kind === 'organization') {
                                    return props;
                                }
                                const allowdRouteKeys = new Set<string>([
                                    'subscription',
                                    'payment',
                                ]);
                                return {
                                    ...props,
                                    isVisible(item) {
                                        if (props.isVisible(item)) {
                                            if (allowdRouteKeys.has(item.key)) {
                                                return props.isVisible(item);
                                            }
                                        }
                                        return false;
                                    },
                                };
                            },
                        };
                    },
                },
            },
        });
    };
}
