import { assert } from '@varos/util-typescript';
import { ReportingShellLoaderBaseConfig } from '../../base';
import { ReportingRootLoader } from './reportingRootlInterface';
import { useMemo } from 'react';
import { ReportingAccountAggregate } from '../../view';

export function createReportingRootLoader(
    config: ReportingShellLoaderBaseConfig
): ReportingRootLoader {
    const { repository } = config;
    const NOW = new Date();
    return {
        useLoad(context, props) {
            const meQuery = repository.me.useLookup(context, {
                suspense: true,
                staleTime: Infinity,
            });

            assert(meQuery.status === 'success', 'expected suspense');
            const accountQuery = repository.account.useLookup(
                context,
                {
                    id: meQuery.data.account.id,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            assert(accountQuery.status === 'success', 'expected suspense');

            const account = useMemo(
                (): ReportingAccountAggregate => ({
                    me: meQuery.data,
                    account: accountQuery.data,
                }),
                [meQuery.data, accountQuery.data]
            );

            return {
                account,
                notification: {
                    items: [
                        {
                            id: '1',
                            title: 'Adipisicing qui magna nulla',
                            description:
                                'Nostrud deserunt fugiat fugiat exercitation ea occaecat et ex magna nisi enim deserunt',
                            createdAt: NOW,
                        },
                        {
                            id: '2',
                            title: 'Commodo magna enim',
                            description:
                                'Veniam reprehenderit irure est est adipisicing dolore deserunt. Minim esse occaecat Lorem eu do dolor duis cupidatat id nulla. Labore cillum adipisicing in reprehenderit cillum eiusmod id ea ex deserunt pariatur nostrud. Irure irure mollit consequat labore.',
                            createdAt: NOW,
                        },
                        {
                            id: '3',
                            title: 'Officia tempor veniam et laboris',
                            description:
                                'Nulla tempor laboris cillum minim qui. Voluptate deserunt mollit reprehenderit laboris commodo pariatur amet ea qui. Laborum tempor ad id ullamco Lorem nulla. Quis Lorem irure aute labore laboris. Do sunt aliquip occaecat do. Non magna sunt commodo enim aliquip ad consectetur duis amet ea ipsum',
                            createdAt: NOW,
                        },
                    ],
                },
            };
        },
    };
}
