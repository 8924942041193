import { AiOutlineUser } from 'react-icons/ai';
import { ReportingControllerBaseConfig } from '../../../base';
import { buildReportItemProps, ReportItemViewProps } from '../item';
import { ReportOverviewController } from './reportOverviewInterface';
import { ReportOverviewViewProps } from './reportOverviewProps';

export function createReportOverviewController(
    config: ReportingControllerBaseConfig
): ReportOverviewController {
    return {
        useProps(context, item, props): ReportOverviewViewProps {
            return {
                items: item.reports.map(
                    (report): ReportItemViewProps =>
                        buildReportItemProps({
                            report: report.report,
                            author: report.author,
                        })
                ),
            };
        },
    };
}
