import { ApplicationEntryEnhancer } from '../../entrypoint';
import { useAccountContextV2 } from '../../context';
import { isWorkspaceContext, useUserApi } from '../..';
import {
    ApplicationNavigationController,
    ShellLayoutController,
    ApplicationShellEnhancer,
} from '../../shell';
import {
    AccountEntity,
    AccountAction,
    AccountActionSpecKind,
    WorkspaceReference,
} from '../../domain';
import queryString from 'querystring';
import { PageIdType } from 'src/v2/config';

function getPendingActionsRoute(
    account: AccountEntity,
    currentWorkspace: WorkspaceReference | null
): {
    route: string;
    act: AccountAction;
} | null {
    if (account.state.pendingActions) {
        for (const accAct of account.state.pendingActions) {
            if (accAct.scope === 'account') {
                if (accAct.spec.kind == 'ingestion_survey') {
                    const [firstSurvey] = accAct.spec.surveys;
                    const qs = firstSurvey.populate_data
                        ? `?${queryString.stringify({
                              populate: JSON.stringify(firstSurvey.populate_data),
                          })}`
                        : '';
                    if (currentWorkspace) {
                        return {
                            route: `/u/assets/${currentWorkspace.id}/reporting/reports/${firstSurvey.survey_id}${qs}`,
                            act: accAct,
                        };
                    } else {
                        return {
                            route: `/u/account/reporting/reports/${firstSurvey.survey_id}${qs}`,
                            act: accAct,
                        };
                    }
                } else if (accAct.spec.kind === 'ingestion_integration') {
                    //todo: redirect to integrations here with forcing to integrate the given integrations
                    console.log(
                        'ingestion integration redirect is requested but not supported yet'
                    );
                }
            } else if (accAct.scope.kind === 'assets') {
                if (
                    currentWorkspace &&
                    accAct.scope.asset_ids.some((x) => x === currentWorkspace.id)
                ) {
                    if (accAct.spec.kind === 'ingestion_survey') {
                        const [firstSurvey] = accAct.spec.surveys;
                        const qs = firstSurvey.populate_data
                            ? `?${queryString.stringify({
                                  populate: JSON.stringify(firstSurvey.populate_data),
                              })}`
                            : '';
                        return {
                            route: `/u/assets/${currentWorkspace.id}/reporting/reports/${firstSurvey.survey_id}${qs}`,
                            act: accAct,
                        };
                    } else if (accAct.spec.kind === 'ingestion_integration') {
                        //todo: redirect to integrations here with forcing to integrate the given integrations
                        console.log(
                            'ingestion integration redirect is requested but not supported yet'
                        );
                    }
                }
            }
        }
    }
    return null;
}

export function createAccountActionsEnhancer(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        // function enhanceController(
        //     controller: AccountRouteController
        // ): AccountRouteController {
        //     return {
        //         ...controller,
        //         useProps(...args) {
        //             const viewProps = controller.useProps(...args);
        //             const {
        //                 data: { account: organization },
        //             } = useAccountContextV2();
        //             const userApi = useUserApi();
        //             const currentWorkspace = userApi.getCurrentWorkspace();
        //             if (!currentWorkspace) {
        //                 return viewProps;
        //             }
        //             const pendingAction = getPendingActionsRoute(
        //                 organization,
        //                 currentWorkspace
        //             );
        //             if (pendingAction) {
        //                 const { route: pendingActionUrl, act } = pendingAction;
        //                 return {
        //                     ...viewProps,
        //                     workspaceUrl: pendingActionUrl,
        //                     dashboardUrl: null,
        //                     homeUrl: pendingActionUrl,
        //                     creativeCoOpUrl: null,
        //                     researchUrl: null,
        //                     insightsUrl: null,
        //                     hideContext: true,
        //                     reportsUrl:
        //                         act.spec.kind === 'ingestion_survey'
        //                             ? pendingActionUrl
        //                             : null,
        //                     peersUrl: null,
        //                     expertCallUrl: null,
        //                     integrationsUrl:
        //                         act.spec.kind === 'ingestion_integration'
        //                             ? viewProps.integrationsUrl
        //                             : null,
        //                     links: {
        //                         ...viewProps.links,
        //                         newGroup: act.spec.kind === 'ingestion_integration'
        //                         ? viewProps.links.newGroup
        //                         : null,
        //                         newCompany: act.spec.kind === 'ingestion_integration'
        //                         ? viewProps.links.newCompany
        //                         : null,
        //                         newIntegration:
        //                             act.spec.kind === 'ingestion_integration'
        //                                 ? viewProps.links.newIntegration
        //                                 : null,
        //                     },
        //                 };
        //             }
        //             return viewProps;
        //         },
        //     };
        // }

        function ennhanceController(
            instance: ShellLayoutController
        ): ShellLayoutController {
            const pagesBySpec = {
                ingestion_integration: ['integration_overview'],
                ingestion_survey: ['reports'],
                // rewards account action is handled in router
                reward_profile: [],
            } satisfies Record<AccountActionSpecKind, PageIdType[]>;
            return {
                ...instance,
                useProps(context, item, props) {
                    const {
                        data: { account: organization },
                    } = useAccountContextV2();
                    const userApi = useUserApi();
                    const currentWorkspace = userApi.getCurrentWorkspace();

                    const pendingAction = getPendingActionsRoute(
                        organization,
                        currentWorkspace
                    );

                    // const pendingAction: null | {
                    //     route: string;
                    //     act: AccountAction;
                    // } = {
                    //     route: `/u/assets/${currentWorkspace?.id}/reporting/reports/demographics_kpis`,
                    //     act: {
                    //         spec: {
                    //             kind: 'ingestion_survey',
                    //             surveys: [
                    //                 { survey_id: 'demographics_kpis', populate_data: {} },
                    //             ],
                    //         },
                    //         isRequired: true,
                    //         scope: 'account',
                    //     },
                    // };

                    return instance.useProps(
                        context,
                        item,
                        pendingAction && currentWorkspace
                            ? {
                                  ...props,
                                  shortcuts: {
                                      // disable quick navigation when actions are pending
                                      groups: [],
                                  },
                                  home: {
                                      link: { to: pendingAction.route },
                                  },
                                  navigation: {
                                      ...props.navigation,
                                      items: props.navigation.items.flatMap((item) => {
                                          const included = pagesBySpec[
                                              pendingAction.act.spec.kind
                                          ] as string[];
                                          if (
                                              pendingAction.act.spec.kind ===
                                                  'ingestion_survey' &&
                                              item.id === 'reports'
                                          ) {
                                              return [
                                                  { ...item, path: pendingAction.route },
                                              ];
                                          }
                                          if (
                                              pendingAction.act.spec.kind ===
                                                  'ingestion_integration' &&
                                              item.id === 'integration_overview'
                                          ) {
                                              return [
                                                  { ...item, path: pendingAction.route },
                                              ];
                                          }
                                          if (
                                              included !== null &&
                                              included.includes(item.id)
                                          ) {
                                              return [item];
                                          }
                                          return [];
                                      }),
                                  },
                              }
                            : props
                    );
                },
            };
        }

        function createShellEnhancer(): ApplicationShellEnhancer {
            return (create) => (config) => {
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        createRootController(...args) {
                            const instance = config.provider.createRootController(
                                ...args
                            );
                            return ennhanceController(instance);
                        },
                    },
                });
            };
        }

        return create({
            ...config,
            enhancer: {
                ...config.enhancer,
                shell: {
                    ...config.enhancer.shell,
                    application: [
                        ...config.enhancer.shell.application,
                        createShellEnhancer(),
                    ],
                },
            },
            redirect: {
                ...config.redirect,
                createRoot(rootConfig) {
                    return config.redirect.createRoot({
                        ...rootConfig,
                        provider: {
                            createController() {
                                const underlyingController =
                                    rootConfig.provider.createController();
                                return {
                                    useProps(controllerProps) {
                                        const pendingAction = getPendingActionsRoute(
                                            controllerProps.account,
                                            controllerProps.workspace
                                        );
                                        if (pendingAction) {
                                            return {
                                                to: pendingAction.route,
                                            };
                                        }
                                        return underlyingController.useProps(
                                            controllerProps
                                        );
                                    },
                                };
                            },
                        },
                    });
                },
            },
            router: {
                ...config.router,
                createReporting(reportingConfig) {
                    return config.router.createReporting({
                        ...reportingConfig,
                        provider: {
                            ...reportingConfig.provider,
                            createLayoutController: (layoutConfig) => {
                                const underlyingController =
                                    reportingConfig.provider.createLayoutController(
                                        layoutConfig
                                    );
                                return {
                                    useProps(context, props) {
                                        const {
                                            data: { account: organization },
                                        } = useAccountContextV2();
                                        const pendingRoute = getPendingActionsRoute(
                                            organization,
                                            isWorkspaceContext(context)
                                                ? context.workspace
                                                : null
                                        );
                                        if (
                                            pendingRoute?.act.spec.kind ===
                                            'ingestion_survey'
                                        ) {
                                            return underlyingController.useProps(
                                                context,
                                                {
                                                    ...props,
                                                    showSidebar: false,
                                                }
                                            );
                                        }
                                        return underlyingController.useProps(
                                            context,
                                            props
                                        );
                                    },
                                };
                            },
                        },
                    });
                },
            },
            route: {
                ...config.route,
                // createAccountRoute(routeConfig) {
                //     return config.route.createAccountRoute({
                //         ...routeConfig,
                //         providers: {
                //             ...routeConfig.providers,
                //             createController(...args) {
                //                 const controller = routeConfig.providers.createController(
                //                     ...args
                //                 );
                //                 return enhanceController(controller);
                //             },
                //         },
                //     });
                // },
                // createAccountWorkspaceRoute(routeConfig) {
                //     return config.route.createAccountWorkspaceRoute({
                //         ...routeConfig,
                //         providers: {
                //             ...routeConfig.providers,
                //             createController(...args) {
                //                 const controller = routeConfig.providers.createController(
                //                     ...args
                //                 );
                //                 return enhanceController(controller);
                //             },
                //         },
                //     });
                // },
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createOrganization() {
                        const controller =
                            config.controller.settings.createOrganization();
                        return {
                            useProps() {
                                const props = controller.useProps();
                                const {
                                    data: { account: organization },
                                } = useAccountContextV2();
                                const userApi = useUserApi();
                                const currentWorkspace = userApi.getCurrentWorkspace();
                                if (!currentWorkspace) {
                                    return props;
                                }
                                const pendingRoute = getPendingActionsRoute(
                                    organization,
                                    currentWorkspace
                                );
                                if (pendingRoute) {
                                    const allowdRouteKeys = new Set<string>([
                                        'companies',
                                        'permissions',
                                        'users',
                                        'subscription',
                                        'payment',
                                    ]);
                                    if (
                                        pendingRoute.act.spec.kind ===
                                        'ingestion_integration'
                                    ) {
                                        allowdRouteKeys.add('integrations');
                                    }
                                    return {
                                        ...props,
                                        isVisible(item) {
                                            if (allowdRouteKeys.has(item.key)) {
                                                return props.isVisible(item);
                                            }
                                            return false;
                                        },
                                    };
                                }
                                return props;
                            },
                        };
                    },
                },
            },
        });
    };
}
