import { capitalize } from 'lodash';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { Location } from 'react-router';
import { titleCase } from '@varos/util-typescript';
import { ApplicationLinkProps } from '../../../../../ui';
import { RewardControllerBaseConfig } from '../../../base';
import { RewardsCopy } from '../../../constant';
import { OfferItemAggregate } from './offerItemModel';
import {
    OfferItemRewardProps,
    OfferItemTaskProps,
    OfferItemViewProps,
} from './offerItemProps';

export function buildOfferItemProps(
    config: Pick<RewardControllerBaseConfig, 'navigation' | 'strategy'>,
    item: OfferItemAggregate,
    props: { location: Location }
): OfferItemViewProps {
    const { root: offer, formsBySlug } = item;

    const tasks =
        item.tasks?.map((item, index): OfferItemTaskProps => {
            const { root: task, status } = item;

            let object: OfferItemTaskProps['task']['object'] | null = null;
            let link: ApplicationLinkProps | null = null;

            if (item.root.type === 'form') {
                const form = formsBySlug?.[item.root.config.form];
                object = {
                    label:
                        task.display_name ?? form?.display_name ?? item.root.config.form,
                    type: item.root.type,
                    description: item.root.description ?? form?.description ?? null,
                };
                link = config.strategy.task[item.root.type]({
                    location: props.location,
                    offer,
                    task: item.root,
                }).link;
            }

            if (item.root.type === 'interview') {
                object = {
                    label: task.display_name ?? item.root.config.interview,
                    type: item.root.type,
                    description: item.root.description,
                };
                link = config.strategy.task[item.root.type]({
                    location: props.location,
                    offer,
                    task: item.root,
                }).link;
            }

            return {
                index,
                id: task.key,
                kind: task.type,
                status:
                    status === null
                        ? null
                        : {
                              colorScheme:
                                  RewardsCopy.task.status[status.status].colorScheme,
                              label: RewardsCopy.task.status[status.status].label,
                              tooltip: {
                                  label: RewardsCopy.task.status[status.status]
                                      .description,
                              },
                          },
                task: {
                    label: RewardsCopy.task.type[task.type].label,
                    tooltip: {
                        label: RewardsCopy.task.type[task.type].description,
                    },
                    object: object,
                    action:
                        status?.status === 'completed'
                            ? {
                                  kind: status.status,
                                  colorScheme: 'green',
                                  label: RewardsCopy.task.status[status.status].label,
                                  link,
                              }
                            : {
                                  kind: 'pending',
                                  colorScheme: 'orange',
                                  label: RewardsCopy.task.type[task.type].action.label,
                                  link,
                              },
                },
            };
        }) ?? [];

    return {
        id: item.root.id,
        label: item.root.name,
        caption: item.root.caption,
        description: item.root.description ?? 'N/A',
        image: item.partner.logo_url
            ? {
                  src: item.partner.logo_url,
              }
            : null,
        tags: [
            {
                id: item.root.category,
                label: titleCase(item.root.category),
            },
        ],
        partner: {
            label: item.partner.display_name,
            website: {
                anchor: item.partner.website_url
                    ? {
                          id: 'offer_detail_website_url',
                          href: item.partner.website_url,
                          target: '_blank',
                      }
                    : null,
            },
        },
        keywords: {
            items: [
                {
                    kind: 'text',
                    id: '1',
                    label: 'Partner',
                    image: item.partner.logo_url
                        ? {
                              src: item.partner.logo_url,
                          }
                        : null,
                    value: null,
                    tooltip: null,
                },
                {
                    kind: 'text',
                    id: '2',
                    label: 'Status',
                    image: null,
                    value: capitalize(item.root.status),
                    tooltip: null,
                },
                {
                    kind: 'text',
                    id: '3',
                    label: 'Category',
                    image: null,
                    value: item.root.category,
                    tooltip: null,
                },
                {
                    kind: 'text',
                    id: '4',
                    label: 'Reward',
                    image: null,
                    value: RewardsCopy.reward[item.root.reward_mode].label,
                    tooltip: {
                        label: RewardsCopy.reward[item.root.reward_mode].description,
                    },
                },
                {
                    kind: 'text',
                    id: '5',
                    label: 'Requirement',
                    image: null,
                    value: RewardsCopy.task.mode[item.root.task_mode].label,
                    tooltip: {
                        label: RewardsCopy.task.mode[item.root.task_mode].description,
                    },
                },
            ],
        },
        link: config.navigation.offer(item.root),
        rewards: item.rewards
            ? {
                  mode: item.root.reward_mode === 'all' ? 'and' : 'or',
                  caption:
                      item.root.reward_mode === 'all'
                          ? `Select all of the specified rewards`
                          : 'Select one of the specified rewards',
                  items: item.rewards.map(
                      (reward, index): OfferItemRewardProps => ({
                          id: reward.root.key,
                          status: {
                              kind:
                                  reward.status.status === 'unfulfilled'
                                      ? 'ineligible'
                                      : 'eligible',
                              label: RewardsCopy.status[reward.status.status].label,
                              description:
                                  RewardsCopy.status[reward.status.status].description,
                              colorScheme:
                                  RewardsCopy.status[reward.status.status].colorScheme,
                              Icon: RewardsCopy.status[reward.status.status].Icon,
                              tooltip: {
                                  label: reward.product.description,
                                  isDisabled: reward.product.description === null,
                              },
                          },
                          product: {
                              name: reward.product.name,
                          },
                          action: null,
                          radio: {
                              isChecked: index === 0,
                          },
                      })
                  ),
              }
            : null,
        tasks,
    };
}
