import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import moment from 'moment';
import { ApiErrorResponseSchema } from '../../api';
import { ApiError, Authenticator, ServiceUnavailableError } from '../../base';
import { AxiosConfig } from './axiosConfig';

export function createAxios(config: AxiosConfig) {
    const instance = axios.create({
        ...config.defaults,
    });
    const serializeDates = (obj: any): any => {
        if (obj instanceof Date) {
            // Check if the Date has time components
            if (
                obj.getHours() === 0 &&
                obj.getMinutes() === 0 &&
                obj.getSeconds() === 0 &&
                obj.getMilliseconds() === 0
            ) {
                // Date has no time component, format as 'YYYY-MM-DD'
                return moment(obj).format('YYYY-MM-DD');
            } else {
                // Date has time component, format including time
                return moment(obj).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            }
        } else if (Array.isArray(obj)) {
            return obj.map((item) => serializeDates(item));
        } else if (obj !== null && typeof obj === 'object') {
            const newObj: any = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    newObj[key] = serializeDates(obj[key]);
                }
            }
            return newObj;
        } else {
            return obj;
        }
    };

    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (axiosError: AxiosError) => {
            if (
                axiosError.status &&
                axiosError.status >= 502 &&
                axiosError.status <= 504
            ) {
                throw new ServiceUnavailableError(axiosError);
            }
            const parsed = ApiErrorResponseSchema.safeParse(
                axiosError.response?.data ?? {}
            );
            if (parsed.success && axiosError.response?.status === 404) {
                const error = new ApiError(parsed.data.detail, axiosError);
                throw error;
            }
            if (parsed.success && axiosError.response?.status === 403) {
                const error = new ApiError(
                    `You do not have permission to perform this action`,
                    axiosError
                );
                throw error;
            }
            if (parsed.success) {
                const error = new ApiError(parsed.data.detail, axiosError);
                throw error;
            }
            throw new ApiError(axiosError.message, axiosError);
        }
    );

    // Add the request interceptor to inject the auth token and serialize dates
    instance.interceptors.request.use(async (requestConfig) => {
        // 1) Grab the token from our custom config
        const token = await config.getToken();

        // 2) If we have a token, inject the Authorization header
        if (token) {
            requestConfig.headers = requestConfig.headers ?? {};
            requestConfig.headers.Authorization = `Bearer ${token}`;
        }

        // 3) Serialize dates in params
        if (requestConfig.params) {
            requestConfig.params = serializeDates(requestConfig.params);
        }
        // 4) Serialize dates in data (request body)
        if (
            requestConfig.data &&
            Object.getPrototypeOf(requestConfig.data) !== FormData.prototype
        ) {
            requestConfig.data = serializeDates(requestConfig.data);
        }

        return requestConfig;
    });

    return instance;
}
