import { BoxProps } from '@chakra-ui/react';

export const rewardStyles = {
    content: {
        padding: {
            base: 12,
        },
        maxW: {
            base: '64rem',
            '2xl': '76rem',
        },
    },
} satisfies Record<string, Pick<BoxProps, 'width' | 'maxW' | 'padding'>>;
