import { useMemo } from 'react';
import { PageIds, StudyConstant } from '../../../../../config';
import { buildVideoViewProps } from '../../../../../view/common';
import { RewardControllerBaseConfig } from '../../../base';
import {
    buildOfferItemProps,
    OfferItemAggregate,
    OfferItemViewProps,
} from '../../../fragment';
import { OfferOverviewController } from './offerOverviewInterface';
import { OfferOverviewViewProps } from './offerOverviewProps';

export function createOfferOverviewController(
    config: RewardControllerBaseConfig
): OfferOverviewController {
    const {
        controller: {
            createSearch: createSearchController,
            createModal: createModalController,
        },
        kernel: {
            provider: { createPageController, createActionController },
        },
    } = config;
    const pageControler = createPageController({
        id: PageIds.REWARD_OFFER_OVERVIEW,
    });
    const requestOfferController = createActionController({
        id: 'offer_overview_request_offer',
    });
    const searchController = createSearchController<OfferItemAggregate>({
        id: 'offer_overview_search',
        placeholder: 'Search offers',
        empty: 'No offers found',
        debounceMs: 50,
        getProps(item) {
            return {
                id: item.root.id,
                term: item.root.name,
            };
        },
    });
    const controller = {
        modal: {
            earn: createModalController({
                id: 'rewards_content_earn_modal',
            }),
            featuring: createModalController({
                id: 'rewards_content_featuring_modal',
            }),
        },
    };
    return {
        useProps(context, item, props): OfferOverviewViewProps {
            const page = pageControler.useProps({}, {});
            const request = requestOfferController.useProps(props.request);
            const search = searchController.useProps(props.search);
            const [promotion] = item.promotions;
            const defaultOffer = promotion?.root ?? item.search[0]?.root;

            const video = {
                earn: useMemo(() => buildVideoViewProps(props.content.earn.video), []),
                featuring: useMemo(
                    () => buildVideoViewProps(props.content.featuring.video),
                    []
                ),
            };

            const modal = {
                earn: controller.modal.earn.useProps({
                    disclosure: props.content.earn.disclosure,
                    trigger: null,
                }),
                featuring: controller.modal.earn.useProps({
                    disclosure: props.content.featuring.disclosure,
                    trigger: null,
                }),
            };

            return {
                request: {
                    action: request,
                },
                hero: {
                    action: {
                        learn: props.action.learn,
                        start: defaultOffer
                            ? {
                                  label: 'Start interview',
                                  link: {
                                      id: 'rewards_hero_main_cta',
                                      ...config.navigation.offer(defaultOffer, {
                                          autostart: true,
                                      }),
                                  },
                              }
                            : null,
                    },
                },
                content: {
                    earn: {
                        video: video.earn,
                        modal: modal.earn,
                    },
                    featured: {
                        video: video.featuring,
                        modal: modal.featuring,
                    },
                },
                promotions:
                    item.promotions.length === 0
                        ? null
                        : {
                              items: item.promotions.map((item) =>
                                  buildOfferItemProps(config, item, props)
                              ),
                          },
                search:
                    item.search.length === 0
                        ? null
                        : {
                              input: search,
                              pagination: {
                                  label: `Showing ${search.matched} of ${item.search.length} offers`,
                              },
                              items: item.search.flatMap((item) => {
                                  const searchProps = search.getItemProps(item);
                                  if (!searchProps.isVisible) {
                                      return [];
                                  }
                                  return [buildOfferItemProps(config, item, props)];
                              }),
                          },
            };
        },
    };
}
