import { EditOverlay } from '../../../common';
import { PageIds } from '../../../../config';
import { assert } from '../../../../util/assert';
import { buildControlSearchParams } from '../../../../app';
import { PeerControllerConfig } from '../../base';
import { PeerGroupDetailRouteController } from './peerGroupDetailInterface';
import {
    PeerGroupDetailAggregate,
    PeerGroupDetailEditFormValues,
} from './peerGroupDetailModel';
import { PeerGroupDetailViewProps } from './peerGroupDetailProps';
import { PeerMemberListController } from '../../member';

export function createPeerGroupDetailRouteController(
    config: PeerControllerConfig & {
        edit: EditOverlay<PeerGroupDetailAggregate, PeerGroupDetailEditFormValues>;
        member: {
            list: PeerMemberListController;
        };
    }
): PeerGroupDetailRouteController {
    const {
        provider: {
            createConfirmationController,
            createPeerDatasetEligibilityController,
        },
        edit: { controller: editController },
        member: { list: memberListController },
        controller: {
            common: { page: pageController, action: actionController },
            group: { item: groupController },
        },
    } = config;

    const { useProps: useEligibilityProps } = createPeerDatasetEligibilityController({
        getLinkProps(item) {
            if (item.healthcheck.transparency.quality === 'insufficient_sample') {
                // group not available, just link to dashboard without pre-defined group filter
                return {
                    to: `/u/assets/${item.asset.id}/dashboards/${item.plugin.defaultDashboard?.id}`,
                };
            }
            let params = buildControlSearchParams({
                mode: 'fixed',
                // hack, be needs to handle competitive set ids
                group: item.peerset.id.replace('cs_', 'grp_'),
            });
            return {
                to: `/u/assets/${item.asset.id}/dashboards/${item.plugin.defaultDashboard?.id}?${params.toString()}`,
            };
        },
    });

    const { useProps: useDeleteConfirmProps } = createConfirmationController({
        id: 'peer_group_delete_confirm',
    });

    return {
        useProps(context, item, props): PeerGroupDetailViewProps {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.PEER_COMPETITIVE_DETAIL,
                    breadcrumbs: [],
                },
            });
            const eligibilityProps = useEligibilityProps(item.eligibility, {});
            const confirmProps = useDeleteConfirmProps(props.confirm);
            const editProps = editController.useProps(item, props.edit);
            const groupProps = groupController.useProps(context, item.group);
            const action = {
                edit: actionController.useProps(
                    {
                        id: 'edit',
                        kind: 'custom',
                        name: 'Edit group',
                        button: editProps.trigger,
                    },
                    props.actions
                ),
                delete: actionController.useProps(
                    {
                        id: 'delete',
                        kind: 'delete',
                        name: 'Delete group',
                        button: {
                            // onClick: props.actions.onDelete,
                            onClick: confirmProps.trigger.button.onClick,
                        },
                    },
                    props.actions
                ),
            };

            const memberProps = memberListController.useProps(item.memberlist, {});

            return {
                confirm: confirmProps,
                page: pageProps,
                group: groupProps,
                member: memberProps,
                eligibility: eligibilityProps,
                action,
                edit: editProps,
                link: {
                    members: {
                        edit: {
                            to: 'members/edit',
                        },
                    },
                },
                anchor: {
                    competitiveSetDocs: config.anchor.competitiveSet.documentation,
                },
            };
        },
    };
}
