import { ReportingThemeConfig, ReportingThemeInit } from './reportingThemeConfig';
import { ReportingTheme, ReportingThemeEnhancer } from './reportingThemeInterface';
import { ContributorThemeViewConfig } from './base';
import {
    createReportingShellDesktopView,
    createReportingShellMobileView,
    createReportingShellErrorView,
} from './shell';

export function createReportingTheme(
    config: ReportingThemeInit,
    enhancer?: ReportingThemeEnhancer
): ReportingTheme {
    return create({ ...config }, enhancer);
}

function create(
    config: ReportingThemeConfig,
    enhancer?: ReportingThemeEnhancer
): ReportingTheme {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const viewConfig: ContributorThemeViewConfig = {
        UI: config.UI,
    };
    return {
        Shell: {
            Desktop: {
                Shell: createReportingShellDesktopView(viewConfig),
                Error: createReportingShellErrorView(viewConfig),
            },
            Mobile: {
                Shell: createReportingShellMobileView(viewConfig),
                Error: createReportingShellErrorView(viewConfig),
            },
        },
    };
}
