import { ReportingLoaderBaseConfig } from '../../../base';
import { ReportOverviewLoader } from './reportOverviewInterface';
import * as fixture from '../../../fixture';

export function createReportOverviewLoader(
    config: ReportingLoaderBaseConfig
): ReportOverviewLoader {
    const {} = config;
    return {
        useLoad(context, props) {
            return {
                reports: [
                    {
                        report: fixture.MaintenanceReport.report,
                        author: fixture.MaintenanceReport.author,
                        content: fixture.MaintenanceReport.content,
                    },
                    {
                        report: fixture.AutomatingKYC.report,
                        author: fixture.AutomatingKYC.author,
                        content: fixture.AutomatingKYC.content,
                    },
                ],
            };
        },
    };
}
