import {
    Box,
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowLeft, AiOutlineEdit, AiOutlineUser } from 'react-icons/ai';
import { GoCheckCircle, GoCircle, GoOrganization } from 'react-icons/go';
// import varosLogoSVG from '../../../../../../../../svg/varos-logo-large.svg';
import { RegistrationViewConfig } from '../../../../../../base';
import { RegistrationAcceptCreateViewProps } from '../../../registrationAcceptCreateProps';
import {
    AcceptFinalizeContainerProps,
    AcceptOrganizationContainerProps,
    AcceptProfileContainerProps,
    AcceptWelcomeContainerProps,
} from '../../../fragment';
import { RegistrationAcceptTheme } from '../../../registrationAcceptCreateInterface';
import {
    MdOutlineRadioButtonChecked,
    MdOutlineRadioButtonUnchecked,
} from 'react-icons/md';
import { ChevronLeftIcon } from '@chakra-ui/icons';

export function createRegistrationAcceptCreateMobileView(
    config: RegistrationViewConfig & {
        Container: {
            Welcome: React.FC<AcceptWelcomeContainerProps>;
            Profile: React.FC<AcceptProfileContainerProps>;
            Organization: React.FC<AcceptOrganizationContainerProps>;
            Finalize: React.FC<AcceptFinalizeContainerProps>;
        };
        Theme: RegistrationAcceptTheme;
    }
): React.FC<RegistrationAcceptCreateViewProps> {
    const {
        Theme,
        Container: {
            Welcome: WelcomeView,
            Profile: ProfileView,
            Organization: OrganizationView,
            Finalize: FinalizeView,
        },
        UI: {
            Application: {
                Link,
                Anchor,
                Stepper: {
                    Container: Stepper,
                    Step,
                    Status: StepStatus,
                    Indicator: StepIndicator,
                },
            },
        },
    } = config;

    return (props) => {
        const steps = [
            {
                id: 'welcome',
                title: 'Welcome',
                Icon: AiOutlineUser,
                children: <WelcomeView as={Theme.Welcome} />,
                isVisible: false,
            },
            {
                id: 'profile',
                title: 'User profile',
                Icon: AiOutlineUser,
                children: <ProfileView as={Theme.Profile} />,
            },
            ...(props.isOrganization
                ? [
                      {
                          id: 'organization',
                          title: 'Organization',
                          Icon: GoOrganization,
                          children: <OrganizationView as={Theme.Organization} />,
                      },
                  ]
                : []),
            {
                id: 'finalize',
                title: 'Finalize',
                Icon: AiOutlineEdit,
                children: <FinalizeView as={Theme.Finalize} />,
                isVisible: false,
            },
        ];

        const currentStep = steps[props.stepper.index];

        return (
            <VStack align="center" bg="white" color="black" h="100dvh" w="full">
                <HStack w="full" spacing={3} px={12} pt={12} justify="space-between">
                    {props.stepper.index > 0 && (
                        <HStack>
                            <IconButton
                                aria-label="back"
                                icon={<Icon fontSize="2xl" as={ChevronLeftIcon} />}
                                color="gray.900"
                                bg="gray.100"
                                onClick={props.stepper.onChange.bind(
                                    null,
                                    props.stepper.index - 1
                                )}
                                _focus={{ outline: 'none' }}
                            />
                        </HStack>
                    )}
                    <Stepper
                        as={
                            <HStack
                                align="center"
                                spacing={3}
                                visibility={
                                    currentStep.isVisible === false ? 'hidden' : 'inherit'
                                }
                            />
                        }
                        {...props.stepper}
                    >
                        {steps
                            .filter((step) => step.isVisible !== false)
                            .map((step, index) => (
                                <StepIndicator
                                    key={step.id}
                                    color="gray.400"
                                    _checked={{ color: 'blue.400' }}
                                    _current={{ color: 'blue.400' }}
                                    _disabled={{ color: 'gray.400' }}
                                >
                                    <StepStatus
                                        complete={
                                            <Icon
                                                w={4}
                                                h={4}
                                                as={MdOutlineRadioButtonChecked}
                                            />
                                        }
                                        incomplete={
                                            <Icon
                                                w={4}
                                                h={4}
                                                as={MdOutlineRadioButtonUnchecked}
                                            />
                                        }
                                        active={
                                            <Icon
                                                w={4}
                                                h={4}
                                                as={MdOutlineRadioButtonUnchecked}
                                            />
                                        }
                                    />
                                </StepIndicator>
                            ))}
                    </Stepper>
                </HStack>
                {/* <VStack w="full" h="full" align="start" justify="space-between"> */}
                {/* <Text>Hello</Text> */}
                {/* <Button colorScheme="green">Submits</Button> */}
                {/* </VStack> */}
                <Box h="calc(100dvh - 6rem)">
                    <Tabs index={props.stepper.index} isLazy={true}>
                        <TabPanels>
                            {steps.map(({ children, ...rest }) => (
                                <TabPanel key={rest.id} p={0}>
                                    {children}
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </Tabs>
                </Box>
            </VStack>
        );
    };
}
