import { compose } from 'lodash/fp';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { createAlertTrackingStrategy } from './alert';
import { createBillingTrackingStrategy } from './billing';
import {
    createAssetChangedTrackingStrategy,
    createDashboardRankingTrackingStrategy,
} from './dashboard';
import { createFormTrackingStrategy } from './form';
import { createGroupTrackingStrategy } from './groups';
import { createHelpTrackingStrategy } from './help';
import { createHomeTrackingStrategy } from './home';
import { createInsightTrackingStrategy } from './insight';
import { createLinkTrackingStrategy } from './link';
import { createMetricTrackingStrategy } from './metric';
import { createModalTrackingStrategy } from './modal';
import { createNavigationTrackingStrategy } from './navigation';
import { createTrackingOnboardingStrategy } from './onboarding';
import { createPageTrackingStrategy } from './page';
import { createPeerTrackingStrategy } from './peer';
import { createReportLoadTrackingStrategy } from './report';
import { createSearchTrackingStrategy } from './search';
import {
    createSettingsUserManagementTrackingStrategy,
    createSettingsWorkspaceManagementTrackingStrategy,
    createTrackingSettingsStrategy,
} from './settings';
import { createTooltipTrackingStrategy } from './tooltip';
import { TrackingMiddlewareConfig } from './trackingConfig';
import { createErrorTrackingStrategy } from './error';
import { KernelEnhancer } from '../../base';
import { createActionTrackingStrategy } from './action';

export function createKernelTrackingStrategy(): KernelEnhancer {
    return compose(createErrorTrackingStrategy(), createActionTrackingStrategy());
}

export function createApplicationTrackingStrategy(
    config: TrackingMiddlewareConfig
): ApplicationEntryEnhancer {
    return compose(
        createPeerTrackingStrategy(),
        createAssetChangedTrackingStrategy(),
        createDashboardRankingTrackingStrategy(),
        createInsightTrackingStrategy(config.insight),
        createReportLoadTrackingStrategy(),
        createSettingsWorkspaceManagementTrackingStrategy(),
        createSettingsUserManagementTrackingStrategy(),
        createHomeTrackingStrategy(),
        createNavigationTrackingStrategy(),
        createTooltipTrackingStrategy(config.tooltip),
        createMetricTrackingStrategy(),
        createTrackingOnboardingStrategy(),
        createTrackingSettingsStrategy(),
        createPageTrackingStrategy(),
        createAlertTrackingStrategy(),
        createGroupTrackingStrategy(),
        createLinkTrackingStrategy(),
        createHelpTrackingStrategy(),
        createModalTrackingStrategy(),
        createBillingTrackingStrategy(),
        createFormTrackingStrategy(),
        createSearchTrackingStrategy()
    );
}
