import { ReportChapterObject } from '../../base';

export const vendors: Omit<ReportChapterObject, 'number'> = {
    id: 'vendors',
    caption: `Vendors`,
    title: `AI KYC might face compliance issues in the future`,
    description: `Magna cupidatat labore eu elit proident excepteur culpa voluptate excepteur magna veniam exercitation eu laborum. Mollit laboris eiusmod non anim adipisicing Lorem eiusmod pariatur id. Occaecat consectetur exercitation qui esse exercitation incididunt magna excepteur proident ipsum dolore cillum. Ad adipisicing consectetur enim nulla aute officia sit consequat qui cillum et ipsum. Ea voluptate irure in eiusmod culpa in occaecat commodo deserunt aute minim duis. Exercitation do eu laboris anim proident ad adipisicing Lorem.`,
    sections: [
        {
            id: '1',
            title: null,
            caption: `Challenges`,
            content: [
                {
                    kind: 'text',
                    text: `Dolor officia est ex officia aute mollit cillum aliqua mollit exercitation dolor eiusmod.`,
                },
            ],
            keywords: [],
            sources: [
                {
                    id: '1',
                    name: `How to integrate AI KYC into your business 2025`,
                    type: null,
                    description: `Velit aliquip eiusmod ad consequat culpa exercitation sunt ipsum proident. Exercitation ex excepteur minim irure sunt laborum proident magna ullamco velit elit nostrud. Magna occaecat minim ex et. Est amet labore ipsum dolore ea pariatur irure sint proident labore enim. Enim adipisicing dolore enim dolor magna elit do. Irure proident nisi amet magna sit in laboris in do tempor labore incididunt.`,
                    url: null,
                    markdown: null,
                    kind: 'external',
                },
            ],
        },
    ],
};
