import React from 'react';
import { compose } from 'lodash/fp';
import { Navigate, Outlet, Route } from 'react-router-dom';
import {
    RewardContainerBaseConfig,
    RewardContextProvider,
    RewardControllerBaseConfig,
    RewardLoaderBaseConfig,
    RewardRouterRepositories,
    RewardViewBaseConfig,
} from './base';
import {
    createOfferDetailContainer,
    createOfferDetailController,
    createOfferDetailLoader,
    createOfferDetailView,
    createOfferOverviewContainer,
    createOfferOverviewController,
    createOfferOverviewLoader,
    createOfferOverviewView,
    createRewardLayout,
} from './page';
import {
    creatRewardRootContainer,
    createRewardRootController,
    createRewardRootLoader,
} from './page';
import { RewardRouterNavigation } from './navigate';
import { RewardRouterConfig, RewardRouterInit } from './rewardRouterConfig';
import { RewardRouter, RewardRouterEnhancer } from './rewardRouterInterface';
import { assert } from '@varos/util-typescript';

export function configureRewardRouter(
    config: RewardRouterInit,
    enhancer?: RewardRouterEnhancer
): RewardRouter {
    let enhancers = enhancer ? [enhancer] : [];
    enhancers = [...enhancers];
    return create(
        {
            ...config,
            strategy: {
                task: {
                    interview(props) {
                        return {
                            link: config.dependencies.router.interview(props),
                        };
                    },
                    form(props) {
                        return {
                            link: config.dependencies.router.form(props),
                        };
                    },
                    onboarded(props) {
                        return {
                            link: null,
                        };
                    },
                },
            },
            provider: {
                createOfferOverviewController: createOfferOverviewController,
                createOfferDetailController: createOfferDetailController,
            },
        },
        compose(...enhancers)
    );
}

function create(
    config: RewardRouterConfig,
    enhancer?: RewardRouterEnhancer
): RewardRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const navigation: RewardRouterNavigation = {
        offer(offer, options) {
            const params = new URLSearchParams(
                Object.entries(options ?? {}).map(([key, value]) => [
                    key,
                    value.toString(),
                ])
            );
            const search = params.toString();
            return {
                to: [`/${config.mount}/offers/${offer.id}`, search]
                    .filter(Boolean)
                    .join('?'),
            };
        },
    };

    const context: RewardContextProvider = {
        useContext() {
            const auth = config.hook.useAuth();
            const account = config.hook.useAccount();
            assert(auth.user, 'auth.user is null');
            return {
                auth,
                principal: {
                    kind: 'user',
                    id: auth.user.id,
                    account: account.scope.account.id,
                },
                organization: null,
            };
        },
    };

    const containerConfig: RewardContainerBaseConfig = {
        UI: config.UI,
        repository: config.repository,
        kernel: config.kernel,
        navigation,
        hook: config.hook,
        context: {
            root: context,
        },
    };

    const loaderConfig: RewardLoaderBaseConfig = {
        kernel: config.kernel,
        repository: config.repository,
    };

    const controllerConfig: RewardControllerBaseConfig = {
        dependencies: config.dependencies,
        kernel: config.kernel,
        controller: config.controller,
        infra: config.infra,
        navigation,
        strategy: config.strategy,
    };

    const viewConfig: RewardViewBaseConfig = {
        UI: config.UI,
        Layout: createRewardLayout(),
    };

    const Root = creatRewardRootContainer(
        containerConfig,
        createRewardRootLoader(loaderConfig),
        createRewardRootController(controllerConfig)
    );

    const Offer = {
        Detail: createOfferDetailContainer(
            containerConfig,
            createOfferDetailLoader(loaderConfig),
            config.provider.createOfferDetailController(controllerConfig),
            createOfferDetailView(viewConfig)
        ),
        Overview: createOfferOverviewContainer(
            containerConfig,
            createOfferOverviewLoader(loaderConfig),
            config.provider.createOfferOverviewController(controllerConfig),
            createOfferOverviewView(viewConfig)
        ),
    };

    return {
        navigation: navigation,
        element: (
            <Route
                path={config.mount}
                element={
                    <Root>
                        <Outlet />
                    </Root>
                }
            >
                <Route
                    index={true}
                    element={<Navigate to="offers/discover" replace={true} />}
                />
                <Route path="offers">
                    <Route
                        index={true}
                        element={<Navigate to="discover" replace={true} />}
                    />
                    <Route path="discover" element={<Offer.Overview />} />
                    <Route path=":offerId" element={<Offer.Detail />} />
                </Route>
            </Route>
        ),
    };
}
