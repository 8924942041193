import { VStack } from '@chakra-ui/react';
import { RewardLayout } from './rewardLayoutInterface';

export function createRewardLayout(): RewardLayout {
    return {
        Container(props) {
            return (
                <VStack align="start" w="full">
                    {props.children}
                </VStack>
            );
        },
        Section(props) {
            return <>{props.children}</>;
        },
    };
}
