import WhiteText from '../../../svg/varos-logo-small-white.svg';
import LogoLarge from '../../../svg/varos-logo-large.svg';
import BrandIcon from '../../../svg/varos-icon.svg';
import Visa from '../../../svg/payment/visa.svg';
import MasterCard from '../../../svg/payment/mastercard.svg';
import AmericanExpress from '../../../svg/payment/american-express.svg';
import Ibex from '../../../images/Logo+for+Ibex+Investors.png';
import YCombinator from '../../../svg/investor/Y_Combinator_id.svg';
import Mosaic from '../../../svg/investor/MosaicGP-logo-1-new.svg';

export const Graphics = {
    Visa,
    MasterCard,
    AmericanExpress,
    Brand: {
        Icon: BrandIcon,
        WhiteText,
        LogoLarge,
    },
    Investor: {
        YCombinator,
        Mosaic,
    },
};

export const Images = {
    Investor: {
        Ibex,
    },
};
