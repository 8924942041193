import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { AssistantControllerBaseConfig } from '../../../base';
import { AssistantLayoutController } from './assistantLayoutInterface';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';

export function createAssistantLayoutController(
    config: AssistantControllerBaseConfig
): AssistantLayoutController {
    const {
        kernel: {
            controller,
            adapter: { help },
        },
    } = config;
    return {
        useProps(context, item, props) {
            const browser = controller.browser.useProps({});
            const navigation = controller.navigation.useProps(props.navigation);
            return {
                isScrollable: props.isScrollable ?? true,
                browser,
                detail: {
                    container: {
                        isVisible: props.detail.value,
                    },
                    button: {
                        onClick: props.detail.onChange.bind(null, !props.detail.value),
                    },
                },
                actions: [
                    {
                        kind: 'button',
                        id: 'details',
                        label: 'Details',
                        Icon: props.detail.value ? GoSidebarCollapse : GoSidebarExpand,
                        button: {
                            onClick: props.detail.onChange.bind(
                                null,
                                !props.detail.value
                            ),
                        },
                    },
                    {
                        kind: 'button',
                        id: 'help',
                        label: 'Help',
                        Icon: AiOutlineQuestionCircle,
                        button: {
                            onClick() {
                                help.open();
                            },
                        },
                    },
                ],
                back: navigation.back,
                link: {
                    back: { to: props.returnPath ?? '/u' },
                    home: { to: '/u' },
                },
            };
        },
    };
}
