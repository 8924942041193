import React from 'react';
import { ReportingContainerBaseConfig } from '../../../base';
import { ReportOverviewUrlParamsSchema } from './reportOverviewSchema';
import {
    ReportOverviewController,
    ReportOverviewLoader,
} from './reportOverviewInterface';
import {
    ReportOverviewContainerProps,
    ReportOverviewViewProps,
} from './reportOverviewProps';

export function createReportOverviewContainer(
    config: ReportingContainerBaseConfig,
    loader: ReportOverviewLoader,
    controller: ReportOverviewController,
    View: React.FC<ReportOverviewViewProps>
): React.FC<ReportOverviewContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    return ({ ...containerProps }) => {
        const options = useOptions(ReportOverviewUrlParamsSchema);
        const context = useContext();
        const data = loader.useLoad(context, {
            template: { slug: options.template },
        });
        const props = controller.useProps(context, data, {});
        return <View {...props} />;
    };
}
