import React from 'react';
import { AssistantContainerBaseConfig } from '../../base';
import { ButtonElementController } from './elementButtonInterface';
import { ButtonElementContainerProps } from './elementButtonProps';

export function createButtonElementContainer(
    config: AssistantContainerBaseConfig,
    controller: ButtonElementController
): React.FC<{ children?: React.ReactNode | undefined } & ButtonElementContainerProps> {
    const {
        context: {
            root: { useContext: useRootContext },
            thread: { useContext: useThreadContext },
        },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = {
            root: useRootContext(),
            thread: useThreadContext(),
        };
        const props = controller.useProps(
            context.root,
            {
                thread: context.thread.item.thread,
                element: containerProps.element,
            },
            {
                onSubmit() {
                    throw new Error('not impl');
                    // const response = context.thread.send({
                    //     kind: 'event',
                    //     data: {
                    //         kind: 'action',
                    //         id: containerProps.element.action,
                    //     },
                    // });
                },
            }
        );
        return <View {...props} />;
    };
}
