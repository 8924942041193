import { Graphics } from '../../../../../config/svg';
import { ReportingControllerBaseConfig } from '../../../base';
import { ReportSectionItemController } from './sectionItemInterface';
import { ReportSectionItemViewProps } from './sectionItemProps';

export function createReportSectionItemController(
    config: ReportingControllerBaseConfig
): ReportSectionItemController {
    const {
        controller: { createModal: createModalController },
    } = config;

    const modalController = createModalController({ id: 'section_source_modal' });

    return {
        useProps(item, props): ReportSectionItemViewProps {
            // const modal = modalController.useProps(props.modal);
            const { section } = item;

            const truncatedCount = section.sources.length - 1;
            const sectionNumber = props.index + 1;

            const [first, ...restSources] = section.sources;
            const sourceFragments = first ? [first.name] : [];
            if (truncatedCount > 0) {
                sourceFragments.push(`${truncatedCount} more`);
            }

            // NOTE we only highlight system-generated sources
            const sourceWithAction =
                first?.kind === 'system' && restSources.length === 0 ? first : null;

            const sourceLabel = sourceWithAction?.type
                ? `Read ${sourceWithAction.type.replaceAll('-', ' ')}`
                : null;

            return {
                id: section.id,
                title: section.title,
                caption: `${props.chapterNumber}.${sectionNumber}. ${section.caption}`,
                content: section.content,
                keywords: section.keywords.map((keyword) => ({
                    id: keyword.id,
                    label: keyword.text,
                })),
                action: sourceWithAction
                    ? {
                          kind: 'overlay',
                          label: sourceLabel ?? 'Read more',
                          button: {
                              onClick: props.onOpen.bind(null, sourceWithAction),
                          },
                      }
                    : null,
                // modal: modal,
                isLast: props.isLast,
                source: {
                    categories: [],
                    label: sourceFragments.join(' and '),
                    // image: first?.kind === 'system' ? { src: Graphics.Brand.Icon } : null,
                    image: null,
                    items: section.sources.map((source) => ({
                        id: source.id,
                        label: source.name,
                        description: source.description,
                        image:
                            source.kind === 'system'
                                ? { src: Graphics.Brand.Icon }
                                : null,
                        anchor: source.url
                            ? {
                                  id: '',
                                  href: source.url,
                              }
                            : null,
                    })),
                    truncated:
                        truncatedCount === 0
                            ? null
                            : {
                                  count: truncatedCount,
                                  label: `${truncatedCount} more`,
                              },
                },
            };
        },
    };
}
