import { ReportingLoaderBaseConfig } from '../../../base';
import { ContentOverlayLoader } from './contentOverlayInterface';
import * as fixture from '../../../fixture';

export function createContentOverlayLoader(
    config: ReportingLoaderBaseConfig
): ContentOverlayLoader {
    const CANDIDATES = Object.values(fixture.Content.CaseStudies);
    return {
        useLoad(context, props) {
            const source = CANDIDATES.find(
                (candidate) => candidate.id === props.source.id
            );
            if (!source) {
                throw new Error(`Source with id ${props.source.id} not found`);
            }
            return {
                source,
            };
        },
    };
}
