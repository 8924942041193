import moment from 'moment';
import { ThreadObject } from '@varos/assistant-sdk';
import { PageIds } from '../../../../../config';
import { AssistantControllerBaseConfig } from '../../../base';
import { ThreadNewController } from './threadNewInterface';
import { ThreadNewControllerProps, ThreadNewViewProps } from './threadNewProps';
import { isEqual } from 'lodash';

export function createThreadNewController(
    config: AssistantControllerBaseConfig
): ThreadNewController {
    const {
        kernel: {
            provider: { createFormController, createPageController },
        },
        controller: { createConfirmTermsController },
    } = config;

    // Maximum number of hours a thread will be considered open
    const MAX_OPEN_HOURS = 24;

    const formController = createFormController({});
    const pageControler = createPageController({
        id: PageIds.ASSISTANT_THREAD_NEW,
    });
    const confirmController = createConfirmTermsController({
        id: 'thread_new_confirmation',
    });

    function isOpen(thread: ThreadObject) {
        const createdAt = moment(thread.created_at);
        const now = moment();
        const diffInHours = now.diff(createdAt, 'hours');
        return thread.status === 'open' && diffInHours <= MAX_OPEN_HOURS;
    }

    return {
        useProps(context, item, props): ThreadNewViewProps {
            const form = formController.useProps(props.form);
            const page = pageControler.useProps({}, {});
            const confirm = confirmController.useProps(props.confirm);

            const mostRecentThread =
                item.recent.find((thread) => {
                    return isEqual(props.thread.input, thread.input);
                }) ?? null;

            return {
                thread: {
                    open:
                        mostRecentThread && isOpen(mostRecentThread)
                            ? {
                                  id: mostRecentThread.id,
                                  title: mostRecentThread.title,
                                  link: config.navigation.thread.detail({
                                      thread: mostRecentThread,
                                      mode: null,
                                      token: null,
                                      returnPath: null,
                                  }),
                              }
                            : null,
                },
                form,
                terms: {
                    link: config.infra.navigation.legal.termsOfService({
                        location: props.location,
                    }),
                    confirm: confirm,
                },
                field: {
                    mode: {
                        options: props.field.mode.options.map((option) => ({
                            id: option.value,
                            label: option.label,
                            Icon: option.Icon,
                            onClick() {
                                props.form.form.setValue('mode', option.value);
                            },
                            isSelected: option.value === props.values.mode,
                            tooltip: {
                                label: option.description,
                            },
                        })),
                    },
                },
                workflow: {
                    title: item.inspection.title,
                    description: item.inspection.description,
                    button: {
                        label: item.workflow.action,
                    },
                },
            };
        },
    };
}
