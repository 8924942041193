import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { assert } from '../../../util/assert';
import { FormControllerConfig } from './formViewConfig';
import { FormViewController } from './formViewInterface';
import { FormControllerProps, FormViewProps } from './formViewProps';

export function createFormController(
    config: FormControllerConfig = {}
): FormViewController {
    const { submitOnEnter = false } = config;
    const shouldPreventEnterKey = !submitOnEnter;
    return {
        useProps<TValues extends FieldValues>(
            props: FormControllerProps<TValues>
        ): FormViewProps<TValues> {
            const ref = useRef<HTMLFormElement>(null);
            const [error, setError] = useState<null | Error>(null);
            const { isSubmitting } = props.form.formState;

            const fieldErrors = useMemo(
                () =>
                    Object.entries(props.form.formState.errors).reduce(
                        (acc, [key, value]) => ({
                            ...acc,
                            [key]: Array.isArray(value) ? value[0] : value,
                        }),
                        {}
                    ),
                [props.form.formState.errors]
            );

            useEffect(() => {
                if (ref.current) {
                    props.onInitialized?.(props.form.getValues() as TValues);
                }
            }, [ref.current]);

            return {
                form: props.form,
                fieldErrors,
                error: error
                    ? {
                          status: 'error',
                          label: error.message,
                      }
                    : null,

                reset() {
                    props.form.reset();
                    setError(null);
                },
                getFormControlProps(key) {
                    const error = props.form.formState.errors[key];
                    return {
                        isInvalid: !!error,
                    };
                },
                getFormErrorMessageProps(key) {
                    const error = props.form.formState.errors[key];
                    if (!error) {
                        return { display: 'none' };
                    }
                    console.log('error key', key, error);
                    assert(typeof error.message === 'string', 'expected string');
                    return { children: error.message };
                },
                getFieldItemProps(key) {
                    const error = props.form.formState.errors[key];
                    const message = error?.message;
                    if (message) {
                        assert(typeof message === 'string', 'expected string');
                    }
                    return {
                        name: key,
                        error: message
                            ? {
                                  message,
                              }
                            : undefined,
                        isReadonly: props.readonly,
                    };
                },
                getFormElementProps() {
                    return {
                        ref: ref,
                        onKeyPress: shouldPreventEnterKey
                            ? (event) => {
                                  if (event.key === 'Enter') {
                                      event.preventDefault();
                                  }
                              }
                            : undefined,
                        onSubmit: props.form.handleSubmit(async (values) => {
                            try {
                                const response = await props.onSubmit(values);
                            } catch (error) {
                                if (error instanceof Error) {
                                    console.error(error);
                                    setError(error);
                                    props.onSubmitError?.(error);
                                    return;
                                }
                                console.error('form unexpected error', error);
                                throw new Error('something went wrong');
                            }
                        }, props.onError),
                    };
                },
                getSubmitButtonProps() {
                    return {
                        type: 'submit',
                        isLoading: isSubmitting,
                        isDisabled: isSubmitting || props.isDisabled,
                        children: props.action?.label ?? 'Save',
                    };
                },
                getSubmitTooltipProps() {
                    const shouldShowTooltip = props.isDisabled && props.disabledReason;
                    return {
                        label: props.disabledReason,
                        isDisabled: !shouldShowTooltip,
                    };
                },
            };
        },
    };
}
