import {
    ApplicationWorkspaceController,
    ApplicationShellEnhancer,
} from '../../../../shell';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';

export function createAssetChangedTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const useTracker = config.infra.createUseTracker();

        function createShellEnhancer(): ApplicationShellEnhancer {
            function enhanceController(
                instance: ApplicationWorkspaceController
            ): ApplicationWorkspaceController {
                return {
                    ...instance,
                    useProps(context, item, props) {
                        const tracker = useTracker();
                        return instance.useProps(context, item, {
                            ...props,
                            select: {
                                ...props.select,
                                onChange(value) {
                                    const [first] = value;
                                    const workspace =
                                        item.workspaces.find(
                                            (candidate) =>
                                                candidate.asset.id.toString() === first
                                        ) ?? null;
                                    if (!workspace) {
                                        console.error(`workspace '${first}' not found`);
                                    } else {
                                        tracker.track(
                                            'dashboardAssetChanged',
                                            // @ts-expect-error
                                            {
                                                assetId: workspace.asset.id,
                                                assetName: workspace.asset.name,
                                            }
                                        );
                                    }
                                    return props.select.onChange(value);
                                },
                            },
                        });
                    },
                };
            }
            return (create) => (config) => {
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        createWorkspaceController(...args) {
                            const instance = config.provider.createWorkspaceController(
                                ...args
                            );
                            return enhanceController(instance);
                        },
                    },
                });
            };
        }

        return create({
            ...config,
            // controller: {
            //     ...config.controller,
            // },
            enhancer: {
                ...config.enhancer,
                shell: {
                    ...config.enhancer.shell,
                    application: [
                        ...config.enhancer.shell.application,
                        createShellEnhancer(),
                    ],
                },
            },
            module: {
                ...config.module,
                // createWorkspaceSelectionModule(moduleConfig) {
                //     return config.module.createWorkspaceSelectionModule({
                //         ...moduleConfig,
                //         provider: {
                //             ...moduleConfig.provider,
                //             createController(controllerConfig) {
                //                 const controller =
                //                     moduleConfig.provider.createController(
                //                         controllerConfig
                //                     );
                //                 return {
                //                     useProps(context) {
                //                         const original = controller.useProps(context);
                //                         const tracker = useTracker();
                //                         return {
                //                             ...original,
                //                             getItemProps(workspace, index) {
                //                                 const itemsProps =
                //                                     original.getItemProps?.(
                //                                         workspace,
                //                                         index
                //                                     );
                //                                 return {
                //                                     ...itemsProps,
                //                                     onClick(event) {
                //                                         tracker.track(
                //                                             'dashboardAssetChanged',
                //                                             // @ts-expect-error
                //                                             {
                //                                                 assetId: workspace.id,
                //                                                 assetName: workspace.name,
                //                                             }
                //                                         );
                //                                         itemsProps?.onClick?.(event);
                //                                     },
                //                                 };
                //                             },
                //                         };
                //                     },
                //                 };
                //             },
                //         },
                //     });
                // },
            },
        });
    };
}
