import { TooltipProps } from '@chakra-ui/react';
import { assert } from '@varos/util-typescript';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { PageIds } from '../../../../../config';
import { Icons } from '../../../../../../config';
import { AnyBlock, SelectBlock } from '../../../../../app/assistant';
import { ServiceUnavailableError } from '../../../../../base';
import { AssistantControllerBaseConfig } from '../../../base';
import { MessageItemViewProps } from './message';
import { ThreadDetailControllerProps, ThreadDetailViewProps } from './threadDetailProps';
import { ThreadDetailController } from './threadDetailInterface';

export function createThreadDetailController(
    config: AssistantControllerBaseConfig
): ThreadDetailController {
    const {
        controller: { createConfirmationController },
        kernel: {
            provider: { createPageController },
        },
    } = config;

    const pageControler = createPageController({
        id: PageIds.ASSISTANT_THREAD_DETAIL,
    });

    const confirmEndController = createConfirmationController({
        id: 'assistant_end_conversation_confirm',
    });

    function getAssistantProps(
        props: ThreadDetailControllerProps
    ): ThreadDetailViewProps['assistant'] {
        if (props.isSubmitting) {
            return {
                status: 'thinking',
            };
        }
        return {
            status: 'idle',
        };
    }

    function getUserProps(
        props: ThreadDetailControllerProps
    ): ThreadDetailViewProps['user'] {
        return {
            status: 'idle',
        };
    }

    function getStatusProps(
        props: ThreadDetailControllerProps
    ): ThreadDetailViewProps['status'] {
        if (props.error) {
            return {
                alert: {
                    status: 'error',
                },
                isLoading: false,
                label: props.error.message ?? `Something went wrong`,
                Icon: null,
                secondary: null,
                action: {
                    label: 'Refresh page',
                    button: {
                        onClick() {
                            window.location.reload();
                        },
                    },
                },
            };
        }
        if (
            props.mode === 'voice' &&
            (props.connection.state.status === 'pending' ||
                props.connection.state.status === 'reconnecting' ||
                props.connection.state.status === 'connecting')
        ) {
            return {
                alert: {
                    status: 'info',
                },
                isLoading: true,
                label: `Connecting`,
                secondary: {
                    label: 'Please wait',
                },
                Icon: null,
                action: null,
            };
        }
        if (props.mode === 'voice' && props.connection.state.status === 'connected') {
            return {
                alert: {
                    status: 'info',
                },
                isLoading: false,
                Icon: Icons.Assistant.AdvancedVoiceMode,
                label: `Voice chat enabled`,
                secondary: {
                    label: 'Start talking',
                },
                action: null,
            };
        }
        return null;
    }

    return {
        useProps(context, item, props): ThreadDetailViewProps {
            const page = pageControler.useProps(
                {
                    entity: {
                        id: item.thread.id,
                        object: 'thread',
                    },
                },
                {}
            );

            const confirmEnd = confirmEndController.useProps(props.confirm.end);

            return {
                page: {
                    title: item.thread.title ?? 'Interview',
                },
                confirm: {
                    end: confirmEnd,
                },
                navigation: {
                    back: {
                        label: 'Go back',
                        link: {
                            id: 'thread_closed_go_back_link',
                            // replace: true,
                            to: props.navigation.back.path,
                        },
                    },
                },
                // showBackButton: props.navigation.back.showBackButton,
                mode: props.mode,
                connection: props.connection,
                isLoading: props.status === 'processing',
                assistant: getAssistantProps(props),
                user: getUserProps(props),
                status: getStatusProps(props),
                detail: {
                    workflow: {
                        label: item.thread.title ?? item.workflow.displayName,
                        description:
                            item.thread.description ??
                            item.workflow.description ??
                            'no description',
                    },
                },
                thread: {
                    isClosed:
                        props.status === 'settled' && item.thread.status === 'closed',
                },
                message: {
                    items: props.messages.items.map((item): MessageItemViewProps => {
                        let content: null | AnyBlock = null;
                        if (item.kind === 'text') {
                            content = {
                                kind: 'text',
                                text: item.content,
                            };
                        }
                        if (item.kind === 'audio') {
                            content = {
                                kind: 'audio_input',
                                transcript: item.transcript,
                                duration_ms: item.durationMs,
                            };
                        }
                        assert(content, 'expected content');
                        return {
                            id: item.id,
                            author: {
                                kind: item.role,
                                label: capitalize(item.role),
                            },
                            content: [content],
                        };
                    }),
                },
            };
        },
    };
}
