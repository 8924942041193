import { ModalV1Controller, ModalV2Controller } from './modalInterface';

export function createModalV1Controller(): ModalV1Controller {
    return {
        useProps(deps, props) {
            return {
                id: props.id,
                containerProps: {
                    isOpen: deps.disclosure.isOpen,
                    onClose: deps.disclosure.onClose,
                },
                triggerProps: {
                    onClick() {
                        deps.disclosure.onOpen();
                    },
                },
                closeProps: {
                    onClick() {
                        deps.disclosure.onClose();
                    },
                },
                disclosure: deps.disclosure,
            };
        },
    };
}

export function createModalV2Controller(config: { id: string }): ModalV2Controller {
    return {
        useProps(props) {
            return {
                id: config.id,
                containerProps: {
                    isOpen: props.disclosure.isOpen,
                    onClose: props.disclosure.onClose,
                },
                triggerProps: {
                    onClick() {
                        props.disclosure.onOpen();
                    },
                },
                closeProps: {
                    onClick() {
                        props.disclosure.onClose();
                    },
                },
                disclosure: props.disclosure,
            };
        },
    };
}
