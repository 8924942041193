import React from 'react';
import { Graphics } from '../../../../../../../../config/svg';
import { RegistrationViewConfig } from '../../../../../../base';
import {
    Box,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AcceptWelcomeViewProps } from '../../../fragment/welcome/acceptWelcomeProps';
import { BsArrowRightShort } from 'react-icons/bs';

export function createRegistrationAcceptWelcomeMobileView(
    config: RegistrationViewConfig
): React.FC<AcceptWelcomeViewProps> {
    const {
        UI: {
            Application: { Button, Link },
        },
    } = config;
    return (props) => {
        return (
            <Grid
                w="full"
                h="full"
                py={12}
                px={12}
                gap={12}
                templateRows="1fr min-content"
            >
                <GridItem as={VStack} align="center" spacing={4}>
                    <Box borderWidth={3} borderColor="gray.100" p={6} borderRadius="full">
                        <Image
                            position="relative"
                            left={-1}
                            top={1}
                            w={8}
                            h={8}
                            alt="brand"
                            src={Graphics.Brand.Icon}
                        />
                    </Box>
                    <VStack w="full" spacing={3} align="center" textAlign="center">
                        <Heading color="black">{props.page.header.title}</Heading>
                        {props.page.header.description && (
                            <Text fontSize="lg" color="blackAlpha.700">
                                {props.page.header.description}
                            </Text>
                        )}
                    </VStack>
                </GridItem>
                <GridItem as={VStack} align="start" spacing={3}>
                    <Button
                        {...props.button}
                        w="full"
                        colorScheme="green"
                        rightIcon={<Icon fontSize="2xl" as={BsArrowRightShort} />}
                        borderRadius="full"
                        size="lg"
                    >
                        Get started
                    </Button>
                    <Link style={{ width: '100%' }} {...props.login.link}>
                        <Button
                            w="full"
                            borderRadius="full"
                            size="lg"
                            color="gray.900"
                            borderColor="gray.200"
                            borderWidth={1}
                        >
                            Login
                        </Button>
                    </Link>
                </GridItem>
            </Grid>
        );
    };
}
