import { ReportChapterObject } from '../../base';

export const benchmarks: Omit<ReportChapterObject, 'number'> = {
    id: 'benchmarks',
    caption: `Benchmarks`,
    title: `Measurable Benchmarks Demonstrate Clear Value of GenAI in Software Development`,
    description: `Organizations leveraging generative AI (GenAI) in software development measure success through clearly defined benchmarks related to productivity, quality, operational efficiency, and adoption. These benchmarks provide actionable insights and quantitative evidence supporting broader adoption and investment in GenAI technologies.`,
    sections: [
        {
            id: '1',
            title: `Key Benchmarks Demonstrating GenAI Value`,
            caption: `Benchmark Metrics`,
            content: [
                {
                    kind: 'text',
                    text: `Enterprises track key performance indicators that highlight the benefits of adopting generative AI tools. These include measurable increases in developer productivity, significant reductions in maintenance efforts, improvements in code quality and test coverage, faster task resolution times, high acceptance rates of AI-generated code, and notable operational efficiencies.`,
                },
                {
                    kind: 'table',
                    id: 'genai-software-dev-metrics',
                    title: 'GenAI Software Development Benchmarks',
                    description:
                        'Aggregated benchmarks and improvements observed across various enterprise implementations of GenAI tools.',
                    caption: null,
                    columns: [
                        {
                            key: 'category',
                            title: 'Category',
                            description: '',
                            type: 'string',
                        },
                        {
                            key: 'metric',
                            title: 'Metric(s)',
                            description: '',
                            type: 'string',
                        },
                        {
                            key: 'improvement',
                            title: 'Improvement with GenAI',
                            description: '',
                            type: 'string',
                        },
                        {
                            key: 'details',
                            title: 'Details',
                            description: '',
                            type: 'string',
                        },
                    ],
                    rows: [
                        {
                            id: '1',
                            data: {
                                category: 'Developer Productivity',
                                metric: 'Coding speed, delivery time',
                                improvement: '20-40% increase',
                                details:
                                    'Up to 90-99% time savings on highly repetitive scripting tasks; typically 20-30% faster code delivery.',
                            },
                        },
                        {
                            id: '2',
                            data: {
                                category: 'Engineering Effort',
                                metric: 'Time spent on maintenance vs. innovation',
                                improvement: '20-40% maintenance reduction',
                                details:
                                    'More time spent on feature development and architecture design.',
                            },
                        },
                        {
                            id: '3',
                            data: {
                                category: 'Time-to-Resolution',
                                metric: 'Task completion time',
                                improvement: '30-50% reduction',
                                details:
                                    'Tasks reduced from hours/days to minutes, significant for onboarding and support.',
                            },
                        },
                        {
                            id: '4',
                            data: {
                                category: 'Code Quality & Coverage',
                                metric: 'Test coverage, defect rates',
                                improvement: '3% to 50%+ coverage increase',
                                details:
                                    'Significant coverage increases and fewer production defects in legacy projects.',
                            },
                        },
                        {
                            id: '5',
                            data: {
                                category: 'Adoption & Acceptance',
                                metric: 'Acceptance rate of AI-generated code',
                                improvement: '25-33% code acceptance',
                                details:
                                    '75-93% of developers report improved or stable productivity.',
                            },
                        },
                        {
                            id: '6',
                            data: {
                                category: 'Operational Impact',
                                metric: 'Engineering hours saved, AI usage volume',
                                improvement: '13,000+ hours saved',
                                details:
                                    'Daily automated tasks, thousands of lines of code generated per day.',
                            },
                        },
                        {
                            id: '7',
                            data: {
                                category: 'Consistency & Collaboration',
                                metric: 'Process standardization, team synergy',
                                improvement: 'Significant overhead reduction',
                                details:
                                    'Unified reporting improves cross-team collaboration.',
                            },
                        },
                    ],
                },
                {
                    kind: 'text',
                    text: `Benchmarks were derived by aggregating quantitative productivity gains, standardizing the data into consistent ranges, and evaluating specific before-and-after scenarios for common development workflows. Time reallocations between maintenance and feature development were quantified based on direct input from engineering teams.`,
                },
                {
                    kind: 'text',
                    text: `User feedback and adoption metrics were consolidated to determine realistic acceptance rates, ensuring the data reflects typical scenarios rather than outliers. Exceptional cases with extremely high or low outcomes were identified but excluded from overall benchmark ranges to maintain accuracy and reliability.`,
                },
            ],
            keywords: [
                // { id: '1', text: 'Productivity', description: null },
                // { id: '2', text: 'ROI', description: null },
                // { id: '3', text: 'Adoption', description: null },
            ],
            sources: [],
        },
    ],
};
