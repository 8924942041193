import { assert } from '@varos/util-typescript';
import { RewardLoaderBaseConfig } from '../../base';
import { RewardRootLoader } from './rewardRootInterface';

export function createRewardRootLoader(config: RewardLoaderBaseConfig): RewardRootLoader {
    const { kernel, repository } = config;
    return {
        useLoad(context, props) {
            const accountQuery = repository.account.useLookup(
                context,
                {
                    id: context.principal.account,
                },
                { suspense: true }
            );
            const profileQuery = repository.reward.profile.useLookup(context, {
                suspense: true,
            });
            assert(accountQuery.status === 'success', 'expected suspense');
            assert(profileQuery.status === 'success', 'expected suspense');

            const ensuredProfileQuery = kernel.infra.repository.useQuery({
                queryKey: ['reward', 'root', 'ensure', context.principal.account],
                queryFn: async () => {
                    if (profileQuery.data) {
                        return profileQuery.data;
                    }
                    const [action] =
                        accountQuery.data.state.pendingActions?.flatMap((candidate) =>
                            candidate.spec.kind === 'reward_profile'
                                ? [candidate.spec]
                                : []
                        ) ?? [];
                    if (!action && !props.token) {
                        console.info('autocreating contributor profile...');
                        const profile = await props.mutation.profile.mutateAsync({
                            kind: 'manual',
                        });
                        console.info('created contributor profile', profile);
                        return profile;
                    }
                    console.info('autocreating contributor profile from token...');
                    const profile = await props.mutation.profile.mutateAsync({
                        kind: 'token',
                        // NOTE we allow overriding the token manually for easier testing
                        token: props.token ?? action.token,
                    });
                    console.info('created contributor profile', profile);
                    return profile;
                },
                suspense: true,
            });

            assert(ensuredProfileQuery.status === 'success', 'expected suspense');

            return {
                account: accountQuery.data,
                profile: ensuredProfileQuery.data,
            };
        },
    };
}
