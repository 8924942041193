import React from 'react';
import { compose } from 'lodash/fp';
import { Outlet, Route } from 'react-router-dom';
import {
    AssistantContainerBaseConfig,
    AssistantControllerBaseConfig,
    AssistantLoaderBaseConfig,
    AssistantViewBaseConfig,
} from './base';
import {
    createThreadDetailContainer,
    createThreadDetailController,
    createThreadDetailLoader,
    createThreadDetailView,
    createThreadNewContainer,
    createThreadNewController,
    createConversationNewLoader,
    createThreadNewView,
    createThreadDetailContextProvider,
    createAssistantRootContainer,
    serializeNewThreadOptions,
    createThreadDetailService,
    createThreadComposerContainer,
    createThreadComposerController,
    ThreadDetailRouteProps,
    createThreadStateProvider,
} from './page';
import {
    createAssistantLayoutContainer,
    createAssistantLayoutController,
    createAssistantLayoutLoader,
    createAssistantMobileLayoutView,
    createAssistantDesktopLayoutView,
    createAssistantLayoutStateProvider,
} from './page';
import { createAssistantErrorView, createAssistantErrorContainer } from './page';
import { createAssistantMiddleware } from './middleware';
import {
    createButtonElementContainer,
    createButtonElementController,
    createVisualizationBlockContainer,
    createVisualizationBlockController,
} from './view';
import { AssistantNavigation } from './navigate';
import { AssistantRouterConfig, ConversationRouteInit } from './assistantConfig';
import { AssistantRouter, AssistantRouterEnhancer } from './assistantInterface';
import { assert } from '@varos/util-typescript';

export function configureAssistantRouter(
    config: ConversationRouteInit,
    enhancer?: AssistantRouterEnhancer
): AssistantRouter {
    const middleware = createAssistantMiddleware();
    const enhancers = enhancer ? [enhancer, middleware] : [middleware];
    return create(
        {
            ...config,
            provider: {
                createThreadDetailController,
                createThreadNewController,
                createButtonElementController,
                createVisualizationBlockController,
            },
        },
        compose(...enhancers)
    );
}

function create(
    config: AssistantRouterConfig,
    enhancer?: AssistantRouterEnhancer
): AssistantRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const navigation: AssistantNavigation = {
        thread: {
            create(props) {
                const payload = serializeNewThreadOptions({
                    return_path: location.pathname,
                    thread: props.thread,
                });
                const stringPayload = Object.entries(payload).reduce(
                    (acc, [key, value]) => {
                        if (value === undefined || value === null) {
                            return acc;
                        }
                        return { ...acc, [key]: String(value) };
                    },
                    {} as Record<string, string>
                );
                const params = new URLSearchParams(stringPayload);
                return {
                    to: `/u/assistant/new?${params.toString()}`,
                };
            },
            detail(props) {
                const base = [`/u/assistant/${props.thread.id}`];
                let payload: ThreadDetailRouteProps = {
                    token: null,
                    mode: null,
                    return_path: props.returnPath,
                };
                if (props.mode === 'voice') {
                    payload = { ...payload, mode: props.mode, token: props.token };
                }
                const stringPayload = Object.entries(payload).reduce(
                    (acc, [key, value]) => {
                        if (value === undefined || value === null) {
                            return acc;
                        }
                        return { ...acc, [key]: String(value) };
                    },
                    {} as Record<string, string>
                );
                const params = new URLSearchParams(stringPayload);
                base.push(params.toString());
                return {
                    to: base.join('?'),
                };
            },
        },
    };

    const containerConfig: AssistantContainerBaseConfig = {
        UI: config.UI,
        repository: config.repository,
        kernel: config.kernel,
        navigation,
        context: {
            thread: createThreadDetailContextProvider(),
            root: {
                useContext() {
                    const account = config.hook.useAccount?.();
                    if (!account?.scope.auth.user) {
                        return {
                            principal: null,
                        };
                    }
                    return {
                        principal: {
                            kind: 'user',
                            id: account.scope.auth.user.id,
                            account: account.data.account.id,
                        },
                        organization: null,
                    };
                },
            },
        },
    };

    const loaderConfig: AssistantLoaderBaseConfig = {
        repository: config.repository,
    };

    const controllerConfig: AssistantControllerBaseConfig = {
        navigation: navigation,
        kernel: config.kernel,
        infra: config.infra,
        controller: config.controller,
    };

    const viewConfig: AssistantViewBaseConfig = {
        UI: config.UI,
        Layout: createAssistantLayoutContainer(
            containerConfig,
            createAssistantLayoutStateProvider(containerConfig),
            createAssistantLayoutLoader(),
            createAssistantLayoutController(controllerConfig),
            {
                Desktop: createAssistantDesktopLayoutView({
                    UI: config.UI,
                }),
                Mobile: createAssistantMobileLayoutView({
                    UI: config.UI,
                }),
            }
        ),
    };

    const Root = createAssistantRootContainer(containerConfig, {
        Error: createAssistantErrorContainer(
            containerConfig,
            createAssistantErrorView(viewConfig)
        ),
    });

    const Thread = {
        Detail: createThreadDetailContainer(
            containerConfig,
            createThreadStateProvider(containerConfig),
            createThreadDetailService(),
            createThreadDetailLoader(loaderConfig),
            config.provider.createThreadDetailController(controllerConfig),
            createThreadDetailView(
                {
                    ...viewConfig,
                    Container: {
                        Composer: createThreadComposerContainer(
                            containerConfig,
                            createThreadStateProvider(containerConfig),
                            createThreadComposerController(controllerConfig)
                        ),
                    },
                },
                {
                    Button: createButtonElementContainer(
                        containerConfig,
                        config.provider.createButtonElementController()
                    ),
                    Visualization: createVisualizationBlockContainer(
                        containerConfig,
                        config.provider.createVisualizationBlockController()
                    ),
                }
            )
        ),
        New: createThreadNewContainer(
            containerConfig,
            createConversationNewLoader(loaderConfig),
            config.provider.createThreadNewController(controllerConfig),
            createThreadNewView(viewConfig)
        ),
    };

    return {
        navigation: navigation,
        element: (
            <Route
                path={config.mount}
                element={
                    <Root>
                        <Outlet />
                    </Root>
                }
            >
                <Route path="new" element={<Thread.New />} />
                <Route path=":threadId" element={<Thread.Detail />} />
            </Route>
        ),
    };
}
