import { ReportingControllerBaseConfig } from '../../../base';
import { ReportNewController } from './reportNewInterface';
import { ReportNewViewProps } from './reportNewProps';

export function createReportNewController(
    config: ReportingControllerBaseConfig
): ReportNewController {
    return {
        useProps(context, item, props): ReportNewViewProps {
            return {};
        },
    };
}
