import { assert } from '@varos/util-typescript';
import { ContributorShellLoaderBaseConfig } from '../../base';
import { ContributorRootLoader } from './contributorRootlInterface';
import { useMemo } from 'react';
import { ContributorAccountAggregate } from '../../view';

export function createContributorRootLoader(
    config: ContributorShellLoaderBaseConfig
): ContributorRootLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const meQuery = repository.me.useLookup(context, {
                suspense: true,
                staleTime: Infinity,
            });

            assert(meQuery.status === 'success', 'expected suspense');
            const accountQuery = repository.account.useLookup(
                context,
                {
                    id: meQuery.data.account.id,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            assert(accountQuery.status === 'success', 'expected suspense');

            const account = useMemo(
                (): ContributorAccountAggregate => ({
                    me: meQuery.data,
                    account: accountQuery.data,
                }),
                [meQuery.data, accountQuery.data]
            );

            return {
                account,
            };
        },
    };
}
