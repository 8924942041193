import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BiMicrophoneOff } from 'react-icons/bi';
import { Icons } from '../../../../../../../config';
import { AssistantContainerBaseConfig } from '../../../../base';
import { ThreadComposerController } from './composerInterface';
import { ThreadComposerContainerProps } from './composerProps';
import { ThreadStateProvider } from '../threadDetailInterface';

export function createThreadComposerContainer(
    config: AssistantContainerBaseConfig,
    states: ThreadStateProvider,
    controller: ThreadComposerController
): React.FC<ThreadComposerContainerProps> {
    const {
        repository: { message: messageRepository, session: sessionRepository },
        context: {
            root: { useContext: useRootContext },
            thread: { useContext: useThreadContext },
        },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const [text, setText] = useState('');
        const [errorState, setErrorState] = useState<Error | null>(null);

        const context = {
            root: useRootContext(),
            thread: useThreadContext(),
        };
        const state = states.useState(context.root, { searchParams: useSearchParams() });

        const ref = {
            textArea: useRef<HTMLTextAreaElement>(null),
        };

        const props = controller.useProps({
            mode: state.mode.value,
            input: {
                ref: ref.textArea,
                value: text,
                onChange: setText,
            },
            recorder: {
                onChunk: ({ base64, elapsedMs }) => {
                    return context.thread.service.voice.chunk({
                        base64,
                        elapsedMs,
                    });
                },
                onStart: () => {
                    console.log('Recording started');
                    context.thread.service.voice.start();
                },
                onStop: ({ elapsedMs, fullAudio }) => {
                    console.log('Recording stopped');
                    context.thread.service.voice.stop({
                        base64: fullAudio,
                        durationMs: elapsedMs,
                    });
                },
            },
            send: {
                kind: 'custom',
                variant: 'primary',
                name: 'Send',
                Icon: Icons.Assistant.Send,
                async onPerform() {
                    setText('');
                    setErrorState(null);
                    ref.textArea.current?.focus();

                    // NOTE do not block here we rely on optimistic updates
                    const result = context.thread.service.text
                        .send({
                            text,
                        })
                        .catch((error) => {
                            if (error instanceof Error) {
                                console.error('onSubmit error', error);
                                setErrorState(error);
                                return;
                            }
                            throw error;
                        });

                    return;
                },
            },
            voice:
                state.mode.value === 'voice'
                    ? {
                          kind: 'custom',
                          name: 'Toggle voice',
                          description: `Stop voice mode`,
                          colorScheme: 'red',
                          Icon: BiMicrophoneOff,
                          isActive: true,
                          onPerform() {
                              state.mode.onChange('text');
                              context.thread.service.toggleMode({
                                  mode: 'text',
                              });
                          },
                      }
                    : {
                          kind: 'custom',
                          variant: 'primary',
                          name: 'Toggle voice',
                          description: `Start voice mode`,
                          Icon: Icons.Assistant.AdvancedVoiceMode,
                          isActive: false,
                          onPerform() {
                              state.mode.onChange('voice');
                              context.thread.service.toggleMode({
                                  mode: 'voice',
                              });
                          },
                      },
        });
        return <View {...props} />;
    };
}
