import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { LegalViewConfig } from '../../base';
import { TermsOfServiceViewProps } from './termsOfServiceProps';

export function createTermsOfServiceView(
    config: LegalViewConfig
): React.FC<{ children?: React.ReactNode | undefined } & TermsOfServiceViewProps> {
    const { Layout } = config;
    return (props) => {
        return (
            <Layout>
                <VStack
                    spacing={12}
                    pt={12}
                    pb={36}
                    color="whiteAlpha.700"
                    align="stretch"
                >
                    <Heading fontWeight="bold" fontSize="2xl" color="whiteAlpha.900">
                        Terms of Use Agreement
                    </Heading>

                    <VStack align="start" spacing={6}>
                        <Text fontWeight="semibold" color="whiteAlpha.800">
                            VAROS CORPORATION
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="bold">
                                1. Introduction.
                            </Box>{' '}
                            Varos Corporation (“Varos”, “us”, or “we”) operates a
                            proprietary online competitive intelligence platform and
                            provide analytics, benchmarking, and market research services,
                            as well as the Varos Network (see Section 4 below), (the
                            “Services”), via and/or in connection with the Varos website,
                            located at varos.com, (the “Site”).
                        </Text>

                        <Text>
                            This Terms of Use Agreement (the “Agreement”), including the
                            Varos Privacy Policy which is incorporated into this Agreement
                            by reference, sets forth the legally binding terms that apply
                            to and govern your access to the Site and your use of the
                            Services and the Site.
                        </Text>

                        <Text>
                            BY CLICKING ON THE "I AGREE" BUTTON, OR BY ACCESSING THE SITE
                            OR USING ANY OF THE SERVICES, INCLUDING BY PROVIDING US WITH
                            INFORMATION, DATA, OR OTHER CONTENT (“USER DATA”) YOU AGREE TO
                            BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE
                            TO BE BOUND BY THE TERMS OF THIS AGREEMENT, DO NOT ACCESS THE
                            SITE OR OTHERWISE USE THE SERVICES.
                        </Text>

                        <Text>
                            The term “User” in this Agreement refers to visitors to the
                            Site, customers of Varos, users of the Services, and members
                            of the Varos Network. If you sign up for additional features
                            and services that are governed by additional terms and
                            conditions, we will inform you of any additional terms and
                            conditions when you sign up for these additional features and
                            services.
                        </Text>

                        <Text>
                            Please read this Agreement carefully before accessing the Site
                            or using the Services.
                        </Text>

                        <Text>
                            In case of an entity, the individual clicking “I Agree” or
                            signing-up to the Services on behalf of the entity confirms
                            that he is authorized to legally bind the entity to this
                            Agreement.
                        </Text>

                        <Text fontWeight="bold">2. Proprietary Rights.</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                2.1 The Site and the Services.
                            </Box>{' '}
                            You acknowledge and agree that (a) the Site and the Services
                            (excluding User Data) and (b) all data collected and processed
                            by Varos in the course of providing the Services that relate
                            to your use of the Services, including performance metrics,
                            metadata, bandwidth utilization, usage data, and information
                            concerning compatibility and interoperability of the Services
                            (“Services Data”) are proprietary to Varos. All right, title
                            and interest, including all intellectual property rights, in
                            and to the Site, the Services, and the Services Data,
                            including computer code, algorithms, know-how, trade secrets,
                            design, graphics, structure, selection, coordination,
                            expression, “look and feel” and arrangement of the Services,
                            as well as all enhancements, modifications, improvements and
                            derivative works thereof, are and shall remain the sole and
                            exclusive property of Varos. All trademarks, trade names,
                            service marks, and copyrights in and pertaining to the Site
                            and the Services are owned by Varos. ALL RIGHTS TO THE SITE
                            AND THE SERVICES NOT EXPRESSLY GRANTED HEREIN ARE RESERVED BY
                            VAROS.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                2.2 Your Grant of a License to Us.
                            </Box>{' '}
                            We do not claim ownership in any User Data that you provide,
                            make available, or otherwise transfer to us. By providing us
                            with User Data, you hereby grant to us an unrestricted,
                            irrevocable, perpetual, non-exclusive, fully-paid and
                            royalty-free, license (with the right to sublicense through
                            unlimited levels of sublicensees) to use, copy, edit, perform,
                            display, share, distribute, and create derivative works of
                            such User Data in any and all media (now known or later
                            developed) throughout the world, including distributing such
                            User Data to other Users and members of the Varos Network.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                2.3 Aggregated Data.
                            </Box>{' '}
                            In the event you are a natural person and the User Data you
                            provide to Varos contains your personally identifiable
                            information, Varos is permitted to aggregate and anonymize
                            such User Data (“Aggregated Data”), provided that such
                            Aggregated Data is stripped of any personally identifying
                            information and does not identify to any third party that you
                            are the source of the Aggregated Data. Your personal
                            information will not be shared with third parties. Varos shall
                            be the owner of the Aggregated Data and reserves the right to
                            use, license, and sublicense the Aggregated Data.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                2.4 Grant to You of Right of Access and Use.
                            </Box>{' '}
                            Provided that you comply with this Agreement, Varos grants to
                            you a non-exclusive, non-transferable limited right to access
                            and use the Services, subject to the following restrictions:
                        </Text>

                        <Text pl={4}>
                            (a) The right acquired by you under this Agreement is solely
                            to access and use the Services for your own internal business
                            purposes; and
                            <br />
                            (b) You may not: (i) reproduce or copy the Services in whole
                            or in part; (ii) sell, license, assign, or transfer the
                            Services to any party, or otherwise permit any party to access
                            or utilize the Services; (iii) translate, reverse engineer,
                            decompile, disassemble, or otherwise attempt to derive source
                            code or other trade secrets from, the Services; (iv) modify
                            the Services or merge all or any part of the Services with
                            another program; (v) prepare derivative works based on the
                            Services; (vi) disable, damage or attempt to penetrate or hack
                            the Services in any manner, including, without limitation,
                            pursuant to an “ethical hack”; (vii) publish, offer or
                            distribute the Services or any data therefrom in any manner;
                            (viii) expose or feed the data from the Services via
                            integration or otherwise to any third party; (ix) remove any
                            proprietary notices, labels, or marks on the Services; nor (x)
                            use the Services for any purpose other than as contemplated
                            under this Agreement.
                        </Text>

                        <Text fontWeight="bold">3. User Responsibilities.</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                3.1 Confirmation.
                            </Box>{' '}
                            You confirm that your use of the Services and/or your
                            participation in the Varos Network:
                            <br />
                            (a) will not breach any contract or agreement you have with a
                            third party;
                            <br />
                            (b) will not violate any duty or obligation you have to any
                            third party, or any policy or code of conduct to which you are
                            subject;
                            <br />
                            (c) Will not create any conflict of interest;
                            <br />
                            (d) will not result in the disclosure of any proprietary or
                            confidential information of a third party, including material
                            non-public information of a public company; and
                            <br />
                            (e) will not violate any law, rule, or regulation
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                3.2 Representations Regarding User Data.
                            </Box>{' '}
                            You represent and warrant that: (a) you own the User Data
                            provided by you or otherwise have the right to grant the
                            license set forth in this Agreement, (b) by providing User
                            Data, you will not violate the privacy rights, publicity
                            rights, copyright rights, or other rights of any person, (c)
                            by providing User Data, you will not violate any
                            confidentiality, non-disclosure, or contractual obligations
                            you might have towards a third party, and (d) Your User Data
                            is accurate and complete. Please note that any User Data you
                            provide may be shared with other Users and members of the
                            Varos Network.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                3.3 User Connections.
                            </Box>{' '}
                            You must provide and/or obtain, at your own expense, any
                            equipment (including servers and computer systems) and/or
                            telecommunications lines, links, and connections that may be
                            necessary for you to access the Site and use the Services.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                3.4 User Logins.
                            </Box>{' '}
                            You agree that the log-in access codes, user names, and/or
                            passwords (“Logins”) provided to a User are personal to, and
                            for use only by, such User. You shall ensure that all such
                            Logins are kept confidential. You will use commercially
                            reasonable efforts to ensure that no unauthorized person
                            accesses the Services through your systems and interfaces. You
                            are responsible and liable for all activities conducted
                            through the Logins.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                3.5 Acceptable Use.
                            </Box>{' '}
                            You are solely responsible for the content of your data or
                            transmissions and any other use of the Services by you. You
                            shall not interfere with, or disrupt, other users of the
                            Services. You will use commercially reasonable efforts to
                            prevent the introduction of any errors into or the corruption
                            of any data which is transmitted via the Services. You shall
                            ensure that you have implemented security systems and
                            procedures to prevent the unauthorized access to, or misuse or
                            disruption of, the Services. You shall immediately email Varos
                            at yarden@varos.io if you become aware of any unauthorized
                            access to, or misuse, or disruption of, the Services, setting
                            forth in reasonable detail the nature of the security breach
                            and the measures taken by you to cure such breach.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                3.6 Suspension of Access.
                            </Box>{' '}
                            Varos may suspend or terminate your access to the Services
                            immediately, without prior notice to you, if Varos believes,
                            in good faith, that you are utilizing the Services for any
                            illegal, abusive, or disruptive purpose. In addition,
                            notwithstanding any other provision of this Agreement, you
                            acknowledge that Varos shall have the right to restrict or
                            suspend your access to the Services if in Varos’s sole
                            determination any of the following circumstances occurs: (i)
                            full or partial interface failure; (ii) a breach in the
                            security of the Services; (iii) a material breach by you of
                            your obligations under this Agreement; or (iv) in order to
                            comply with applicable law.
                        </Text>

                        <Text fontWeight="bold">4. The Varos Network</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                4.1
                            </Box>{' '}
                            The Varos Network consists of a network of professionals in
                            various industries and specialties who educate and share
                            insights with business leaders and other organizations and
                            professionals (“Clients”). Each member of the Varos Network
                            (“Member”) may have the opportunity to participate in
                            different types of Network activities (“Projects”), such as
                            telephone or video conversations and online surveys.
                            Additionally, Members may be eligible to:
                        </Text>

                        <Text pl={4}>
                            • Receive rewards for referring friends and colleagues to the
                            Varos Network;
                            <br />
                            • Participate in discussions with other Members and Clients;
                            <br />
                            • Engage in other networking and educational opportunities;
                            and
                            <br />• Receive rewards for participating in Projects based on
                            the quality, accuracy, and depth of the Member’s responses.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                4.2
                            </Box>{' '}
                            Varos has complete discretion to determine your eligibility
                            for membership in the Varos Network and your compensation, if
                            any, for participating. Varos reserves the right to withhold
                            compensation in the event of a Member’s non-compliance with
                            this Agreement.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                4.3
                            </Box>{' '}
                            Please be aware that our telephone and video conversations and
                            interviews will in most instances be conducted by an AI tool
                            and therefore may operate with errors or inconsistencies. Our
                            AI conducted conversations and interviews are provided as-is
                            without any warranties or guarantees of any kind, including
                            appropriateness. We disclaim any liability for damages or
                            losses resulting from the performance or non-performance of
                            our AI tool or for any questions or statements that you may
                            deem offensive. We are constantly working to improve the
                            accuracy and quality of our AI tool. However, there may still
                            be errors or inaccuracies, as well as content that you may
                            deem inappropriate. We apologize for any inconvenience this
                            may cause.
                        </Text>

                        <Text fontWeight="bold">5. Confidentiality.</Text>

                        <Text>
                            Each party (the “Receiving Party”) acknowledges that it will
                            have access to certain confidential information of the other
                            party (the “Disclosing Party”) concerning the Disclosing
                            Party’s business, plans, customers, technology, data, and
                            products, and other information held in confidence by the
                            Disclosing Party (“Confidential Information”). Confidential
                            Information will include all information in tangible or
                            intangible form that is marked or designated as confidential
                            or that, under the circumstances of its disclosure, should be
                            considered confidential. Each Receiving Party agrees that it
                            will not use in any way, for its own account or the account of
                            any third party, except as expressly permitted by, or required
                            to achieve the purposes of, this Agreement, nor disclose to
                            any third party (except as required by law or to Receiving
                            Party’s consultants, contractors, attorneys, accountants and
                            other advisors as reasonably necessary), any of the Disclosing
                            Party’s Confidential Information and will take reasonable
                            precautions to protect the confidentiality of such
                            information, at least as stringent as it takes to protect its
                            own Confidential Information.
                        </Text>

                        <Text fontWeight="bold">6. Feedback</Text>

                        <Text>
                            You may provide Varos with information about your experience
                            using the Service, including information pertaining to bugs,
                            errors and malfunctions of the Services, performance of the
                            Services, the Services’ compatibility and interoperability,
                            and information or content concerning enhancements, changes or
                            additions to the Services that you request, desire, or suggest
                            (collectively, the “Feedback”). You hereby assign all right,
                            title and interest in and to the Feedback to Varos, including
                            the right to make commercial use thereof, for any purpose
                            Varos deems appropriate.
                        </Text>

                        <Text fontWeight="bold">7. Privacy Policy</Text>

                        <Text>
                            Any personal information provided by you or obtained by us
                            will be held and used by us in accordance with the Varos
                            privacy policy available at{' '}
                            <a
                                href="https://www.varos.com/p/privacy-policy"
                                style={{ color: '#ddd' }}
                            >
                                https://www.varos.com/p/privacy-policy
                            </a>
                            .
                        </Text>

                        <Text fontWeight="bold">8. Support</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                8.1
                            </Box>{' '}
                            Varos will endeavor to have the Site and the Services operate
                            properly. However, as a service that relies on back-end
                            software, infrastructure, servers, third-party networks, and
                            continuous internet connectivity, it cannot guarantee that the
                            Services will operate in an uninterrupted or error-free
                            manner, or that it will always be available, or free from
                            errors, omissions or malfunctions. If Varos becomes aware of
                            any failure or malfunction, it shall attempt to regain the
                            Service’s availability as soon as practicable. However, such
                            incidents will not be considered a breach of this Agreement.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                8.2
                            </Box>{' '}
                            Varos, either directly or with the assistance of third
                            parties, will endeavor to provide you with technical support
                            for technical questions, problems, and inquiries regarding the
                            Services, during Varos’ business days and hours, and pursuant
                            to its then-applicable support scheme, hours, and channels.
                            Varos will attempt to respond to your technical questions,
                            problems and inquiries as soon as practicably possible.
                            However, Varos makes no warranties to the successful or
                            satisfactory resolution of the question, problem or inquiry;
                            and may decline to provide such support for matters that it
                            deems, in its sole discretion, to require unreasonable time,
                            effort, costs or expenses. For the purpose of the provision of
                            technical support for your technical questions, problems and
                            inquiries, you will cooperate, and work closely with Varos, to
                            reproduce malfunctions, including conducting diagnostic or
                            troubleshooting activities, as Varos reasonably requests.
                        </Text>

                        <Text fontWeight="bold">9. Consideration</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                9.1
                            </Box>{' '}
                            In consideration for your access to and use of the Services,
                            you will pay Varos the fees specified in Varos’ Pricing Policy
                            at{' '}
                            <a
                                href="http://www.varos.io/pricing"
                                style={{ color: '#ddd' }}
                            >
                                www.varos.io/pricing
                            </a>{' '}
                            as applied to the Services for which you subscribe (the
                            “Consideration”), in accordance with the payment terms
                            specified in the Pricing Policy. The Consideration is
                            non-refundable.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                9.2
                            </Box>{' '}
                            The Consideration is exclusive of any excise, sales tax, VAT,
                            withholding tax or other governmental charges or transaction
                            charges associated with use of the Services. You shall bear
                            all such taxes and charges, excluding taxes based solely on
                            Varos’ net income.
                        </Text>

                        <Text fontWeight="bold">10. Term and Termination</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                10.1
                            </Box>{' '}
                            This Agreement shall remain in full force and effect while you
                            are accessing the Site or using the Services.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                10.2
                            </Box>{' '}
                            Either you or Varos may terminate this Agreement at any time
                            upon thirty (30) days prior written notice to the other. Your
                            notice of termination shall be sent via email to:
                            support@varos.io. Any material failure by you to comply with
                            the terms and conditions of this Agreement will result in
                            automatic termination of your right of access and use and this
                            Agreement, unless otherwise agreed to by Varos in writing.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                10.3
                            </Box>{' '}
                            Upon termination of this Agreement:
                        </Text>

                        <Text pl={4}>
                            (a) You shall cease any and all use of the Services; <br />
                            (b) Varos will charge you for all then-outstanding fees (if
                            any).
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                10.4
                            </Box>{' '}
                            Sections in this Agreement that by their purpose or nature
                            should survive termination of this Agreement, will so survive.
                        </Text>

                        <Text fontWeight="bold">
                            11. No Warranty and Limitation on Liability
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                11.1
                            </Box>{' '}
                            THE SITE AND THE SERVICES ARE PROVIDED “AS IS”. VAROS HEREBY
                            DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS, EITHER EXPRESS
                            OR IMPLIED, WITH RESPECT TO THE SITE AND THE SERVICES,
                            INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE, QUALITY, ACCURACY, NON-INFRINGEMENT,
                            TITLE, SECURITY, COMPATIBILITY OR PERFORMANCE.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                11.2
                            </Box>{' '}
                            From time to time, Varos may change the Services's structure,
                            features, layout, design or display, as well as the scope and
                            availability of the information and content therein, without
                            prior notice. Changes of this type by their very nature may
                            result in glitches or cause inconvenience of some kind. YOU
                            AGREE AND ACKNOWLEDGE THAT VAROS DOES NOT ASSUME ANY
                            RESPONSIBILITY WITH RESPECT TO, OR IN CONNECTION WITH THE
                            INTRODUCTION OF SUCH CHANGES OR FROM ANY MALFUNCTIONS OR
                            FAILURES THAT MAY RESULT THEREFROM.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                11.3
                            </Box>{' '}
                            We do not guarantee that the Site or the Services will be
                            secure or free from bugs or viruses. You are responsible for
                            configuring your information technology, computer programs,
                            and platform in order to access the Site and the Services. You
                            should use your own virus protection software. You must not
                            misuse the Site by knowingly introducing viruses, trojans,
                            worms, logic bombs or other material which is malicious or
                            technologically harmful. You must not attempt to gain
                            unauthorized access to the Site, the server on which the Site
                            is stored or any server, computer or database connected to the
                            Site. You must not attack the Site via a denial-of-service
                            attack or a distributed denial-of-service attack. By breaching
                            this provision, you may be committing a criminal offence. We
                            will report any such breach to the relevant law enforcement
                            authorities, and we will cooperate with those authorities by
                            disclosing your identity to them. In the event of such a
                            breach, your right to use the Site will cease immediately.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                11.4
                            </Box>{' '}
                            YOU BEAR SOLE AND EXCLUSIVE RESPONSIBILITY FOR COMPLYING WITH
                            ANY APPLICABLE LAWS REGARDING YOUR USE OF THE SERVICES,
                            INCLUDING, BUT NOT LIMITED TO, ANY LAWS AND REGULATIONS
                            REGARDING THE PROTECTION OF PERSONAL INFORMATION. VAROS TAKES
                            NO RESPONSIBILITY FOR ANY CLAIM WHICH MAY ARISE OUT OF OR IN
                            CONNECTION WITH YOUR USE OF THE SERVICES IN BREACH OF
                            APPLICABLE LAW.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                11.5
                            </Box>{' '}
                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND EXCEPT
                            IN THE EVENT OF INTENTIONAL MISCONDUCT OR BREACH OF VAROS’
                            CONFIDENTIALITY OBLIGATIONS, VAROS, INCLUDING ITS EMPLOYEES,
                            DIRECTORS, OFFICERS, SHAREHOLDERS, ADVISORS, AND ANYONE ACTING
                            ON ITS BEHALF, WILL NOT BE LIABLE FOR ANY INDIRECT,
                            INCIDENTAL, CONSEQUENTIAL, SPECIAL, STATUTORY OR PUNITIVE
                            DAMAGES, LOSSES (INCLUDING LOSS OF PROFIT, LOSS OF BUSINESS OR
                            BUSINESS OPPORTUNITIES AND LOSS OF DATA), ARISING FROM, OR IN
                            CONNECTION, WITH THIS AGREEMENT, ANY USE OF, OR THE INABILITY
                            TO USE THE SERVICES, ANY RELIANCE UPON THE SERVICES, OR ANY
                            ERROR, INCOMPLETENESS, INCORRECTNESS OR INACCURACY OF THE
                            SERVICES.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                11.6
                            </Box>{' '}
                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT
                            IN THE EVENT OF INTENTIONAL MISCONDUCT, OR BREACH OF
                            CONFIDENTIALITY OBLIGATIONS, THE AGGREGATE LIABILITY OF VAROS
                            (INCLUDING ITS RESPECTIVE EMPLOYEES, DIRECTORS, OFFICERS,
                            SHAREHOLDERS, ADVISORS, AND ANYONE ACTING ON ITS BEHALF), FOR
                            ANY DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE
                            SERVICES, SHALL BE LIMITED TO THE FEES PAID BY YOU TO VAROS
                            FOR THE SERVICE FROM WHICH THE CLAIM AROSE IN THE 12 MONTHS
                            PRIOR TO THE OCCURRENCE OF THE EVENT GIVING RISE TO THE CLAIM.
                        </Text>

                        <Text fontWeight="bold">12. Indemnification</Text>

                        <Text>
                            You agree to indemnify and hold harmless Varos and its
                            directors, officers, employees, and subcontractors, upon
                            Varos’ request and at your expense, from, and against, any
                            damages, loss, costs, expenses and payments, including
                            reasonable attorney’s fees and legal expenses, arising from
                            any third-party complaint, claim, plea, or demand in
                            connection with your breach of any provision in this
                            Agreement.
                        </Text>

                        <Text fontWeight="bold">13. Governing Law and Jurisdiction</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                13.1
                            </Box>{' '}
                            Regardless of your jurisdiction of incorporation, or the
                            jurisdiction where you engage in business or from where you
                            access the Services, this Agreement and your use of the
                            Services will be exclusively governed by and construed in
                            accordance with the laws of the State of New York, excluding
                            any otherwise applicable rules of conflict of laws, which
                            would result in the application of the laws of a jurisdiction
                            other than New York. Any dispute, controversy or claim which
                            may arise out of or in connection with this Agreement or the
                            Services, shall be submitted to the sole and exclusive
                            jurisdiction of the competent court in New York City. Subject
                            to Section 13.2 below, you and Varos hereby expressly consent
                            to the exclusive personal jurisdiction and venue of such
                            courts, and waive any objections related thereto including
                            objections on the grounds of improper venue, lack of personal
                            jurisdiction or forum non conveniens.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                13.2
                            </Box>{' '}
                            Notwithstanding the foregoing, Varos may also lodge a claim
                            against you: (a) pursuant to the indemnity clause above, in
                            any court adjudicating a third party claim against Varos; and
                            (b) for interim, emergency or injunctive relief in any other
                            court having general jurisdiction over User.
                        </Text>

                        <Text fontWeight="bold">14. Miscellaneous</Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                14.1 Assignment.
                            </Box>{' '}
                            You may not assign this Agreement without obtaining Varos’
                            prior written consent. Any purported assignment without Varos’
                            prior written consent is void.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                14.2 Relationship of the Parties.
                            </Box>{' '}
                            The relationship between you and Varos is strictly that of
                            independent contractors, and neither is an agent, partner,
                            joint venturer or employee of the other.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                14.3 Entire Agreement and Severability.
                            </Box>{' '}
                            This Agreement constitutes the entire and complete agreement
                            between you and Varos concerning the subject matter herein and
                            supersedes all prior oral or written statements,
                            understandings, negotiations and representations with respect
                            to the subject matter herein. If any provision of this
                            Agreement is held invalid or unenforceable, that provision
                            shall be construed in a manner consistent with the applicable
                            law to reflect, as nearly as possible, the original intentions
                            of the parties, and the remaining provisions will remain in
                            full force and effect.
                        </Text>

                        <Text>
                            <Box as="span" fontWeight="semibold">
                                14.4 No Waiver.
                            </Box>{' '}
                            Neither you nor Varos will, by mere lapse of time, without
                            giving express notice thereof, be deemed to have waived any
                            breach, by the other, of any terms or provisions of this
                            Agreement. The waiver, by either you or Varos, of any such
                            breach, will not be construed as a waiver of subsequent
                            breaches or as a continuing waiver of such breach.
                        </Text>

                        <Text fontWeight="bold">Version Date: February 25, 2025</Text>
                    </VStack>
                </VStack>
            </Layout>
        );
    };
}
