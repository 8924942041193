import React from 'react';
import {
    ContributorShellContextProvider,
    ContributorShellContext,
} from './contributorContextInterface';
import { ContributorShellContextContainerProps } from './contributorContextProps';

export function createContributorShellContextProvider(
    Container: React.FC<ContributorShellContextContainerProps>,
    Context: React.Context<ContributorShellContext | null>
): ContributorShellContextProvider {
    return {
        Provider: Container,
        useContext() {
            const context = React.useContext(Context);
            if (!context) {
                throw new Error('not inside report context');
            }
            return context;
        },
    };
}
