import { useQueryClient } from '@tanstack/react-query';
import { CallCreateRequest, CallObject } from '@varos/assistant-sdk';
import { AssistantSdk } from '@varos/assistant-sdk';
import { ApplicationContext, Kernel } from '../../../base';
import {
    AssistantCallCreateMutation,
    AssistantCallRepository,
} from './assistantCallInterface';
import { ThreadRepository } from '../thread';

export function createCallRepository(config: {
    kernel: Kernel;
    api: AssistantSdk;
    repository: {
        thread: ThreadRepository;
    };
}): AssistantCallRepository {
    const { kernel, api, repository } = config;
    const PREFIX = ['conversations', 'call'];

    // Helper function to generate consistent query keys
    const getQueryKey = (context: ApplicationContext) => [
        ...PREFIX,
        context.principal?.id,
    ];

    return {
        useCreate(context): AssistantCallCreateMutation {
            const client = useQueryClient();
            const mutation = kernel.infra.repository.useMutation(context, {
                async mutationFn(props: CallCreateRequest) {
                    const response = await api.callCreate({
                        callCreateRequest: props,
                    });
                    if (response.data.closed) {
                        console.log(
                            'thread was closed from call, refetching...',
                            response.data.thread
                        );
                        await repository.thread.refetch(
                            context,
                            client,
                            response.data.thread
                        );
                        // NOTE its important that we also refetch the list cache entry to ensure
                        // side-effects from calling conversation tools are reflected in the local state as well
                        await repository.thread.refetch(context, client, null);
                    }
                    return response.data;
                },
            });
            return mutation;
        },
    };
}
