import { ReportingRouterConfig, ReportingRouterInit } from './reportingRouterConfig';
import { ReportingRouter, ReportingRouterEnhancer } from './reportingRouterInterface';
import {
    ReportingContainerBaseConfig,
    ReportingContextBaseConfig,
    ReportingControllerBaseConfig,
    ReportingLoaderBaseConfig,
    ReportingViewBaseConfig,
} from './base';
import {
    createReportChapterView,
    createReportDetailView,
    createReportGenerateContainer,
    createReportGenerateController,
    createReportGenerateLoader,
    createReportGenerateView,
    createReportDetailContainer,
    createReportDetailController,
    createReportDetailLoader,
    createReportNewContainer,
    createReportNewController,
    createReportNewLoader,
    createReportNewView,
    createReportOverviewContainer,
    createReportOverviewController,
    createReportOverviewLoader,
    createReportOverviewView,
    createReportChapterContainer,
    createReportChapterLoader,
    createReportChapterController,
    createContentOverlayContainer,
    createContentOverlayController,
    createContentOverlayLoader,
    createContentOverlayView,
} from './page';
import { createReportSectionItemController, createSectionItemContainer } from './view';
export function createReportingRouter(
    config: ReportingRouterInit,
    enhancer?: ReportingRouterEnhancer
) {
    return create({ ...config }, enhancer);
}

function create(
    config: ReportingRouterConfig,
    enhancer?: ReportingRouterEnhancer
): ReportingRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const contextConfig: ReportingContextBaseConfig = {};

    const Context = {};

    // Create all routers needed for the application
    const reportDetailRouter = config.kernel.provider.createRouter({
        state: {
            kind: 'hash',
        },
    });

    // Create a router map to be used throughout the app
    const router = {
        report: reportDetailRouter,
    };

    const containerConfig: ReportingContainerBaseConfig = {
        context: config.context,
        kernel: config.kernel,
        // repository: config.repository,
        UI: config.UI,
        router, // Pass the router map to containers
    };

    const loaderConfig: ReportingLoaderBaseConfig = {
        // repository: config.repository,
    };

    const controllerConfig: ReportingControllerBaseConfig = {
        navigation: {},
        controller: config.controller,
        kernel: config.kernel,
    };

    const viewConfig: ReportingViewBaseConfig = {
        UI: config.UI,
        Layout: config.Layout,
        router,
        kernel: config.kernel,
    };

    const ReportOverview = createReportOverviewContainer(
        containerConfig,
        createReportOverviewLoader(loaderConfig),
        createReportOverviewController(controllerConfig),
        createReportOverviewView(viewConfig)
    );

    const ReportDetail = createReportDetailContainer(
        containerConfig,
        createReportDetailLoader(loaderConfig),
        createReportDetailController(controllerConfig),
        createReportDetailView(viewConfig)
    );

    const ReportGenerate = createReportGenerateContainer(
        containerConfig,
        createReportGenerateLoader(loaderConfig),
        createReportGenerateController(controllerConfig),
        createReportGenerateView(viewConfig)
    );

    const ReportNew = createReportNewContainer(
        containerConfig,
        createReportNewLoader(loaderConfig),
        createReportNewController(controllerConfig),
        createReportNewView(viewConfig)
    );

    const ChapterDetail = createReportChapterContainer(
        containerConfig,
        createReportChapterLoader(loaderConfig),
        createReportChapterController(controllerConfig),
        createReportChapterView({
            ...viewConfig,
            Container: {
                Section: createSectionItemContainer(
                    containerConfig,
                    createReportSectionItemController(controllerConfig)
                ),
            },
        })
    );

    const ContentOverlay = createContentOverlayContainer(
        containerConfig,
        createContentOverlayLoader(loaderConfig),
        createContentOverlayController(controllerConfig),
        createContentOverlayView(viewConfig)
    );

    return {
        Pages: {
            Report: {
                Overview: ReportOverview,
                Detail: ReportDetail,
                Generate: ReportGenerate,
                New: ReportNew,
            },
            Chapter: {
                Detail: ChapterDetail,
            },
            Content: {
                Overlay: ContentOverlay,
            },
        },
        router,
    };
}
