import { useMemo } from 'react';
import { ContributorShellControllerBaseConfig } from '../../base';
import { ContributorNavigationController } from './contributorNavigationInterface';

export function createContributorNavigationController(
    config: ContributorShellControllerBaseConfig
): ContributorNavigationController {
    const {
        kernel: { controller },
    } = config;

    return {
        useProps(context, item, props) {
            const browser = controller.browser.useProps({});
            const items = useMemo(() => {
                return props.items.map((item) => ({
                    ...item,
                    tag: item.tag ?? null,
                    link: {
                        to: item.path,
                    },
                    isActive: props.location.pathname.includes(item.path),
                }));
            }, [props.items, props.location.pathname]);
            return {
                browser,
                navigation: {
                    items,
                },
                drawer: {
                    isOpen: props.disclosure.isOpen,
                    onOpen: props.disclosure.onOpen,
                    onClose: props.disclosure.onClose,
                },
            };
        },
    };
}
