import { KernelEnhancer } from '../../../base';
import {
    ActionControllerConfigV2,
    ActionItemControllerPropsV2,
    ActionItemControllerV2,
} from '../../../view/common';
import { AnyActionEvent } from './trackingActionEvent';

export function createActionTrackingStrategy(): KernelEnhancer {
    return (create) => (init) => {
        function useTracker() {
            const tracker = instance.infra.useTracker<AnyActionEvent>();
            return tracker;
        }

        function enhanceController(
            config: ActionControllerConfigV2,
            controller: ActionItemControllerV2
        ): ActionItemControllerV2 {
            return {
                ...controller,
                useProps(props) {
                    const tracker = useTracker();
                    const mapped: ActionItemControllerPropsV2 =
                        props.kind === 'custom'
                            ? {
                                  ...props,
                                  async onPerform(...args) {
                                      const response = await props.onPerform(...args);
                                      tracker.track('action_triggered', {
                                          action_id: config.id,
                                      });
                                      return response;
                                  },
                              }
                            : props;
                    const viewProps = controller.useProps(mapped);
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...init,
            provider: {
                ...init.provider,
                createActionController(...args) {
                    const instance = init.provider.createActionController(...args);
                    return enhanceController(...args, instance);
                },
            },
        });
        return instance;
    };
}
