import { useDisclosure } from '@chakra-ui/react';
import { ReportingContainerBaseConfig } from '../../../base';
import { ReportSectionItemController } from './sectionItemInterface';
import { ReportSectionItemContainerProps } from './sectionItemProps';
import { useSearchParams } from 'react-router-dom';

export function createSectionItemContainer(
    config: ReportingContainerBaseConfig,
    controller: ReportSectionItemController
): React.FC<ReportSectionItemContainerProps> {
    return ({ as: View, item, ...containerProps }) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const props = controller.useProps(item, {
            ...containerProps,
            // modal: {
            //     trigger: null,
            //     disclosure: useDisclosure({
            //         isOpen: !!searchParams.get('source'),
            //         onClose: () => {
            //             setSearchParams({}, { replace: true });
            //         },
            //     }),
            // },
        });
        return <View {...props} />;
    };
}
