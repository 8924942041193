import { ReportingShellLoaderBaseConfig } from '../../../base';
import { ReportingShellContextLoader } from './reportingContextInterface';

export function creatReportingContextLoader(
    config: ReportingShellLoaderBaseConfig
): ReportingShellContextLoader {
    return {
        useLoad(props) {
            return {};
        },
    };
}
