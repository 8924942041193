import { MouseEvent } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { ReportingControllerBaseConfig } from '../../base';
import { ReportChapterController } from './reportChapterInterface';
import { ReportChapterViewProps } from './reportChapterProps';

// Helper function to find the scrollable ancestor or window
const getScrollableAncestor = (element: HTMLElement | null): HTMLElement | Window => {
    if (!element) {
        return window;
    }

    let parent = element.parentElement;
    while (parent && parent !== document.body) {
        const styles = window.getComputedStyle(parent);
        if (styles.overflowY === 'scroll' || styles.overflowY === 'auto') {
            return parent;
        }
        parent = parent.parentElement;
    }

    // If no scrollable parent found, assume window is the scroller
    return window;
};

export function createReportChapterController(
    config: ReportingControllerBaseConfig
): ReportChapterController {
    return {
        useProps(context, item, props): ReportChapterViewProps {
            const { chapter, report, content } = item;

            // Define the click handling logic here
            const handleHighlightClick = (
                event: MouseEvent<HTMLDivElement>,
                offset: number
            ) => {
                const target = event.target as HTMLElement;
                const anchor = target.closest('a[href^="#"]');

                if (anchor) {
                    event.preventDefault();
                    const href = anchor.getAttribute('href');
                    if (href) {
                        const targetId = href.substring(1);
                        const targetElement = document.getElementById(targetId);

                        if (targetElement) {
                            const scrollContainer = getScrollableAncestor(targetElement);
                            let targetPosition = 0;

                            if (scrollContainer === window) {
                                targetPosition =
                                    window.scrollY +
                                    targetElement.getBoundingClientRect().top -
                                    offset;
                            } else if (scrollContainer instanceof HTMLElement) {
                                targetPosition =
                                    scrollContainer.scrollTop +
                                    targetElement.getBoundingClientRect().top -
                                    scrollContainer.getBoundingClientRect().top -
                                    offset;
                            }

                            scrollContainer.scrollTo({
                                top: targetPosition,
                                behavior: 'smooth',
                            });
                        }
                    }
                }
            };

            return {
                id: chapter.id,
                number: chapter.number,
                parent: {
                    title: item.report.title,
                    summary: chapter.description,
                },
                hightlights: chapter.sections.map((section, index) => ({
                    id: section.id,
                    label: `${chapter.number}.${index + 1}. ${section.caption}`,
                })),
                keywords: [
                    {
                        id: '0',
                        label: item.author.name,
                        Icon: AiOutlineUser,
                    },
                    {
                        id: '1',
                        label: `Updated today`,
                        Icon: null,
                    },
                ],
                author: {
                    label: item.author.name,
                    Icon: AiOutlineUser,
                },
                title: chapter.title,
                description: chapter.description,
                caption: `${chapter.number}. ${chapter.caption}`,
                link: {
                    to: chapter.id,
                },
                sections: chapter.sections.map((section, index) => {
                    return {
                        item: { section },
                        index,
                        chapterNumber: chapter.number,
                        isLast: index === chapter.sections.length - 1,
                        onOpen: props.onOpen,
                    };
                }),
                // Pass the handler down
                onHighlightClick: handleHighlightClick,
            };
        },
    };
}
