import React, { Children, isValidElement, ReactElement } from 'react';
import {
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Text,
    VStack,
    Divider,
} from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { AssistantLayoutViewProps } from '../assistantLayoutProps';
import { AssistantViewBaseConfig } from '../../../../base';

/**
 * Separate action content into buttons (for menu) and other components (to render outside)
 */
function separateActionContent(content: React.ReactNode): {
    buttons: ReactElement[];
    otherElements: React.ReactNode[];
} {
    const buttons: ReactElement[] = [];
    const otherElements: React.ReactNode[] = [];

    if (!content) {
        return { buttons, otherElements };
    }

    // Helper function to process a node
    const processNode = (node: React.ReactNode) => {
        if (!isValidElement(node)) {
            return;
        }

        // Handle React Fragments (type is a symbol, so we check for props.children)
        if (node.type === React.Fragment) {
            // Process all children of the fragment
            React.Children.forEach(node.props.children, (child) => {
                processNode(child);
            });
            return;
        }

        // If it's a Button, add to buttons array
        if (node.type === Button || node.type === IconButton) {
            buttons.push(node);
        }
        // Otherwise, add to otherElements array
        else {
            otherElements.push(node);
        }
    };

    // Start processing the content
    processNode(content);

    return { buttons, otherElements };
}

export function createAssistantMobileLayoutView(
    config: Pick<AssistantViewBaseConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & AssistantLayoutViewProps> {
    return (props) => {
        // Separate action content into buttons and other elements
        const { buttons: actionButtons, otherElements } = props.actionContent
            ? separateActionContent(props.actionContent)
            : { buttons: [], otherElements: [] };

        return (
            <Grid
                w="100dvw"
                h="100dvh"
                templateRows="min-content 1fr"
                templateColumns="1fr"
                overflow={props.isScrollable ? undefined : 'hidden'}
            >
                {/* Header */}
                <GridItem
                    py={2}
                    px={4}
                    borderBottomWidth={2}
                    borderColor="whiteAlpha.300"
                    zIndex={10}
                >
                    <SimpleGrid columns={3}>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={12}>
                                {props.back.button &&
                                    !props.detail.container.isVisible && (
                                        <IconButton
                                            {...props.back.button}
                                            aria-label="go back"
                                            variant="link"
                                            icon={<Icon as={BsChevronLeft} />}
                                            _focus={{ outline: 'none' }}
                                        />
                                    )}
                                {/* when detail is open the back button should instead close the details */}
                                {props.detail.container.isVisible && (
                                    <IconButton
                                        {...props.detail.button}
                                        aria-label="close"
                                        variant="link"
                                        icon={<Icon as={BsChevronLeft} />}
                                        _focus={{ outline: 'none' }}
                                    />
                                )}
                            </HStack>
                        </HStack>
                        <HStack w="full" justify="center">
                            {props.title && (
                                <Text
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    fontSize={{ base: 'md', lg: 'lg' }}
                                    fontWeight="semibold"
                                >
                                    {props.title}
                                </Text>
                            )}
                        </HStack>
                        <HStack justify="end" spacing={6}>
                            {/* Render the non-button elements outside the menu */}
                            {otherElements}

                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    icon={<Icon as={AiOutlineMenu} />}
                                    bg="none"
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                    _focus={{ outline: 'none' }}
                                />
                                <MenuList>
                                    {props.actions.map((action) => (
                                        <MenuItem
                                            key={action.id}
                                            // icon={<Icon as={action.Icon} />}
                                            onClick={action.button.onClick}
                                            fontWeight="semibold"
                                            textAlign="left"
                                            justifyContent="flex-start"
                                            px={4}
                                            w="full"
                                        >
                                            {action.label}
                                        </MenuItem>
                                    ))}

                                    {/* Add a divider if there are custom action buttons */}
                                    {actionButtons.length > 0 && (
                                        <Divider my={2} borderColor="whiteAlpha.300" />
                                    )}

                                    {/* Render extracted action buttons as menu items */}
                                    {actionButtons.map((button, index) => {
                                        // Extract properties from the button
                                        const { children, onClick, colorScheme } =
                                            button.props;

                                        return (
                                            <MenuItem
                                                key={`action-button-${index}`}
                                                onClick={onClick}
                                                textAlign="left"
                                                justifyContent="flex-start"
                                                px={4}
                                                w="full"
                                                fontWeight="semibold"
                                                color={
                                                    colorScheme === 'red'
                                                        ? 'red.400'
                                                        : undefined
                                                }
                                            >
                                                {children}
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </Menu>
                        </HStack>
                    </SimpleGrid>
                </GridItem>
                <GridItem position="relative">
                    {props.detail.container.isVisible && props.sidebar && (
                        <VStack align="start" w="full" h="full" p={6}>
                            {props.sidebar}
                        </VStack>
                    )}
                    {!props.detail.container.isVisible && props.children}
                </GridItem>
            </Grid>
        );
    };
}
