import pluralize from 'pluralize';
import { ReportingControllerBaseConfig } from '../../../base';
import { ContentOverlayController } from './contentOverlayInterface';
import { capitalize } from 'lodash';
import { assert } from '@varos/util-typescript';

export function createContentOverlayController(
    config: ReportingControllerBaseConfig
): ContentOverlayController {
    return {
        useProps(context, item, props) {
            assert(item.source.markdown, 'expected markdown to be set on system source');
            return {
                breadcrumb: {
                    items: [
                        ...(item.source.type
                            ? [
                                  {
                                      id: 'type',
                                      label: capitalize(
                                          pluralize(item.source.type.replaceAll('-', ' '))
                                      ),
                                      isActive: false,
                                      link: null,
                                  },
                              ]
                            : []),
                        {
                            id: 'provider',
                            label: 'Varos',
                            isActive: false,
                            link: null,
                        },
                        {
                            id: item.source.id,
                            label: item.source.name,
                            isActive: true,
                            link: null,
                        },
                    ],
                },
                source: {
                    title: item.source.name,
                    markdown: item.source.markdown,
                },
            };
        },
    };
}
