import { ConfirmTermsController } from './confirmTermsInterface';
import { ConfirmTermsViewProps } from './confirmTermsProps';

export function createConfirmTermsController(config: {
    id: string;
}): ConfirmTermsController {
    return {
        useProps(props): ConfirmTermsViewProps {
            return {
                checkbox: {
                    isChecked: props.value,
                    onChange(event) {
                        props.onChange(event.target.checked);
                    },
                },
            };
        },
    };
}
