import { useQueryClient } from '@tanstack/react-query';
import { RewardSdk } from '@varos/reward-sdk';
import { Kernel } from '../../../base';
import { RewardPartnerRepository } from './rewardPartnerInterface';

export function createRewardPartnerRepository(
    kernel: Kernel,
    api: RewardSdk
): RewardPartnerRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['reward', 'partner'];
    return {
        useFind(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, props],
                async queryFn() {
                    const response = await api.contributor.partnerList(props);
                    return response.data.data;
                },
            });
            return query;
        },
    };
}
