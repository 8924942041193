import { VideoControllerProps } from '../../view/common';

export const Videos = {
    // invitations
    INVITE_WELCOME: {
        id: 'invite_welcome',
        url: `https://www.loom.com/embed/ac371d62228f46d48e4f5b8bbf2b1acb?sid=c8527e20-c6f9-4bb9-9f4f-d4f65c957956`,
        title: 'Welcome',
    },
    // rewards
    REWARDS_EARN: {
        id: 'rewards_earn_rewards',
        url: `https://www.loom.com/embed/3991f8c7b3164537a45500f3f70e95e7?sid=f06061d8-65d4-4250-9c07-ed6f720db565`,
        title: 'Earn $ for Rewards',
    },
    REWARDS_FEATURING: {
        id: 'rewards_report_featuring',
        url: `https://www.loom.com/embed/d35cb472fc70438fb61a3a391313a30c?sid=d2799f60-e38f-40dd-bd01-45422b6b94bb`,
        title: 'Showcase Your Profile to Enterprise Tech Execs',
    },
} satisfies Record<string, VideoControllerProps>;
