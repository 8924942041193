import { useMemo } from 'react';
import { assert } from '@varos/util-typescript';
import { RewardLoaderBaseConfig } from '../../../base';
import { keyBySafe } from '../../../../../util';
import { OfferDetailLoader } from './offerDetailInterface';
import { OfferDetailAggregate } from './offerDetailModel';
import {
    OfferItemAggregate,
    OfferRewardAggregate,
    OfferTaskAggregate,
} from '../../../fragment';
import { FormLookupRequest } from '@varos/form-sdk';

export function createOfferDetailLoader(
    config: RewardLoaderBaseConfig
): OfferDetailLoader {
    const { repository } = config;

    const MAX_RELATED = 3;

    return {
        useLoad(context, props) {
            const partnerQuery = repository.reward.partner.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );
            const offerQuery = repository.reward.offer.useLookup(
                context,
                {
                    offer: props.offer,
                },
                {
                    suspense: true,
                }
            );

            const offerRelatedQuery = repository.reward.offer.useFind(
                context,
                {
                    // add one because we migth filter out one if the ID is the same
                    // as the currently loaded one.
                    // TODO add a "not equals" filter
                    limit: MAX_RELATED + 1,
                },
                {
                    suspense: true,
                }
            );
            const productQuery = repository.reward.product.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );
            const eligibilityQuery = repository.reward.eligibility.useLookup(
                context,
                {
                    offer: props.offer,
                },
                {
                    // NOTE we refresh on every page load to always show
                    // the latest status
                    cacheTime: 0,
                    suspense: true,
                }
            );
            const promotionQuery = repository.reward.promotion.useFind(
                context,
                {},
                {
                    suspense: true,
                }
            );
            assert(offerQuery.status === 'success', 'expected suspense');
            assert(partnerQuery.status === 'success', 'expected suspense');
            assert(productQuery.status === 'success', 'expected suspense');
            assert(offerRelatedQuery.status === 'success', 'expected suspense');
            assert(eligibilityQuery.status === 'success', 'expected suspense');
            assert(promotionQuery.status === 'success', 'expected suspense');

            const formQuery = repository.form.definition.useLookupMulti(
                context,
                offerQuery.data.tasks.flatMap((task): FormLookupRequest[] => {
                    if (task.type !== 'form') {
                        return [];
                    }
                    return [{ definitionIdOrSlug: task.config.form }];
                }),
                {
                    suspense: true,
                }
            );

            assert(formQuery.status === 'success', 'expected suspense');

            const formsById = useMemo(
                () => keyBySafe(formQuery.data, (item) => item.id),
                [formQuery.data]
            );

            const formsBySlug = useMemo(
                () => keyBySafe(formQuery.data, (item) => item.slug),
                [formQuery.data]
            );

            const partnerById = useMemo(
                () => keyBySafe(partnerQuery.data, (item) => item.id),
                [partnerQuery.data]
            );

            const promotionByOffer = useMemo(
                () => keyBySafe(promotionQuery.data, (item) => item.offer),
                [promotionQuery.data]
            );

            const productById = useMemo(
                () => keyBySafe(productQuery.data, (item) => item.id),
                [productQuery.data]
            );

            const rewardStatusByKey = useMemo(
                () => keyBySafe(eligibilityQuery.data.rewards, (item) => item.key),
                [eligibilityQuery.data]
            );

            const taskStatusByKey = useMemo(
                () => keyBySafe(eligibilityQuery.data.tasks, (item) => item.key),
                [eligibilityQuery.data]
            );

            const partner = partnerById[offerQuery.data.partner];
            assert(partner, 'partner not found');

            return useMemo(
                (): OfferDetailAggregate => ({
                    eligibility: eligibilityQuery.data,
                    related: null,
                    // related: offerRelatedQuery.data
                    //     .filter((candidate) => candidate.id !== props.offer.id)
                    //     .slice(0, MAX_RELATED)
                    //     .flatMap((offer): OfferItemAggregate[] => {
                    //         const partner = partnerById[offer.partner];
                    //         if (!partner) {
                    //             console.warn(
                    //                 `offer partner '${offer.partner}' not found`,
                    //                 productById
                    //             );
                    //             return [];
                    //         }
                    //         return [
                    //             {
                    //                 root: offer,
                    //                 partner,
                    //                 rewards: null,
                    //                 tasks: null,
                    //                 promotion: null,
                    //             },
                    //         ];
                    //     }),
                    offer: {
                        root: offerQuery.data,
                        tasks: offerQuery.data.tasks.map((task): OfferTaskAggregate => {
                            const status = taskStatusByKey[task.key];
                            return {
                                root: task,
                                status: status ?? null,
                            };
                        }),
                        rewards: offerQuery.data.rewards.flatMap(
                            (reward): OfferRewardAggregate[] => {
                                const product = productById[reward.product];
                                if (!product) {
                                    console.warn(
                                        `reward product '${reward.product}' not found`,
                                        productById
                                    );
                                    return [];
                                }
                                const status = rewardStatusByKey[reward.key];
                                if (!status) {
                                    console.warn(
                                        `reward status '${reward.key}' not found`,
                                        rewardStatusByKey
                                    );
                                    return [];
                                }
                                return [
                                    {
                                        root: reward,
                                        product,
                                        status,
                                    },
                                ];
                            }
                        ),
                        partner,
                        promotion: promotionByOffer[offerQuery.data.id] ?? null,
                        formsBySlug: formsBySlug,
                    },
                }),
                [
                    formsBySlug,
                    offerQuery.data,
                    offerRelatedQuery.data,
                    eligibilityQuery.data,
                    partner,
                    productById,
                    rewardStatusByKey,
                ]
            );
        },
    };
}
