import { useRef, useState, useEffect } from 'react';
import { ConfirmationControllerConfig } from './confirmationConfig';
import { ConfirmationController } from './confirmationInterface';
import { ConfirmationViewProps } from './confirmationProps';

export function createConfirmationController(
    config: Partial<ConfirmationControllerConfig>
): ConfirmationController {
    return {
        useProps(props): ConfirmationViewProps {
            const cancelRef = useRef(null);
            const [isSubmitting, setIsSubmitting] = useState(false);
            const isMounted = useRef<boolean | null>(null);

            useEffect(() => {
                isMounted.current = true;
                return () => {
                    isMounted.current = false;
                };
            }, []);

            return {
                modal: {
                    isOpen: props.disclosure.isOpen,
                    onClose: props.disclosure.onClose,
                },
                alert: {
                    leastDestructiveRef: cancelRef,
                    isOpen: props.disclosure.isOpen,
                    onClose: props.disclosure.onClose,
                },
                trigger: {
                    button: {
                        onClick() {
                            return props.disclosure.onOpen();
                        },
                    },
                },
                submit: {
                    button: {
                        isLoading: props.isSubmitting ?? isSubmitting,
                        async onClick() {
                            setIsSubmitting(true);
                            try {
                                const result = await props.onSubmit();
                                return result;
                            } finally {
                                if (isMounted.current) {
                                    setIsSubmitting(false);
                                    props.disclosure.onClose();
                                }
                            }
                        },
                    },
                },
                cancel: {
                    cancelRef,
                    button: {
                        onClick() {
                            return props.disclosure.onClose();
                        },
                    },
                },
            };
        },
    };
}
