import {
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    InputGroup,
    InputRightElement,
    Text,
    Textarea,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/react';
import { assert } from '@varos/util-typescript';
import TextareaAutosize from 'react-textarea-autosize';
import { AssistantViewBaseConfig } from '../../../../base';
import { ThreadComposerViewProps } from './composerProps';

export function createComposerView(
    config: AssistantViewBaseConfig
): React.FC<ThreadComposerViewProps> {
    const {
        UI: {
            Application: { Tooltip },
        },
    } = config;
    // Turn the TextareaAutosize into a Chakra component
    const ChakraTextareaAutosize = chakra(TextareaAutosize, {
        baseStyle: {
            // any default Chakra styles you want here
            // e.g. fontSize: "md", etc.
        },
    });
    return (props) => {
        return (
            <Grid
                w="full"
                gap={4}
                templateColumns={{ base: '1fr', md: '1fr min-content' }}
            >
                <GridItem>
                    <ChakraTextareaAutosize
                        {...props.textarea.input}
                        ref={props.textarea.inputRef}
                        w="full"
                        lineHeight={1.25}
                        autoFocus={true}
                        minRows={1}
                        maxRows={8}
                        border="none"
                        bg="none"
                        tabIndex={1}
                        resize="none"
                        placeholder="Send a message"
                        _focus={{
                            outline: 'none',
                        }}
                        _focusVisible={{
                            outline: 'none',
                        }}
                    />
                </GridItem>
                <GridItem>
                    <HStack
                        w="full"
                        h="full"
                        justify="end"
                        spacing={{ base: 3, md: 3 }}
                        align={{ base: 'center', md: 'end' }}
                    >
                        {props.record && (
                            <HStack spacing={3}>
                                {props.record.timer && (
                                    <Text color="red.400" fontWeight="medium">
                                        {props.record.timer.label}
                                    </Text>
                                )}
                                <Tooltip
                                    {...props.record.action.tooltip}
                                    arrowShadowColor="gray.100"
                                    shouldWrapChildren={true}
                                    openDelay={300}
                                    py={2}
                                    px={3}
                                    placement="top"
                                    hasArrow={true}
                                    bg="gray.100"
                                    color="gray.900"
                                >
                                    <IconButton
                                        {...props.record.action.button}
                                        aria-label="submit"
                                        borderRadius="full"
                                        icon={
                                            <Icon
                                                as={props.record.action.Icon!}
                                                fontSize="xl"
                                            />
                                        }
                                        color="whiteAlpha.800"
                                        bg="none"
                                        _active={{
                                            bg: 'red.400',
                                            color: 'white',
                                            _hover: { bg: 'red.500' },
                                        }}
                                        _disabled={{
                                            opacity: 0.33,
                                            cursor: 'default',
                                        }}
                                        _hover={{ bg: 'whiteAlpha.50' }}
                                        _focus={{ outline: 'none' }}
                                    />
                                </Tooltip>
                            </HStack>
                        )}
                        {props.action.map((action) => {
                            assert(action.Icon, 'Icon is required');
                            return (
                                <Tooltip
                                    key={action.id}
                                    {...action.tooltip}
                                    arrowShadowColor="gray.100"
                                    openDelay={300}
                                    py={2}
                                    px={3}
                                    placement="top"
                                    hasArrow={true}
                                    bg="gray.100"
                                    color="gray.900"
                                >
                                    <IconButton
                                        {...action.button}
                                        aria-label={action.label}
                                        icon={<Icon as={action.Icon} fontSize="xl" />}
                                        borderRadius="full"
                                        {...(action.variant === 'primary'
                                            ? {
                                                  bg: 'white',
                                                  color: 'black',
                                                  _hover: { bg: 'gray.50' },
                                              }
                                            : {
                                                  color: 'whiteAlpha.800',
                                                  bg: 'none',
                                                  _hover: { bg: 'whiteAlpha.50' },
                                              })}
                                        {...(action.button.colorScheme === 'red'
                                            ? {
                                                  color: 'white',
                                                  bg: 'red.300',
                                                  _hover: { bg: 'red.400' },
                                              }
                                            : {})}
                                        _disabled={{
                                            opacity: 0.33,
                                            cursor: 'default',
                                        }}
                                        _focus={{ outline: 'none' }}
                                    />
                                </Tooltip>
                            );
                        })}
                    </HStack>
                </GridItem>
            </Grid>
        );
    };
}
