import { ShellLayoutConfig, ShellLayoutInit } from './applicationShellConfig';
import { createApplicationShellContainer } from './page/root/applicationShellContainer';
import { createApplicationShellController } from './page/root/applicationShellController';
import { createApplicationShellLoader } from './page/root/applicationShellLoader';
import {
    BaseApplicationShellContainerConfig,
    BaseApplicationShellControllerConfig,
    BaseApplicationShellLoaderConfig,
} from './base';
import {
    createApplicationAccountController,
    createApplicationNavigationController,
    createApplicationWorkspaceController,
} from './view';
import {
    ApplicationShellComponents,
    ApplicationShellEnhancer,
} from './applicationShellInterface';
import {
    ApplicationShellContext,
    createApplicationContextController,
    createApplicationContextLoader,
    createApplicationErrorContainer,
    createApplicationErrorController,
    createApplicationShellContextContainer,
    createApplicationShellContextProvider,
} from './page';
import React from 'react';

export function createApplicationShell(
    config: ShellLayoutInit,
    enhancer?: ApplicationShellEnhancer
) {
    return create(
        {
            ...config,
            provider: {
                createRootController: createApplicationShellController,
                createNavigationController: createApplicationNavigationController,
                createWorkspaceController: createApplicationWorkspaceController,
                createAccountController: createApplicationAccountController,
            },
        },
        enhancer
    );
}

function create(
    config: ShellLayoutConfig,
    enhancer?: ApplicationShellEnhancer
): ApplicationShellComponents {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const Context = React.createContext<ApplicationShellContext | null>(null);

    const loaderConfig: BaseApplicationShellLoaderConfig = {
        kernel: config.kernel,
        repository: config.repository,
    };

    const controllerConfig: BaseApplicationShellControllerConfig = {
        kernel: config.kernel,
        view: config.view,
        hook: config.hook,
        macro: config.macro,
        legacy: config.legacy,
    };

    const context = createApplicationShellContextProvider(
        createApplicationShellContextContainer(
            config,
            Context,
            createApplicationContextLoader(loaderConfig),
            createApplicationContextController(controllerConfig)
        ),
        Context
    );

    const containerConfig: BaseApplicationShellContainerConfig = {
        kernel: config.kernel,
        context,
        Theme: config.Theme,
        view: config.view,
        UI: config.UI,
        repository: config.repository,
    };

    const loader = createApplicationShellLoader(loaderConfig);
    const controller = config.provider.createRootController({
        ...config,
        controller: {
            navigation: config.provider.createNavigationController(controllerConfig),
            account: config.provider.createAccountController(controllerConfig),
            workspace: config.provider.createWorkspaceController(controllerConfig),
        },
    });

    const Shell = createApplicationShellContainer(
        {
            ...containerConfig,
            Container: {
                Error: createApplicationErrorContainer(
                    containerConfig,
                    createApplicationErrorController()
                ),
            },
        },
        loader,
        controller
    );

    const { Provider: ContextProvider } = context;
    return {
        Root(props) {
            return (
                <ContextProvider>
                    <Shell {...props} />
                </ContextProvider>
            );
        },
    };
}
