import AuthStore from '../../stores/AuthStore';
import { TrackHookConfig } from './trackerConfig';
import { Tracker } from './trackerInterface';

export const useTracker = (auth: Pick<AuthStore, 'reportEvent'>, _config: Partial<TrackHookConfig> = {}): Tracker => {
    return {
        track(event, payload) {
            auth.reportEvent(event, payload);
        },
    };
};
