import React, { useState } from 'react';
import { PiMagicWand } from 'react-icons/pi';
import { useLocation } from 'react-router';
import { Icons } from '../../../../../config';
import { StudiesBaseContainerConfig } from '../../base';
import { SurveyDetailController, SurveyDetailLoader } from './surveyDetailInterface';
import { SurveyDetailStateValue } from './surveyDetailModel';
import { SurveyDetailContainerProps, SurveyDetailViewProps } from './surveyDetailProps';
import { SurveyDetailUrlParamSchema } from './surveyDetailSchema';

export function createSurveyDetailContainer(
    config: StudiesBaseContainerConfig,
    loader: SurveyDetailLoader,
    controller: SurveyDetailController,
    View: React.FC<{ children?: React.ReactNode | undefined } & SurveyDetailViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & SurveyDetailContainerProps> {
    const {
        infra: {
            optionParser: { useOptions },
        },
        context: { useContext },
        navigate: { assistant },
    } = config;

    const AiIcon = PiMagicWand;

    return ({ children, ...containerProps }) => {
        throw new Error('not impl');
        const [state, setState] = useState<SurveyDetailStateValue>({
            mode: 'interview',
        });
        const location = useLocation();
        const options = useOptions(SurveyDetailUrlParamSchema);
        const context = useContext();
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {
            link: {
                thread: assistant.thread.create({
                    location,
                    thread: {
                        // @ts-expect-error
                        type: 'interview',
                        data: {
                            // @ts-expect-error
                            interview: data.survey.id,
                            asset: context.workspace
                                ? // @ts-expect-error
                                  (context.workspace.id as number)
                                : null,
                        },
                    },
                }),
            },
            mode: {
                items: [
                    {
                        value: 'form',
                        label: 'Multiple-choice',
                        description: `Join survey as part of a traditional multiple-choice form`,
                        isDisabled: false,
                        Icon: Icons.Reports.Submission,
                    },
                    {
                        value: 'interview',
                        label: 'AI Interview',
                        description: `Join survey by conducting an AI-driven interview`,
                        isDisabled: false,
                        Icon: Icons.Reports.Study,
                    },
                ],
                value: state.mode,
                onChange(value) {
                    setState({ mode: value });
                },
            },
        });
        return <View {...props}>{children}</View>;
    };
}
