import { ContributorShellLoaderBaseConfig } from '../../../base';
import { ContributorShellContextLoader } from './contributorContextInterface';

export function createContributorContextLoader(
    config: ContributorShellLoaderBaseConfig
): ContributorShellContextLoader {
    return {
        useLoad(props) {
            return {
                asset: null,
            };
        },
    };
}
