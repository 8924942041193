import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { RewardRootController } from './rewardRootInterface';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import { RewardControllerBaseConfig } from '../../base';

export function createRewardRootController(
    config: RewardControllerBaseConfig
): RewardRootController {
    const {
        kernel: {
            controller,
            adapter: { help },
        },
    } = config;
    return {
        useProps(context, item, props) {
            const browser = controller.browser.useProps({});
            const navigation = controller.navigation.useProps(props.navigation);
            return {
                browser,
            };
        },
    };
}
