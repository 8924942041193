import React from 'react';
import { ContributorErrorController } from './contributorErrorInterface';
import { ContributorErrorContainerProps } from './contributorErrorProps';
import { ContributorShellContainerBaseConfig } from '../../base';

export function createContributorErrorContainer(
    config: ContributorShellContainerBaseConfig,
    controller: ContributorErrorController
): React.FC<{ children?: React.ReactNode | undefined } & ContributorErrorContainerProps> {
    return ({ as: View, children, ...containerProps }) => {
        const props = controller.useProps(containerProps);
        return <View {...props} />;
    };
}
