import { z } from 'zod';

export const ThreadDetailUrlParamSchema = z.object({
    mode: z.enum(['text', 'voice']).nullable().default(null),
    token: z.string().nullable().default(null),
    return_path: z.string().nullable().default(null),
});

export const ThreadDetailOptionSchema = ThreadDetailUrlParamSchema.extend({
    threadId: z.string(),
});
