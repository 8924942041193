import { useQueryClient } from '@tanstack/react-query';
import { AssistantSdk } from '@varos/assistant-sdk';
import { Kernel } from '../../../base';
import { InspectionRepository } from './assistantInspectionInterface';

export function createInspectionRepository(
    kernel: Kernel,
    api: AssistantSdk
): InspectionRepository {
    const PREFIX = ['conversations', 'inspections'];

    return {
        useLookup(context, props, options) {
            const query = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, props],
                async queryFn() {
                    const response = await api.inspectionCreate({
                        threadCreatePayload: props,
                    });
                    return response.data;
                },
            });
            return query;
        },
    };
}
