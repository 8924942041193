import {
    Divider,
    BreadcrumbLink,
    Breadcrumb,
    Heading,
    HStack,
    Text,
    VStack,
    Wrap,
    BreadcrumbItem,
    Image,
    Box,
    StackDivider,
    Tag,
    Link as ChakraLink,
    Icon,
} from '@chakra-ui/react';
import ReactMarkdown, { Components } from 'react-markdown';
import { Graphics } from '../../../../../config/svg';
import { VAROS_YELLOW } from '../../.././../../config';
import { ReportingViewBaseConfig } from '../../../base';
import { ContentOverlayViewProps } from './contentOverlayProps';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import React from 'react';

export function createContentOverlayView(
    config: ReportingViewBaseConfig
): React.FC<ContentOverlayViewProps> {
    const {
        UI: {
            Application: { Anchor },
        },
    } = config;

    const markdownComponents: Components = {
        h1: (props) => (
            <Heading
                as="h1"
                color="whiteAlpha.900"
                fontWeight="semibold"
                fontSize="xl"
                mt={8}
                mb={2}
                {...props}
            />
        ),
        h2: (props) => (
            <Heading
                as="h2"
                color="whiteAlpha.900"
                fontWeight="semibold"
                fontSize="xl"
                mt={8}
                mb={2}
                {...props}
            />
        ),
        h3: (props) => (
            <Heading
                as="h3"
                color="whiteAlpha.900"
                fontWeight="semibold"
                fontSize="lg"
                mt={6}
                my={2}
                {...props}
            />
        ),
        p: (props) => {
            const { children } = props;
            return (
                <Text m={0} color="whiteAlpha.700" {...props}>
                    {children}
                </Text>
            );
        },
        a: (props) => {
            const { children, href } = props;
            return (
                <ChakraLink href={href} color="blue.300" target="_blank" {...props}>
                    {children}
                </ChakraLink>
            );
        },
        blockquote: (props) => {
            const { children } = props;
            console.log('props', props);
            return (
                <Box
                    as="blockquote"
                    bg="whiteAlpha.50"
                    borderRadius="md"
                    mt={4}
                    p={4}
                    {...props}
                >
                    <Text fontStyle="italic" color="whiteAlpha.800" fontSize="lg">
                        <Icon
                            as={RiDoubleQuotesL}
                            display="inline"
                            fontSize="2xl"
                            color={VAROS_YELLOW[100]}
                            mr={2}
                        />
                        {props.node?.children.flatMap((node, index) => {
                            if (node.type === 'element') {
                                return node.children.map((child, innerIndex) => {
                                    if (child.type === 'text') {
                                        return (
                                            <React.Fragment key={innerIndex}>
                                                {child.value}
                                            </React.Fragment>
                                        );
                                    }
                                    return null;
                                });
                            }
                            return [];
                        })}
                        {/* <Icon
                            ml={2}
                            as={RiDoubleQuotesR}
                            display="inline"
                            fontSize="2xl"
                            color={VAROS_YELLOW[100]}
                        /> */}
                    </Text>
                </Box>
            );
        },
    };

    return (props) => {
        return (
            <HStack
                w="full"
                py={{ base: 8, lg: 16 }}
                pb={{ base: 16, lg: 32 }}
                justify="center"
            >
                <VStack maxW="48rem" w="full" align="start" spacing={8}>
                    <Breadcrumb spacing={4}>
                        {props.breadcrumb.items.map((item) => (
                            <BreadcrumbItem
                                key={item.id}
                                isCurrentPage={item.isActive}
                                color="whiteAlpha.400"
                            >
                                <BreadcrumbLink
                                    maxW="24rem"
                                    isTruncated={true}
                                    aria-selected={item.isActive}
                                    _selected={{
                                        color: 'whiteAlpha.600',
                                        cursor: 'default',
                                        _hover: {
                                            color: 'whiteAlpha.600',
                                            cursor: 'default',
                                        },
                                    }}
                                >
                                    {item.label}
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        ))}
                    </Breadcrumb>
                    <Image src={Graphics.Brand.WhiteText} h={8} w="auto" />
                    <Heading fontSize="4xl" fontWeight="semibold">
                        {props.source.title}
                    </Heading>
                    <HStack
                        spacing={3}
                        color="whiteAlpha.600"
                        divider={<Box border="none">&#x2022;</Box>}
                    >
                        <Text>Apr 3, 2025 at 12:00 GMT+3</Text>
                        <Text>Susie</Text>
                        <Text>Less than 5 min read</Text>
                    </HStack>
                    <Wrap shouldWrapChildren={true} spacing={3}>
                        <Tag size="lg" borderRadius="sm" colorScheme="blue">
                            Case study
                        </Tag>
                    </Wrap>
                    <Box
                        lineHeight={1.6}
                        sx={{
                            '& > *:first-child:is(h1, h2, h3, h4, h5, h6)': {
                                marginTop: 0,
                            },
                        }}
                    >
                        <ReactMarkdown
                            components={markdownComponents}
                            children={props.source.markdown}
                            skipHtml={true}
                        />
                    </Box>
                </VStack>
            </HStack>
        );
    };
}
