import { useQueryClient } from '@tanstack/react-query';
import { RewardSdk } from '@varos/reward-sdk';
import { Kernel } from '../../../base';
import { EligibilityRepository } from './eligibilityOfferInterface';
import { EligibilityLookupProps } from './eligibilityOfferProps';

export function createEligibilityRepository(
    kernel: Kernel,
    api: RewardSdk
): EligibilityRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['reward', 'eligibility'];
    function getLookupKey(props: EligibilityLookupProps) {
        return [...PREFIX, 'lookup', props];
    }
    return {
        useLookup(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: getLookupKey(props),
                async queryFn() {
                    const response = await api.contributor.eligibilityCreate({
                        eligibilityCreateRequest: {
                            offer: props.offer.id,
                        },
                    });
                    return response.data;
                },
            });
            return query;
        },
    };
}
