import { createContext } from 'react';

/**
 * Context type for router state and navigation
 */
export interface RouterContextType {
    currentPath: string;
    navigate: (path: string | null, replace?: boolean) => void;
}

/**
 * Creates a new router context
 */
export const createRouterContext = () => {
    return createContext<RouterContextType>({
        currentPath: '',
        navigate: () => {},
    });
};
