import { ButtonProps } from '@chakra-ui/react';
import { ReportingShellControllerBaseConfig } from '../../../base';
import { ShortcutItem, ShortcutItemViewProps } from '../item';
import { ShortcutListController } from './shortcutListInterface';

export function createShortcutListController(
    config: ReportingShellControllerBaseConfig
): ShortcutListController {
    function getButtonProps(props: ShortcutItem): ButtonProps {
        if (props.variant === 'primary') {
            return {
                colorScheme: 'blue',
                variant: 'solid',
            };
        }
        return {};
    }
    return {
        useProps(context, props) {
            return {
                items: props.items.map(
                    (item): ShortcutItemViewProps => ({
                        id: item.id,
                        label: item.label,
                        button: getButtonProps(item),
                        Icon: item.Icon,
                        action: {
                            kind: 'link',
                            link: {
                                id: `${item.id}_shortcut_link`,
                                to: item.path,
                            },
                        },
                    })
                ),
            };
        },
    };
}
