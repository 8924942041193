import { z } from 'zod';

export const OrganizationTypeSchema = z.union([
    z.literal('mobile_app'),
    z.literal('ecommerce_website'),
    z.literal('saas'),
    z.literal('website'),
    z.literal('marketplace'),
    z.literal('ad_agency'),
]);

export const OrganizationRoleSchema = z.union([
    z.literal('admin'),
    z.literal('standard'),
    z.literal('viewer'),
    z.literal('owner'),
]);

export const AccountProfileSchema = z.object({
    type: z.enum(['contributor']).nullable().default(null),
    industry: z.string().nullable(),
    tags: z.record(z.string(), z.string()),
});

export const MeUserSchema = z.object({
    id: z.number(),
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    email: z.string(),
    title: z.nullable(z.string()),
    created_at: z.date({ coerce: true }),
    memberships: z.array(
        z.object({
            role: OrganizationRoleSchema,
            last_login: z.nullable(z.date({ coerce: true })),
            profile: z.nullable(
                z.object({
                    title: z.string(),
                })
            ),
            account_id: z.string(),
        })
    ),
    personal_profile: z.nullable(
        z.object({
            work_experiences: z.nullable(
                z.array(
                    z.object({
                        company_name: z.nullable(z.string()),
                        company_domain: z.nullable(z.string()),
                        job_title: z.nullable(z.string()),
                        company_type: z.nullable(z.string()),
                        currently_working: z.boolean(),
                        updated_at: z.date({ coerce: true }),
                    })
                )
            ),
            tags: z.nullable(z.record(z.string())),
        })
    ),
});

export const MeDtoSchema = z.object({
    user: MeUserSchema,
    current_account: z
        .discriminatedUnion('kind', [
            z.object({
                kind: z.literal('organization'),
                id: z.string(),
                finished_on_boarding: z.boolean(),
                name: z.string(),
                domain: z.string(),
                organization_id: z.number(),
                profile: AccountProfileSchema,
            }),
            z.object({
                kind: z.literal('user'),
                id: z.string(),
                finished_on_boarding: z.boolean(),
                profile: AccountProfileSchema,
            }),
        ])
        .nullable(),
    // on_boarding_states: [
    //     {
    //         state: 'req_integration',
    //         can_skip: false,
    //         subject_id: 21439,
    //     },
    //     {
    //         state: 'subscription',
    //         can_skip: false,
    //         subject_id: null,
    //     },
    //     {
    //         state: 'user_invites',
    //         can_skip: true,
    //         subject_id: 21439,
    //     },
    //     {
    //         state: 'pre_assigned_peer_groups',
    //         can_skip: false,
    //         subject_id: 21439,
    //     },
    // ],
});
