import { useQueryClient } from '@tanstack/react-query';
import { RewardSdk } from '@varos/reward-sdk';
import { ApiError, ApplicationContext, Kernel } from '../../../base';
import { ProfileRepository } from './profileInterface';
import { ProfileCreateProps } from './profileProps';

export function createProfileRepository(
    kernel: Kernel,
    api: RewardSdk
): ProfileRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['reward', 'profile'];

    function getQueryKey(context: ApplicationContext) {
        return [...PREFIX, context.principal?.account];
    }

    return {
        useLookup(context, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: getQueryKey(context),
                async queryFn() {
                    try {
                        const response = await api.contributor.profileRetrieve({});
                        return response.data;
                    } catch (error) {
                        if (error instanceof ApiError && error.statusCode === 404) {
                            return null;
                        }
                        console.error('error', error);
                        throw error;
                    }
                },
            });
            return query;
        },
        useCreate(context) {
            const client = useQueryClient();
            const mutation = repository.useMutation(context, {
                async mutationFn(props: ProfileCreateProps) {
                    const response =
                        props.kind === 'token'
                            ? await api.token.tokenProfileCreate({
                                  tokenAcceptRequest: props,
                              })
                            : await api.contributor.profileCreate({});
                    return response.data;
                },
                onSuccess(data) {
                    const queryKey = getQueryKey(context);
                    client.setQueryData(queryKey, data);
                    client.invalidateQueries(queryKey);
                },
            });
            return mutation;
        },
    };
}
