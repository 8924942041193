import React from 'react';
import { ReportingContainerBaseConfig } from '../../../base';
import { InstanceReportDetailParameterSchema } from './reportDetailSchema';

import {
    ReportInstanceDetailController,
    ReportInstanceDetailLoader,
} from './reportDetailInterface';
import { ReportDetailContainerProps, ReportDetailViewProps } from './reportDetailProps';
import {
    Modal,
    ModalContent,
    ModalBody,
    HStack,
    IconButton,
    Icon,
    Text,
    ModalCloseButton,
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';

export function createReportDetailContainer(
    config: ReportingContainerBaseConfig,
    loader: ReportInstanceDetailLoader,
    controller: ReportInstanceDetailController,
    View: React.FC<ReportDetailViewProps>
): React.FC<ReportDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
        router: {
            report: {
                Component: { Root, Route },
            },
        },
    } = config;

    return ({ ...containerProps }) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const options = useOptions(InstanceReportDetailParameterSchema);
        const context = useContext();
        const data = loader.useLoad(context, {
            report: {
                id: options.reportId,
            },
        });
        const props = controller.useProps(context, data, {
            onOpen(source) {
                searchParams.set('source', source.id);
                setSearchParams(searchParams, { replace: true });
            },
        });

        return <View {...props} />;
    };
}
