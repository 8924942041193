import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReportingContainerBaseConfig } from '../../base';
import { ReportChapterLoader, ReportChapterController } from './reportChapterInterface';
import {
    ReportChapterContainerProps,
    ReportChapterViewProps,
} from './reportChapterProps';
import { ReportChapterParameterSchema } from './reportChapterSchema';

export function createReportChapterContainer(
    config: ReportingContainerBaseConfig,
    loader: ReportChapterLoader,
    controller: ReportChapterController,
    View: React.FC<ReportChapterViewProps>
): React.FC<ReportChapterContainerProps> {
    const {
        context: { useContext },
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    return ({ ...containerProps }) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const context = useContext();
        const options = useOptions(ReportChapterParameterSchema);
        const data = loader.useLoad(context, {
            report: {
                id: options.reportId,
            },
            chapter: {
                id: options.chapterId,
            },
        });
        const props = controller.useProps(context, data, {
            onOpen(source) {
                searchParams.set('source', source.id);
                setSearchParams(searchParams, { replace: true });
            },
        });
        return <View {...props} />;
    };
}
