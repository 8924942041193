import { assertNever } from '@varos/util-typescript';
import { BoxProps, Button, HStack, Icon, Select, Text, VStack } from '@chakra-ui/react';
import { AnyBlock, AnyElement, AnyInputBlock } from '../../../../../../app/assistant';
import {
    ButtonElementContainerProps,
    ButtonElementViewProps,
    VisualizationBlockContainerProps,
    VisualizationBlockViewProps,
} from '../../../../view';
import { AssistantViewBaseConfig } from '../../../../base';
import {
    ActionBlock,
    AudioInputBlock,
    EventBlock,
    SectionBlock,
    SelectBlock,
    TextBlock,
} from '@varos/assistant-sdk';
import { useSearchParams } from 'react-router-dom';
import { AiOutlineLink, AiOutlineWarning } from 'react-icons/ai';
import { capitalize } from 'lodash';

export function createBlockView(
    config: AssistantViewBaseConfig & {
        style: {
            container: {
                maxW: BoxProps['maxW'];
            };
        };
    },
    ElementUI: {
        Button: React.FC<
            { children?: React.ReactNode | undefined } & ButtonElementContainerProps
        >;
        Visualization: React.FC<
            { children?: React.ReactNode | undefined } & VisualizationBlockContainerProps
        >;
    }
) {
    const {
        UI: {
            Application: { Link },
            // Visualization: { BarChartV2: BarChart },
        },
        style: { container: containerStyle },
    } = config;
    const { Button: ButtonElementContainer, Visualization: VisualizationBlockContainer } =
        ElementUI;

    const ButtonElement: React.FC<
        { children?: React.ReactNode | undefined } & ButtonElementViewProps
    > = (props) => {
        return (
            <Button {...props.button} _focus={{ outline: 'none' }}>
                {props.label}
            </Button>
        );
    };

    const Element: React.FC<
        { children?: React.ReactNode | undefined } & { data: AnyElement }
    > = (props) => {
        if (props.data.kind === 'button') {
            return <ButtonElementContainer as={ButtonElement} element={props.data} />;
        }
        assertNever(props.data.kind);
    };

    // const TextAttachment: React.FC<AttachmentReferenceObject> = (props) => {
    //     const [searchParams] = useSearchParams();
    //     const assetId = searchParams.get('asset');
    //     if (props.type === 'submission') {
    //         return (
    //             // TODO generalize and abstract out the link generation
    //             <Link
    //                 to={`/u/assets/${assetId}/reporting/surveys/submissions/${props.id}`}
    //             >
    //                 <HStack
    //                     py={2}
    //                     px={4}
    //                     cursor="pointer"
    //                     borderWidth={2}
    //                     borderColor="whiteAlpha.300"
    //                     borderRadius="full"
    //                     fontWeight="medium"
    //                     _hover={{ textDecor: 'underline' }}
    //                 >
    //                     <Icon as={AiOutlineLink} />
    //                     <Text>{props.id}</Text>
    //                 </HStack>
    //             </Link>
    //         );
    //     }
    //     assertNever(props.type);
    // };

    const TextBlock: React.FC<{ children?: React.ReactNode | undefined } & TextBlock> = (
        props
    ) => {
        return (
            <VStack
                align="start"
                w="full"
                spacing={4}
                maxW={containerStyle.maxW}
                // fontWeight={props.modifiers?.includes('bold') ? 'semibold' : 'normal'}
                // fontStyle={props.modifiers?.includes('italic') ? 'italic' : undefined}
            >
                <Text
                // color={
                //     props.modifiers?.includes('bold') ? undefined : 'whiteAlpha.800'
                // }
                >
                    {props.text}
                </Text>
                {/* {props.attachments && (
                    <HStack w="full" spacing={3}>
                        {props.attachments.map((attachment, index) => (
                            <TextAttachment key={attachment.id} {...attachment} />
                        ))}
                    </HStack>
                )} */}
            </VStack>
        );
    };

    const AudioInputBlock: React.FC<AudioInputBlock> = (props) => {
        return (
            <HStack>
                <Text>Audio input</Text>
            </HStack>
        );
    };

    // const JsonBlock: React.FC<{children?: React.ReactNode | undefined } & JsonBlock> = (props) => {
    //     return (
    //         <VStack align="start" w="full" spacing={1} maxW={containerStyle.maxW}>
    //             <Text color="whiteAlpha.800">{JSON.stringify(props.data, null, 2)}</Text>
    //         </VStack>
    //     );
    // };

    const SelectBlock: React.FC<
        { children?: React.ReactNode | undefined } & SelectBlock
    > = (props) => {
        return (
            <HStack>
                <Select placeholder={props.placeholder ?? 'Select'}>
                    {props.options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
            </HStack>
        );
    };

    const ActionBlock: React.FC<
        { children?: React.ReactNode | undefined } & ActionBlock
    > = (props) => {
        return (
            <HStack>
                {props.elements.map((element, index) => (
                    <Element key={index} data={element} />
                ))}
            </HStack>
        );
    };

    const SectionBlock: React.FC<
        { children?: React.ReactNode | undefined } & SectionBlock
    > = (props) => {
        return (
            <VStack align="start" w="full" spacing={3} maxW={containerStyle.maxW}>
                {props.text && <Text color="whiteAlpha.800">{props.text}</Text>}
                <VStack
                    align="start"
                    w="full"
                    px={6}
                    py={6}
                    borderWidth={2}
                    borderColor="whiteAlpha.300"
                    borderRadius="lg"
                    spacing={3}
                >
                    {props.fields.map((field, index) => (
                        <VStack key={index} align="start" w="full" spacing={0}>
                            <Text fontWeight="medium" color="whiteAlpha.600">
                                {field.label}
                            </Text>
                            <Text fontWeight="semibold" color="white">
                                {field.value}
                            </Text>
                        </VStack>
                    ))}
                </VStack>
            </VStack>
        );
    };

    const InputBlock: React.FC<
        { children?: React.ReactNode | undefined } & { data: AnyInputBlock }
    > = (props) => {
        if (props.data.type === 'select') {
            return <SelectBlock {...props.data} />;
        }
        assertNever(props.data.type);
    };

    const EventBlock: React.FC<
        { children?: React.ReactNode | undefined } & EventBlock
    > = (props) => {
        return (
            <HStack
                color="whiteAlpha.600"
                fontWeight="medium"
                userSelect="none"
                fontStyle="italic"
                // bg="whiteAlpha.50"
                // borderColor="whiteAlpha.300"
                // borderWidth={2}
                px={6}
                py={4}
                borderRadius="lg"
            >
                <Text>{capitalize(props.data.kind)} submitted</Text>
            </HStack>
        );
    };

    // const VisualizationBlock: React.FC<
    //     { children?: React.ReactNode | undefined } & VisualizationBlockViewProps
    // > = (props) => {
    //     return (
    //         <HStack
    //             w="full"
    //             py={12}
    //             // borderWidth={2}
    //             // borderStyle="solid"
    //             // borderColor="whiteAlpha.300"
    //             // borderRadius="lg"
    //         >
    //             <BarChart.Container
    //                 labels={['a', 'b', 'c', 'd', 'e', 'f']}
    //                 formatCategory={(value) => value}
    //                 formatScale={(value) => value.toString()}
    //                 formatTooltip={(value) => value.toString()}
    //                 formatValue={(value) => value.toString()}
    //                 series={[
    //                     {
    //                         name: 'series 1',
    //                         colorScheme: 'red',
    //                         data: [
    //                             { value: 3 },
    //                             { value: 2.5 },
    //                             { value: 2 },
    //                             { value: 2 },
    //                             { value: 1.4 },
    //                             { value: 1 },
    //                         ],
    //                     },
    //                 ]}
    //             >
    //                 <BarChart.Item bg="red.300" />
    //                 <BarChart.Scale label="CPM" />
    //             </BarChart.Container>
    //         </HStack>
    //     );
    // };

    const Block: React.FC<
        { children?: React.ReactNode | undefined } & { data: AnyBlock }
    > = (props) => {
        if (props.data.kind === 'text') {
            return <TextBlock {...props.data} />;
        }
        if (props.data.kind === 'audio_input') {
            return <AudioInputBlock {...props.data} />;
        }
        if (props.data.kind === 'input') {
            return <InputBlock data={props.data} />;
        }
        if (props.data.kind === 'section') {
            return <SectionBlock {...props.data} />;
        }
        if (props.data.kind === 'action') {
            return <ActionBlock {...props.data} />;
        }
        // if (props.data.kind === 'custom') {
        //     return <Text>custom block</Text>;
        // }
        if (props.data.kind === 'event') {
            return <EventBlock {...props.data} />;
        }
        if (props.data.kind === 'unknown') {
            return (
                <HStack userSelect="none" color="red.400" spacing={2}>
                    <Icon as={AiOutlineWarning} color="red.400" />
                    <Text>Unsupported block</Text>
                </HStack>
            );
        }
        if (props.data.kind === 'custom') {
            if (props.data.custom.type === 'visualization') {
                throw new Error('not impl');
                // return (
                //     <VisualizationBlockContainer
                //         block={props.data.custom}
                //         as={VisualizationBlock}
                //     />
                // );
            }
            assertNever(props.data.custom.type);
        }
        assertNever(props.data);
    };

    return Block;
}
