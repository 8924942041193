import { IntercomClient } from '../../vendor/intercom';
import { HelpAdapter } from '../../base';
import { IntercomHelpImplConfig } from './intercomHelpConfig';

export function createIntercomHelpImpl(config: IntercomHelpImplConfig): HelpAdapter {
    const actionQueue: Array<(client: IntercomClient) => void> = [];
    let isCheckingForClient = false;

    function log(...messages: any[]) {
        console.info('[Intercom]', ...messages);
    }

    function runOrQueueAction(action: (client: IntercomClient) => void) {
        const client = config.getClient();

        if (client) {
            action(client);
        } else {
            actionQueue.push(action);
            log('Client not available; queuing action');
            ensureClientCheck();
        }
    }

    function ensureClientCheck() {
        if (isCheckingForClient) {
            return;
        }
        isCheckingForClient = true;

        const checkInterval = setInterval(() => {
            const client = config.getClient();
            if (client) {
                log('Client loaded, flushing queue');
                clearInterval(checkInterval);
                isCheckingForClient = false;
                flushQueue(client);
            }
        }, 500); // Check every 500ms (adjust as needed)
    }

    function flushQueue(client: IntercomClient) {
        while (actionQueue.length > 0) {
            const nextAction = actionQueue.shift();
            if (nextAction) {
                nextAction(client);
            }
        }
    }

    return {
        open() {
            log('Opening widget');
            runOrQueueAction((client) => client('show'));
        },

        show() {
            log('Showing launcher');
            runOrQueueAction((client) =>
                client('update', { hide_default_launcher: false })
            );
        },

        hide() {
            log('Hiding launcher');
            runOrQueueAction((client) =>
                client('update', { hide_default_launcher: true })
            );
        },
    };
}
