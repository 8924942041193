import { ContributorShellConfig, ContributorShellInit } from './contributorShellConfig';
import { createContributorRootContainer } from './page/root/contributorRootContainer';
import { createContributorRootController } from './page/root/contributorRootController';
import { createContributorRootLoader } from './page/root/contributorRootLoader';
import {
    ContributorShellContainerBaseConfig,
    ContributorShellControllerBaseConfig,
    ContributorShellLoaderBaseConfig,
} from './base';
import {
    createContributorAccountController,
    createContributorNavigationController,
} from './view';
import {
    ContributorShellComponents,
    ContributorShellEnhancer,
} from './contributorShellInterface';
import {
    ContributorShellContext,
    createContributorContextController,
    createContributorContextLoader,
    createContributorErrorContainer,
    createContributorErrorController,
    createContributorShellContextContainer,
    createContributorShellContextProvider,
} from './page';
import React from 'react';

export function createContributorShell(
    config: ContributorShellInit,
    enhancer?: ContributorShellEnhancer
) {
    return create(
        {
            ...config,
            provider: {
                createRootController: createContributorRootController,
                createNavigationController: createContributorNavigationController,
                createAccountController: createContributorAccountController,
            },
        },
        enhancer
    );
}

function create(
    config: ContributorShellConfig,
    enhancer?: ContributorShellEnhancer
): ContributorShellComponents {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const Context = React.createContext<ContributorShellContext | null>(null);

    const loaderConfig: ContributorShellLoaderBaseConfig = {
        kernel: config.kernel,
        repository: config.repository,
    };

    const controllerConfig: ContributorShellControllerBaseConfig = {
        kernel: config.kernel,
        view: config.view,
        hook: config.hook,
    };

    const context = createContributorShellContextProvider(
        createContributorShellContextContainer(
            config,
            Context,
            createContributorContextLoader(loaderConfig),
            createContributorContextController(controllerConfig)
        ),
        Context
    );

    const containerConfig: ContributorShellContainerBaseConfig = {
        basePath: config.basePath,
        kernel: config.kernel,
        context,
        Theme: config.Theme,
        view: config.view,
        UI: config.UI,
        repository: config.repository,
    };

    const loader = createContributorRootLoader(loaderConfig);
    const controller = config.provider.createRootController({
        ...config,
        controller: {
            navigation: config.provider.createNavigationController(controllerConfig),
            account: config.provider.createAccountController(controllerConfig),
        },
    });

    const Shell = createContributorRootContainer(
        {
            ...containerConfig,
            Container: {
                Error: createContributorErrorContainer(
                    containerConfig,
                    createContributorErrorController()
                ),
            },
        },
        loader,
        controller
    );

    const { Provider: ContextProvider } = context;
    return {
        Root(props) {
            return (
                <ContextProvider>
                    <Shell {...props} />
                </ContextProvider>
            );
        },
    };
}
