export const VAROS_GREEN = {
    200: '#02C380',
};

export const VAROS_YELLOW = {
    50: '#ddf07c', // darkened to be less brigh
    100: '#cdf052', // darkened to be less brigh
    200: '#c6e74f', // provided base
    300: '#b8e044', // a bit darker than 200
    400: '#aacb39',
    500: '#9cb02e',
    600: '#8d9624',
    700: '#7f7c1a',
    800: '#716110',
    900: '#635606', // darkest shade
};

export const GRAY = {
    500: '#46454a',
    600: '#2d2d2e',
    700: '#1f1f1f',
    800: '#161518',
    900: '#151515',
};
