import { ReportingLoaderBaseConfig } from '../../../base';
import { ReportInstanceDetailLoader } from './reportDetailInterface';
import * as fixture from '../../../fixture';

export function createReportDetailLoader(
    config: ReportingLoaderBaseConfig
): ReportInstanceDetailLoader {
    const CANDIDATES = [
        {
            report: fixture.MaintenanceReport.report,
            author: fixture.MaintenanceReport.author,
            content: fixture.MaintenanceReport.content,
        },
        {
            report: fixture.AutomatingKYC.report,
            author: fixture.AutomatingKYC.author,
            content: fixture.AutomatingKYC.content,
        },
    ];
    return {
        useLoad(context, props) {
            const found = CANDIDATES.find((item) => item.report.id === props.report.id);
            if (!found) {
                throw new Error(`Report with id ${props.report.id} not found`);
            }
            return found;
        },
    };
}
