import { capitalize } from 'lodash';
import { ReportingShellControllerBaseConfig } from '../../base';
import { ReportingAccountController } from './reportingAccountInterface';
import { ReportingAccountViewProps } from './reportingAccountProps';

export function createReportingAccountController(
    config: ReportingShellControllerBaseConfig
): ReportingAccountController {
    const {
        kernel: { controller },
    } = config;

    const logoutController = config.kernel.provider.createActionController({
        id: 'logout',
    });

    return {
        useProps(context, item, props): ReportingAccountViewProps {
            const browser = controller.browser.useProps({});
            const logout = logoutController.useProps(props.action.logout);
            return {
                browser,
                logout,
                actions: {
                    items: props.actions.map((item) => ({
                        ...item,
                        link: { to: item.path },
                    })),
                },
                account: {
                    label: item.me.firstName
                        ? [item.me.firstName, item.me.lastName].filter(Boolean).join(' ')
                        : item.me.email,
                    role: capitalize(item.me.account.role),
                },
                drawer: {
                    isOpen: props.disclosure.isOpen,
                    onOpen: props.disclosure.onOpen,
                    onClose: props.disclosure.onClose,
                },
            };
        },
    };
}
